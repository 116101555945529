.form-radio {
    display: flex;
    align-items: center;
}

    .form-radio__input {
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid #B2BEC3;
        position: relative;
    }

    .form-radio__input-field:checked + .form-radio__input::after {
        position: absolute;
        content: '';
        display: block;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        top: 2px;
        left: 2px;
        background-color: #fa583a;
    }

    .form-radio__input-label {
        color: #636E72;
        display: block;
        margin-left: 8px;
    }