@media only screen and (max-width: 430px) {
    .checkout-popup {
        width: 100%;
        height: 100%;
    }

        .checkout-popup__left {
            display: none;
        }

        .checkout-popup__right__main-content {
            overflow-y: auto;
        }

    .workflow-chooser-box__list {
        flex-direction: column;
    }

        .workflow-chooser-box {
            width: 100%;
            height: 85px;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;
        }

            .workflow-chooser-box__icon {
                margin: 0 auto 0 30px;
            }

            .workflow-chooser-box__label {
                margin-right: 30px;
            }

    .workflow-step__personal-info {
        flex-direction: column-reverse;
    }

        .workflow-step__info-container {
            max-width: 100%;
        }

    .workflow-step__delivery-location {
        flex-direction: column-reverse;
    }

    .workflow-step__delivery-datetime {
        flex-direction: column-reverse;
    }

    .workflow-step__reciever-info {
        flex-direction: column-reverse;
    }
}