html, body {
	margin: 0px;
	width: 100%;
	height: 100%;
	background: #F5F6FA;
	font-family: 'Alegreya Sans', sans-serif;
	font-display: swap;
	position: relative;
	overflow: unset !important;
	-webkit-text-size-adjust: none;
	counter-reset: step-counter;
	scroll-behavior: smooth;
}

#safari-home-screen {
	display: none;
}

_::-webkit-full-page-media, _:future, :root #safari-home-screen {
	display: block;
	position: fixed;
	bottom: 0;
	height: 60px;
	background: #fff;
	z-index: 9999;
}

/* 
	RESETERS AND STANDARDIZATIONS
*/

span {
	touch-action: manipulation;
}
ol, ul {
	margin: 0px;
	padding: 0px;
	list-style: none;
	outline: none !important;
	touch-action: manipulation;
}
li {
	outline: none !important;
	touch-action: manipulation;
}
a{
	text-decoration: none;
	color: inherit;
	outline: none !important;
	touch-action: manipulation;
}
h1, h2, h3, h4, h5, h6 {
	margin: 0px;
	outline: none !important;
	touch-action: manipulation;
}
div, input, textarea, button, select {
	outline: none !important;
	touch-action: manipulation;
}
input, textarea, button {
	font-family: 'Alegreya Sans';
	-webkit-appearance: none;
	outline: none !important;
	touch-action: manipulation;
}

/* select:focus {
	box-shadow: 0px 0px 5px #FA583A;
} */

button {
	cursor: pointer;
	border: 0px;
	background: transparent;
	-webkit-appearance:button;
	appearance: button;
	touch-action: manipulation;
}
input[type=button]{
	-webkit-appearance: button;
	touch-action: manipulation;
}

#root, #app {
	width: 100%;
	height: 100%;
}

.app-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 90px 0px 0px 0px;
	/* overflow: auto; */
}

.app__container {
	width: 100%;
	height: 100%;
}

.popup--opened {
	overflow: hidden;
}

.product__popup.popup--hide {
    visibility: hidden;
    opacity: 0;
}

.product__popup.popup--show {
    visibility: visible;
    opacity: 1;
}

.product__popup.popup--show .product__popup-container {
	transform: scale(1);
}

li, a, button, input, textarea, h1, h2, h3, h4, h5, h6, span, label, div, strong, b, p {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	
}

[draggable=true] {
	-khtml-user-drag: element;
	-webkit-user-drag: element;
}

/* 
	BUTTONS
*/

/* SAFARI BUTTON FIX */
button::before,
button::after {
    content: '';
	flex: 1 0 auto;
	touch-action: manipulation;
}
/* SAFARI BUTTON FIX */

.button--primary {
	background: #FA583A;
	border-radius: 3px;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: left;
	color: #fff;
	height: 38px;
	padding: 0px 17px;
	box-shadow: 2px 2px 3px 0px #00000040;
	transition: all 0.2s ease-in-out;
}
.button--primary:hover {
	background: #F9775F;
	box-shadow: 2px 2px 4px 1px #0000001f;
}
.button--primary:active {
	background: #DE4225;
}

.icon-button--naked {
	font-size: inherit;
	color: inherit;
	background: transparent;
	border: 0px;
	cursor: pointer;
	position: relative;
}

.icon-button--primary {
	font-size: 16px;
	background: #FA583A;
	color: #F5F6FA;
	border-radius: 3px;
	box-shadow: 2px 2px 3px 0px #00000040;
	border: 0px;
	box-sizing: border-box;
	padding: 10px 20px;
	cursor: pointer;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}
.icon-button--primary:hover {
	background: #F9775F;
	box-shadow: 2px 2px 4px 1px #0000001f;
}
.icon-button--primary:active {
	background: #DE4225;
}

.icon-button--secondary {
	font-size: 16px;
	background: #636E72;
	color: #F5F6FA;
	border-radius: 3px;
	box-shadow: 2px 2px 3px 0px #00000040;
	border: 0px;
	box-sizing: border-box;
	padding: 10px 20px;
	cursor: pointer;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	white-space: nowrap;
	transition: all 0.2s ease-in-out;
}
.icon-button--secondary:hover {
	background: #80898D;
	box-shadow: 2px 2px 4px 1px #0000001f;
}
.icon-button--secondary:active {
	background: #3D4A4E;
}

.small-button--primary {
    background: #FA583A;
    border-radius: 3px;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding: 0px 11px;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
	height: 28px;
	white-space: nowrap;
    line-height: 28px;
}
.small-button--primary:hover {
	background: #F9775F;
}
.small-button--primary:active {
	background: #DE4225;
}

.icon-button__textless--dark {
	width: 32px;
	height: 32px;
	background: #636E72;
	border-radius: 3px;
	box-shadow: 2px 2px 3px 0px #00000040;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	transition: all 0.2s ease-in-out;
	text-align: center;
}
.icon-button__textless--dark:hover {
	background: #818A8D;
	box-shadow: 2px 2px 4px 1px #0000001f;
}
.icon-button__textless--dark:active {
	background: #40494D;
}

.icon-button__textless--red {
	width: 32px;
	height: 32px;
	background: #FA583A;
	border-radius: 3px;
	box-shadow: 2px 2px 3px 0px #00000040;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	transition: all 0.2s ease-in-out;
	text-align: center;
}

.icon-button__textless--red:hover {
	background: #F9775F;
	box-shadow: 2px 2px 4px 1px #0000001f;
}
.icon-button__textless--red:active {
	background: #DE4225;
}

.small-button--rounded {
	width: 36px;
	height: 36px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #FA583A;
	white-space: nowrap;
	border: 3px solid #fff;
}

.small-button--rounded {
	width: 36px;
	height: 36px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #FA583A;
	border: 3px solid #fff;
	box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.15);
	white-space: nowrap;
	transition: all 0.2s ease-in-out;
}
.small-button--rounded:hover {
	background: #F9775F;
	box-shadow: 2px 2px 4px 1px #0000001f;
}
.small-button--rounded:active {
	background: #DE4225;   
}

.full-width__button--primary {
	width: 100%;
	font-size: 20px;
	background: #FA583A;
	color: #F5F6FA;
	border-radius: 3px;
	box-shadow: 2px 2px 3px 0px #00000040;
	border: 0px;
	box-sizing: border-box;
	height: 40px;
	cursor: pointer;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	text-align: center;
	white-space: nowrap;
	transition: all 0.2s ease-in-out;
}
.full-width__button--primary:hover {
	background: #F9775F;
	box-shadow: 2px 2px 4px 1px #0000001f;
}
.full-width__button--primary:active {
	background: #DE4225;
}

.full-width__button--primary:disabled  {
	background: #DFE6E9;
	color: #B2BEC3;
	box-shadow: none;
	cursor: not-allowed;
}

.icon-button__textless--dark:disabled {
	background: #DFE6E9;
	color: #B2BEC3;
	box-shadow: none;
	cursor: not-allowed;
}

	.icon-button__textless--dark:disabled span{
		cursor: not-allowed;
}

.full-width__button--ghost-primary {
	width: 100%;
	font-size: 20px;
	background: transparent;
	border: 2px solid #FA583A;
	color: #FA583A;
	border-radius: 3px;
	box-sizing: border-box;
	height: 40px;
	cursor: pointer;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}
.full-width__button--ghost-primary:hover {
	border: 2px solid #F9775F;
}
.full-width__button--ghost-primary:active {
	border: 2px solid #DE4225;
}

.button__ghost--primary {
	font-size: 20px;
	background: transparent;
	border: 1px solid #FA583A;
	/* border: 1px solid #AC1D07; */
	color: #FA583A;
	/* color: #AC1D07; */
	border-radius: 3px;
	box-sizing: border-box;
	height: 40px;
	cursor: pointer;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}
.button__ghost--primary:hover {
	border-color: #F9775F;
}
.button__ghost--primary:active {
	border-color: #DE4225;
}

.button__ghost--borderless--primary {
	font-size: 20px;
	background: transparent;
	border: 0px;
	color: #FA583A;
	border-radius: 3px;
	box-sizing: border-box;
	height: 40px;
	line-height: 40px;
	cursor: pointer;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}
.button__ghost--borderless--primary:hover {
	background: #fa583a21;
}
.button__ghost--borderless--primary:active {
	background: #fa583a3b;
}

.icon-button--primary:disabled, .icon-button--secondary:disabled  {
	background: #DFE6E9;
	color: #B2BEC3;
	box-shadow: none;
	cursor: not-allowed;
}
.icon-button--primary:disabled span, .icon-button--secondary:disabled span{
	color: #B2BEC3;
}

.icon-button--primary:disabled .icon-button--naked, .icon-button--secondary:disabled .icon-button--naked {
	background: #B2BEC3;
}

.icon-button--primary:disabled:hover, .icon-button--secondary:disabled:hover {
	box-shadow: none;
}

.button--primary:disabled {
	background: #DFE6E9;
	color: #B2BEC3;
	box-shadow: none;
	cursor: not-allowed;
}

/* 
	RAFT ILLUSTRATIONS AS SVG
*/

.a{ fill:#b2bec3; width: 100%; height: 20px; }
.b{ opacity:0.502; }
.c{ clip-path:url(#a) }
.d{ opacity:0.217; }
.e,.f,.i{ fill:none; }
.e{ stroke:#636e72; }
.f{ stroke:#707070; }
.g{ fill:#dfe6e9; opacity:0.8; }
.h{ stroke:none; }
.a_02{ opacity:0.12; fill:url(#a_02); }
.cls-1 { fill: #e3f2f8; }

/* 
	HIDING MOBILE COMPONENTS
*/

.mobile__more-button,
.chc--mobile,
.saved-cards--mobile,
.chc--mobile,
.short__detail-info.collapsible__header--content.chc--mobile,
.popup__buttons--mobile,
.category-src.tabs.mobile
{
	display: none;
}

#product--web {
	z-index: 9999;
}

.popup__buttons--mobile {
	display: none !important;
}

.payment-item--mobile, .datepicker--mobile {
	display: none !important;
}

.mobile--alignment {
    text-align: center !important;
}

/* 
	CALENDARS
*/

.DayPicker-Day.DayPicker-Day--outside:hover {
	background: transparent !important;
}

_::-webkit-full-page-media, _:future, :root .DayPicker-Day.DayPicker-Day--outside:hover {
	background: transparent !important;
}

.rc-collapse-content-inactive {
	display: none;
}

_::-webkit-full-page-media, _:future, :root .user-dropdown {
    top: 40px;
    margin: 0px 0px 0px -185px;  
}

_::-webkit-full-page-media, _:future, :root .notifications-dropdown {
    top: 70px;
    margin: 0px 0px 0px -321px;  
}

_::-webkit-full-page-media, _:future, :root .header {
    overflow: unset !important;  
}

_::-webkit-full-page-media, _:future, :root .DayPicker-Caption {
	margin-top: 0.8rem;
	margin-bottom: 0.8rem;
	color: #848484 !important;
}

_::-webkit-full-page-media, _:future, :root .DayPicker-Month {
	margin: 0px;
}

_::-webkit-full-page-media, _:future, :root .DayPicker-Day:hover {
	background: rgba(250, 88, 58, 0.09) !important;
	/* color: #FA583A !important; */
}

	_::-webkit-full-page-media, _:future, :root .DayPicker-Day {
		display: unset;
		padding: 0;
		height: 41px;
		width: calc(100% / 7);
		line-height: 39px;
	}

_::-webkit-full-page-media, _:future, :root .DayPicker-NavButton {
	right: 0.5rem;
}

_::-webkit-full-page-media, _:future, :root .DayPicker-Months {
	box-sizing: border-box;
	padding: 5px;
}

_::-webkit-full-page-media, _:future, :root .DayPicker-Caption > div {
	font-weight: 600;
	color: #848484 !important;
}

_::-webkit-full-page-media, _:future, :root .DayPicker-wrapper {
	padding-bottom: 0 !important;
}

_::-webkit-full-page-media, _:future, :root .DayPicker-wrapper .DayPicker-Weekdays {
	margin-top: 0rem;
	display: unset;
	width: 100%;
}

_::-webkit-full-page-media, _:future, :root .DayPicker-wrapper .DayPicker-WeekdaysRow {
	display: flex;
}

_::-webkit-full-page-media, _:future, :root .DayPicker-wrapper .DayPicker-Body {
	width: 100%;
	display: unset;
}

_::-webkit-full-page-media, _:future, :root .DayPicker-Week {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
}

_::-webkit-full-page-media, _:future, :root .DayPicker-Weekday {
	font-size: 0.875em;
	text-align: center;
	color: #8b9898;
	width: calc(100% / 7);
	display: inline-block;
	padding: 0;
}

_::-webkit-full-page-media, _:future, :root .DayPickerInput-OverlayWrapper, .DayPickerInput-Overlay {
	width: 300px;
	max-width: 300px !important
}

.DayPicker-Caption {
	margin-top: 0.8rem;
	margin-bottom: 0.8rem;
	color: #848484 !important;
}

.DayPicker-Month {
	margin: 0px;
}

.DayPicker-Day:hover {
	background: rgba(250, 88, 58, 0.09) !important;
	/* color: #FA583A !important; */
}

.DayPicker-Body .DayPicker-Week .DayPicker-Day DayPicker-Day--outside:hover {
	background: transparent !important;
}

.DayPicker-Day {
	display: unset !important;
	padding: 0 !important;
	height: 41px;
	width: calc(100% / 7);
	line-height: 39px;
}

.DayPicker-NavButton {
	right: 0.5rem;
}

.DayPicker-Months {
	box-sizing: border-box;
	padding: 5px;
}
.DayPicker-Caption > div {
	font-weight: 600;
	color: #848484 !important;
}

.DayPicker-wrapper {
	padding-bottom: 0 !important;
}

.DayPicker-wrapper .DayPicker-Weekdays {
	margin-top: 0rem;
	display: unset;
	width: 100%;
}

.DayPicker-wrapper .DayPicker-WeekdaysRow {
	display: flex;
}

.DayPicker-wrapper .DayPicker-Body {
	width: 100%;
	display: unset;
}

.DayPicker-Week {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
}

.DayPicker-Weekday {
	font-size: 0.875em;
	text-align: center;
	color: #8b9898;
	width: calc(100% / 7);
	display: inline-block;
	padding: 0;
}

.DayPickerInput-OverlayWrapper, .DayPickerInput-Overlay {
	width: 300px;
	max-width: 300px !important
}

#payment__popup .DayPickerInput-Overlay {
    width: 300px;
    max-width: 300px !important;
    max-height: 280px;
    height: 280px;
    overflow: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.DayPicker-Month {
	margin: 0px !important;
}

.DayPicker-NavButton {
	right: 0.5rem !important;
}

.DayPicker-Week {
	display: flex !important;
}

#payment__popup .rdtPicker {
	width: 280px;
	height: auto !important;
    background: #fff;
    box-shadow: 0px 10px 33px 1px rgba(0,0,0,.1);
}

.rdtPicker td.rdtDisabled, .rdtPicker td.rdtDisabled:hover {
	color: #cacaca !important;
	text-decoration: line-through;
}

.rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
	background-color: #ee582e !important;
	font-weight: bold;
}

.rdtPicker td.rdtToday:before {
	border-bottom: 7px solid #ee582e !important;
}

.rdtPicker td.rdtToday {
	font-weight: bold;
}


/* 
	OVERLAY ANIMATION FIX
*/

.animated.fadeIn.faster.single-page-wrapper {
	animation-fill-mode: none !important; 
}

/* 
	HOMEPAGE LOADER AND EMPTY SEARCH DISCLAIMER
*/


.spinner-container {
    width: calc(100% - 505px);
    height: calc(100% - 185px);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

	.centered-spinner {
		text-align: center;
	}

		.centered-spinner h2 {
			font-weight: 300;
			font-size: 22px;
			margin: 20px 0px 0px 0px;
			color: #636e72;
		}

		.lds-ring {
			display: inline-block;
			position: relative;
			width: 64px;
			height: 64px;
		}

			.lds-ring div {
				box-sizing: border-box;
				display: block;
				position: absolute;
				width: 51px;
				height: 51px;
				margin: 6px;
				border: 6px solid #FA583A;
				border-radius: 50%;
				animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
				border-color: #FA583A transparent transparent transparent;
			}

				.lds-ring div:nth-child(1) {
					animation-delay: -0.10s;
				}

				.lds-ring div:nth-child(2) {
					animation-delay: -0.2s;
				}

				.lds-ring div:nth-child(3) {
					animation-delay: -0.15s;
				}

				@keyframes lds-ring {
					0% {
						transform: rotate(0deg);
					}
					100% {
						transform: rotate(360deg);
					}
				}

.empty-search .empty-icon {
	display: inline-block;
	width: 65px;
	height: 65px;
	-webkit-mask-image: url("../images/empty_cart.svg");
	mask-image: url("../images/empty_cart.svg");
	background-size: 100%;
	background: #FA583A;
}

/*------------------------------------------------------------------------------*\
  #HOME
/*------------------------------------------------------------------------------*\


/* 
	HEADER_COMPONENT
*/

.header {
	width: 100%;
	height: 72px;
	background: #fff;
	box-sizing: border-box;
	padding: 0px 66px 0px 0px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 2px solid #DFE6E9;
	position: fixed;
	top: 0;
	z-index: 9997;
}

	.raft--cart {
		width: 100%;
	}

		.raft--cart:after {
			content: "";
			display: table;
			clear: both;
		}

	.header-container {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

		.header__section-left {
			display: flex;
			height: 100%;
		}

			.header__item {
				height: 100%;
			}

				.header__menu {
					background: #FA583A;
					width: 72px;
					height: 100%;
					cursor: pointer;
					display: flex;
					display: -webkit-box;
					display: -ms-flexbox;
					display: -webkit-flex;
					align-items: center;
					justify-content: center;
				}

					.header__menu div {
						background: transparent;
						border: 0px;
						color: #fff;
						font-size: 14px;
						font-weight: bold;
						display: flex;
						display: -webkit-box;
						display: -ms-flexbox;
						display: -webkit-flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						flex: 0 auto;
					}

					.header__menu-icon {
						display: block;
						background: url("../images/menu_dots.svg") no-repeat center center;
						width: 29px;
						height: 6px;
						text-indent: -9999px;
						margin: 5px 0px 5px 0px;
					}

					.header__menu h2{
						color: #fff;
						font-weight: bold;
						font-size: 16px;
						width: 100%;
						text-align: center;
					}

			.header__logo {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0px 31px;
			}

				.header__logo a {
					display: inline-block;
					background: url("../images/logo_red.svg") no-repeat center center;
					width: 70px;
					height: 28px;
					text-indent: -9999px;
				}

			.home {
				width: 31px;
				height: 31px;
				background: url("../images/home_icon_red.svg");
				background-repeat: no-repeat;
				background-size: 58%;
				cursor: pointer;
				margin: 2px 0px 0px 22px;
				background-color: #f5f6fa;
				box-sizing: border-box;
				border-radius: 50%;
				text-align: center;
				background-position: center center;
				box-shadow: 2px 2px 3px 0px #00000040;
				transition: all 0.2s ease-in-out;
			}

			.home:hover {
				box-shadow: 2px 2px 4px 1px #0000001f;
			}
			
			.header__search {
				height: 100%;
				position: relative;
			}

				.header__search input {
					width: 408px;
					box-sizing: border-box;
					padding: 0px 50px 0px 20px;
					height: 70px;
					border: 0px;
					box-shadow: 0px 0px 45px -10px #00000038;
					font-size: 16px;
					color: #2F3640;
					font-weight: 100;
					transition: all 0.2s ease-in-out;
				}

				.header__search input:focus {
					box-shadow: 0px 0px 65px -8px #0000002b;
				}

					.header__search input:focus::-webkit-input-placeholder {
						color: #2F3640;
					}

					.header__search input:focus::-moz-placeholder {
						color: #2F3640;
					}

					.header__search input:focus::-ms-input-placeholder {
						color: #2F3640;
					}

					.header__search input:focus:-moz-placeholder {
						color: #2F3640;
					}

					.header__search input:focus + .header__search-button{
						opacity: 1;
					}

				.header__search input::-webkit-input-placeholder {
					color: #B2BEC3;
					font-size: 16px;
					font-weight: 100;
					transition: all 0.2s ease-in-out;
				}

				.header__search input::-moz-placeholder {
					color: #B2BEC3;
					font-size: 16px;
					font-weight: 100;
				}

				.header__search input:-ms-input-placeholder {
					color: #B2BEC3;
					font-size: 16px;
					font-weight: 100;
				}

				.header__search input:-moz-placeholder {
					color: #B2BEC3;
					font-size: 16px;
					font-weight: 100;
				}

				.header__search-button {
					background: url("../images/search_icon.svg") no-repeat center center;
					width: 16px;
					height: 16px;
					position: absolute;
					right: 22px;
					top: 0;
					bottom: 0;
					margin: auto;
					opacity: 0.5;
					transition: all 0.2s ease-in-out;
				}

			.header__login {
				margin: 0px 0px 0px 40px;
			}
			
				.header__login .header__login-button {
					background: url("../images/user_icon.svg") no-repeat center center;
					width: 13px;
					height: 13px;
					text-indent: -9999px;
					display: inline-block;
					margin: 0px 7px 0px 0px;
				}

				.header__login .header__help-button {
					mask: url("../images/question-circle.svg") no-repeat center center;
					-webkit-mask: url("../images/question-circle.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
					background: #fff;
					width: 14px;
					height: 14px;
					text-indent: -9999px;
					display: inline-block;
					margin: 0px 7px 0px 0px;
				}

				.header__login .header__register-button {
					mask: url("../images/user-plus.svg") no-repeat center center;
					-webkit-mask: url("../images/user-plus.svg") no-repeat center center;
					-webkit-mask-size: 100%;
					mask-size: 100%;
					/* background: #AC1D07; */
					background: #FA583A;
					width: 16px;
					height: 16px;
					text-indent: -9999px;
					display: inline-block;
					margin: 0px 7px 0px 0px;
				}

		.header__section-right {
			display: flex;
			align-items: center;
		}

			.user__corner {
				display: flex;
				align-items: center;
			}

				.dropdowns ul {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
				}

					.dropdowns ul li{
						display: flex;
						align-items: center;
						position: relative;
						cursor: pointer;
					}

						.dropdowns ul li .icon__divider {
							width: 1px;
							height: 10px;
							background: #e0e5e7b0;
							border: 0px;
							margin: 0px 13px;
						}				

						.user__corner-favorites.user__corner-favorites-icon {
							display: inline-block;
							width: 22px;
							height: 20px;
							background: url("../images/heart_icon_ghost.svg") no-repeat center center;
							text-indent: -9999px;
						}

						.user__corner-track-order .user__corner-track-order-icon {
							display: inline-block;
							width: 33px;
							height: 20px;
							background: url("../images/truck_icon.svg") no-repeat center center;
							text-indent: -9999px;
						}

						.user__corner-notifications .user__corner-notifications-icon {
							display: inline-block;
							width: 17px;
							height: 20px;
							background: url("../images/notification_icon.svg") no-repeat center center;
							text-indent: -9999px;
						}

							.icon__counter--round {
								width: 20px;
								height: 20px;
								line-height: 20px;
								box-shadow: 3px 3px 4px 0 #0000001a;
								background-color: #fa583a;
								font-size: 11px;
								font-weight: bold;
								color: #ffffff;
								border-radius: 50%;
								display: flex;
								justify-content: center;
								position: absolute;
								top: -8px;
								right: -3px;
								font-family: sans-serif;
								font-weight: 400;
							}
						
							_::-webkit-full-page-media, _:future, :root .icon__counter--round {
								right: 6px;
								top: -9px;
							}

							_::-webkit-full-page-media, _:future, :root .dropdowns .user__corner-notifications .icon__counter--round {
								right: -2px;
								top: -9px;
							}
			
			.avatar {
				margin: 0px 0px 0px 5px;
				display: flex;
				align-items: center;
				cursor: pointer;
				position: relative;
			}

				.avatar__button {
					display: flex;
					align-items: center;
					cursor: pointer;
					box-sizing: border-box;
   	 				padding: 0px 15px 0px 0px;
				}

				.avatar::after {
					content: url("../images/chevron_down.svg");
					width: 8px;
					height: 13px;
					line-height: 11px;
					margin: 0px 0px 0px 8px;
					pointer-events: none !important;
					position: absolute;
					right: 0;
				}

				_::-webkit-full-page-media, _:future, :root .avatar::after {
					top: 9px;
				}

				.avatar__image-holder {
					margin: 0px;
					width: 33px;
					height: 33px;
					border-radius: 50%;
					overflow: hidden;
				}

					.avatar__image-holder img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}

				.avatar h2 {
					font-weight: 300;
					color: #B2BEC3;
					font-size: 18px;
					margin: 0px 0px 0px 10px;
				}
	
	.menu__home {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding: 0px 0px 0px 5px;
	}

		.menu__home .header__menu {
			box-sizing: border-box;
			padding: 15px 20px 15px 15px;
		}

		.menu__home .home {
			margin: 1px 0px 0px 0px;
			box-shadow: none;
			background-color: transparent;
			background: url("../images/home_icon_dark.svg");
			background-repeat: no-repeat;
			background-size: 73%;
			background-position: center;
			border-radius: 0px;
			box-sizing: border-box;
			padding: 15px 20px 15px 5px;
		}

		.header__login {
			display: flex;
			align-items: center;
		}

			.register-button {
				font-size: 16px;
				position: relative;
				top: 1px;
				margin: 0px 10px 0px 0px;
				box-sizing: border-box;
				padding: 0px 20px;
			}

			.mobile__header-search.icon-button--naked.active {
				pointer-events: none;
			}

			.search-button-container {
				position: relative;
				top: 2px;
			}

			_::-webkit-full-page-media, _:future, :root .search-button-container {
				top: 0px;
			}

				.dot {
					width: 7px;
					height: 7px;
					background: #FA583A;
					display: inline-block;
					position: absolute;
					top: -5px;
					right: -3px;
					border-radius: 50%;
				}

					_::-webkit-full-page-media, _:future, :root .search-button-container .dot {
						top: -1px;
						right: -1px;
					}
 
/* 
	MAIN_CONTENT[SHOP]
*/

.wrapper {
	width: 90%;
    margin: 0px auto;
    /* height: calc(100% - 97px); */
    height: calc(100% - 90px);
}

	.wrapper:after {
		content: "";
		display: table;
		clear: both;
	}

	/* .main-content-component { */
		/* margin-top: 97px; */
		/* margin-top: 90px; */
	/* } */

	.cart {
		width: 350px;
		/* height: calc(100% - 97px); */
		height: calc(100% - 90px);
		height: calc(100% - 82px);
		float: right;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 3px 0px 0px 0px;
		position: fixed;
		z-index: 10;
		right: 60px;
		/* top: 97px; */
		top: 90px;
	}

	.columns {
		width: calc(100% - 380px);
		float: left;
	}

		.raft__categories {
			position: relative;
		}

			.category-content-container {
				display: flex;
				align-items: center;
				/* overflow: hidden; */
				position: relative;
			}

				.raft__categories--expander {
					width: 120px;
					height: 28px;
					display: inline-block;
					background: -webkit-linear-gradient(left, rgba(245,246,250,0) 49%,rgba(245,246,250,1) 100%);
					background: -webkit-gradient(linear, left top, right top, color-stop(49%, rgba(245,246,250,0)),to(rgba(245,246,250,1)));
					background: -o-linear-gradient(left, rgba(245,246,250,0) 49%,rgba(245,246,250,1) 100%);
					background: linear-gradient(to right, rgba(245,246,250,0) 49%,rgba(245,246,250,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f5f6fa', endColorstr='#f5f6fa',GradientType=1 );
					position: absolute;
					left: -140px;
					pointer-events: none;
				}

				_::-webkit-full-page-media, _:future, :root .raft__categories--expander {
					/* left: -98px; */
				}

				_::-webkit-full-page-media, _:future, :root .raft__categories--right {
					height: 37px;
				}

				.category-title a {
					display: flex;
					align-items: center;
					/* cursor: pointer; */
				}
				
				.raft .raft__categories--left .category-title a {
					cursor: pointer;
				}

					.category-title h2 {
						color: #636E72;
						font-weight: bold;
						font-size: 20px;
						white-space: nowrap;
						text-transform: uppercase;
						/* transition: all 0.1s ease-in-out; */
					}
					
					.category-title .chevron {
						background: url("../images/chevron_right_red.svg") no-repeat center center;
						width: 9px;
						height: 12px;
						text-indent: -9999px;
						margin: 0px 7px 0px 0px;
						position: relative;
						top: -1px;
						transition: all 0.1s ease-in-out;
					}

					.raft__categories--left .category-title h2 {
						opacity: 0.5;
					}
					.raft__categories--left .category-title .chevron {
						opacity: 0.3;
					}

					.raft__categories--left .category-title .main--active .chevron {
						opacity: 1;
					}

					.raft__categories--left .category-title .main--active h2 {
						opacity: 1;
					}

			.tabs {
				/* width: calc(100% - 180px); */
				width: 100%;
				height: 30px;
				margin: 0px 0px 0px 15px;
				padding: 0px 0px 3px 0px;
				/* border-bottom: 1.5px solid #ccd8dd; */
				display: flex;
				align-items: center;
			}

				.raft-category-divider {
					width: 100%;
					height: 2px;
					margin: 0px;
					background: #ccd8dd;
					border: 0px;
					position: absolute;
					bottom: -3px;
					right: 0;
				}

				.category-src.tabs ul {
					display: -ms-flexbox;
					display: flex;
					-ms-flex-align: center;
					align-items: center;
					white-space: nowrap;
					width: calc(100% - 45px);
					overflow: hidden;
					box-sizing: border-box;
    				padding: 0px 0px 0px 0px;
				}

					.category-src.tabs.mobile ul {
						width: 100%;
						overflow-x: scroll;
						overflow-y: hidden;
						box-sizing: border-box;
						padding: 0px 0px 0px 0px;
					}

					.prev-button {
						display: -ms-flexbox;
						display: flex;
						width: 9px;
						height: 19px;
						padding: 0px;
						margin: 2px 0px 0px 0px;
						position: relative;
						z-index: 1;
						/* border: 1.2px solid #FA583A; */
						border-radius: 50%;
						-ms-flex-align: center;
						align-items: center;
						-ms-flex-pack: center;
						justify-content: center;
					}

						.prev-button span {
							display: inline-block;
							width: 7px;
							height: 11px;
							mask: url("../images/slider_arrow_left_icon.svg") center center;
							-webkit-mask: url("../images/slider_arrow_left_icon.svg") center center;
							mask-size: 100%;
							-webkit-mask-size: 100%;
							background: #b2bec2;
							mask-repeat: no-repeat;
							-webkit-mask-repeat: no-repeat;
							text-align: center;
							line-height: 10px;
							margin: 1px 0px 0px 0px;
						}
					
					.prev-button.disabled, .next-button.disabled {
						pointer-events: none;
						cursor: pointer;
					}

					.raft.expand .tabs-buttons-list.full-width-tabs {
						/* width: 100%; */
					}

					.raft.expand .tabs-buttons-list {
						/* width: calc(100% - 75px); */
						/* width: calc(100% - 125px); */
						width: calc(100% - 93px);
					}

					.raft.button-expand.expand .tabs-buttons-list {
						/* width: calc(100% - 85px); */
						width: calc(100% - 93px);
					}

					.next-button {
						display: -ms-flexbox;
						display: flex;
						width: 9px;
						height: 19px;
						padding: 0px;
						/* margin: 2px 10px 0px 12px; */
						margin: 2px 0px 0px 15px;
						position: relative;
						z-index: 1;
						/* border: 1.2px solid #FA583A; */
						border-radius: 50%;
						-ms-flex-align: center;
						align-items: center;
						-ms-flex-pack: center;
						justify-content: center;
					}

						.next-button span {
							display: inline-block;
							width: 7px;
							height: 11px;
							mask: url("../images/slider_arrow_right_icon.svg") center center;
							-webkit-mask: url("../images/slider_arrow_right_icon.svg") center center;
							mask-size: 100%;
							-webkit-mask-size: 100%;
							background: #b2bec2;
							mask-repeat: no-repeat;
							-webkit-mask-repeat: no-repeat;
							text-align: center;
							line-height: 10px;
							margin: 1px 0px 0px 1px;
						}

				.tabs ul li{
					float: left;
					padding: 10px 10px 6px 10px;
					box-sizing: border-box;
					border-bottom: 2px solid transparent;
					position: relative;
					transition: all 0.2s ease-in-out;
					background: transparent;
					background-image: none;
					cursor: pointer;
					position: relative;
					right: 0;
					white-space: nowrap;
					transform: translate3d(0px, 0px, 0px);
				}

					.tabs ul li:hover h3{
						color: #292929
					}

				.tabs ul li:before {
					content: "";
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					z-index: -1;
					opacity: 0;
					background: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0));
					/* transition: opacity 0.1s ease-in-out; */
				}

				.tabs ul li.active__sub:before {
					opacity: 1;
				}

					.tabs ul li:after {
						content: "";
						width: 100%;
						height: 2px;
						background: #fa583a;
						position: absolute;
						bottom: -5px;
						left: 0;
						opacity: 0;
						/* transition: all 0.2s ease-in-out; */
					}

					.tabs ul li.active__sub:after {
						bottom: -2px;
						opacity: 1;
					}

					.tabs ul li.active__sub h3 {
						color: #292929;
					}

				.tabs ul li.active__sub h3{
					/* font-weight: 600; */
					text-shadow: 0 0 .05px #292929;
				}

				.tabs ul li h3 {
					display: inline-block;
					font-size: 14px;
					color: #636E72;
					cursor: pointer;
					font-weight: normal;
					text-transform: uppercase;
					font-weight: 300;
					/* transition: all 0.4s ease-in-out; */
					pointer-events: none !important;
				}

				.popup__tabs .rc-tabs-nav-container .rc-tabs-nav .rc-tabs-tab-active {
					background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(242,242,242,1) 100%);
					background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(242,242,242,1) 100%);
					background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(242,242,242,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f2f2f2',GradientType=0 );
				}
			
			.tab_item a{
				text-transform: uppercase;
			}

		.raft__categories--left {
			width: 100%;
		}

		.raft__categories--right {
			position: relative;
			display: flex;
			align-items: center;
			transition: all 0.2s ease-in-out;
			z-index: 5;
		}

		.tabs-container-right {
			display: flex;
			width: 100%;
			height: 100%;
		}

		_::-webkit-full-page-media, _:future, :root .raft__categories--right {
			top: -2px;
		}
	
	.raft__content {    
		position: relative;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		overflow: hidden;
		/* -webkit-overflow-scrolling: touch; */
		margin: 0px 0px 0px 0px;
		z-index: 1;
		box-sizing: border-box;
		padding: 0px 0px 0px 6px;
		scroll-snap-type: x mandatory;
		/* scroll-padding: 18px; */
		/* background:  repeat-y 0px 0px; */

		background-image: url("../images/full_raft_illustration_board_2.svg");
		background-repeat: repeat-y;
		background-position-x: 0;
		/* background-position-y: 260px;
		background-size: 100% 303px;
		height: 303px; */
		background-position-y: 271px;
		background-size: 100% 324px;
		height: 324px;
	}

	.raft.expand .raft__content {
		flex-wrap: wrap;
		overflow: hidden;
		height: auto;

		background-image: url("../images/full_raft_illustration_board_2.svg");
		background-repeat: repeat-y;
		background-position-x: 0;
		/* background-position-y: 260px;
		background-size: 100% 320px; */
		background-position-y: 270px;
		background-size: 100% 333px;
		margin-bottom: 6px;
	}

	.raft.expand .raft__content .raft-card{
		margin-bottom: 45px;
	}

	.raft__content .raft-card {
		/* scroll-snap-align: start; */
	}

	.raft .raft__content .raft-card:first-child {
		margin-left: 0px !important;
	}

		.raft__slider--decorator {
			width: 21px;
			height: 269px;
			display: inline-block;
			position: absolute;
			left: 39px;
			top: 20px;
			z-index: 0;
			-webkit-box-shadow: -20px 0px 10px 4px rgba(0, 0, 0, 0.04);
			box-shadow: -20px 0px 10px 4px rgba(0, 0, 0, 0.04);
			background: transparent;
			pointer-events: none;
			-webkit-transition: all 0.2s ease-in-out;
			-o-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
		}

		.raft__slider--decorator-end {
			width: 130px;
			height: 275px;
			display: inline-block;
			position: absolute;
			right: 0px;
			background: -moz-linear-gradient(left, rgba(245,246,250,0) 0%, rgba(245,246,250,1) 100%);
			background: -webkit-linear-gradient(left, rgba(245,246,250,0) 0%,rgba(245,246,250,1) 100%);
			background: linear-gradient(to right, rgba(245,246,250,0) 0%,rgba(245,246,250,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f5f6fa', endColorstr='#f5f6fa',GradientType=1 );
			top: 46px;
			z-index: 2;
			pointer-events: none;
		}	

		.raft-illustration {
			width: 100%;
			position: absolute;
			/* bottom: 63px; */
			bottom: 43px;
			z-index: 0;
			display: block !important;
		}

			.raft-illustration__boards {
				width: 100%;
				height: 106px;
				position: absolute;
				top: 26px;
				z-index: 2;
			}

			.raft-illustration__shadow {
				width: calc(100% + 61px);
				height: 60px;
				position: absolute;
				top: 73px;
				left: 0;
				z-index: 1; 
			}

	.tabs-container-left {
		width: 100%;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		padding: 0px 5px 0px 0px;
		justify-content: space-between;
	}

		.tabs-buttons-list {
			width: calc(100% - 113px);
			display: flex;
			align-items: center;
			transition: all 0.2s ease-in-out;
		}

		.category-content-container.no-children .full-width-tabs {
			display: none;
		}

		.category-content-container.no-children .raft-category-divider {
			display: none;
		}

		.category-content-container.no-children .raft__categories--expander {
			display: none;
		}

		.category-content-container.no-children .tabs-container-left {
			justify-content: flex-end;
		}

		.category-content-container.no-children .raft__categories--right.no-children {
			top: 2px;
		}

		#raft__slider .carousel-initialized {
			padding: 45px 0px 0px 0px !important;
			bottom: 29px;
			pointer-events: none;
		}

		#raft__slider .carousel-item {
			/* width: unset !important; */
		}

		/* #raft__slider:before {
			width: 10px;
			height: 243px;
			content: "";
			background: #f5f6fa;
			position: absolute;
			left: 4px;
			top: 35px;
			z-index: 0;
			box-shadow: 0 0 22px 12px rgba(0, 0, 0, 0.04);
		} */

/* 
	CARD
*/

/* .raft:first-child {
	margin: 3px 0px 0px 0px;
} */

.raft {
	position: relative;
	margin: 0px 0px 15px 0px;
}

.raft-card {
	width: 193px;
	flex: 0 0 auto;
	/* margin: 0px 3px; */
	margin: 13px 3px 50px 3px;
	height: 275px;
	background: #fff;
	box-shadow: 0 0 22px 5px rgba(0, 0, 0, 0.04);
	box-sizing: border-box;
	padding: 15px;
	display: flex;
    flex-wrap: wrap;
	align-content: space-between;
	background: url("../images/card_decoration.svg") no-repeat top center;
	background-color: #fff;
	/* z-index: 1; */
	cursor: pointer;
	/* pointer-events: auto; */
	position: relative;
}

	_::-webkit-full-page-media, _:future, :root .raft-card .raft-card__title {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		 -khtml-user-select: none; 
		   -moz-user-select: none;
			-ms-user-select: none; 
				user-select: none; 
	}

	_::-webkit-full-page-media, _:future, :root .raft-card {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		 -khtml-user-select: none; 
		   -moz-user-select: none;
			-ms-user-select: none; 
				user-select: none; 
	}

	.raft-card__block {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		/* margin-bottom: 20px; */
	}

		.raft-card .animated-product-image-container {
			height: 100px;
			max-height: 100px;
		}

		.raft-card__supplier {
			color: #B2BEC3;
			font-size: 14px;
			font-weight: normal;
		}

		.badges__container {
			display: flex;
			align-items: center;
		}  

			.badge__container {
				margin: 0px 5px 0px 0px;
			}

				.badge__tooltip {
					width: auto;
					background: #fff;
					border: 2px solid #F1F4F5;
					border-radius: 4px;
					position: absolute;
					z-index: 1;
					transform: translate3d(-10px, 0px, 0px);
					pointer-events: none;
					white-space: nowrap;
					box-sizing: border-box;
					padding: 7px 15px 5px 15px;
					display: none;
					transform: translate3d(-10px, 0px, 0px);
					margin: -8px 0px 0px 45px;
					box-shadow: 10px 10px 24px 0px #0000001f;
					transition: all 0.2s ease-in-out;
				}

					.badge__tooltip h2 {
						font-size: 16px;
						font-weight: 400;
						color: #636E72;
					}

					.badge__tooltip:before {
						content: "";
						background: url("../images/tooltip_chevron.svg") no-repeat center center;
						display: inline-block;
						width: 21px;
						height: 16px;
						position: absolute;
						left: -20.8px;
						top: 1px;
						bottom: 0;
						margin: auto;
						background-size: 100%;
					}

				.badge {
					width: 21px;
					height: 21px;
					display: -ms-flexbox;
					display: flex;
					-ms-flex-pack: center;
					justify-content: center;
					-ms-flex-align: center;
					align-items: center;
					-webkit-mask-repeat: no-repeat;
					mask-repeat: no-repeat;
					-webkit-transform: scale(1);
					-ms-transform: scale(1);
					transform: scale(1);
					-webkit-transition: all 0.2s ease-in-out;
					-o-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					position: relative;
					z-index: 0;
					cursor: help;
					border-radius: 50%;
				}

					.badge__container:hover .badge__tooltip {
						display: block;
					}

					.badge__container:last-child {
						margin: 0px;
					}
					
					.badge__container .badge:hover + #tooltip {
						opacity: 1;
						visibility: visible;
						transform: translate3d(0px, 0px, 0px);
						z-index: 1;
					}

					.badge__container .badge:hover + .badge__container {
						z-index: -1;
					}

				.badge.only-here-icon {
					background: #FA583A;
				}

				.badge.orange {
					background: #e67e22;
				}

				.badge.flyer-icon {
					background: #e67e22;
				}

				.badge.recommended-icon  {
					background: #9b59b6;
				}

				.badge.only-here {
					background: #74b9ff;
				}

				.badge.halal-icon {
					background: #78e08f;
				}

				.badge.low-price-icon {
					background: #cd6133;
				}

				.badge.kosher-icon {
					background: #d1ccc0;
				}

				.badge.organic-icon {
					background: #2ecc71;
				}

				.badge.local-icon  {
					background: #f9ca24;
				}

				.badge.vegan-icon  {
					background: #27ae60;
				}

				.badge.best-seller-icon {
					background: #2980b9;
				}

				.badge.raft-icon {
					background: #FA583A;
				}

				.badge.gluten-icon {
					background: #fad390;
				}

				.badge.new-product-icon {
					background: #00cec9;
				}

				.badge__container #tooltip {
					white-space: nowrap;
					left: unset;
					top: unset;
					margin: -31px 0px 0px 36px;
					right: unset;
				}

					.badge__container #tooltip:before {
						top: 0;
					}

					.badge__title {
						display: inline-block;
						width: 100%;
						height: 100%;
						background: #B2BEC3;
					}
					
						.badge__title.best-seller-icon  {
							mask: url("../images/trophy.svg") no-repeat center center;
							-webkit-mask: url("../images/trophy.svg") no-repeat center center;
							-webkit-mask-size: contain;
							mask-size: contain;
							width: 11px;
							height: 11px;
							background: #fff;
						}

						.badge__title.gluten-icon  {
							mask: url("../images/bread-slice-solid.svg") no-repeat center center;
							-webkit-mask: url("../images/bread-slice-solid.svg") no-repeat center center;
							-webkit-mask-size: contain;
							mask-size: contain;
							width: 11px;
							height: 10px !important;
							background: #fff;
						}

						.badge__title.recommended-icon  {
							mask: url("../images/thumbs-up.svg") no-repeat center center;
							-webkit-mask: url("../images/thumbs-up.svg") no-repeat center center;
							-webkit-mask-size: contain;
							mask-size: contain;
							width: 9px;
							height: 9px;
							background: #fff;
						}

						.badge__title.local-icon  {
							mask: url("../images/map-marked-alt.svg") no-repeat center center;
							-webkit-mask: url("../images/map-marked-alt.svg") no-repeat center center;
							-webkit-mask-size: contain;
							mask-size: contain;
							width: 11px;
							height: 11px;
							background: #fff;
						}

						.badge__title.flyer-icon  {
							mask: url("../images/copy.svg") no-repeat center center;
							-webkit-mask: url("../images/copy.svg") no-repeat center center;
							-webkit-mask-size: contain;
							mask-size: contain;
							width: 11px;
							height: 11px;
							background: #fff;
						}

						.badge__title.organic-icon  {
							mask: url("../images/seedling.svg") no-repeat center center;
							-webkit-mask: url("../images/seedling.svg") no-repeat center center;
							-webkit-mask-size: contain;
							mask-size: contain;
							width: 11px;
							height: 11px;
							background: #fff;
						}

						.badge__title.raft-icon  {
							mask: url("../images/raft-stack.svg") no-repeat center center; 
							-webkit-mask: url("../images/raft-stack.svg") no-repeat center center;
							-webkit-mask-size: contain;
							mask-size: contain;
							width: 9px;
							height: 9px;
							background: #fff;
						}

						.badge__title.low-price-icon  {
							mask: url("../images/money-bill-alt.svg") no-repeat center center;
							-webkit-mask: url("../images/money-bill-alt.svg") no-repeat center center;
							-webkit-mask-size: cover;
							mask-size: cover;
							width: 11px;
							height: 7px;
							background: #fff;
						}

						.badge__title.vegan-icon  {
							mask: url("../images/carrot.svg") no-repeat center center;
							-webkit-mask: url("../images/carrot.svg") no-repeat center center;
							-webkit-mask-size: contain;
							mask-size: contain;
							width: 11px;
							height: 11px;
							background: #fff;
						}

						.badge__title.kosher-icon  {
							mask: url("../images/star-of-david.svg") no-repeat center center;
							-webkit-mask: url("../images/star-of-david.svg") no-repeat center center;
							-webkit-mask-size: contain;
							mask-size: contain;
							width: 11px;
							height: 11px;
							background: #fff;
						}

						.badge__title.halal-icon  {
							mask: url("../images/star-and-crescent.svg") no-repeat center center;
							-webkit-mask: url("../images/star-and-crescent.svg") no-repeat center center;
							-webkit-mask-size: contain;
							mask-size: contain;
							width: 9px;
							height: 9px;
							background: #fff;
						}

						.badge__title.new-product-icon {
							mask: url("../images/star.svg") no-repeat center center;
							-webkit-mask: url("../images/star.svg") no-repeat center center;
							-webkit-mask-size: contain;
							mask-size: contain;
							width: 9px;
							height: 9px;
							background: #fff;
						}

						.badge__title.only-here-icon {
							mask: url("../images/raft-stack.svg") no-repeat center center;
							-webkit-mask: url("../images/raft-stack.svg") no-repeat center center;
							-webkit-mask-size: contain;
							mask-size: contain;
							width: 9px;
							height: 9px;
							background: #fff;
						}

					.product__popup--full .badge__title {
						width: 11px;
						height: 11px;
					}

					.product__popup--full .badge__tooltip {
						margin: 0px 0px 0px 50px;
    					top: -5px;
					}

					.product__popup--full .badge__title.low-price-icon {
						width: 15px !important;
						height: 10px;
					}

					.product__popup--full .badge__title.raft-icon {
						height: 16px !important;
					}

		.raft-card__favorite {
			background: url("../images/heart_icon_ghost.svg") no-repeat center center;
			width: 20px;
			height: 20px;
		}

		.go-back {
			background: url("../images/popup_back_icon.svg") no-repeat center center;  
			width: 30px;
			height: 25px;	
			text-indent: -9999px;
			margin: 0px 13px 0px 0px;
		}

		.raft-card__favorite.icon-button--naked.favorite--active {
			background: url("../images/heart_icon_filled.svg") no-repeat center center;
			width: 20px;
			height: 20px;
			position: absolute;
			right: 0;
			/* transition: all 0.5s cubic-bezier(1, -1.66, 0, 3.46); */
			-webkit-animation: HEART_JUMP_ON_SUCCESS 0.2s; 
			-moz-animation:    HEART_JUMP_ON_SUCCESS 0.2s; 
			-o-animation:      HEART_JUMP_ON_SUCCESS 0.2s; 
			animation:         HEART_JUMP_ON_SUCCESS 0.2s; 
		}

		.raft-card__favorite {
			-webkit-animation: HEART_JUMP_ON_START 0.2s; 
			-moz-animation:    HEART_JUMP_ON_START 0.2s; 
			-o-animation:      HEART_JUMP_ON_START 0.2s; 
			animation:         HEART_JUMP_ON_START 0.2s; 
		}

		@-webkit-keyframes HEART_JUMP_ON_SUCCESS {
			0%   { transform: scale(0); opacity: 0; }
			50%   { transform: scale(1.1); opacity: 0.5; }
			100% { transform: scale(1); opacity: 1; }
		}
		
		@-moz-keyframes HEART_JUMP_ON_SUCCESS {
			0%   { transform: scale(0); opacity: 0; }
			50%   { transform: scale(1.1); opacity: 0.5; }
			100% { transform: scale(1); opacity: 1; }
		}

		@-o-keyframes HEART_JUMP_ON_SUCCESS {
			0%   { transform: scale(0); opacity: 0; }
			50%   { transform: scale(1.1); opacity: 0.5; }
			100% { transform: scale(1); opacity: 1; }
		}

		@keyframes HEART_JUMP_ON_SUCCESS {
			0%   { transform: scale(0); opacity: 0; }
			50%   { transform: scale(1.1); opacity: 0.5; }
			100% { transform: scale(1); opacity: 1; }
		}

		@-webkit-keyframes HEART_JUMP_ON_START {
			0%   { transform: scale(1); opacity: 1; }
			50%   { transform: scale(1.2); opacity: 0.5; }
			100% { transform: scale(1); opacity: 1; }
		}

		button.remove-favorite.raft-card__favorite.icon-button--naked.favorite--active.move-button {
			left: 43px;
			position: absolute !important;
		}

		.raft-card__favorited.icon-button--naked.favorite--active {
			visibility: visible;
			opacity: 1;
		}

		.raft-card__favorited {
			background: url("../images/heart_icon_filled.svg") no-repeat center center;
			width: 20px;
			height: 20px;
		}

		.raft-card__favorited.icon-button--naked.favorite--active.back-included {
			left: 74px;
		}

		button.raft-card__favorited.icon-button--naked.back-included {
			left: 76px;
		}

	.raft-card__product-info {
		text-align: center;
		width: 100%;
	}

		.raft-card__sub-title {
			font-size: 14px;
			color: #B2BEC3;
			display: block;
			width: 100%;
			word-break: break-word;
			margin: 0px 0px 3px 0px;
		}

		.raft-card__title {
			font-size: 18px;
			color: #636E72;
			font-weight: bold;
			text-transform: uppercase;
			line-height: 18px;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

	.raft-card__actions {
		margin: 0px;
	}

	.raft-card__add-to-cart {
		transform: scale3d(1, 1, 1);
		-webkit-backface-visibility: hidden; 
		-ms-transform: translate3d(0px, 0px, 0px);
		-webkit-transform: translate3d(0px, 0px, 0px);
		transition: all 0.2s ease-in-out;
	}

		.raft-card__add-to-cart span {
			display: inline-block;
			width: 13px;
			height: 13px;
			background: url("../images/plus_icon.svg") no-repeat center center;
			text-indent: -9999px;
			cursor: pointer;
			margin: 0px auto;
		}

		.raft-card__price {
			font-size: 20px;
			line-height: 19px;
			color: #FA583A;
			font-weight: bold;
			display: flex;
			align-items: flex-start;
		}

			.raft-card__price span {
				color: #fa583a;
				display: block;
				font-weight: bold;
			}

			.main-price-data {
				text-align: center;
				font-family: sans-serif;
				font-size: 23px;
			}

			.price-currency  {
				font-size: 12px;
				font-weight: 500;
				color: #9CA3A5 !important;
				text-align: center;
				line-height: 16px;
				box-sizing: border-box;
				padding: 0px 0px 0px 4px; 
				text-decoration: none !important;
			}

			span.main-price-data-discount {
				text-align: center;
				font-size: 16px;
				font-weight: normal;
				font-family: sans-serif;
				color: #9DA4A6;
				display: inline-block;
				width: 100%;
				position: relative;
				line-height: 14px;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				padding: 0px 3px 0px 7px;
				text-decoration: none !important;
			}

				.main-price-data-discount:before {
					content: "";
					width: 100%;
					height: 1.5px;
					background: #f9593bcf;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: -2px;
					margin: auto;
					-webkit-transform: rotate(-18deg);
					-ms-transform: rotate(-18deg);
					transform: rotate(-18deg);
				}
			
			.raft-card__price .cross {
				font-size: 18px;
				text-align: right;
				font-weight: normal;
				color: #b4bfc4;
				margin: 2px 5px 0px 0px;
				display: -ms-flexbox;
				display: flex;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				-ms-flex-line-pack: start;
				align-content: flex-start;
				-ms-flex-pack: center;
				justify-content: center;
				-ms-flex-align: baseline;
				align-items: baseline;
				-ms-flex-direction: column;
				flex-direction: column;
				text-decoration: none !important;
			}

			.raft-card__price .cross .main-price-data-discount-new {
				font-family: sans-serif;
				color: #27AE60;
				font-weight: normal;
				font-size: 14px;
				display: inline-block;
				width: 100%;
				box-sizing: border-box;
				padding: 0px 0px 0px 7px;
			}

	.recommended__product .animated-product-image-container {
		height: 63px !important;
	}

	.raft-card__product-img {
		text-align: center;
		width: 100%;
		height: 100%;
		margin: 0px 0px 0px 0px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}

		.animated-product-image-container {
			display: flex;
			align-items: center;
			justify-content: center;
			/* height: 100px !important; */
			/* max-height: 100px; */
		}

		.raft-card__product-img img {
			display: inline-block;
			height: 100%;
			object-fit: cover;
			pointer-events: none;
			position: absolute;
			right: 0;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
		}

		.raft .minicar-list .raft-card__product-img img.lazy {
			width: 80px !important;
			height: 80px !important;
		}

		.show-image {
			
			display: flex !important;
		}
		.hide-image {
			display: none !important;
		}

		.if-not-loaded {
			width: 80px;
			height: 80px;
			background: #f1f1f1;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

			.if-not-loaded .placeholder {
				width: 37px;
				height: 42px;
				background: url("../images/empty_cart.svg");
				background-repeat: no-repeat;
				background-size: 100%;
				opacity: 0.8;
			}

			.recommended__product .if-not-loaded {
				width: 63px;
				height: 63px;
				transform: scale(1);
			}

				.recommended__product .if-not-loaded .placeholder {
					width: 28px;
					height: 30px;
				}

			#product--web .product__section--left .if-not-loaded {
				width: 180px;
				height: 180px;
				transform: scale(1);
			}

				#product--web .product__section--left .if-not-loaded .placeholder {
					width: 68px;
					height: 78px;
				}

		.slick-slide {
			/* touch-callout: none;
			user-select: none; */
			cursor: pointer;
		}

		.slick-slide ~ div {
			-webkit-touch-callout: none !important;
			user-select: none !important;
			pointer-events: auto !important;
			cursor: pointer !important;
		}


		.slick-track {
			cursor: pointer;
		}

		.slick-list {
			cursor: pointer;
		}

		.slick-slider.slick-initialized {
			cursor: pointer;
		}

		.product__popup .main-price-data {
			font-size: 33px;
		}

		.product__popup .price-currency {
			font-size: 14px;
			line-height: 22px;
		}

		.product__popup .main-price-data-discount {
			font-size: 22px;
			font-weight: 100;
			padding: 0px 3px 0px 6px;
		}

		.product__popup .raft-card__price .cross .main-price-data-discount-new {
			font-weight: 100;
			font-size: 20px;
			padding: 4px 0px 0px 7px;
		}

		.product__popup .raft-card__price .cross {
			margin: 1px 10px 0px 0px;
		}

		.product__popup .main-price-data-discount:before {
			top: -3px;
		}
/* 
	CARD SLIDER
*/

#raft__slider .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
	padding: 13px 15px 13px 0px;
    z-index: 1;
	left: 0px;
	transform: translate3d(0px, 0px, 0px) !important;
}

	#raft__slider .slick-list .slick-slide {
		margin: 0px 3px;
		/* transform: translate3d(0px, 0px, 0px) !important; */
	}


	#raft__slider .slick-arrow {
		width: 22px;
		height: 22px;
		border: 1px solid #FA583A;
		border-radius: 3px;
		display: flex;
		align-items: center;
		justify-content: center;
		/* top: -26px; */
		/* top: -36px; */
		/* top: -25px; */
		top: -20px;
		transition: all 0.2s ease-in-out;
	}

	button.carousel-arrow.block.custom {
		width: 22px;
		height: 22px;
		border: 1px solid #FA583A;
		border-radius: 3px;
		display: flex;
		align-items: center;
		justify-content: center;
		/* top: -20px; */
		top: 0;
		pointer-events: auto;
		transition: all 0.2s ease-in-out;
		display: flex !important;
		opacity: 1 !important;
	}

	#raft__slider .slick-next {
		right: 0;
	}

	button.carousel-arrow.block.carousel-next.custom {
		right: 0;
	}

	#raft__slider .slick-prev {
		right: 30px;
		left: auto;
	}

	button.carousel-arrow.block.carousel-prev.custom {
		margin: 0px 8px 0px 0px;
	}

	#raft__slider .slick-disabled {
		border-color: #F4CECC;
	}

	.category-content-container.no-children {
		height: 37px;
		display: flex;
		align-items: center;
	}

		.category-content-container.no-children .category-src.tabs {
			border: 0px !important;
		}

			.category-src.tabs ul {
				transition: all 0.4s ease-in-out;
			}

		#raft__slider .slick-prev:before {
			content: "";
			display: inline-block;
			mask: url("../images/slider_arrow_left_icon.svg") no-repeat center center;
			-webkit-mask-size: contain;
			mask-size: contain;
			height: 10px;
			background: #FA583A;
			width: 17px;
		}

		#raft__slider button.carousel-arrow.block.carousel-prev.custom:before {
			content: "";
			display: inline-block;
			mask: url("../images/slider_arrow_left_icon.svg") no-repeat center center;
			-webkit-mask-size: contain;
			mask-size: contain;
			height: 10px;
			background: #FA583A;
			width: 17px;
		}

		#raft__slider .slick-next:before {
			content: "";
			display: inline-block;
			mask: url("../images/slider_arrow_right_icon.svg") no-repeat center center;
			-webkit-mask-size: contain;
			mask-size: contain;
			height: 10px;
			background: #FA583A;
			width: 17px;
		}

		#raft__slider button.carousel-arrow.block.carousel-next.custom:before {
			content: "";
			display: inline-block;
			mask: url("../images/slider_arrow_right_icon.svg") no-repeat center center;
			-webkit-mask-size: contain;
			mask-size: contain;
			height: 10px;
			background: #FA583A;
			width: 17px;
		}

		.disabled-buttons {
			position: absolute;
			right: 0;
			top: 4px;
			width: 52px;
			height: 22px;
			display: flex;
		}

			button.carousel-arrow.block.custom.disabled {
				width: 22px;
				height: 22px;
				border: 1px solid #cecece;
				border-radius: 3px !important;
			}

			.disabled-buttons button.carousel-arrow.block.carousel-next:before {
				content: "";
				display: inline-block;
				mask: url("../images/slider_arrow_right_icon.svg") no-repeat center center;
				-webkit-mask-size: contain;
				mask-size: contain;
				height: 10px;
				background: #FA583A;
				width: 17px;
			}

			.disabled-buttons button.carousel-arrow.block.carousel-prev:before {
				content: "";
				display: inline-block;
				mask: url("../images/slider_arrow_left_icon.svg") no-repeat center center;
				-webkit-mask-size: contain;
				mask-size: contain;
				height: 10px;
				background: #FA583A;
				width: 17px;
			}

	
/* 
	CART
*/

.cart__content {
	width: 100%;
	height: 100%;
	position: relative;
}

	.cart__header {
		width: 100%;
		height: 40px;
		background: #dfe6e9;
		box-sizing: border-box;
		padding: 0px 20px;
		position: relative;
		display: flex;
		align-items: center;
		border-radius: 4px;
	}

		/* .cart__header::before {
			content: url("../images/cart_header_icon.svg");
			width: 50px;
			height: 40px;
			position: absolute;
			right: 0;
		} */

		.cart__header h2 {
			font-weight: 500;
			color: #414648;
			font-size: 18px;
		}

		.cart__count {
			width: 45px;
			height: 40px;
			line-height: 42px;
			background: #646e72;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-pack: center;
			justify-content: center;
			color: #fff;
			font-size: 16px;
			font-weight: 300;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			font-family: sans-serif;
			border-radius: 0px 4px 4px 0px;
		}

	.cart__items {
		width: calc(100% + 13px);
		height: calc(100% - 104px);
		/* overflow: auto; */
		/* margin: 5px 0px 0px 0px; */
		margin: 0px 0px 0px 0px;
		
		position: relative;
		left: -13px;
	}

	.cart__items--scrollable {
		overflow: auto;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 15px 0px 0px 15px;
		transform: scale(1);
		opacity: 1;
		transition: all 0.2s ease-in-out;
	}

	.cart-item-src.unavaialbe .cart__item-container {
		/* opacity: 0.4; */
	}

		.cart-item-status {
			position: absolute;
			right: 0;
			top: 17px;
			font-size: 14px;
			font-weight: bold;
			background: #fa583a;
			color: #ffffff;
			padding: 0px 7px;
			border-radius: 50px;
			height: 22px;
			line-height: 22px;
		}

		.cart__items:last-of-type {
			margin-bottom: 100px;
		}

		.cart__items--scrollable::-webkit-scrollbar {
			width: 8px;
			border-radius: 50px;
		}
		
		.cart__items--scrollable::-webkit-scrollbar-track {
			background: #DFE6E9;
			border-radius: 50px;
		}
		
		.cart__items--scrollable::-webkit-scrollbar-thumb {
			background: #B2BEC3;
			border-radius: 50px;
		}
	
		.cart__item-container {
			width: 100%;
			display: flex;
			box-sizing: border-box;
			padding: 20px 0px;
			border-bottom: 1px solid #DFE6E9;
			cursor: pointer;
			position: relative;
			transition: all 0.2s ease-in-out;
		}

		.cart-item-src {
			width: calc(100% - 10px);
			cursor: pointer;
			position: relative;
		}

			.cart-item-src .actions__value {
				cursor: default;
			}

			.cart__item-container .if-not-loaded {
				width: 50px;
				height: 50px;
				margin: 3px 0px 0px 0px;
			}

				.cart__item-container .if-not-loaded .placeholder {
					width: 25px;
					height: 30px;
				}

			.cart-item-src:last-of-type {
				margin-bottom: 100px;
			}

			.cart__item-container:before {
				/* content: "";
				background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0));
				width: calc(100% + 10px);
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 0;
				opacity: 0;
				pointer-events: none;
				transition: all 0.2s ease-in-out; */
			}

			.cart__item-container:hover:before{
				opacity: 1;
			}

			.cart__item-container:hover .remove__cart-item {
				transform: scale(1);
				opacity: 1;
				visibility: visible;
			}

			.cart-item-src:first-child .cart__item-container {
				padding-top: 0px;
			}

			.cart-item-src:first-child .cart__item-container .remove__cart-item {
				top: -13px;
			}

			.cart-item-src:first-child .cart-item-status {
				top: 0;
			}

			.cart-item-src.unavaialbe .quantity-container__actions {
				pointer-events: none;
			}

			.cart__item-container:first-child:before {
				top: -15px;
				padding: 0px 0px 15px 0px;
			}

			/* .cart__item-container:first-child .remove__cart-item{
				top: -16px;
			} */
		
			.cart__product-img {
				width: 80px;
				height: 80px;
				background: url("../images/cart_image_decorator.svg") no-repeat top center;
				background-color: #fff;
				box-shadow: 0 0 22px 3px rgba(0, 0, 0, 0.08);
				border-radius: 5px;
				position: relative;
			}

				.mobile__cart .animated-product-image-container {
					height: 78px;
				}

				.cart__product-img .raft-card__product-img {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}

					.cart__product-img img {
						width: 80%;
						height: 70%;
						object-fit: contain;
						pointer-events: none;
					}

			.cart__product-info {
				height: 100%;
				box-sizing: border-box;
				padding: 0px 0px 0px 10px;
				width: calc(100% - 72px);
				position: relative;
			}
			
			.payment__cart .cart__product-info {
				height: 80px;
				display: flex;
				flex-wrap: wrap;
				align-content: space-between;
			}
			
			.payment__cart .cart__item-container, 
			.payment__cart .cart__item-container .cart__product-img ,
			.payment__cart .cart__item-container .cart__product-img .animated-product-image-container,
			.payment__cart .cart__item-container .cart__product-img .animated-product-image-container .raft-card__product-img,
			.payment__cart .cart__item-container .cart__product-img .animated-product-image-container .raft-card__product-img .raft-card__product-img-src,
			.payment__cart .cart__item-container .cart__product-img .animated-product-image-container .raft-card__product-img .raft-card__product-img-src img {
				pointer-events: none !important;
			}

		.quantity-container__actions {
			display: flex;
			align-items: center;
		}

			.cart__product--top {
				width: 100%;
				margin: 2px 0px 0px 0px;
			}

			.cart-item-src.unavaialbe .cart__product--top {
				width: calc(100% - 96px);
			}

			.cart__product--bottom {
				margin: 2px 0px 0px 0px;
				width: 100%;
			}

				.payment__cart .cart__product--bottom {
					margin: 0px 0px 0px 0px;
				}

				.quantity-container {
					display: flex;
					justify-content: space-between;
				}

					.quantity-container__values {
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						text-align: right;
					}

						.payment__cart .quantity-container__values {
							flex-wrap: unset;
							white-space: nowrap;
							margin: 0px;
						}

						.quantity-container__values h2 {
							font-size: 16px;
							font-weight: normal;
							color: #636E72;
							width: 100%;
							text-transform: lowercase;
						}

						.quantity-container__values h1 {
							font-size: 16px;
							font-weight: bold;
							color: #2F3640;
							width: 100%;
							text-transform: lowercase;
						}

							.payment__cart .quantity-container__values h1 {
								color: #f55a30;
							}

								.payment__cart .quantity-container__values h1 span {
									width: 3px;
									display: inline-block;
								}
							
							.payment__cart .product__quantity {
								margin: 0px 0px 0px 0px;
							}
			
					.quantity-container__actions .plus, .quantity-container__actions .minus {
						width: 25px;
						height: 25px;
						font-size: unset !important;
						box-shadow: none;
					}

					.plus span {
						background: url("../images/plus_icon.svg") no-repeat;
						width: 10px;
						height: 10px;
						margin: 0px auto;
					}

					.minus span {
						background: url("../images/minus_icon.svg") no-repeat;
						width: 10px;
						height: 2px;
						margin: 0px auto;
					}

					.actions__value {
						border-radius: 3px;
						background: #fff;
						width: 40px;
						height: 27px;
						box-sizing: border-box;
						box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
						color: red;
						margin: 0px 5px;
						border: 0px;
						text-align: center;
						font-size: 16px;
						font-weight: 500;
						border: 1px solid #00000021;
					}

						.actions__value input::-webkit-input-placeholder {
							color: #FA583A;
							font-size: 26px;
							font-weight: 500;
						}

						.actions__value input::-moz-placeholder {
							color: #FA583A;
							font-size: 16px;
							font-weight: 500;
						}

						.actions__value input:-ms-input-placeholder {
							color: #FA583A;
							font-size: 16px;
							font-weight: 500;
						}
						
						.actions__value input:-moz-placeholder {
							color: #FA583A;
							font-size: 16px;
							font-weight: 500;
						}

					.product__title {
						font-size: 16px;
						font-weight: bold;
						color: #2F3640;
					}

						.payment__cart .product__title {
							font-size: 16px;
							font-weight: bold;
							color: #2F3640;
							max-width: 120px;
							word-break: break-word;
						}

					.product__subtitle {
						font-size: 14px;
						font-weight: 300;
						color: #636E72;
					}

						.payment__cart .product__subtitle {
							margin: 2px 0px 0px 0px;
						}

		.cart__payments {
			width: 100%;
			margin: 15px 0px 0px 0px;
			position: absolute;
			bottom: 30px;
		}

			.cart--gradient {
				width: calc(100% + 12px);
				height: 100%;
				position: absolute;
				z-index: 1;
				left: -12px;
				bottom: 22px;
				pointer-events: none;
				background: -moz-linear-gradient(top, rgba(245,246,250,0) 0%, rgba(245,246,250,0) 1%, rgba(245,246,250,1) 67%, rgba(245,246,250,1) 68%);
				background: -webkit-linear-gradient(top, rgba(245,246,250,0) 0%,rgba(245,246,250,0) 1%,rgba(245,246,250,1) 67%,rgba(245,246,250,1) 68%);
				background: linear-gradient(to bottom, rgba(245,246,250,0) 0%,rgba(245,246,250,0) 1%,rgba(245,246,250,1) 67%,rgba(245,246,250,1) 68%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f5f6fa', endColorstr='#f5f6fa',GradientType=0 );
				padding: 70px 0px 20px 0px;
				transition: all 0.2s ease-in-out;
			}

			.cart--positioned {
				position: relative;
				z-index: 1;
			}

			.remove__cart-item {
				position: absolute;
				z-index: 1;
				left: -15px;
				top: 4px;
				transform: scale(0.8);
				opacity: 0;
				visibility: hidden;
				transition: all 0.2s ease-in-out;
			}

				.remove__cart-item .small-button--rounded {
					padding: 0px;
				}

				.small-button--rounded .remove__icon{
					mask: url("../images/x_icon.svg") no-repeat center center;
					-webkit-mask: url("../images/x_icon.svg") no-repeat center center;
					-webkit-mask-size: contain;
					background: #fff;
					width: 9px;
					height: 9px;
					display: inline-block;
					-webkit-mask-position-x: 0px;
				}

			.cart__total-value {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
			}

				.value__text {
					line-height: 21px;
				}

					.cart__total-value span {
						font-size: 18px;
						color: #636E72;
						font-weight: 300;
					}

					.cart__total-value h2 {
						font-size: 26px;
						color: #2F3640;
						font-weight: 900;
					}
			
			.clear-cart button {
			    color: #636E72;
				font-size: 14px;
				font-weight: bold;
				display: flex;
				align-items: flex-end;
			}

				.clear-cart button span {
					font-size: 24px;
					font-weight: bold;
					line-height: 18px;
					margin: 0px 5px 0px 0px;
				}

		.cart__buttons {
			width: 100%;
			margin: 12px 0px 0px 0px;
			display: flex;
		}

			.cart__payments-block.cart__buttons button {
				flex: 0 0 100%;
				width: 100%;
			}

			.cart__pay-button {
				font-weight: 700;
				font-size: 16px;
				text-transform: uppercase;
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
			}

				.cart__pay-button .cart__pay-button-icon {
					mask: url("../images/credit_card_icon.svg") no-repeat center center;
					-webkit-mask: url("../images/credit_card_icon.svg") no-repeat center center;
					-webkit-mask-size: cover;
					background: #fff;
  					mask-size: cover;
					color: #fff;
					width: 18px;
					height: 14px;
					text-indent: -9999px;
					display: inline-block;
					margin: 0px 10px 0px 0px;
				}

				.frown {
					mask: url("../images/frown.svg") no-repeat center center;
					-webkit-mask: url("../images/frown.svg") no-repeat center center;
					-webkit-mask-size: cover;
					background: #fff;
  					mask-size: cover;
					color: #fff;
					width: 14px;
					height: 14px;
					display: inline-block;
					margin: 0px 3px 0px 0px;
					position: relative;
					top: 3px;
				}

			.cart__save-button {
				font-weight: 700;
				font-size: 16px;
				text-transform: uppercase;
				border-top-left-radius: 0px;
				border-bottom-left-radius: 0px;
			}

				.cart__save-button .cart__save-button-icon {
					mask: url("../images/plus_icon.svg") no-repeat center center;
					-webkit-mask: url("../images/plus_icon.svg") no-repeat center center;
					-webkit-mask-size: cover;
					background: #fff;
  					mask-size: cover;
					width: 14px;
					height: 14px;
					text-indent: -9999px;
					display: inline-block;
					margin: 0px 7px 0px 0px;
					position: relative;
				}
		
		#empty-cart {
			width: 100%;
			height: calc(100% - 187px);
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			margin: 15px 0px;
			border-radius: 5px;
			box-sizing: border-box;
			border: 2px dashed transparent;
			transition: all 0.2s ease-in-out;
		}

		.trim__items:before {
			content: "";
			display: inline-block;
			position: absolute;
			border: 4px dashed #DFE6E9;
			-webkit-transition: all 0.2s ease-in-out;
			-o-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			opacity: 0;
			width: 94%;
			height: 90%;
			left: 14px;
			top: 6px;
			border-radius: 4px;
		}

		.drop-area .trim__items:before {
			opacity: 1;
		}

		.cart__content.deactivate .cart__items {
			pointer-events: none;
		}

		.cart__content.deactivate .cart__items,
		.cart__content.deactivate .cart__items .cart__items--scrollable--web.cart__items--scrollable,
		.cart__content.deactivate .cart__items:before,
		.cart__content.deactivate .cart__items .cart-item-src,
		.cart__content.deactivate .cart__items .cart__item-container,
		.cart__content.deactivate .cart__items .cart__item-container .remove__cart-item,
		.cart__content.deactivate .cart__items .cart__item-container .remove__cart-item .small-button--rounded,
		.cart__content.deactivate .cart__items .cart__item-container .remove__cart-item .small-button--rounded .remove__icon,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-img,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-img .animated-product-image-container,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-img .animated-product-image-container .raft-card__product-img,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-img .animated-product-image-container .raft-card__product-img .raft-card__product-img-src,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-img .animated-product-image-container .raft-card__product-img .raft-card__product-img-src img,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--top,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--top .title-status,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--top .title-status .product__title,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--top .title-status .status-disclaimer,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--bottom,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--bottom .product__quantity,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--bottom .product__quantity .quantity-container,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--bottom .product__quantity .quantity-container,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--bottom .product__quantity .quantity-container .quantity-container__actions,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--bottom .product__quantity .quantity-container .quantity-container__actions .minus,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--bottom .product__quantity .quantity-container .quantity-container__actions .plus,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--bottom .product__quantity .quantity-container .quantity-container__actions .actions__value,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--bottom .product__quantity .quantity-container .quantity-container__values,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--bottom .product__quantity .quantity-container .quantity-container__values h2,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--bottom .product__quantity .quantity-container .quantity-container__values h1,
		.cart__content.deactivate .cart__items .cart__item-container .cart__product-info .cart__product--bottom .product__quantity .quantity-container .quantity-container__values h1 span
		{
			pointer-events: none;
		}

		.drop-area .cart__items--scrollable {
			transform: scale(0.9);
			opacity: 0.5;
		}

		.ondragover {
			border: 2px solid dashed #B2BEC3;
		}

			.drop-area .empty-cart__container {
				border: 4px dashed #DFE6E9;
				border-radius: 5px;
			}

			.drop-area .empty-cart__container .scaled {
				transform: scale(0.9);
				opacity: 0.3;
			}

			.scaled {
				transition: all 0.2s ease-in-out;
				opacity: 1;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				margin: 0px 0px 30px 0px
			}

			.empty-cart__container {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				margin: 0px 0px 15px 0px;
				pointer-events: none;
				width: 100%;
				height: 95%;
				border: 2px dashed transparent;
				transition: all 0.2s ease-in-out;
			}

				.empty-cart-icon {
					width: 128px;
					height: 128px;
					background: #DFE6E9;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: all 0.2s ease-in-out;
				}

				.empty-state-icon {
					width: 76px;
					height: 76px;
					background: #DFE6E9;
					border-radius: 50%;
					display: flex;
					align-items: center;
					opacity: 1;
					justify-content: center;
				}

				.empty-cart-icon span{
					background: url("../images/empty_cart.svg") no-repeat center center;
					width: 58px;
					height: 58px;
				}
				
				.empty-cart__container h2 {
					font-size: 22px;
					font-weight: 400;
					color: #636E72;
					width: 70%;
					text-align: center;
					display: inline-block;
					margin: 30px 0px 0px 0px;
					opacity: 1;
					box-sizing: border-box;
					padding: 0px 20px;
					transition: all 0.2s ease-in-out;
				}
				
				#empty-state {
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				#empty-locations {
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}

					#empty-locations .empty-cart__container h2 {
						width: 100%;
						margin: 20px 0px 0px 0px;
						font-size: 20px;
						color: #dee6e9;
					}
				
				#empty-state .empty-cart__container {
					margin: 0px;
				}

					.empty-state-icon.empty-location-icon {
						width: 62px;
						height: 62px;
					}
				
					.empty-state-icon span{
						background: url("../images/empty_notifications_icon.svg") no-repeat center center;
						width: 24px;
						height: 28px;
					}

					.empty-location-icon span{
						-webkit-mask-image: url("../images/map-marker-alt.svg");
						mask-image: url("../images/map-marker-alt.svg");
						background: #636E72;
						background-size: 100%;
						-webkit-mask-size: 100%;
						mask-size: 100%;
						-webkit-mask-repeat: no-repeat;
						mask-repeat: no-repeat;
						background: #fff;
						width: 24px;
						height: 34px;
					}

					.empty-favorites-icon {
						width: 76px;
						height: 76px;
						background: #DFE6E9;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
					}

						.empty-favorites-icon span{
							background: url("../images/empty_favorites_icon.svg") no-repeat center center;
							width: 32px;
							height: 28px;
							display: inline-block;
						}

					#empty-state h2 {
						font-size: 18px;
						font-weight: 400;
						color: #B2BEC3;
						width: 70%;
						text-align: center;
						display: inline-block;
						margin: 30px 0px 0px 0px;
					}

	.cart-src {
		display: flex;
    	align-items: center;
	}
	
		.cart-src .cart__pay-button-text {
			line-height: 13px;
			display: inline-block;
			margin: 1px 0px 0px 0px;
			font-weight: normal;
			font-size: 16px;
		}

	.cart .cart__items .animated-product-image-container {
		height: 80px;
		max-height: 80px;
	}

/* 
	#PRODUCT POPUP
*/

.product__popup {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
	z-index: 9997 !important;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s ease-in-out;
}

.testovi {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

	.product__popup--overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 9999;
		opacity: 0.7;
		background: #100e0e;
	}

	.product__popup--blur {
        filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');
        -webkit-filter: url(#blur-filter);
        filter: url(#blur-filter);
        -webkit-filter: blur(3px);
        filter: blur(3px);
    }

	.product__popup--blur {
		position: absolute;
		pointer-events: none;
		width: 100%;
		height: 100%;
		filter: blur(5px);
	}

	.product__popup-container {
		position: relative;
		z-index: 9999;
		background: #fff;
		/* width: 70%; */
		width: 896px;
		/* max-height: 90%; */
		max-height: 95%;
		overflow: auto;
		box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
		border-radius: 4px;
		box-sizing: border-box;
		padding: 0px 30px 0px 0px;
		transform: scale(0.95);
		transition: all 0.2s ease-in-out;
		transform: translate3d(0%, 0%, 0);
	}

		#product--web .product__popup-container .centered-data__picture img {
			max-height: 200px;
		}

		#product--web .product__popup-container {
			width: 896px;
			/* height: 540px; */
			height: 560px;
		}

		#product--web .product__popup-container.expand-popup {
			width: 896px;
			height: 560px;
			max-height: unset;
		}

		#payment__popup .product__popup-container {
			width: 958px;
			height: 580px;	
		}

		.product__popup-container::-webkit-scrollbar {
			width: 8px;
			border-radius: 50px;
		}
		
		.product__popup-container::-webkit-scrollbar-track {
			background: #DFE6E9;
			border-radius: 50px;
		}
		
		.product__popup-container::-webkit-scrollbar-thumb {
			background: #B2BEC3;
			border-radius: 50px;
		}

		.product__popup-main {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}

			.product__popup--full {
				display: flex;
				width: 100%;
				justify-content: space-between;
				position: absolute;
				z-index: 9999;
				top: 30px;
				box-sizing: border-box;
				padding: 0px 30px;
			}

			.product__section--left {
				width: 35%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background: url("../images/popup_image_decorator.svg") no-repeat top left;
				position: relative;
			}

				.product__section--left .meta-measurment {
					right: 50px;
					bottom: 40px;
					top: unset;
				}

				#product--mobile .product__section--left .meta-measurment {
					right: 0;
					bottom: 0;
				}

				#product--mobile .recommended__titles h2 {
					font-size: 16px !important;
					margin: 0px !important;
				}

				.section__favorite {
					display: flex;
					align-items: center;
					flex-direction: row-reverse;
					position: relative;
				}

					.remove-favorite.raft-card__favorite.icon-button--naked.favorite--active {
						position: absolute !important;
						left: 0;
						z-index: 1;
					}

					.section__favorite .raft-card__favorite.icon-button--naked.favorite--active {
						position: static;
						width: 28px;
						height: 28px;
					}

					.section__favorite .badges__container {  
						margin: 0px 0px 0px 15px;
					}

					.badges__container.clip-margin {
						margin: 0px;
					}

						.section__favorite .badge__container {
							margin: 0px 5px 0px 0px;
						}

							.section__favorite .badge {
								width: 26px;
								height: 26px;
								margin: 0px 0px 0px 0px;
							}

					.section__favorite .raft-card__favorite {
						width: 28px;
						height: 28px;
						
					}

					.section__favorite .raft-card__favorited {
						width: 29px;
						height: 25px;
						left: 30px;
					}

					.product__section--left .raft-card__product-img {
						width: 65%;
						height: 100%;
						margin: 60px 0px 0px 0px;
					}

					.product__section--left .raft-card__product-img img {
						width: 100%;
						height: 100%;
						object-fit: contain;
					}

			.product__section--right {
				width: 65%;
				box-sizing: border-box;
				padding: 0px 0px 0px 20px;
				margin: 60px 0px 0px 0px;
			}

				/* .close__popup-button  {
					background: url("../images/x_dark_icon.svg") no-repeat center center;
					width: 18px;
					height: 18px;
				} */

				.product__section--top {
					display: flex;
					justify-content: space-between;
				}

				.product__titles:last-of-type {
					display: flex;
					align-items: flex-end;
				}

					.popup__product-category {
						width: 100%;
						font-size: 16px;
						text-transform: uppercase;
						color: #B2BEC3;
					}

					.popup__product-title {
						font-weight: bold;
						font-size: 28px;
						color: #636E72;
						width: 100%;
					}

						.popup__product-title span {
							text-transform: lowercase;
							margin: 0px 0px 0px 8px;
						}

					.popup__product-price {
						font-size: 25px;
						color: #2F3640;
						font-weight: 800;
					}

						.price-row.main {
							font-weight: 900;
							font-size: 30px;
							line-height: 22px;
						}

						.price-row.below {
							font-size: 16px;
							font-weight: 500;
							opacity: 0.5;
							margin: 0px 0px 0px 0px;
						}

						#product--web .product__titles:last-of-type {
							/* align-items: center; */
						}

						.cross {
							font-size: 18px;
							text-decoration: line-through;
							text-align: right;
							font-weight: normal;
							color: #b4bfc4;
						}

						.popup__product-price span {
							text-transform: lowercase;
							display: block;
						}
				
				.product__section-block-inside button {
					font-size: 20px;
					height: 42px;
					line-height: 40px;
					padding-top: 0px;
					padding-bottom: 0px;
				}

			.popup__tabs {
				display: inline-block;
				/* margin: 10px 0px 0px 0px; */
				margin: 0px 0px 0px 0px;
				width: 100%;
				position: relative;
			}

				.popup__tabs .popup__tabs-content{
					display: inline-block;
					width: 100%;
				}

					.popup__tabs .popup__tabs-content .tabs{
						margin: 0px;
					}

						.popup__tabs .popup__tabs-content .tabs::after{
							content: "";
							display: table;
							clear: both;
						}
						cart-item-src 
					.tab__paragraph {
						font-size: 18px;
						font-weight: 300;
						line-height: 1.44;
						color: #636e72;
						margin: 0px;
					}

					.tabs-fade {
						width: 100%;
						height: 50px;
						position: absolute;
						bottom: 8px;
						left: 0;
						pointer-events: none;
						background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
						background: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0)),to(rgba(255,255,255,1)));
						background: -o-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
						background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
						opacity: 0.7;
					}

					.popup__add-to-cart {
						mask: url("../images/plus_icon.svg") no-repeat center center;
						-webkit-mask: url("../images/plus_icon.svg") no-repeat center center;
						-webkit-mask-size: contain;
						mask-size: contain;
						background: #fff;
						width: 11px;
						height: 40px;
						text-indent: -9999px;
						margin: 0px 7px 0px 0px;
					}

					_::-webkit-full-page-media, _:future, :root .popup__add-to-cart {
						/* margin: 7px 8px 0px 0px; */
					}

		.popup__quantity {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

			.button-disclaimer h2 {
				font-size: 13px;
				color: #fa583a;
				font-weight: bold;
				margin: 0px 0px 5px 0px;
			}

			.popup__quantity .quantity-container{
				flex-wrap: wrap;
			}

				.popup__quantity .quantity-container__actions{
					/* width: 100%; */
					margin: 0px 20px 0px 0px;
				}

				.popup__quantity .quantity-container__values{
					flex-wrap: wrap;
					text-align: left;
					margin: 0px 0px 0px 0px;
					line-height: 18px;
				}

		.product__popup--recommended {
			width: 100%;
			box-sizing: border-box;
			padding: 0px 0px 0px 30px;
			margin: 20px 0px 0px 0px;
			position: relative;
		}

			.recommended__header {
				text-align: center;
				width: 100%;
				box-sizing: border-box;
				padding: 10px 0px;
				margin: 0px 0px 20px 0px;
				border-top: 1px solid #DFE6E9;
				border-bottom: 1px solid #DFE6E9;
			}

				.recommended__header h2 {
					font-size: 14px;
					font-weight: bold;
					letter-spacing: 5px;
					text-transform: uppercase;
					color: #636E72;
				}

			.recommended__product {
				width: 150px;
			}

				.recommended__product .raft-card__product-img{
					height: 63px;
				}

				.recommended__titles {
					text-align: center;
					margin: 15px 0px 0px 0px;
					line-height: 17px;
				}

					.recommended__titles span{
						font-size: 16px;
						color: #B2BEC3;
						text-transform: lowercase;
						margin: 0px 0px 0px 5px;
					}

					.recommended__titles .recommended__sub-title {
						display: inline-block;
						width: 80%;
						margin: 0px auto;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}

					.recommended__titles h2{
						font-size: 16px;
						font-weight: bold;
						line-height: 20px;
						color: #2F3640;
						margin: 0px 0px 0px 0px;
					}
				

				.product__popup--recommended .slick-slider{
					box-sizing: border-box;
    				padding: 0px 35px 0px 20px;
    				/* padding: 0px 35px 0px 20px; */
				}

					.product__popup--recommended .slick-arrow {
						width: 22px;
						height: 22px;
						border: 1px solid #FA583A;
						border-radius: 3px;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: all 0.2s ease-in-out;
						z-index: 9999;
						top: 50px;
					}

					.product__popup--recommended .slick-prev {
						left: -5px;	
					}

					.product__popup--recommended .slick-next {
						right: 0;
					}

					.product__popup--recommended .slick-disabled {
						border-color: #F4CECC;
					}

						.product__popup--recommended .slick-prev:before {
							content: url("../images/slider_arrow_left_icon.svg");
							width: 6px;
							height: 11px;
							display: block;
							line-height: 5px;
							margin: 0px auto;
						}

						.product__popup--recommended .slick-next:before {
							content: url("../images/slider_arrow_right_icon.svg");
							width: 6px;
							height: 11px;
							display: block;
							line-height: 5px;
							margin: 0px auto;
						}


				.recommended__gradient--decorator {
					width: 250px;
					/* height: 140px; */
					height: 171px;
					position: absolute;
					right: 0;
					top: 52px;
					margin: auto;
					pointer-events: none;
					background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
					background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
					background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
				}
	


		.tab__paragraph {
			font-size: 18px;
			font-weight: 300;
			line-height: 1.44;
			color: #636e72;
			margin: 0;
		}

	.nutri-table-container {
		position: relative;
	}

		.nutri-table-container .ingridient-list {
			display: flex;
			flex-wrap: wrap;
			color: #636E72;
			font-size: 16px;
			font-weight: 400;
		}

		.nutri-table {
			width: 100%;
			border: 0px;
			border-collapse: collapse;
			margin: 0px 0px 30px 0px;
		}

			.nutri-table thead tr th {
				background: #f7f7f7;
				border-radius: 4px;
				border-right: 3px solid #fff;
				text-align: left;
				box-sizing: border-box;
				padding: 5px 12px;
				text-transform: capitalize;
				color: #636E72;
				font-size: 16px;
			}

			.nutri-table tbody tr {
				border-bottom: 1px solid #00000014;
			}

				.nutri-table tbody tr td {
					background: #fff;
					border-radius: 4px;			
					text-align: left;
					box-sizing: border-box;
					padding: 5px 12px;
					text-transform: capitalize;
					color: #636E72;
					font-size: 15px;
					border: 0px;
				}

			.table__gradient--decorator {
				width: 100%;
				height: 55px;
				position: fixed;
				/* bottom: 0; */
				margin: -97px 0px 0px 0px;
				pointer-events: none;
				background: -moz-linear-gradient(bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
				background: -webkit-linear-gradient(bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
				background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
			}

			.rc-tabs-tabpane.rc-tabs-tabpane-active.nutri-table-container {
				box-sizing: border-box;
				padding: 1px 0px 0px 0px;
			}

				.nutri-table-container .tab__paragraph {
					display: flex;
					flex-wrap: wrap;
				}

				.ingridients-table-container .ingridient-list {
					display: flex;
					flex-wrap: wrap;
					color: #636E72;
					font-size: 16px;
					font-weight: 400;
				}

					.tab__paragraph-container {
						display: flex;
						align-items: flex-start;
						margin: 0px 0px 5px 0px;
					}
				
					.ingridients-table-container .tab-pane-title {
						font-size: 16px;
						margin: 0px 5px 0px 0px;  
					}

					.ingridients-table-container .ingridient-list .meta-item-group {
						font-weight: 400;
						margin: 0px 5px 0px 0px;
						/* white-space: nowrap; */
					}

						.ingridients-table-container .ingridient-list .meta-item-group:before {
							content: none;
						}

						.ingridients-table-container .ingridient-list .meta-item-group:after {
							content: ",";
						}

							.ingridients-table-container .ingridient-list .meta-item-group:last-child:after {
								content: none;
							}

					.meta-item-group {
						display: flex;
						align-items: center;
						margin: 0px 10px 10px 0px;
					}

						.meta-item-group:before {
							content: "";
							background: #f55a30;
							width: 8px;
							height: 8px;
							display: inline-block;
							margin: 0px 6px 0px 0px;
							border-radius: 50%;
						}


						.meta-item-group h2 {
							font-size: 16px;
							color: #636E72;
							font-weight: 600;
							text-transform: lowercase;
						}

							.meta-item-group h2::first-letter {
								text-transform: uppercase;
							}

						.ingridients-table-container .ingridient-list .meta-item-group h2 {
							font-weight: 400;

						}

							.meta-item-group h2 span {
								margin: 0px 0px 0px 5px;
								box-sizing: border-box;
								padding: 3px 8px;
								background: #f1f1f1;
								border-radius: 50px;
								font-family: sans-serif;
								font-size: 14px;
								color: #636E72;
								font-weight: 400;
							}

								.meta-item-group h2 span .unit {
									margin: 0px 0px 0px 2px;
									padding: 0px;
									font-size: 11px;
								}
	
	.cart-item-src.unavaialbe .cart__item-container {
		/* pointer-events: none; */
	}

	.cart-item-src.unavaialbe .cart__product-img, .cart-item-src.unavaialbe .cart__product-info {
		pointer-events: none;
		opacity: 0.4;
	}

	#product--web .section__close .close__popup-button.icon-button--naked {
		display: inline-block;
		background: url("../images/x_dark_icon.svg") no-repeat center center;
		width: 16px;
		height: 16px;
	}

	#product--mobile .section__close .close__popup-button.icon-button--naked {
		display: inline-block;
		background: url("../images/x_dark_icon.svg") no-repeat center center;
		width: 18px;
		height: 18px;
	}

	#product--mobile .raft-card__price {
		margin: 10px 0px 0px 0px;
	}

	.close__popup-button {
		display: inline-block;
		background: url("../images/x_dark_icon.svg") no-repeat center center;
		width: 18px;
		height: 18px;
	}

	.cart-button-title {
		height: 17px;
		line-height: 14px;
	}

	#product--web .product__section--left .animated-product-image-container {
		height: 200px !important;
		max-height: 200px;
	}

		#product--mobile .product__section--left .animated-product-image-container {
			width: 100%;
			height: 100%;
		}

			#product--mobile .product__section--left .animated-product-image-container .raft-card__product-img {
				width: 100%;
				height: 100%;
			}

				#product--mobile .product__section--left .animated-product-image-container .raft-card__product-img .raft-card__product-img-src {
					width: 100%;
					height: 100%;
				}


/* 
	LOGIN & REGISTER POPUP
*/

#login__popup .product__popup--full {
	position: static;
	padding: 0px;
}

	#login__popup .product__popup-container {
		padding: 30px;
		/* width: 40%;   */
		width: 550px;  
	}

		#login__popup .product__popup-main {
			flex-wrap: wrap;
			flex-direction: column;
		}

			#login__popup .auth__popup-title {
				font-size: 22px;
				color: #636E72;
			}

		.social-login {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 40px 0px 0px 0px;
		}

			.social-login a {
				width: 49%;
				height: 40px;
				border-radius: 50px;
				color: #F5F6FA;
				font-size: 18px;
				font-weight: 300;
				display: flex;
				align-items: center;
				justify-content: center;
			}

				.slb__facebook {
					background: #3B5998;
				}

					.slb__facebook span{
						display: inline-block;
						background: url("../images/facebook_icon.svg") no-repeat center center;
						width: 9px;
						height: 17px;
						margin: 0px 10px 0px 0px;
					}

				.slb__google {
					background: #DD4B39;
				}

					.slb__google span{
						display: inline-block;
						background: url("../images/google_icon.svg") no-repeat center center;
						width: 27px;
						height: 17px;
						margin: 0px 10px 0px 0px;
					}

	.popup__section-divider {
		width: 100%;
		height: 1px;
		border: 0px;
		background: #DFE6E9;
		margin: 20px 0px 0px 0px;
	}

	.popup__inputs {
		margin: 30px 0px 0px 0px;
	}

		.popup__inputs p{
			width: 100%;
			margin: 0px 0px 13px 0px;
		}

			.password__input {
				position: relative;
			}

				.password__input .reveal__password-button {
					background: url("../images/reveal_password_icon.svg") no-repeat center center; 
					width: 20px;
					height: 40px;
					position: absolute;
					right: 20px;
					line-height: 40px;
					/* top: 0; */
					/* bottom: 0; */
					/* margin: auto; */
				}

				.reveal__password-button.icon-button--naked.revealed {
					background: url("../images/hide_password_icon.svg") no-repeat center center; 
				}

			.popup__inputs p .auth__popup-title {
				display: block;
				margin: 30px 0px 15px 0px;
				font-weight: bold;
			}

			.popup__inputs p:last-of-type{
				margin: 0px;
			}

			.placeholder__input {
				width: 100%;
				height: 40px;
				border: 1px solid #B2BEC3;
				border-radius: 4px;
				padding: 0px 17px;
				box-sizing: border-box;
				font-size: 18px;
				font-weight: 300;
				color: #B2BEC3;
				transition: all 0.2s ease-in-out;
			}

				.placeholder__input:focus {
					color: #636E72;
					border-color: #636E72;
				}

					.placeholder__input:focus::-webkit-input-placeholder  {
						color: #636E72;
						border-color: #636E72;
					}

					.placeholder__input:focus::-moz-placeholder  {
						color: #636E72;
						border-color: #636E72;
					}

					.placeholder__input:focus::-ms-input-placeholder  {
						color: #636E72;
						border-color: #636E72;
					}

					.placeholder__input:focus:-moz-placeholder  {
						color: #636E72;
						border-color: #636E72;
					}

				.placeholder__input::-webkit-input-placeholder {
					color: #B2BEC3;
					transition: all 0.2s ease-in-out;
				}

				.placeholder__input::-moz-placeholder {
					color: #B2BEC3;
					transition: all 0.2s ease-in-out;
				}

				.placeholder__input::-ms-input-placeholder {
					color: #B2BEC3;
					transition: all 0.2s ease-in-out;
				}

				.placeholder__input:-moz-placeholder {
					color: #B2BEC3;
					transition: all 0.2s ease-in-out;
				}

			.checkbox__label {
				display: flex;
				align-items: center;
				margin-bottom: 40px !important;
			}

			.checkbox__label.recovery {
				justify-content: space-between;
			}

				.input-entry {
					display: flex;
					align-items: center;
				}

					.checkbox__label.recovery .recovery-link {
						font-size: 18px;
						color: #B2BEC3;
						font-weight: 300;
						cursor: pointer;
						text-decoration: underline;
					}

				.checkbox__label label {
					font-size: 18px;
					color: #B2BEC3;
					font-weight: 300;
					margin: 0px 0px 0px 7px;
					cursor: pointer;
				}

					.checkbox {
						background: transparent;
						border: 2px solid #707070;
						width: 20px;
						height: 20px;
						border-radius: 4px;
						display: inline-block;
						position: relative;
						cursor: pointer;
					}

						.checkbox:active, .checkbox:checked:active {
							box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
						}
						
						.checkbox:checked:after {
							content: url("../images/checked_icon.svg");
							width: 20px;
							height: 14px;
							position: absolute;
							top: -2px;
							left: 3px;
						}
						.checkbox:hover:after {
							content: url("../images/checked_icon.svg");
							width: 20px;
							height: 14px;
							position: absolute;
							top: -2px;
							left: 3px;
							opacity: 0.3;
						}
						.checkbox:checked:hover:after {
							content: url("../images/checked_icon.svg");
							width: 20px;
							height: 14px;
							position: absolute;
							top: -2px;
							left: 3px;
							opacity: 1;
						}

		.popup__inputs--half {
			display: flex;
			justify-content: space-between;
		}

			.popup__inputs--half input{
				width: 49% !important;
			}

			.register .popup__inputs p .auth__popup-title {
				margin: 8px 0px;
			}

.bad-login {
    color: #e74c3c;
    font-size: 17px;
    line-height: 23px;
    margin: 18px 0px 15px 0px;
}

.placeholder__input.bad-input {
    border-color: #e74c3c;
}

	.product__popup .button-loader {
		height: 100%;
		position: relative;
	}

		.product__popup .button-loader .lds-ellipsis {
			display: inline-block;
			position: relative;
			width: 64px;
			height: 38px;
		}

		#location__popup .settings__content-block--actions .button-loader {
			width: auto !important;
		}

			#location__popup .settings__content-block--actions .button-loader .lds-ellipsis {
				justify-content: center;
				align-items: center;
				width: 60px;
			}

				#location__popup .settings__content-block--actions .button-loader .lds-ellipsis div {
					width: 9px !important;
				}


			.product__popup .lds-ellipsis div {
				top: 0;
				bottom: 0;
				margin: auto;
				width: 9px;
				height: 9px;
				background: #ffffff;
			}
	
	#recover__popup .product__popup--full {
		position: static;
		padding: 0px;
	}

		#recover__popup .product__popup-container {
			padding: 30px;
			width: 40%;  
		}

			#recover__popup .product__popup-main {
				flex-wrap: wrap;
				flex-direction: column;
			}

				#recover__popup .auth__popup-title {
					font-size: 22px;
					color: #636E72;
				}

			#recover__popup .popup__inputs {
				margin: 20px 0px 0px 0px;
			}

				#recover__popup .popup__inputs .action-paragraph{
					margin: 25px 0px 0px 0px;
				}

				.text-info {
					margin: 0px 0px 20px 0px;
				}

					.text-info p {
						font-size: 18px;
						font-weight: 100;
						color: #636E72;  
					}

/* 
	MENU
*/

.menu {
    width: 400px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    box-shadow: none;
	background: #f5f6fa;
	transform: translate3d(-400px, 0px, 0px);
	transition: all 0.2s ease-in-out;
}

	.menu.menu--show {
		box-shadow: 5px 0 23px 0 rgba(0, 0, 0, 0.16);
	}

.menu_oppened--overlay {
    position: absolute;
    left: 0;
    top: 0;
	background: #636e72;
    width: 100%;
    height: 100%;
	z-index: 9998;
	visibility: hidden;
	opacity: 0;
	transition: all 0.2s ease-in-out;
}

.app__container {
	transition: all 0.2s ease-in-out;
}

	.slide__menu--on {
		position: fixed;
		transform: translate3d(400px, 0px, 0px);
	}

	.slide__menu--on .menu_oppened--overlay {
		visibility: visible;
		opacity: 0.6;
	}

	.menu__header {
		width: 100%;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #fff;
		padding: 15px 30px;
		box-sizing: border-box;
		border-bottom: 1px solid rgba(223, 230, 233, 0.5);
	}

		.menu__header .close-menu .close__popup-button {
			width: 12px;
			height: 12px;
		}

		.menu__header .avatar {
			cursor: default;
		}

			.menu__header .avatar:after {
				display: none;
			}

		.menu__header .header__login {
			margin: 0px;
		}

		.menu__header .header__login .register-button {
			margin: 0px;
		}

		.menu-action-buttons {
			display: flex;
		}
		
			.menu-action-buttons .header__login {
				margin: 0px 0px 0px 10px;
			}

	.menu__content {
		width: 100%;
		height: calc(100% - 80px);
		overflow: auto;
		padding: 15px 30px;
		box-sizing: border-box;
		margin: 10px 0px 0px 0px;
	}

		.menu__content--block.intro h2 {
			font-size: 26px;
			color: #636E72;
			font-weight: 600;
		}

		.menu__content--block.intro span {
			font-size: 18px;
			color: #B2BEC3;
			line-height: 24px;
			font-weight: 300;
			display: block;
			margin: 15px 0px 0px 0px;
		}

		.categories__header h2 {
			font-size: 26px;
			color: #636E72;
			padding: 0px 0px 20px 0px;
			border-bottom: 1px solid #dfe6e9;
		}

			.categories__header h2:before {
				content: url("../images/list_icon.svg");
				width: 21px;
				height: 16px;
				display: inline-block;
				margin: 0px 12px 0px 0px;
			}

		.menu__content--block.categories {
			margin: 20px 0px 0px 0px;
		}

			.menu__content--block.categories ul {
				margin: 20px 0px 0px 0px;
			}

			.sub-categories-list {
				box-sizing: border-box;
				padding: 0px 0px 0px 28px;
			}

				.menu__content--block.categories ul li{
					margin: 0px 0px 30px 0px;
					cursor: pointer;
				}

					.menu__content--block.categories ul li a{
						font-size: 24px;
						color: #636E72;
						font-weight: 300;
					}

						.menu__content--block.categories .sub-categories-list li a {
							font-size: 20px;
						}

						.menu__content--block.categories ul li a:before{
							content: url("../images/chevron_right_red.svg");
							width: 10px;
							height: 14px;
							display: inline-block;
							margin: 0px 15px 0px 0px;
						}

						.menu__content--block.categories .sub-categories-list li a:before{
							width: 8px;
							height: 12px;
						}

/* 
	#TRACK ORDER
*/

.track-order {
    width: 100%;
    height: 114px;
    display: flex;
    margin: 72px 0px 0px 0px;
    align-items: center;
    justify-content: center;
    background: #eef1f4f7;
    border-bottom: 1px solid rgba(178, 190, 195, 0.25);
    position: absolute;
	z-index: 9996;
	transform-origin: center top;
	top: 0;
	transition: all 0.2s ease-in-out;
}
	.track-order.track-order--slideup {
		transform: translate3d(0px, -116px, 0px);
		visibility: hidden;
		opacity: 0;
	}

	.track-order.track-order--slidedown {
		transform: translate3d(0px, 0px, 0px);
		visibility: visible;
		opacity: 1;
	}

	.track-order__container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

		.track-order__item {
			margin: 0px 40px;
		}

		.track-order__container .track-order__item.status {
			display: flex;
			margin-right: 14.5px;
			margin-left: 0px;
		}

			.status__item {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				position: relative;
				/* width: calc(100% / 4); */
				width: calc(100% / 3);
				box-sizing: border-box;
				padding: 0px 27.5px;
			}

				.status__item:last-of-type {
					margin: 0px;
				}

					.status__item:last-of-type:before, .status__item:last-of-type:after{
						display: none;
					}

				.status__item:after {
					content: "";
					width: 7px;
					height: 7px;
					border-radius: 50%;
					background: #B2BEC3;
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto;
					right: 7px;
				}

				.status__item:before {
					content: "";
					width: 7px;
					height: 7px;
					border-radius: 50%;
					background: #B2BEC3;
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto;
					right: -13px;
				}

				.status__item .status__item--circle {
					width: 43px;
					height: 43px;
					border-radius: 50%;
					border: 3px solid #707070;
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center
				}

					.status__item .status__item--circle:before {
						content: "";
						width: 100%;
						height: 100%;
						border-radius: 50%;
						background: #DFE6E9;
						border: 2px solid #fff;
						box-sizing: border-box;
						position: absolute;
						left: 0;
						top: 0;
					}

					.packing .status__item--circle span {
						width: 22px;
						height: 19px;
						display: block;
						background: url("../images/track_order_packing_icon.svg") no-repeat center center;
						position: relative;
					}

					.loading .status__item--circle span {
						width: 13px;
						height: 19px;
						display: block;
						background: url("../images/track_order_loading_icon.svg") no-repeat center center;
						position: relative;
					}

					.shipping .status__item--circle span {
						width: 24px;
						height: 14px;
						display: block;
						background: url("../images/track_order_shipping_icon.svg") no-repeat center center;
						position: relative;
					}

					.shipped .status__item--circle span {
						width: 19px;
						height: 17px;
						display: block;
						background: url("../images/track_order_shipped_icon.svg") no-repeat center center;
						position: relative;
					}

				.status__item .status__item--info {
					margin: 10px 0px 0px 0px;
				}

					.status__item .status__item--info h2 {
						font-size: 16px;
						font-weight: 300;
						color: #636E72;
						text-transform: lowercase;
						white-space: nowrap;
					}

			.timing {
				display: flex;
			}

			.timing span {
				font-size: 16px;
				color: #636E72;
				margin: 0px 3px 0px 0px;
			}

			.timing h2 {
				font-size: 16px;
				color: #636E72;
			}

	.track-order .centered-spinner h2 {
		font-size: 18px;
		margin: 0px 0px 0px 0px;
	}

	.track-order .lds-ring {
		width: 50px;
		height: 50px;
	}

		.track-order .lds-ring div {
			width: 36px;
			height: 36px;
			border: 4px solid #FA583A;
		}
/* 
	DROPDOWNS
*/

.dropdown-src {
	position: absolute;
    top: 80px;
    z-index: 9996;
    margin: 0px 0px 0px 0px;
    background: #fff;
    box-shadow: -6px 6px 36px 2px rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    border: 1px solid rgba(223, 230, 233, 0.45);
    border-radius: 4px;
    box-sizing: border-box;
	padding: 8px 0px;
	transition: all 0.2s ease-in-out;
	z-index: 9999;
}

	.dropdown-src:before {
		content: url("../images/dropdown_chevron.svg");
		width: 36px;
		height: 26px;
		display: inline-block;
		line-height: 26px;
		position: absolute;
		top: -24px;
		right: 10px;
	}

	#notifications:before {
		content: url("../images/dropdown_chevron_dark.svg") !important;
	}

.user-dropdown {
    /* top: 70px;
    margin: 0px 0px 0px -37px;  
	width: 233px; */
	top: 45px;
    right: -26px;
    width: 233px;
}

.notifications-dropdown {
	top: 70px;
    margin: 0px 0px 0px -168px;
	width: 335px;
	height: 310px;
	padding: 0px;
}

	.dropdown__header {
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		background: #F2F4F6;
		box-sizing: border-box;
		padding: 0px 20px;
	}

	#notifications .dropdown__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

		#notifications .dropdown__header .dropdown__header-item {
			display: flex;
			align-items: center;
		}

			.dropdown__header-item .clear__button {
				font-size: 13px;
				color: #636e72;
				font-weight: 600;
				text-transform: uppercase;
				text-align: right;
				box-sizing: border-box;
				position: relative;
				right: -7px;
			}

			.dropdown__header h2 {
				font-size: 16px;
				color: #636E72;
				margin: 0px 5px 0px 0px;
			}

			.dropdown__header span {
				width: 20px;
				height: 20px;
				line-height: 20px;
				box-shadow: 3px 3px 4px 0 #0000001a;
				background-color: #fa583a;
				font-size: 11px;
				font-weight: bold;
				color: #ffffff;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				font-family: sans-serif;
				font-weight: 400;
			}

	.notifications-container {
		width: 100%;
	}

		#notifications .notifications-container{
			overflow: auto;
			width: 100%;
			height: calc(100% - 40px);
		}

			#notifications .notifications-container::-webkit-scrollbar {
				width: 8px;
				border-radius: 50px;
			}
			
			#notifications .notifications-container::-webkit-scrollbar-track {
				background: #DFE6E9;
				border-radius: 50px;
			}
			
			#notifications .notifications-container::-webkit-scrollbar-thumb {
				background: #B2BEC3;
				border-radius: 50px;
			}

			#notifications .notifications-container ul li {
				float: left;
				width: 100%;
				box-sizing: border-box;
				padding: 15px 20px;
				border-bottom: 1px solid #EDF1F2;
				display: flex;
				flex-wrap: wrap;
				transition: all 0.2s ease-in-out;
			}

				#notifications .notifications-container ul li h2{
					color: #636E72;
					font-size: 15px;
					font-weight: 400;
				}

				#notifications .notifications-container ul li span{
					color: #B2BEC3;
					font-size: 14px;
					font-weight: 400;
					margin: 10px 0px 0px 0px;
				}

		.notifications-container ul li:hover .dropdown-item {
			background: #F8F9FA;
		}

		#notifications .notifications-container ul li:hover {
			background: #F8F9FA;
		}

		.notifications-container ul li .dropdown-item{
			width: 100%;
			display: flex;
			align-items: center;
			box-sizing: border-box;
			height: 42px;
			font-size: 15px;
			color: #636E72;
			font-weight: 300;
			text-transform: capitalize;
			padding: 0px 0px 0px 15px;
			transition: all 0.2s ease-in-out;
		}

			.notifications-container ul li.notifications__list--settings .dropdown-item span {
				mask: url("../images/settings_icon.svg") no-repeat center center;
				-webkit-mask: url("../images/settings_icon.svg") no-repeat center center;
				-webkit-mask-size: 70%;
				mask-size: 70%;
				background: #FA583A;
				width: 20px;
				height: 16px;
				display: inline-block;
				margin: 0px 12px 0px 0px;
			}

			.notifications-container ul li.notifications__list--statistics .dropdown-item span {
				mask: url("../images/statistics_icon.svg") no-repeat center center;
				-webkit-mask: url("../images/statistics_icon.svg") no-repeat center center;
				-webkit-mask-size: 90%;
				mask-size: 90%;
				-webkit-mask-position-x: 3px;
				background: #FA583A;
				width: 20px;
				height: 16px;
				display: inline-block;
				margin: 0px 12px 0px 0px;
			}

			.notifications-container ul li.notifications__list--order-history .dropdown-item span {
				mask: url("../images/order_history_icon.svg") no-repeat center center;
				-webkit-mask: url("../images/order_history_icon.svg") no-repeat center center;
				-webkit-mask-size: 80%;
				mask-size: 80%;
				-webkit-mask-position-x: 4px;
				background: #FA583A;
				width: 20px;
				height: 16px;
				display: inline-block;
				margin: 0px 12px 0px 0px;
			}

			.notifications-container ul li.notifications__list--logout .dropdown-item span {
				mask: url("../images/logout_icon.svg") no-repeat center center;
				-webkit-mask: url("../images/logout_icon.svg") no-repeat center center;
				-webkit-mask-size: cover;
				mask-size: cover;
				background: #FA583A;
				width: 21px;
				height: 16px;
				display: inline-block;
				margin: 0px 12px 0px 0px;
				-webkit-mask-position-x: 3px;
				padding: 0px !important;
			}

	.hide--dropdown {
		visibility: hidden;
		opacity: 0;
		transform: translate3d(0px, -5px, 0px);
	}

	.show--dropdown {
		visibility: visible;
		opacity: 1;
		transform: translate3d(0px, 0px, 0px);
	}

/* 
	#PAYMENT POPUP
*/

#payment__popup .product__popup-container {
	padding: 0px;
	/* width: 75%;
	height: 100%; */
	overflow: unset !important;
}

#payment__popup .product__popup-container .DayPickerInput-OverlayWrapper {
    position: relative;
	/* width: 280px; */
	max-width: 280px;
    position: fixed;
    z-index: 1;
}

#payment__popup .input__holders--col:last-of-type {
    /* margin: 0px 0px 22px 0px; */
}

.payment-item--web.input__holders--col.input__holders--col--halfed {
    margin: 0px 0px 10px 0px !important;
}

.payment-item--web .datepicker.d-from.datepicker--web{
    width: 49%;
}

	.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
		background: #FA583A !important;
	}

	#payment__popup .payment-item--web .DayPickerInput {
			width: 100%;
			max-width: 100%;
			position: relative;
	}

		.payment-item--web .DayPickerInput:after {
			content: url("../images/chevron_down.svg");
			width: 10px;
			height: 8px;
			position: absolute;
			right: 15px;
			top: 0;
			bottom: 0;
			margin: auto;
			line-height: 8px;
		}

	#payment__popup .product__popup-main {
		height: 100%;
		overflow: hidden;
	}

		.payment__cart {  
			width: 400px;
			background: #F5F6FA;
			overflow: auto;
			box-sizing: border-box;
			padding: 30px 0px 0px 0px;
			position: relative;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		}

			.payment__cart::-webkit-scrollbar {
				width: 8px;
				border-radius: 50px;
				position: relative;
				z-index: 10;
			}
			
			.payment__cart::-webkit-scrollbar-track {
				background: #DFE6E9;
				border-radius: 50px;
			}
			
			.payment__cart::-webkit-scrollbar-thumb {
				background: #B2BEC3;
				border-radius: 50px;
			}

			.payment__cart .spinner-container {
				width: 100%;
				height: calc(100% - 80px);
			}

			.payment__cart .cart__item-container {
				width: calc(100% - 40px);
				box-sizing: border-box;
				margin: 0px auto;
			}

				.payment__cart .cart__item-container:before {
					width: calc(100% + 30px);
					left: 0;
				}

				.payment__cart .cart__item-container:first-child:before {
					top: -29px;
					padding: 15px 0px 15px 0px;
				}

				.payment__cart .cart__item-container:last-child {
					border-bottom: 0px;
				}

				.payment__cart .cart__item-container .remove__cart-item{
					left: -15px;
				}

			.payment__cart .cart--gradient {
				width: 400px;
				height: 67px;
				position: fixed;
				z-index: 1;
				left: 0;
				bottom: 0px;
				pointer-events: none;
				padding: 0px;
				background: -moz-linear-gradient(top, rgba(245,246,250,0) 0%, rgba(245,246,250,1) 100%);
				background: -webkit-linear-gradient(top, rgba(245,246,250,0) 0%,rgba(245,246,250,1) 100%);
				background: linear-gradient(to bottom, rgba(245,246,250,0) 0%,rgba(245,246,250,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00f5f6fa', endColorstr='#f5f6fa',GradientType=0 );
				border-bottom-left-radius: 4px;
			}

		.payment__main-content {
			width: calc(100% - 400px);
			height: 100%;
			box-sizing: border-box;
			padding: 30px;
			position: relative;
			z-index: 1;
		}

			.payment__main-content .product__popup--full{
				position: static;
				padding: 0px;
			}

				.payment__main-content .product__popup--full h2{
					font-size: 22px;
					color: #2F3640;
				}

			.collapsibles {
				width: 100%;
				/* height: calc(100% - 110px); */
				/* height: calc(100% - 60px); */
				height: calc(100% - 40px);
				margin: 10px 0px 0px 0px;
				position: relative;
			}

				.collapsibles form {
					height: 100%;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}

					.payment-footer-absolute {
						position: absolute;
						left: 0;
						bottom: 0;
						pointer-events: none;
					}
				
					.collapsibles form .rc-collapse {
						width: 100%;
					}

				.collapsible__item {
					width: 100%;
				}
					.rc-collapse-item-active .collapsible__triger {
						/* font-weight: bold; */
						color: #636E72;
					}

					.rc-collapse-item {
						opacity: 1;
						/* transition: all 0.4s ease-in-out; */
					}

					.rc-collapse-item-active .collapsible__triger:before {
						opacity: 1;
						/* transition: all 0.4s ease-in-out; */
					}

					.collapsible__triger {
						width: 100%;
						font-size: 20px;
						color: #636E72;
						box-sizing: border-box;
						margin: 7px 0px 8px 0px;
						border-bottom: 1px solid #EFF2F4;
						cursor: pointer;
						counter-increment: step-counter;
						transition: all 0.4s ease-in-out;
					}

						#payment__popup .collapsible__triger {
							padding: 5px 12px 12px 0px;
							display: flex;
							align-items: center;
							position: relative;
						}

						#payment__popup .collapsible__triger:before {
							content: counter(step-counter);
							display: inline-block;
							margin: 0px 10px 0px 0px;
							opacity: 1;
							color: #fff;
							font-size: 20px;
							width: 17px;
							background: #FA583A;
							width: 21px;
							height: 21px;
							border-radius: 50%;
							text-align: center;
							line-height: 21px;
							transition: all 0.4s ease-in-out;
						}

						#payment__popup .collapsible__triger:after {
							content: url("../images/chevron_down.svg");
							display: inline-block;
							opacity: 1;
							font-size: 20px;
							width: 11px;
							height: 11px;
							position: absolute;
							right: 0;
							top: 0;
							bottom: 0;
							margin: auto;
							transform: rotate(-90deg);
							-webkit-transition: all 0.4s ease-in-out;
							-o-transition: all 0.4s ease-in-out;
							transition: all 0.4s ease-in-out;
							line-height: 7px;
						}

						#payment__popup .rc-collapse-item.rc-collapse-item-active.collapsible__content .collapsible__triger:after {
							transform: rotate(0deg);
						}

				.collapsible__content {
					width: 100%;
				}

					.collapsible__content .input__holders--col {
						width: 100%;
						margin: 0px 0px 15px 0px;
						position: relative;
						display: flex;
						align-items: center;
						justify-content: space-between;
						flex-wrap: wrap;
					}

						.collapsible__content .input__holders--col label {
							color: #99A1A3;
							font-size: 18px;
							font-weight: 500;
							-webkit-transition: all 0.2s ease-in-out;
							-o-transition: all 0.2s ease-in-out;
							transition: all 0.2s ease-in-out;
							text-transform: lowercase;
							/* max-width: 130px; */
							width: 100%;
							height: 22px;
							line-height: 22px;
						}

						#payment__popup .basic_info .input__holders--col label {
							max-width: 100%;
   							width: 100%;
						}

							#payment__popup .card_info .input__holders--col input {
								width: calc(100% - 160px);
							}

						#payment__popup .card_info .input__holders--col label {
							max-width: 150px;
   						 	width: auto;
						}

							#payment__popup .basic_info .input__holders--col input {
								width: calc(100% - 170px);
							}

						#payment__popup .delivery_info .halfed {
							display: flex;
							align-items: center;
							justify-content: space-between;
							flex-wrap: wrap;
						}

							#payment__popup .delivery_info .input__holders--col label {
								max-width: 100%;
								width: 100%;
							}

								#payment__popup .delivery_info .DayPickerInput input {
									width: 100% !important;
									max-width: 100% !important;
								}

								#payment__popup .delivery_info .input__holders--col--halfed {
									width: 100%;
								}

								#payment__popup .delivery_info .halfed .input__holders--col{
									margin: 0px 0px 10px 0px;
								}

								/* #payment__popup .delivery_info .di--address input {
									width: calc(100% - 140px);
								} */
							
						.input__holders--col.di--address .label__input.select-box {
							margin: 10px 0px 0px 0px;
							/* width: calc(100% - 170px); */
							width: 49%;
						}

						.label__info {
							color: #99A1A3;
							font-size: 16px;
							font-weight: 400;
							transition: all 0.2s ease-in-out;
							width: 100%;
						}

							.collapsible__content p input {
								width: 70%;
								max-width: 70%;
							}

						.label__input {
							width: auto;
							height: 40px;
							background: #F5F6FA;
							border: 1px solid #c1c1c1;
							border-radius: 5px;
							box-sizing: border-box;
							padding: 0px 15px;
							font-size: 18px;
							font-weight: 400;
							color: #636E72;
							margin: 10px 0px 0px 0px;
							transition: all 0.2s ease-in-out;
						}

						.label__input.error-field {
							border-color: #f55a30;
						}

						.label__input.select-box {
							width: 49%;
							font-size: 15px;
							cursor: pointer;
							outline: none;
							margin: 0px;
							border: 1px solid #c1c1c1;
							color: #636E72;
							-webkit-box-sizing: border-box;
							box-sizing: border-box;
							padding: 0px 15px;
							border-radius: 5px;
							background-image: url("../images/chevron_down.svg");
							background-repeat: no-repeat, repeat;
							-webkit-appearance: none;
							appearance: none;
							background-position: right .7em top 50%, 0 0;
							background-size: .65em auto, 100%;
						}

						#payment__popup .payment-item--web .datepicker input {
							width: auto;
							max-width: 90%;
							height: 40px;
							background: #F5F6FA;
							border: 1px solid #c1c1c1;
							border-radius: 5px;
							box-sizing: border-box;
							padding: 0px 15px;
							font-size: 18px;
							font-weight: 400;
							color: #636E72;
							line-height: 40px;
							transition: all 0.2s ease-in-out;
							box-shadow: none !important;
						}

						#payment__popup .payment-item--web .datepicker input::placeholder {
							color: #636E72;
						}

						#payment__popup .payment-item--web .datepicker input::-moz-placeholder {
							color: #636E72;
						}

							.label__input:focus {
								color: #636E72;
								border-color: #636E72;
							}

							.label__input:focus + label{
								color: #636E72;
							}

						.card_info .input__holders--col label{
							display: flex;
							align-items: center;
							position: relative;
						}

						.card_info .input__holders--col input{
							width: 60%;
    						max-width: 70%;
						}

						.card_info .input__holders--col label:after {
							content: url("../images/question_mark_icon.svg");
							display: inline-block;
							width: 15px;
							height: 15px;
							margin: 0px 0px 0px 7px;
							cursor: help;
						}
						
				/* .halfed .input__holders--col:first-of-type {
					max-width: 140px;
				} */

				.halfed .input__holders--col input {
					/* width: 140px !important; */
					width: 100% !important;
					max-width: 100% !important;
				}
			
	.payment__footer {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex-direction: row-reverse;
	}

		.payment__total span{
			font-size: 18px;
			color: #636E72;
		}

		.payment__total h2{
			font-size: 26px;
			color: #2F3640;
		}

		.payment__action button {
			padding: 0px 30px;
			height: 48px;
			font-size: 23px;
		}
		
		#payment__popup .payment-popup--item.unavailable .cart__product-img {
			opacity: 0.2;
		}

		#payment__popup .payment-popup--item.unavailable .product__title, 
		#payment__popup .payment-popup--item.unavailable .product__subtitle, 
		#payment__popup .payment-popup--item.unavailable .quantity-container__values h2,
		#payment__popup .payment-popup--item.unavailable .quantity-container__values h1 {
			color: #2f36403d;
		}

		.status-disclaimer {
			display: none;
			border-radius: 50px;
			height: 22px;
			line-height: 22px;
			padding: 0px 10px;
			font-size: 14px;
			font-weight: bold;
		}

		.payment-popup--item.unavailable .status-disclaimer {
			background: #FA583A;
			color: #fff;
		}

		.payment-popup--item.limited .status-disclaimer {
			background: #f39c12;
			color: #fff;
		}

		#payment__popup .payment-popup--item.limited .status-disclaimer,
		#payment__popup .payment-popup--item.unavailable .status-disclaimer {
			display: block
		}

		.title-status {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.payment-popup--item:first-child .cart__item-container {
			padding-top: 0px;
		}

		.empty-payment-info {
			display: inline-block;
			width: 60px;
			height: 60px;
			mask: url("../images/empty_cart.svg") no-repeat center center;
			-webkit-mask: url("../images/empty_cart.svg") no-repeat center center;
			-webkit-mask-size: cover;
			background: #636E72;
			mask-size: cover;
		}

		.payment__cart .centered-spinner h2 {
			font-size: 19px;
			box-sizing: border-box;
			padding: 0px 50px;
		}

	#payment__popup .input__holders--col label {
		max-width: 100%;
		width: 100%;
	}

	.input-grid {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}

		.input-grid .input__holders--col.input__holders--col--half {
			width: 49% !important;
		}

		.input-grid .input__holders--col.input__holders--col--sts {
			width: 69% !important;
		}

		.input-grid .input__holders--col.input__holders--col--stt {
			width: 29% !important;
		}

			.input-grid .input__holders--col.input__holders--col--half .label__input {
				width: 100% !important;
			}

			.input-grid .input__holders--col.input__holders--col--half .label__input.select-box {
				width: 100%;
				margin: 10px 0px 0px 0px;
			}

			.input-grid .input__holders--col.input__holders--col--half span.datepicker.d-from.datepicker--web {
				width: 100%;
			}

			.input-grid .input__holders--col.input__holders--col--half span.datepicker.d-from.datepicker--web input, 
			#payment__popup .input-grid .input__holders--col.input__holders--col--half span.datepicker.d-from.datepicker--web input {
				width: 100%;
				max-width: 100%;
				margin: 10px 0px 0px 0px;
			}

	.icon-button--naked.new-address-button {
		font-weight: bold;
		color: #2f3640;
		display: flex;
		align-items: center;
	}

		.new-address-button button {
			width: 100%;
		}

		.icon-button--naked.new-address-button span {
			display: inline-block;
			width: 11px;
			height: 11px;
			-webkit-mask-image: url("../images/plus_icon.svg");
			mask-image: url("../images/plus_icon.svg");
			background-size: 100%;
			background: #2f3640;
			margin: 0px 5px 0px 0px;
		}

		.new-address-button {
			margin: 8px 0px 0px 0px;
			width: 29%;
			white-space: nowrap;
		}

		.input__holders--col.input__holders--col--half.input__holders--col--sts.di--address-select {
			margin: 0px 0px 15px 0px !important;
		}

		.di--address.disabled input, 
		.di--delivery_number.disabled input {
			background: #f3f3f3 !important;
			border-color: #ececec;
			pointer-events: none;
			cursor: default;
			color: #848484;
		}

		#payment__popup .input__holders--col.input__holders--col--half.input__holders--col--sts.di--address-select.disabled select {
			background: #f3f3f3 !important;
			border-color: #ececec;
			pointer-events: none;
			cursor: default;
			color: #848484;
		}

		.icon-button--naked.new-address-button button {
			width: 100% !important;
		}

		.address-chosen {
			width: 100%;
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;
		}

		.input__holders--col--full {
			width: 100%;
		}

			.input__holders--col--full select.label__input.select-box {
				width: 100%;
				margin: 10px 0px 0px 0px;
			}

			#payment__popup .address-chosen label {
				/* padding: 7px 0px; */
				font-weight: 400;
				color: #99A1A3;
			}

			.address-chosen .input__holders--col {
				margin: 0px;
			}

				.address-chosen .label__input {
					padding: 0px;
					background: none;
					border: 0px;
					width: auto;
					height: auto;
					margin: 0px;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					padding: 10px 0px;
					background: #ffffff;
					border-radius: 0px;
					display: -ms-flexbox;
					display: flex;
					-ms-flex-align: center;
					align-items: center;
				}

					/* .address-chosen .label__input:before {
						content: "";
						display: inline-block;
						mask: url("../images/checked_icon.svg") no-repeat center center;
						-webkit-mask: url("../images/checked_icon.svg") no-repeat center center;
						-webkit-mask-size: 70%;
						mask-size: 70%;
						background: #2ecc71;
						width: 15px;
						height: 15px;
						margin: 0px 7px 0px 0px;
					} */

	#mobile__payment-container #location__popup {
		z-index: 9999 !important;
	}

	.unchecked-error {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: #e74c3c;
		position: absolute;
		right: 20px;
	}

		.unchecked-error:before {
			content: "";
			display: inline-block;
			width: 5px;
			height: 12px;
			-webkit-mask-image: url("../images/exclamation-solid.svg");
			mask-image: url("../images/exclamation-solid.svg");
			background-size: 100%;
			background: #fff;
		}

/* 
	SAVE CART
*/

#save-cart__popup .product__popup-container {
	width: 495px;
	padding: 0px;
}

	.popup__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 55px;
		background: #F5F6FA;
		box-sizing: border-box;
		padding: 0px 25px;
	}

		.popup__header .popup__header--block h2{
			font-size: 18px;
			font-weight: 300;
			color: #B2BEC3;
		}

		.popup__header .popup__header--block button{
			width: 13px;
			height: 13px;
		}

	#save-cart__popup .popup__content {
		box-sizing: border-box;
		padding: 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}

		#save-cart__popup .popup__content input {
			width: 100%;
		}


		.popup__buttons {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			box-sizing: border-box;
			padding: 0px 25px 25px 0px;
		}

			.popup__buttons button {
				width: 120px;
				height: 38px;
				font-size: 18px;
				text-transform: uppercase;
				margin: 0px 0px 0px 15px;
				text-align: center;
			}

#saved-carts {
	margin-bottom: 10px;
}

.saved-carts {
	position: relative;
}

	.trigger-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

		.dropdown__trigger {
			width: calc(100% - 45px);
			display: flex;
			align-items: center;
			background: #fff;
			border: 1px solid #DFE6E9;
			height: 42px;
			border-radius: 5px;
			box-sizing: border-box;
			padding: 0px 15px;
			cursor: pointer;
		}

			.dropdown__trigger h2 {
				font-size: 18px;
				color: #636E72;
				font-weight: 400;
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				user-select: none;
				text-transform: capitalize;			
			}

				.dropdown__trigger h2:after {
					content: url("../images/chevron_down.svg");
					width: 8px;
					height: 10px;
					display: inline-block;
					line-height: 5px;
				}

				#saved-carts .dropdown__trigger h2:first-letter {
					text-transform: uppercase !important;
				}
		
		.trigger-buttons {
			display: flex;
			align-items: center;
		}

		.remove-list.icon-button--naked {
			padding: 0px;
		}

			.remove-list.icon-button--naked span {
				display: inline-block;
				mask: url("../images/trash.svg") no-repeat center center;
				-webkit-mask: url("../images/trash.svg") no-repeat center center;
				-webkit-mask-size: 86%;
				background: #f55a30;
				mask-size: 86%;
				width: 13px;
				height: 13px;
			}
			.create-list.icon-button--naked span {
				display: inline-block;
				mask: url("../images/plus_icon.svg") no-repeat center center;
				-webkit-mask: url("../images/plus_icon.svg") no-repeat center center;
				-webkit-mask-size: cover;
				background: #fff;
				mask-size: cover;
				width: 14px;
				height: 14px;
			}

			.trigger-buttons .remove-list {
				margin: 0px 0px 0px 5px;
			}

			.trigger-buttons button {
				padding: 0px;
				width: 35px;
				height: 35px;
				border-radius: 50%;
				background: #f55a30;
				-webkit-box-shadow: 2px 2px 15px 0px #00000014;
				box-shadow: 2px 2px 15px 0px #00000014;
				border: 1px solid #DFE6E9;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				-webkit-transition: all 0.2s ease-in-out;
				-o-transition: all 0.2s ease-in-out;
				transition: all 0.2s ease-in-out;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.trigger-buttons button:hover {
				opacity: 0.7;
				box-shadow: 2px 2px 4px 1px #0000001f;
			}
			.trigger-buttons button:active {
				background: #f7f7f7;
			}

	.dropdown__content {
		width: 100%;
		position: absolute;
		top: 52px;
		z-index: 2;
		background: #fff;
		border-radius: 2.5px;
		box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
		padding: 8px 0px;
		max-height: 220px;
		overflow: auto;
		transition: all 0.2s ease-in-out;
	}

		.dropdown__content ul li {
			float: left;
			width: 100%;
			padding: 10px 15px;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			cursor: pointer;
			-webkit-transition: all 0.2s ease-in-out;
			-o-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

			.dropdown-disclaimer {
				font-size: 16px;
				padding: 10px 15px;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				font-weight: bold;
				color: #646e72;
				font-style: italic;
				pointer-events: none;
				cursor: default;
			}

			.dropdown__content ul li:hover {
				background: #F8F9FA;
			}

			.dropdown-disclaimer:hover {
				background: #F8F9FA;
			}

			.dropdown__content ul li h2 {
				font-size: 16px;
				color: #636E72;
				font-weight: 400;
				user-select: none;
			}

				.dropdown__content ul li h2::first-letter {
					text-transform: uppercase;
				}

				.dropdown__trigger h2::first-letter {
					text-transform: uppercase;
				}

	.cart__header.push_header {
		/* margin: 10px 0px 0px 0px;	 */
	}
	.cart__items.trim__items {
		height: calc(100% - 160px);
	}

	#web__payment-container #location__popup {
		z-index: 9999 !important;
	}

/* 
	CONFIRMATION_POPUP
*/


.confirmation__popup .product__popup-container {
	width: 495px;
	padding: 0px;
}

	.confirmation__popup .popup__content {
		box-sizing: border-box;
		padding: 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

		.confirmation__popup .popup__content input {
			width: 85%;
		}


		.popup__disclaimer {
			font-size: 18px;
			color: #636E72;
			font-weight: 400;
			line-height: 26px;
		}

			.confirmation__popup .popup__buttons button{
				width: auto;
				padding: 0px 22px;
			}

	.di--delivery_number-auth {
		margin: 17px 0px 0px 0px !important;
	}

/* 
	EDIT SAVED CART POPUP
*/


.radio__button--holder {
	display: flex;
	align-items: center;
}

	.edit-saved-cart__popup .popup__content {
		justify-content: space-evenly;
	}

		.radio__button--holder strong{
			width: auto;
			max-width: 110px;
			font-size: 18px;
		}

		input[type="radio"] {
			display:none;
		}

		input[type="radio"] + label {
			color: #99A1A3;
			font-size: 18px;
			display: flex;
			align-items: center;
			cursor: pointer;
			width: auto;
		}

		input[type="radio"] + label span {
			width: 20px;
			height: 20px;
			margin: 0px 10px 0 0;
			background: transparent;
			cursor: pointer;
			border: 2px solid #FA583A;
			border-radius: 50%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
		}

			input[type="radio"]:checked + label span:before {
				content: "";
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: #FA583A;
				display: inline-block;
			}


/* 
	TOOLTIP
*/

#tooltip {
	width: auto;
    max-width: 250px;
    background: #fff;
    border: 2px solid #F1F4F5;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    right: -275px;
    top: -17px;
	opacity: 0;
	visibility: hidden;
	transform: translate3d(-10px, 0px, 0px);
	transition: all 0.2s ease-in-out;
	pointer-events: none;
}

	#tooltip:before {
		content: url("../images/tooltip_chevron.svg");
		display: inline-block;
		width: 25px;
		height: 41px;
		position: absolute;
		left: -25px;
		top: 10px;
	}

	.tooltip__container p {
		margin: 0px;
		font-size: 14px;
		font-weight: 400;
		color: #636E72;
		box-sizing: border-box;
		padding: 10px;
		box-shadow: 4px 6px 11px 0 rgba(0, 0, 0, 0.1);
	}

	.card_info .input__holders--col label:hover #tooltip {
		opacity: 1;
		visibility: visible;
		transform: translate3d(0px, 0px, 0px);   
	}


/* 
	TRANSACTION PROCESSING POPUP
*/

.transaction-popup--block {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 30px;
}

.transaction-popup--block.transaction__register-helper {
	padding-left: 0px;
}

	.transaction__register-helper .insider {
		background: #F5F6FA;
		width: 100%;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
	}

		.transaction__register-helper .insider .section__close {
			width: 100%;
			margin: 10px 0px 0px 0px;
		}

			.transaction__register-helper .insider .section__close button.close__popup-button.button__ghost--borderless--primary {
				width: 100%;
				color: #b2bec4;
				font-weight: normal;
				background: transparent;	
			}

				.transaction__register-helper .insider .section__close button.close__popup-button.button__ghost--borderless--primary:hover {
					background: #b2bec424;
				}

		.transaction__register-helper .insider form {
			width: 70%;	
		}

		.transaction-popup--block h2 {
			width: 100%;
			text-align: left;
			font-size: 20px;
			font-weight: 400;
			color: #636E72;
		}

			.user-name {
				text-transform: capitalize;
			}

		.transaction-popup--block p {
			width: 100%;
			text-align: left;
			font-size: 16px;
			font-weight: 400;
			line-height: 26px;
			color: #636E72;
			margin: 0px 0px 10px 0px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
		}

			.transaction__register-helper .insider p .label__input{
				background: #fff;
				width: 100%;
			}

			.insider .transcation-info {
				margin: 20px 0px 20px 0px;
			}

		.transaction-processing h2 {
			text-align: center;
			font-size: 27px;
			color: #b2bec4;
			font-weight: 300;
			margin: 20px 0px 40px 0px;
		}

		.insider .transcation-button-holder {
			margin: 40px 0px 0px 0px;
		}

			.lds-ellipsis {
				display: inline-block;
				position: relative;
				width: 64px;
				height: 44px;
			}

			.lds-ellipsis div {
				position: absolute;
				top: 27px;
				width: 13px;
				height: 13px;
				border-radius: 50%;
				background: #B2BEC3;
				animation-timing-function: cubic-bezier(0, 1, 1, 0);
			}

			.lds-ellipsis div:nth-child(1) {
				left: 6px;
				animation: lds-ellipsis1 0.6s infinite;
			}

			.lds-ellipsis div:nth-child(2) {
				left: 6px;
				animation: lds-ellipsis2 0.6s infinite;
			}

			.lds-ellipsis div:nth-child(3) {
				left: 26px;
				animation: lds-ellipsis2 0.6s infinite;
			}

			.lds-ellipsis div:nth-child(4) {
				left: 45px;
				animation: lds-ellipsis3 0.6s infinite;
			}

			@keyframes lds-ellipsis1 {
				0% {
				  transform: scale(0);
				}
				100% {
				  transform: scale(1);
				}
			}

			@keyframes lds-ellipsis3 {
				0% {
				  transform: scale(1);
				}
				100% {
				  transform: scale(0);
				}
			}

			@keyframes lds-ellipsis2 {
				0% {
				  transform: translate3d(0, 0, 0);
				}
				100% {
				  transform: translate3d(19px, 0, 0);
				}
			}


		.transaction--loading {
			text-align: center;
		}

			.transaction--loading .lds-ellipsis {
				height: 15px;
			}

				.transaction--loading .lds-ellipsis div {
					background: #b2bec4;
					width: 14px;
					height: 14px;
				}
		
		.transaction--done .done {
			background: url("../images/order_done_icon.svg") no-repeat center center;
			width: 60px;
			height: 60px;
			margin: 0px auto;
		}

		.transaction--fail .fail {
			background: url("../images/order_error_icon.svg") no-repeat center center;
			background-size: 100%;
			width: 60px;
			height: 60px;
			margin: 0px auto;
		}

			.transaction--fail h2 {
				margin-bottom: 0px;
				box-sizing: border-box;
				padding: 0px 40px;
				color: #484848;
			}

	.transaction__register-helper .insider .success-register {
		width: 100%;
		box-sizing: border-box;
		padding: 10px;
		border: 1px solid #36d1a169;
		margin: 20px 0px 0px 0px;
		background: #fff;
		border-radius: 4px;
		box-shadow: 0px 0px 15px 1px #00000014;
		display: flex;
		align-items: center;
		justify-content: center;
	}

		.success-register h2 {
			display: inline-block;
			font-size: 17px;
			color: #36d1a1;
			width: auto;
			margin: 0px 10px 0px 0px;
		}

		.transaction__register-helper .insider .success-register .small-button--primary {
			background: #36d1a1;
		}

	#processing__popup.product__popup.trigger-resize .product__popup-container {
		width: 400px;
		height: 280px;
		padding: 0px;
	}

		#processing__popup.product__popup.trigger-resize .product__popup-container .product__popup-main {
			width: 100%;
			height: 100%;
		}

			.product__popup.trigger-resize .product__popup-container .product__popup-main .transaction-popup--block {
				width: 100%;
				height: 100%;
				padding: 0px;
			}

				.product__popup.trigger-resize .product__popup-container .product__popup-main .transaction-popup--block .transaction--done h2 {
					margin: 10px 0px 0px 0px;
					color: #484848;
				}

	#processing__popup .product__popup-container {
		padding: 0px;
		width: 75%;
		height: 100%;
		overflow: unset !important;
	}

		#processing__popup .product__popup-container .product__popup-main {
			width: 100%;
			height: 100%;
		}

		#processing__popup .product__popup-container .product__popup-main .lds-ellipsis {
			top: 1px;
		}

			#processing__popup .product__popup-container .product__popup-main .lds-ellipsis div {
				width: 9px;
			}
			
	.disclaimer-info {
		text-align: center;
		margin: 30px 0px 0px 0px;
	}

		.disclaimer-info p {
			color: #bbbbbb;
			font-size: 16px;
			text-align: center;
			margin: 10px 0px 0px 0px;
			display: block;
		}

		.grouped-buttons {
			display: flex;
			align-items: center;
			justify-content: center;
		}

			.disclaimer-divider {
				display: inline-block;
				width: 1px;
				height: 17px;
				border: 0px;
				background: #d0d0d0;
				margin: 0px 5px;
			}

			.grouped-buttons a,
			.grouped-buttons button.button__ghost--borderless--primary {
				font-weight: bold;
				font-size: 14px;
				text-transform: uppercase;
				letter-spacing: 1px;
				color: #484848;
				transition: background 0.2s ease-in-out;
				width: auto;
				height: auto;
				line-height: unset;
				padding: 5px 10px 4px 10px;
			}

			.grouped-buttons a:hover {
				background: #fa583a21;
			}


/* 
	TOASTS
*/

.toast {
    position: fixed;
    right: 80px;
    bottom: 80px;
	max-width: 500px;
	min-width: 300px;
    background: #fff;
    box-sizing: border-box;
    z-index: 9999;
    padding: 22px;
    border-radius: 4px;
	border-left: 8px solid;
	box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.1);
}

.animated.slideInDown.fast.animated--toast {
	position: fixed;
    right: 80px;
	bottom: 80px;
	z-index: 9999;
}

	.toast.code-green {
		border-color: #1DD1A1;
	}

	.toast.code-red {
		border-color: #e74c3c;
	}

	.toast-content {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

		.toast.code-green .toast-icon{
			background: url("../images/order_done_icon.svg") no-repeat center center;
			width: 38px;
			height: 38px;
			background-size: 100%;
		}

		.toast.code-red .toast-icon{
			background: url("../images/error_icon.svg") no-repeat center center;
			width: 38px;
			height: 38px;
			background-size: 100%;
		}

		.toast-message p {
			margin: 0px;
			font-size: 18px;
			font-weight: 400;
			color: #636E72;
			margin: 0px 0px 0px 20px;
		}

		.toast-message {
			width: calc(100% - 38px);
			float: left;
		}

.animated--toast {
	animation-fill-mode: none !important;
}
/* 
	RAFT EXPANSION
*/

.raft .minicar {
	position: relative;
	z-index: 1;
	margin: 2px 0px 0px 20px;
}

	.raft .minicar .minicar-list {
		width: calc(100% - 10px);
	}

.raft.expand .minicar-track {
	flex-wrap: wrap;
	transform: none;
	width: 100%;
	margin-bottom: 0px;
}

_::-webkit-full-page-media, _:future, :root #raft__slider .minicar-track {
	display: flex;
}

_::-webkit-full-page-media, _:future, :root .raft.expand #raft__slider .minicar-track {
	flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	flex: 1 0 auto;
}

_::-webkit-full-page-media, _:future, :root .raft.expand .minicar-slide {
	flex: 0 0 auto;
}

.raft.expand .slick-slider, .raft.expand .slick-list {
	width: 100% !important;
	padding-right: 0px !important;
}

	.raft.expand .slick-track {
		width: 100% !important;
		transform: none !important;
		left: 0 !important;
	}

		.raft.expand .slick-list .slick-slide {
			margin: 0px 0px 10px 0px !important;
			float: left;
			/* width: calc(100% / 4 - 6px); */
		}

			.raft.expand .slick-list .slick-slide .raft-card:nth-child(5n + 4) {
				margin-right: 0px !important;
			}

			.raft.expand .slick-list .slick-slide .raft-card {
				margin: 0px 6px 46px 0px !important;
				/* width: 100%; */
			}

			.raft.expand .raft-illustration--expanded {
				background: url("../images/full_raft_illustration_board_2.svg") repeat-y 0px 0px;
				width: 100%;
				height: calc(100% - 250px);
				top: 273px;
				bottom: 60px;
				/* background-size: 100% 332px; */
				background-size: 100% 325px;
				background-repeat: repeat-y;
				position: absolute;
			}

			.raft.expand .raft-illustration-shadow--expanded {
				background: url("../images/full_raft_illustration_shadow.svg") repeat-y 0px 0px;
				width: calc(100% + 103px);
				height: calc(100% - 250px);
				top: 273px;
				bottom: 60px;
				background-size: 100% 332px;
				background-repeat: repeat-y;
				position: absolute;
			}

			.raft.expand .minicar-slide {
				margin-bottom: 57px;
			}
			
		.raft.expand .slick-arrow {
			display: none !important;
		}

		.web__more-button {
			transform: translate3d(0px, 0px, 0px);
			transition: all 0.2s ease-in-out;
			white-space: nowrap;

		}

		.web__more-button span.undo {
			mask: url("../images/undo.svg") no-repeat center center;
			-webkit-mask: url("../images/undo.svg") no-repeat center center;
			-webkit-mask-size: 70%;
			mask-size: 70%;
			background: #fff;
			width: 15px;
			height: 15px;
			display: inline-block;
			margin: 0px 3px 0px 0px;
		}

		.raft.expand .web__more-button {
			transform: translate3d(10px, 0px, 0px);
			display: flex;
			align-items: center;
			justify-content: center;
		}

		_::-webkit-full-page-media,
		_:future,
		:root .raft.expand .web__more-button {
			transform: translate3d(13px, 0px, 0px);
		}

		_::-webkit-full-page-media,
		_:future,
		:root .disabled-buttons {
			top: 2px;
		}

		.small-button--primary.web__more-button:disabled {
			background: #dcdcdc;
			color: #b7b7b7;
			cursor: not-allowed;  
		}

		.small-button--primary.mobile__more-button:disabled {
			background: #dcdcdc;
			color: #b7b7b7;
			cursor: not-allowed;  
		}

			/* .raft.expand .tabs-buttons-list {
				width: calc(100% - 37px);
			} */

			.raft.expand .next-button {
				left: 16px;	
			}

			.raft.expand .category-src.tabs ul {
				/* width: calc(100% - 45px); */
				width: 100%;
			}

			.raft.expand .category-src.tabs.mobile ul {
				width: 100%;
			}

			.raft.expand .web__more-button span {
				display: flex;
				align-items: center;
				justify-content: center;
			}

		.raft__categories--expander {
			transform: translate3d(0px, 0px, 0px);
			transition: all 0.2s ease-in-out;
		}
		
		.raft.expand .raft__categories--expander {
			transform: translate3d(0px, 0px, 0px);
			/* display: none; */
		}

		.raft.expand .slick-arrow {
			display: none !important;
		}

		/* .raft.expand + .raft {
			margin-top: 0px;
		} */

		.raft.expand .raft__slider--decorator {
			display: none;
		}

		.raft.expand .minicar-list .eSGzmI {
			padding: 15px 0px 0px 10px;
		}

		.raft.expand .minicar {
			margin: 2px 0px 0px 10px;
		}


/* 
	FOOTER
*/

#footer {
	width: 100%;
	float: left;
    margin: 50px 0px 23px 0px;
}

	.footer--block {
		float: left;
		width: 100%;
	}

		.footer--block.copyright span {
			font-size: 14px;
			color: #B2BEC3;
		}

	.footer--block.sitemap {
		margin: 25px 0px 0px 0px;
	}

		.footer--block.sitemap ul {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: flex-start;
		}
	
			.footer--block.sitemap ul li {
				float: left;
				margin: 0px 20px 20px 0px;
				border-top: 2px solid #636e7230;
				padding: 5px 0px 0px 0px;
				min-width: 200px !important;
				width: 230px;
			}

			.footer--block.sitemap ul li {
				float: left;
				margin: 0px 20px 20px 0px;
				border-top: 2px solid #636e7230;
				padding: 5px 0px 0px 0px;
				min-width: 200px !important;
				width: calc(100% / 3 - 20px);
			}
			
				/* .footer-home .footer--block.sitemap ul li {
					min-width: 200px !important;
					width: 230px;
				} */

				.footer--block.sitemap ul li a{
					font-size: 18px;
					color: #2F3640;
					font-weight: bold;
					display: block;
					width: 100%;
				}

					.footer--block.sitemap ul li a:hover {
						text-decoration: underline;
					}

				.footer-items-description {
					width: 100%;
					display: block;
					font-size: 14px;
					line-height: 16px;
					margin: 5px 0px 0px 0px;
					color: #636E72;
				}

	.footer--block.disclaimer {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: flex-end;
		align-items: flex-end;
		-ms-flex-pack: justify;
		/* justify-content: flex-start; */
		margin: 30px 0px 0px 0px;
	}

		.footer--block.disclaimer .company {
			flex-direction: column;
			flex-grow: 1;
		}

		.footer--block.disclaimer .company img {
			width: 140px;
			margin-right: 30px;
		}
		
		.footer--block.disclaimer .company span {
			display: block;
			margin-top: 10px;
		}

		.footer--block.disclaimer span, .footer--block.disclaimer a{
			font-size: 14px;
			color: #636E72;
		}

		.footer--block.disclaimer .powered-by {
			display: block;
			text-align:right;
		}
		
		.footer--block.disclaimer .powered-by a {
			font-weight: 600;
			color: #FA583A;
			text-decoration: none;
		}
		
		.footer--block.disclaimer .powered-by a:hover {
			text-decoration: underline;
		}

	.footer--block.question h2 {
		font-size: 26px;
		color: #2F3640;
	}

	.footer-block-description {
		font-size: 18px;
		color: #636E72;
		/* width: 70%; */
		line-height: 22px;
		margin: 10px 0px 0px 0px;
	}

		.tour-footer-link {
			color: #FA583A;
			text-decoration: underline;
			cursor: pointer;
		}
		
		.tour-footer-link:hover {
			text-decoration: none;
		}

	.supported-payment-cards {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		margin: 20px 0px 0px 0px;
	}

		.single-spc {
			width: 60px;
			height: 40px;
			overflow: hidden;
			background: #fff;
			border: 1px solid #dfe6e9;
			border-radius: 5px;
			margin: 0px 10px 0px 0px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

			.single-spc img {
				width: 70%;
				height: 70%;
				object-fit: contain;
			}

#mobile__payment-container #mobile__payment-footer-left {
	position: fixed;
	width: 50%;
	left: 0;
	bottom: 0;
	box-sizing: border-box;
	padding: 20px;
	background: #f5f6fa;
	z-index: 9998;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/*  
/*------------------------------------------------------------------------------*\
  #FAVORITES
/*------------------------------------------------------------------------------*\ 
*/


#favorites {
	width: 100%;
	margin: 4px 0px 0px 0px;
}

	#favorites #empty-state {
		margin: 160px 0px;
	}

	.component__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.component__content {
		margin: 20px 0px;
	}

		.component__content .raft__content {
			display: flex;
			flex-wrap: wrap;
		}

			.component__content .raft-card {
				width: 100%;
				margin: 0px 8px 8px 0px;
			}

				.raft-card .raft-card__favorite.icon-button--naked.favorite--active {
					position: static !important;
				}

		.all-products .favorite {
			position: relative;
			width: 193px; 
			margin: 0px 6px 15px 0px;
		}

		.all-products .favorite:nth-child(4n + 5) {
			/* margin-right: 0px; */
		}
		

			.favorite:hover .remove__cart-item {
				transform: scale(1);
				opacity: 1;
			 	visibility: visible;
			}

			.favorite .remove__cart-item {
				left: -15px;
				top: -23px;
				z-index: 2;
			}

				.favorite .remove__cart-item button {
					width: 40px;
					height: 40px
				}

				.favorite .remove__cart-item  .small-button--rounded .remove__icon {
					width: 12px;
					height: 12px;
				}

	.no-favorites {
		height: calc(100% - 30px);
	}

		.no-favorites .columns {
			height: 100%;
		}

			.no-favorites #favorites {
				height: 100%;
			}

				.no-favorites #favorites .component__content {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
				}

					.no-favorites #favorites .component__content #empty-state {
						height: auto;
						margin: 0px 0px 80px 0px;
					}

/*  
/*------------------------------------------------------------------------------*\
  #ORDER HISTORY
/*------------------------------------------------------------------------------*\ 
*/


.order-history__wrapper {
	width: 883px;
	margin: 0px auto;
}

.single-page-wrapper {
	width: 883px;
	margin: 0px auto;
}

.order-history .component__header {
	border-bottom: 1px solid rgba(223, 230, 233, 0.5);
	padding: 0px 0px 10px 0px;
	margin-top: 20px;

}
	.order-history .category-title {
		display: flex;
	}

	.component__header--home {
		display: none !important;
		width: 20px;
		height: 18px;
		background: url("../images/home_icon_red.svg") no-repeat center center;
		display: inline-block;
		text-indent: -9999px;
		margin: 0px 10px 0px 0px;
	}

		.date__input--holder {
			position: relative;
		}

		.datepicker--web .date-decorator {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			bottom: 0;
			margin: auto;
		}

		#statistics .rdtPicker {
			width: calc(100% - 8px);
			height: 267px;
		}

		.order-history .rdtPicker {
			width: calc(100% - 8px);
			height: 267px;
		}

		#web__payment-container .rdtPicker {
			position: fixed;
		}

		.datepicker--web input {
			width: 350px;
			height: 60px;
			border-radius: 5px;
			border: 0px;
			background: #fff;
			-webkit-box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.07);
			box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.07);
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			padding: 0px 35px 0px 85px;
			font-size: 18px;
			color: #B2BEC3;
			cursor: pointer;
		}

				.date__input--icon {
					font-size: 16px;
					color: #B2BEC3;
					display: flex;
					align-items: center;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 35px;
				}

					.date__input--icon::-webkit-input-placeholder {
						color: #B2BEC3;
					}

					.date__input--icon::-moz-placeholder {
						color: #B2BEC3;
					}

					.date__input--icon::-ms-input-placeholder {
						color: #B2BEC3;
					}

					.date__input--icon:-moz-placeholder {
						color: #B2BEC3;
					}

					.date__input--icon:after {
						content: url("../images/calendar_icon.svg");
						display: inline-block;
						width: 15px;
						height: 17px;
						margin: 0px 0px 0px 10px;
					}

	
	.order-history__wrapper .rc-collapse {
		box-sizing: border-box;
		padding: 0px 35px;
	}
	
		.collapsible__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 60px;
			border-bottom: 3px solid transparent;
			box-sizing: border-box;
			padding: 0px 15px;
			background: transparent;
			transition: all 0.2s ease-in-out;
		}

		.collapsible__triger {
			border-bottom: 1px solid rgba(223, 230, 233, 0.5);
		}

			.collapsible__triger.collapsible__content.basic_info.expand {
				box-shadow: 0px 0px 15px 0px #00000017;
			}

			.collapsible__triger.collapsible__content.basic_info.expand	.collapsible__header {
				background: #fff;
				border-bottom: 3px solid #f55a30;
			}

			.collapsible__triger.collapsible__content.basic_info.expand	.collapsible__header--content .chevron {
				opacity: 1;
				transform: rotate(90deg);
			}

			.collapsible__triger.collapsible__content.basic_info.expand	.chc--rounded span, 
			.collapsible__triger.collapsible__content.basic_info.expand	.chc--rounded h2, 
			.collapsible__triger.collapsible__content.basic_info.expand	.collapsible__header--content h2
			{
				color: #656f73;
			}

			.collapsible__header--content {
				display: flex;
			}

				.order-history__wrapper .rc-collapse > .rc-collapse-item > .rc-collapse-header {
					padding: 0px;
					border: 0px;
					margin: 0px;
				}

				#payment__popup .rc-collapse-item.rc-collapse-item-active.collapsible__content .collapsible__triger:before {
					opacity: 1;
				}
		
				.order-history__wrapper .rc-collapse .collapsible__triger:before {
					display: none;
				}

				.collapsible__header--content .chevron {
					margin: 0px 14px 0px 0px;
					opacity: 0.3;
					transition: all 0.2s ease-in-out;
				}

					.collapsible__header--content h2 {
						font-size: 16px;
						font-weight: 400;
						color: #B2BEC3;
						font-family: sans-serif;
						transition: all 0.2s ease-in-out;
					}

					.rc-collapse-item-active .chevron {
						opacity: 1;
					}

					.rc-collapse-item-active .collapsible__header--content h2 {
						/* font-weight: 600; */
						color: #636E72;
						font-family: sans-serif;
						font-size: 16px;
					}

					.chc--rounded {
						display: flex;
						align-items: center;
						margin: 0px 0px 0px 30px;
						justify-content: flex-end;
					}

						.chc--rounded span.value {
							background: #DFE6E9;
							border-radius: 5px;
							box-sizing: border-box;
							padding: 0px 10px;
							height: 30px;
							line-height: 30px;
							font-size: 18px;
							margin: 0px 7px 0px 20px;
						}

						.chc--rounded span.measure {
							font-size: 16px;
							/* flex-grow: 1; */
							text-align: right;
						}

					.chc--rounded.items-price {
						width: 100px;
					}



			.order-history__wrapper .rc-collapse .rc-collapse-item.rc-collapse-item-active {
				background: #fff;
				border-radius: 5px;
				box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.07);
			}

			.order-history__wrapper .rc-collapse .rc-collapse-content .rc-collapse-content-box {
				margin-top: 16px;
    			margin-bottom: 16px;
			}

			#web__payment-container .rc-collapse .rc-collapse-content {
				padding: 0px; 
			}

				.orders__table {
					width: 100%;
					border-collapse: collapse;
				}

					.orders__table .if-not-loaded {
						width: 33px;
						height: 33px;
					}

						.orders__table .if-not-loaded .placeholder {
							width: 18px;
							height: 18px;
						}

							.orders__table .animated-product-image-container {
								height: 44px;
							}

					.orders__table tr th{
						font-size: 16px;
						font-weight: 400;
						text-align: left;
						color: #b2bec3;
						padding: 0px 0px 15px 0px;
					}

					.orders__table--product {
						width: 60%;
						text-align: left;
					}

					.orders__table .cart__product-img {
						width: 44px;
						height: 44px;
						margin: 0px 10px 0px 0px;
						float: left;
					}

					.orders__table .cart__product--top {
						margin: 2px 0px 0px 0px;
						float: left;
						width: auto;
					}

					.orders__table tbody tr {
						height: 65px;
						border-bottom: 1px solid rgba(223, 230, 233, 0.5);
					}

						.if-unavailable {
							color: #fff;
							background: #fa583a;
							font-size: 15px;
							font-weight: normal;
							box-sizing: border-box;
							padding: 2px 10px;
							border-radius: 50px;
							margin: 0px 0px 0px 20px;
						}

					.orders__table tbody tr.unavaialbe {
						opacity: 0.4;
					}

						.middle {
							display: flex;
							width: 100%;
							align-items: center;
						}

						.orders__table tbody tr:nth-child(even) {
							background: #f5f6fa;
						}

						.orders__table tbody tr .product__title{
							font-size: 18px;
						}

						.orders__table tbody tr .product__subtitle{
							font-size: 14px;
							color: #B2BEC3;
						}

						.orders__table tbody tr td {
							font-size: 16px;
							color: #636e72;
						}

						.row_count {
							float: left;
							height: 60px;
							font-size: 16px;
							font-weight: 300;
							color: #B2BEC3;
							line-height: 65px;
							margin: 0px 15px 0px 0px;
						}
				
			.component__content--footer {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 25px 0px 0px 0px;
			}

				.component__content--footer .component__footer--block {
					display: flex;
					align-items: center;
				}

					.component__content--footer .component__footer--block button {
						margin: 0px 10px 0px 0px;
					}

					.order__collapsible--button .order__collapsible-button-pdf-icon {
						background: url("../images/pdf_icon.svg") no-repeat center center;
						width: 9px;
						height: 13px;
						text-indent: -9999px;
						display: inline-block;
						margin: 0px 7px 0px 0px;
					}

					.order__collapsible--button .order__collapsible-button-print-icon {
						background: url("../images/print_icon.svg") no-repeat center center;
						width: 13px;
						height: 13px;
						text-indent: -9999px;
						display: inline-block;
						margin: 0px 7px 0px 0px;
					}
					
					.component__footer--block.cfb__total {
						flex-direction: column;
						line-height: 22px;
					}

						.cfb__total span {
							width: 100%;
							font-size: #636E72;
							font-weight: 400;
						}

						.cfb__total h2 {
							width: 100%;
							color: #2F3640;
							font-size: 26px;
							font-weight: 700;
						}

	.order-history-id-spinner {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

		.order-history-id-spinner span { 
			display: inline-block;
			width: 32px;
			height: 32px;
			line-height: 80px;
			text-align: center;
			mask: url("../images/empty_cart.svg") no-repeat center center;
			-webkit-mask: url("../images/empty_cart.svg") no-repeat center center;
			-webkit-mask-size: cover;
			background: #636E72;
			mask-size: cover;
			text-indent: -9999px;
		}

	.loading-placeholder {
		margin: 0px 0px 0px 0px;
		font-size: 18px;
		font-weight: normal;
		color: #656f73;
		position: relative;
		top: -10px;
	}

	.empty-data-container {
		width: 100%;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin: 50px 0px 0px 0px;
		box-sizing: border-box;
		padding: 30px 0px 0px 0px;
	}

		.empty-data-centered span {
			display: inline-block;
			width: 60px;
			height: 60px;
			line-height: 80px;
			text-align: center;
			mask: url("../images/empty_cart.svg") no-repeat center center;
			-webkit-mask: url("../images/empty_cart.svg") no-repeat center center;
			-webkit-mask-size: cover;
			background: #636E72;
			mask-size: cover;
			text-indent: -9999px;
		}

		.empty-data-centered h2 {
			font-weight: 300;
			color: #636E72;
			font-size: 18px;
			margin: 20px 0px 0px 0px;
		}

	.orders-src {
		box-sizing: border-box;
		padding: 0px 20px 0px 20px;
		background: #fff;
		border-radius: 4px;
		height: 0;
		max-height: 0;
		overflow: hidden;
		visibility: hidden;
		transition: all 0.2s ease-in-out;
	}

	.collapsible__triger.collapsible__content.basic_info.expand	.orders-src{
		padding: 20px 20px 20px 20px;
		overflow: auto;
		visibility: visible;
		max-height: 1000px;
		height: auto;
	}

	.lds-ring.loader-order-history {
		display: inline-block;
		position: relative;
		width: 22px;
		height: 22px;
		margin: 0px 0px 0px 15px;
	}

		.lds-ring.loader-order-history div {
			width: 16px;
			height: 16px;
			margin: 0px;
			border: 2px solid #b3bfc3;
			margin: 2px 0px 0px 0px;
			border-color: #b3bfc3 transparent transparent transparent;
		}

	.order-status {
		margin: 0px 0px 0px 10px;
	}

		.order-status h2 {
			color: #2F3640;
			font-size: 18px;
			font-weight: bold;
		}

			.order-status h2 span {
				background: #DFE6E9;
				border-radius: 50px;
				box-sizing: border-box;
				padding: 0px 15px;
				height: 30px;
				line-height: 30px;
				margin: 0px 0px 0px 5px;
				display: inline-block;
				font-weight: 500;
				color: #656f73;
			}

		.empty-order-data {
			font-size: 18px;
			color: #657074;
			font-weight: normal;
			margin: 10px 0px 5px 0px;
		}

		.cart__product-img.empty-order-data-placeholder {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		#web__payment-container .animated-product-image-container {
			height: 80px;
			max-height: 80px;
		}

/*  
/*------------------------------------------------------------------------------*\
  #SETTINGS
/*------------------------------------------------------------------------------*\ 
*/

.info__navigation--left {
    float: left;
    width: 280px;
}

	.settings__avatar {
		width: 100%;
		display: inline-block;
	}

		.settings__avatar__image-holder {
			width: 100px;
			height: 100px;
			margin: 0px;
			border-radius: 50%;
			overflow: hidden;
			position: relative;
		}

			.info__navigation--left .settings__avatar__image-holder:before {
				content: url("../images/avatar_decorator_top.svg");
				width: 104px;
				height: 46px;
				display: inline-block;
				position: absolute;
				top: -3px;
				left: -1px;
				opacity: 0.3;
			}

			.info__navigation--left .settings__avatar__image-holder:after {
				content: url("../images/avatar_decorator_bottom.svg");
				width: 102px;
				height: 46px;
				display: inline-block;
				position: absolute;
				bottom: -7px;
				left: -1px;
				opacity: 0.3;
			}

			.settings__avatar__image-holder img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 50%;
				border: 5px solid transparent;
				box-sizing: border-box;
			}

		.settings__avatar h2 {
			font-size: 24px;
			color: #000;
			margin: 20px 0px 0px 0px;
		}

		.settings__avatar a {
			display: block;
			font-size: 18px;
			color: #B2BEC3;
			margin: 3px 0px 0px 0px;
		}

	.subpage-nav {
		margin: 30px 0px 0px 0px;
	}

		.subpage-nav ul li {
			display: flex;
			align-items: center;
			margin: 0px 0px 15px 0px;
		}

			.subpage-nav ul li:before {
				content: url("../images/chevron_right_red.svg");
				width: 10px;
				height: 14px;
				display: inline-block;
			}

			.subpage-nav ul li:last-child {
				margin: 0px 0px 0px 0px;
			}

				.subpage-nav ul li a {
					color: #B2BEC3;
					font-size: 19px;
					font-weight: 300;
					margin: 0px 0px 0px 10px;
				}

				.subpage-nav ul li a.active-settings {
					font-weight: bold ;
					color: #636E72;
				}

.settings__content--right {
    float: left;
	width: calc(100% - 280px);
	margin: 10px 0px 0px 0px;
}

	.settings__content--block {
		width: 100%;
		background: #fff;
		box-shadow: 0 0 15px 6px rgba(0, 0, 0, 0.03);
		border-radius: 4px;
	}

		.settings__content--block .settings__content--header {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

			.settings__content--block .settings__content--header .settings__content-block--actions {
				margin: 0px;
			}

			.settings__content--block .settings__content--header .settings__content-block--actions button {
				margin: 0px;
				font-size: 16px;
				padding: 0px 10px;
				height: 28px;
				text-transform: lowercase;
			}

			.settings__content--block .settings__content--main button {
				margin: 0px;
				font-size: 16px;
				padding: 0px 10px;
				height: 28px;
				text-transform: lowercase;
			}

				#settings--basic-info .button-holder {
					justify-content: flex-start;
				}

				#settings--basic-info .button-holder .label__info {
					width: 170px;
				}

				.button-holder .edit-basic-info--button {
					top: 1px;
				}

			.edit-basic-info--button {
				mask: url("../images/pencil-alt.svg") no-repeat center center;
				-webkit-mask: url("../images/pencil-alt.svg") no-repeat center center;
				-webkit-mask-size: cover;
				background: #FA583A;
  				mask-size: cover;
				width: 11px;
				height: 11px;
				text-indent: -9999px;
				display: inline-block;
				margin: 0px 6px 0px 0px;
				position: relative;
				top: 5px;
			}

			.new-location--button {
				mask: url("../images/plus_icon.svg") no-repeat center center;
				-webkit-mask: url("../images/plus_icon.svg") no-repeat center center;
				-webkit-mask-size: cover;
				background: #FA583A;
  				mask-size: cover;
				width: 11px;
				height: 11px;
				text-indent: -9999px;
				display: inline-block;
				margin: 0px 6px 0px 0px;
				position: relative;
				top: 4px;
			}

		.settings__content--header {
			width: 100%;
			height: 65px;
			line-height: 65px;
			box-sizing: border-box;
			padding: 0px 35px;
			border-bottom: 1px solid rgba(223, 230, 233, 0.5);
		}

			.settings__content--right .category-title h2 {
				text-transform: capitalize;
				font-size: 20px;
			}

			.settings__content--right .category-title .chevron {
				width: 6px;
				height: 10px;
				margin: 0px 10px 0px 0px;
			}

		.settings__content--main {
			box-sizing: border-box;
			padding: 35px;
		}

			.settings__content--main p {
				width: 80%;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

				.settings-children-container .settings__content--main p .label__info-src {
					font-size: 16px;
					font-weight: 400;
					color: #636E72;
					width: calc(100% - 130px);
				}

				.settings-children-container .settings__content--main .settings-info--holder .label__info-src {
					font-size: 20px;
					font-weight: 400;
					color: #636E72;
					width: calc(100% - 130px);
				}

				.settings-children-container .settings__content--main .settings-info--holder {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

					.settings-children-container .settings__content--main .settings-info--holder .empty-cart__container {
						margin: 30px 0px 35px 0px;
					}

				.address-list {
					width: 100%;
				}

				.address-list.hide-list {
					display: none;
				}

					.address-list li {
						display: flex;
						align-items: center;
						justify-content: space-between;
						box-sizing: border-box;
						padding: 10px 20px;
						background: #f5f6fa;
						border-radius: 4px;
						margin: 0px 0px 10px 0px;
						border: 1px solid #0000000a;
						cursor: pointer;
					}

						.address-list li:last-of-type {
							margin: 0px 0px 0px 0px;
						}

						.address-list li .address-info--holder {
							display: flex;
							align-items: center;
							flex-wrap: wrap;
						}

							.address-list li .address-info--holder span {
								width: 100%;
							}

							.address-list li .address-info--holder .address-label {
								font-size: 18px;
								text-transform: capitalize;
								font-weight: bold;
								color: #636E72;
							}
							
							.address-list li .address-info--holder .address-src {
								font-size: 16px;
								color: #B2BEC3;
								margin: 5px 0px 0px 0px;
							}

							.show-map {
								width: 35px;
								height: 35px !important;
								border-radius: 50%;
								background: #636E72;
								display: flex;
								align-items: center;
								justify-content: center;
								box-shadow: 2px 2px 3px 0px #00000040;
							}

								.show-map span {
									display: inline-block;
									-webkit-mask-image: url("../images/map-marker-alt.svg");
									mask-image: url("../images/map-marker-alt.svg");
									background-size: 100%;
									-webkit-mask-size: 100%;
									mask-size: 100%;
									-webkit-mask-repeat: no-repeat;
									mask-repeat: no-repeat;
									background: #fff;
									text-indent: -9999px;
									width: 15px;
									height: 20px;
									margin: 1px 0px 0px 0px;
								}

				.settings-children-container .settings__content--main .settings-info--holder input {
					width: 50%;
				}

				.settings__content--main .settings-info--holder .label__info-src {
					font-size: 20px;
					font-weight: 400;
					color: #636E72;
					width: calc(100% - 130px);
				}

				.settings__content--main .file-upload__holder {
					width: 80%;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				.settings__content--main p .label__input{
					width: 70%;
				}

				.settings__content--main p:first-of-type {
					margin-top: 0px;
				}

				.settings__content--main .switch__holder {
					justify-content: flex-start;
					margin-top: 20px;
				}

				.switch {
					position: relative;
					display: inline-block;
					width: 43px;
					height: 22px;
				}
				  
				.switch input {
					display:none;
				}
				  
				.slider {
					position: absolute;
					cursor: pointer;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: #F5F6FA;
					border: 1px solid #dfe6e9;
					-webkit-transition: .4s;
					transition: .4s;
				}
				  
				.slider:before {
					position: absolute;
					content: "";
					height: 16px;
					width: 16px;
					left: 4px;
					bottom: 0;
					top: 0;
					margin: auto;
					background-color: #FA583A;
					-webkit-transition: .4s;
					transition: .4s;
				}
				  
				input:checked + .slider:before {
					-webkit-transform: translate3d(17px, 0px, 0px);
					-ms-transform: translate3d(17px, 0px, 0px);
					transform: translate3d(17px, 0px, 0px);
					background: #B2BEC3;
				}
				  
				.slider.round {
					border-radius: 34px;
				}
				  
				.slider.round:before {
					border-radius: 50%;
				}

				.settings__content--main .file-upload__holder {
					justify-content: flex-start;
					margin-top: 10px;
				}

					.file-upload__button-container {
						display: flex;
						align-items: center;
					}

						.file-upload__holder .file__input {
							width: 0.1px;
							height: 0.1px;
							opacity: 0;
							overflow: hidden;
							position: absolute;
							z-index: -1;
						}

						.file__input + label {
							color: #FA583A;
							border-radius: 3px;
							border: 1px solid #FA583A;
							font-size: 15px;
							font-weight: bold;
							padding: 0px 10px;
							height: 26px;
							line-height: 26px;
							display: block;
							width: auto !important;
						}

						.file__input + label {
							cursor: pointer;
						}

						#settings--basic-info .file-upload__holder .label__info {
							width: 165px;
						}

						.inside__avatar--holder {
							width: 33px;
							height: 33px;
							margin: 0px;
							border-radius: 50%;
							overflow: hidden;
							display: inline-block;
							margin: 0px 10px 0px 0px;
						}

							.inside__avatar--holder .settings__avatar__image-holder {
								width: 100%;
								height: 100%;
							}
				
							.inside__avatar--holder img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
			
			.settings__content-block--actions {
				margin: 30px 0px 0px 0px;
			}

				.settings__content-block--actions button {
					display: inline-block;
					height: 38px;
					font-size: 18px;
					text-transform: uppercase;
					margin: 0px 15px 0px 0px;
					padding: 0px 20px;
				}

			#settings--locations .settings__content-block--actions div {
				width: 100%;
				display: flex;
				justify-content: flex-end;
			}

		#settings--basic-info .switch__holder .label__info {
			width: 150px;
		}

		#settings--basic-info .label__info {
			text-transform: lowercase;
			width: 200px;
		}

		.notification-type--divider {
			margin: 0px 0px 20px 0px;
		}

			.notification-type--divider h2 {
				font-size: 18px;
				font-weight: bold;
				color: #636E72;
			}

		.notification-switcher-list label {
			font-weight: 400;
			font-size: 16px;
		}
/*  
/*------------------------------------------------------------------------------*\
  #ALL PRODUCTS
/*------------------------------------------------------------------------------*\ 
*/


.all-products .raft__content {
    display: flex;
	flex-wrap: wrap;
	margin: 20px 0px 0px 0px;
}
	.all-products .raft-card {
		width: 193px;
		margin: 0px 0px 46px 0px;
	}

	.columns .raft.expand .raft-illustration, .raft.expand .raft__slider--decorator-end {
		display: none;
		visibility: hidden;
	}

	.raft.expand .slick-slide.slick-cloned {
		display: none;
	}

	.raft__content.expand:after {
		content: "";
		display: table;
		clear: both;
	}

	.all-products .raft-illustration {
		background: url("../images/full_raft_illustration_2.svg") repeat-y 0px 0px;
		width: calc(100% + 60px);
		height: 100%;
		top: 262px;
		background-size: 100% 330px;
		background-repeat: round;
		pointer-events: none !important;
	}

	.all-products .raft__categories--right {
		right: 0;
	}

	.raft__categories.no-children .disabled-buttons {
		top: 5px;
	}

	#raft__slider .raft__categories.no-children .slick-arrow {
		top: -19px;
	}

	.raft__categories.no-children button.carousel-arrow.block.custom {
		margin-top: 1px;
	}

	.disabled-buttons.raft-no-children {
		top: 9px;
	}

	.category-content-container.no-children button.prev-button, .category-content-container.no-children button.next-button {
		display: none;
	}

	


/*  
/*------------------------------------------------------------------------------*\
  #STATISTICS
/*------------------------------------------------------------------------------*\ 
*/

#statistics .component__header {
	display: flex;
	align-items: center;
}

	.order-history__wrapper .datepicker {
		width: 50%;
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer; 
	}

		.order-history__wrapper .datepicker--web {
			width: 100%;
		}

		.order-history__wrapper .datepicker--web .DayPickerInput-Overlay{
			max-width: 100% !important;
		}

		.order-filter-datepicker-container {
			/* width: 200px; */
			height: 60px;
			background-color: #fff;
			border-radius: 5px;
			color: #B2BEC3;
			box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.07);
			box-sizing: border-box;
			padding: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

			.order-filter-datepicker-container .filter-title {
				margin-left: 10px;
				margin-right: 10px;
				display: inline-block;
				line-height: 17px;
				text-transform: uppercase;
				margin-top: 2px;
			}
		
			.order-filter-datepicker-container select {
				width: 200px;
				height: 35px;
				border: 1px solid #DFE6E9;
				cursor: pointer;
				background: transparent;
				font-size: 14px;
				color: #636E72;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				padding: 0px 11px;
				border-radius: 5px;
				background-image: url('../images/chevron_down.svg');
				background-repeat: no-repeat, repeat;
				-webkit-appearance: none;
				background-position: right .7em top 50%, 0 0;
				background-size: .65em auto, 100%;
				margin-left: 10px;
			}

		.order-history__wrapper .datepicker:after {
			content: url("../images/chevron_down.svg");
			width: 10px;
			height: 19px;
			position: absolute;
			right: 40px;
			top: 0;
			bottom: 0;
			margin: auto;
			pointer-events: none;
		}

		.order-history__wrapper .datepicker .DayPickerInput{
			width: 100%;
		}

			.date-decorator div {
				color: #B2BEC3;
				font-size: 16px;
				display: flex;
				align-items: center;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 43px;
				pointer-events: none;
			}

			
				.date-decorator--icon {
					background: url("../images/calendar_icon.svg") no-repeat center center;
					width: 15px;
					height: 17px;
					display: inline-block;
					margin: 0px 0px 0px 10px;
				}
				
			.date-decorator hr{
				width: 50%;
				height: 1px;
				border: 0px;
				background: #b2bec3;
				opacity: 0.3;
				position: absolute;
				left: 109px;
				top: 35px;
			}

			.datepicker .DayPickerInput input {
				width: 100%;
				height: 60px;
				/* line-height: 60px; */
				background: #fff;
				box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.07);
				box-sizing: border-box;
				padding: 0px 35px;
				padding-left: 110px;
				border: 0px;
				font-size: 18px;
				color: #B2BEC3;
				cursor: pointer;
			}

			#statistics .DayPickerInput-OverlayWrapper, .DayPickerInput-Overlay, .DayPicker, .DayPicker-Month {
				width: 100%;
			}


			#statistics .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
				color: #fff;
				background: transparent;
			}

			#statistics .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
				background: transparent;
				color: #FA583A;
			}

				.order-history__wrapper .datepicker.d-from .DayPickerInput input {
					border-bottom-left-radius: 5px;
					border-top-left-radius: 5px;
				}

				.order-history__wrapper .datepicker.d-to .DayPickerInput input {
					border-bottom-right-radius: 5px;
					border-top-right-radius: 5px;
				}

				.order-history__wrapper .datepicker:first-of-type .DayPickerInput input {
					border-right: 1px solid #EEF2F3;
				}

				.datepicker .DayPickerInput input::-webkit-input-placeholder {
					color: #B2BEC3;
				}

				.datepicker .DayPickerInput input::-moz-placeholder {
					color: #B2BEC3;
				}

				.datepicker .DayPickerInput input::-ms-input-placeholder {
					color: #B2BEC3;
				}

				.datepicker .DayPickerInput input:-moz-placeholder {
					color: #B2BEC3;
				}
	
	#statistics .settings__content--main {
		/* padding-top: 0px; */
	}

	.settings__content--block.statistics {
		margin: 30px 0px 0px 0px;
	}

		.charts {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

			.chart-legend {
				text-align: center;
				margin: 0px 0px 15px 0px;
			}

				.chart-legend h2 {
					color: #636E72;
					font-size: 17px;
					font-weight: 400;
				}

		.chart {
			height: 320px;
		}

			.chart.monthly-chart .chart-disclaimer {
				margin: 0px;
			}

				.chart.monthly-chart .echarts-for-react.react_for_echarts {
					top: -20px;
				}

			.chart-disclaimer {
				width: 100%;
				text-align: center;
				margin: 0px 0px 20px 0px; 
			}

				.chart-disclaimer h2 {
					font-size: 20px;
					color: #636E72;
					font-weight: bold;
				}

				.chart-disclaimer span {
					display: inline-block;
					width: 80%;
					font-size: 15px;
					margin: 5px 0px 0px 0px;
					color: #636E72;
					opacity: 0.5;
				}

			.chart.monthly-chart rect {
				cursor: pointer;
			}

			.settings__content--main:after {
				content: "";
				display: table;
				clear: both;
			}

			.content--two-type {
				width: 100%;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin: 30px 0px 0px 0px;
			}
			
				.chart--pie{
					width: 48%;
					margin: 20px auto;
				}

				.echarts-for-react.react_for_echarts_pie {
					height: auto !important;
				}

					.chart--pie .tooltip-wrap {
						z-index: 2 !important;
					}

				.stats-grouped {
					width: 100%;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					margin: 50px 0px 0px 0px;
					display: -ms-flexbox;
					display: flex;
					-ms-flex-wrap: wrap;
					flex-wrap: wrap;
					-ms-flex-align: center;
					align-items: center;
					-ms-flex-pack: justify;
					justify-content: space-between;
				}

					.stats-grouped.trim-margin {
						margin-top: 0px;
					}

					.static {
						width: 47%;
						height: 90px;
						position: relative;
					}

						.static:before {
							content: url("../images/stat_money_rounded_icon.svg");
							width: 32px;
							height: 32px;
							line-height: 21px;
							position: absolute;
							left: -15px;
							top: -15px;
							background-color: #FA583A;
							box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.15);
							border-radius: 50%;
							display: inline-block;
							box-sizing: border-box;
							padding: 7px;
						}

						.static .single-stat--block {
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
						}

							.static .stat-disclaimer {
								position: absolute;
								left: 22px;
								top: -19px;
								font-size: 11px;
								color: #FA583A;
								text-transform: uppercase;
								letter-spacing: 2px;
								font-weight: 800;
								white-space: nowrap;
							}
					.stats-grouped-item {
						width: calc(100% / 4 - 10px);
						height: 120px;
						background: #F5F6FA;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 5px;
						border: 1px solid #7070701c;
					}

						.single-stat--block .visual {
							max-width: 50px;
							width: 50px;
							height: 50px;
							overflow: hidden;
							margin: 0px 10px 0px 0px;
							display: flex;
							align-items: center;
							justify-content: center;
						}

							.single-stat--block.iconed .visual span{
								width: 40px;
								display: inline-block;
							}

							.single-stat--block.iconed .visual .cart--icon{
								height: 30px;
								background: url("../images/stat_cart_icon.svg");
								background-repeat: no-repeat;
							}

							.single-stat--block.iconed .visual .money--icon{
								height: 22px;
								background: url("../images/stat_money_icon.svg");
								background-repeat: no-repeat;
							}

							.single-stat--block .visual .raft-card__product-img .animated {
								width: 100%;
								height: 100%;
							}

								.single-stat--block .visual .raft-card__product-img {
									height: 100%;
								}

								.single-stat--block .visual img{
									/* max-width: 100%; */
									width: 100%;
									height: 100%;
									object-fit: contain;
								}

							.single-stat--block .textual h2 {
								color: #636E72;
								font-weight: bold;
								font-size: 22px;
								line-height: 15px;
							}

							.single-stat--block .textual h4 {
								color: #B2BEC3;
								font-weight: 200;
								font-size: 18px;
								max-width: 110px;
								line-height: 15px;
								margin: 5px 0px 0px 0px;
							}

							.single-stat--block .if-not-loaded {
								width: 40px;
								height: 40px;
							}

								.single-stat--block .if-not-loaded .placeholder {
									width: 24px;
									height: 27px;
								}

							.single-stat--block .if-not-loaded.show-image {
								margin: 0px 10px 0px 0px;
								background: transparent;
							}

	.statistics-tab {
		width: 100%;
		display: flex;
		align-items: center;
		box-shadow: 0 0 15px 6px rgba(0, 0, 0, 0.03);
		background: #fff;
		border-radius: 4px;
	}
	
		.single-tab {
			width: 50%;
			text-align: center;
			height: 45px;
			line-height: 45px;
			background: #fff;
			cursor: pointer;
			border: 4px solid transparent;
			transition: background 0.2s ease-in-out;
		}

			.single-tab.active-stats-tab {
				background: #FA583A;
				border-bottom-left-radius: 4px;
				border-top-left-radius: 4px;
				border: 4px solid #fff;
			}

		.single-tab h2 {
			font-size: 20px;
			font-weight: 500;
			color: #636E72;
		}

			.single-tab.active-stats-tab h2 {
				color: #fff;
			}

	.stats-picker {
		display: flex;
		align-items: center;
	}

		.single-stat-picker {
			width: 220px;
			margin: 0px 0px 0px 10px;
		}

			.single-stat-picker select {
				width: 100%;
				height: 35px;
				border: 1px solid #DFE6E9;
				cursor: pointer;
				background: transparent;
				font-size: 14px;
				color: #636E72;
				box-sizing: border-box;
				padding: 0px 11px;
				border-radius: 5px;
				background-image: url("../images/chevron_down.svg");
				background-repeat: no-repeat, repeat;
				-webkit-appearance: none;
				background-position: right .7em top 50%, 0 0;
				background-size: .65em auto, 100%;
			}

			/* Hide arrow icon in IE browsers */
			.single-stat-picker select::-ms-expand {
				display: none;
			}
			/* Hover style */
			.single-stat-picker select:hover {
				border-color: #888;
			}
			/* Focus style */
			.single-stat-picker select:focus {
				border-color: #aaa;
				/* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
				box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
				box-shadow: 0 0 0 3px -moz-mac-focusring;
				color: #222; 
				outline: none;
			}

	#statistics .no-data {
		height: 420px;
    	position: relative;
	}

	#statistics .spinner-container {
		width: calc(100% - 110px);
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	.chart-src {
		position: relative;
	}

		.overlayed-statistics-disclaimer.empty-data-container {
			position: absolute;
			top: 0;
			margin: 10px 0px 0px 0px;
			bottom: 0;
		}

		.lower-opacity, .lower-opacity .echarts-for-react.react_for_echarts {
			opacity: 0.3;
		}
			
/* 
	LOCATION POPUP
*/

#location__popup .product__popup-container {
	padding: 25px;
	width: 500px;
	box-sizing: border-box;
	overflow-x: hidden;
}

	#location__popup .product__popup-container .product__popup--full {
		position: static;
		padding: 0px;
	}

	#location__popup .product__popup-container .inputs {
	    margin: 20px 0px 0px 0px;
	}

		#location__popup .product__popup-container .inputs .input__holders--col {
			width: 100%;
			margin: 0px 0px 15px 0px;
			position: relative;
			/* display: flex;
			align-items: center;
			justify-content: space-between; */
		}

			#location__popup .product__popup-container .grouped__holders .zip{
				width: 100px;
			}

			#location__popup .product__popup-container .grouped__holders .city{
				width: calc(100% - 165px);
				margin: 0px 0px 0px 20px;
			}

			#location__popup .product__popup-container .inputs .input__holders--col label {
				color: #99A1A3;
				font-size: 18px;
				font-weight: 500;
				transition: all 0.2s ease-in-out;
				text-transform: lowercase;
				/* max-width: 130px; */
				/* width: 100%; */
			}

			#location__popup .input__holders--col input {
				width: 100%;
			}

		#location__popup .settings__content-block--actions {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		
		#location__popup .settings__content-block--actions button {
			margin: 0px;
		}
		
		#location__popup .settings__content-block--actions div button {
			margin: 0px 0px 0px 10px;
		}

		#location__popup .delete-location {
			padding: 0px;
			background: #FA583A;
			width: 17px;
			height: 19px;
			-webkit-mask-image: url("../images/trash-alt.svg");
			mask-image: url("../images/trash-alt.svg");
			background-size: 100%;
			mask-size: 100%;
			mask-repeat: no-repeat;
			text-indent: -9999px;
		}

			#location__popup .delete-location:hover {
				opacity: 0.8;
			}

			#location__popup .delete-location:active {
				opacity: 0.6;
			}

			#location__popup .settings__content-block--actions div {
				width: 100%;
				display: flex;
				justify-content: flex-end;
			}
			
	#settings .subpage-nav ul li:before {
		opacity: 0.3;
	}

		#settings .subpage-nav ul li.active-settings-chevron:before {
			opacity: 1;
		}


	.linked-profile {
		width: 100%;
		padding: 0px 0px 20px 0px;
		border-bottom: 1px solid #00000026;
		margin: 0px 0px 30px 0px;
	}

		.linked-profile:last-child {
			padding: 0px;
			border-bottom: 0px;
			margin: 0px;
		}

		.profile-intro {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: justify;
			justify-content: space-between;
			width: 100%;
			margin: 0px 0px 20px 0px;
		}

			.profile-info {
				width: 100%;
				margin: 15px 0px 0px 0px;
			}

			.profile-intro div {
				display: flex;
				align-items: center;
			}

			.profile-intro .profile-icon {
				width: 18px;
				height: 20px;
				display: inline-block;
				background-size: 100%;
				mask-size: 100%;
				mask-repeat: no-repeat;
				text-indent: -9999px;
			}

			.profile-intro.facebook .profile-icon {
				display: inline-block;
				background: url("../images/facebook_icon_new.svg");
				background-repeat: no-repeat;
			}

			.profile-intro.google .profile-icon {
				display: inline-block;
				background: url("../images/google_icon_new.svg");
				background-repeat: no-repeat;
			}

			.profile-intro.boostercloud .profile-icon {
				display: inline-block;
				-webkit-mask-image: url("../images/boostercloud.svg");
				mask-image: url("../images/boostercloud.svg");
				background-color: #F94E20;
			}

			.profile-network {
				font-size: 18px;
				font-weight: bold;
				text-transform: capitalize;
				margin: 0px 0px 0px 10px;
				line-height: 7px;
				color: #636E72;
			}

			.linked-profile .profile-info p .label__info {
				width: 140px !important;
			}

	.settings__content--main p:last-child {
		margin-bottom: 0px;
	}

	#location__popup .section__favorite h2 {
		color: #444;
		font-size: 21px;
	}

		#location__popup .section__favorite h2::first-letter {
			text-transform: uppercase;
		}

		span.mismatch {
			color: red;
			font-size: 16px;
			color: #FA583A;
		}

	.popup--gradient {
		background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
		width: 100%;
		height: 120px;
		position: absolute;
		top: -118px;
		transition: all 0.2s ease-in-out;
		pointer-events: none !important;
	}

	.single-stat--block .visual .animated-product-image-container {
		height: 50px;
	}

/* 
	HELP
*/


#help .single-page-wrapper {
	display: flex;
    justify-content: space-between;
}

.help-nav {
	width: 280px;
	float: left;
}

.help-nav ul {
	margin: 20px 0px 0px 0px;
	position: sticky;
	top: 95px;
}

.help-nav ul li{
	margin: 0px 0px 15px 0px;
	cursor: pointer;
}

.help-nav ul li a{
	font-size: 20px;
	color: #B2BEC3;
	font-weight: 300;
}

.help-nav ul li a:before{
	content: url("../images/chevron_right_red.svg");
	width: 7px;
	height: 10px;
	display: inline-block;
	margin: 0px 10px 0px 0px;
	opacity: 0.3;
	position: relative;
	top: -1px;
}

.help-nav ul li .active-helper {
	font-weight: bold;
	color: #636E72;
}

.help-nav ul li .active-helper:before {
	opacity: 1;
}

.help-container-switcher {
	width: calc(100% - 280px);
	float: left;
}

	.help-container-switcher .text-block-header.text-block-header-main {
		padding: 10px 20px;
		height: unset;
	}

.sub-page {
	margin: 20px 0px 40px 0px; 
	box-sizing: border-box;
	padding: 0px 0px 0px 20px;
}

.text-block {
	margin: 30px 0px 0px 0px;
}

.text-block:first-child {
	margin: 0px 0px 0px 0px;
}

.text-block-header {
	display: flex;
	align-items: center;
	margin: 0px 0px 20px 0px;
	width: 100%;
	height: 50px;
	
}

.text-block-header-main {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 2.5px 20px 0px 20px;
	background: #fff;
	border-radius: 4px;
}

.text-block-header .chevron {
	background: url("../images/chevron_right_red.svg") no-repeat center center;
	width: 9px;
	height: 12px;
	text-indent: -9999px;
	margin: 0px 10px 0px 0px;
	position: relative;
	top: -0.8px;
}

.sub-page .sub-content h2 {
	color: #444;
    font-weight: bold;
	font-size: 20px;
	line-height: 24px;
    text-transform: uppercase;
    margin: 35px 0px 15px 0px;
}

.text-block-text {
	font-size: 18px !important;
	font-weight: 300 !important;
	color: #636E72 !important;
	margin: 0px 0px 15px 0px !important;
	line-height: 24px !important;
}

.sub-page .sub-content p {
	font-size: 18px;
	font-weight: 300 !important;
	color: #636E72 !important;
	margin: 0px 0px 15px 0px !important;
	line-height: 24px !important;
}

.sub-page .sub-content li {
	font-size: 18px;
	font-weight: 300 !important;
	color: #636E72 !important;
	line-height: 24px !important;
}

.sub-page .sub-content span, .sub-page .sub-content div {
	font-size: 18px !important;
	font-weight: 300 !important;
	color: #636E72 !important;
	margin: 0px 0px 15px 0px !important;
	line-height: 24px !important;
}

.iconless .text-block-header h2 {
	color: #636E72;
}

.text-block.iconless .input__holders--col label {
	font-size: 20px;
	font-weight: bold;
	color: #636E72;
	width: 100%;
}

.text-block.iconless .input__holders--col .label__input {
	background: #fff;
	width: 100%;
	margin: 10px 0px 0px 0px;
}

.text-block.iconless.text-block-inputs {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 40px 0px 0px 0px;
}

	#contact .text-block.iconless.text-block-inputs form {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	#contact form .lds-ellipsis {
		position: relative;
		width: 59px;
		height: 12px;
		margin: 0px;
		text-align: center;
		left: 0px;
	}

		#contact form .lds-ellipsis div {
			margin: auto;
			bottom: 0;
			top: 0;
			width: 10px;
			height: 10px;
			background: #ffffff;
		}

.text-block-inputs .input__holders--col {
	width: 48%;
	margin: 0px 0px 20px 0px;
}

.text-block-inputs .input__holders--col.ihc--full {
	width: 100%;
}

.text-block-inputs .input__holders--col.ihc--full textarea {
	width: 100%;
	max-width: 100%;
	height: 150px;
	padding: 15px;
	resize: none;
}

.send-contact-button {
	font-size: 18px;
	font-weight: bold;
	width: 140px;
	text-align: center;
}

#help .spinner-container {
	width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}


.contact-button {
	margin: 20px 0px 0px 0px;
}

	.contact-button-icon {
		display: inline-block;
		width: 13px;
		height: 13px;
		-webkit-mask-image: url("../images/envelope.svg");
		mask-image: url("../images/envelope.svg");
		mask-size: cover;
		-webkit-mask-size: cover;
		background: #fff;
		margin: 0px 10px 0px 0px;
	}

/* 
CONFIRM
*/

.confirm-animated {
	width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#confirm {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left: 0;
	right: 0;
	top: -30px;
	bottom: 0px;
}

#confirm .centered {
	text-align: center;
}

#confirm .centered h2 {
	font-weight: 400;
	color: #5a5a5a;
	font-size: 23px;
	margin: 20px 0px 0px 0px;
}

/* 
	LIST_POPUP
*/

#list__popup-container .confirmation__popup .popup__content {
	flex-wrap: wrap;
}

	#list__popup-container .product__popup-container {
		width: 500px;
		overflow: visible !important;
	}

	.dropdown-choice {
		pointer-events: none;
	}

		.popup__content .input-holder {
			width: 100%;
			margin: 0px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

			.popup__content .input-holder .label__info {
				width: 100%;
				margin: 0px 0px 10px 0px;
			}

			.popup__content .input-holder .label__input {
				width: 70%;
				margin: 0px;
			}

			#list__popup-container .select-box {
				width: 70%;
			}

		#list__popup-container .popup__disclaimer {
			font-weight: bold;
			margin: 25px 0px 15px 0px;
		}

		#list__popup-container .cart-choices .single-choice:last-of-type {
			margin: 0px 0px 10px 0px;
		}

			#list__popup-container .dropdown__trigger .dropdown-disclaimer {
				justify-content: flex-start;
				padding: 0px;
			}

				#list__popup-container .dropdown__trigger .dropdown-disclaimer:after {
					content: none;
				}

		.cart-choices {
			width: 100%;
		}

			.single-choice {
				display: flex;
				align-items: center;
				margin: 0px 0px 20px 0px;
				flex-wrap: wrap;
				position: relative;
			}
			
				.single-choice .dropdown__trigger {
					width: 315px;
					pointer-events: auto;
				}

				#exisitinglist {
					top: 42px;
					width: 314px;
					pointer-events: auto;
					max-height: 220px;
					overflow: auto;
				}

				.single-choice .input-holder{
					display: flex;
					align-items: center;				
				}

					.single-choice .input-holder .label__info {
						margin: 0px 0px 0px 5px !important;
						width: auto;
					}

				.single-choice h2 {
					width: 100%;
				}
			
				.single-choice .label__info {
					width: 100%;
					margin: 0px 0px 10px 0px;
				}

				#list__popup-container .single-choice .default-cart {
					width: 20px;
				}

	
	#saved-carts .dropdown-disclaimer {
		pointer-events: auto;
		justify-content: flex-start;
	}
	
	#saved-carts .dropdown__trigger::after {
		content: url("../images/chevron_down.svg");
		width: 8px;
		height: 10px;
		display: inline-block;
		line-height: 5px;
	}

		#saved-carts .dropdown__trigger .dropdown-disclaimer {
			padding: 0px;
		}

			#saved-carts .dropdown__trigger .dropdown-disclaimer:hover {
				background: transparent;
			}

			#saved-carts .dropdown__trigger .dropdown-disclaimer::after {
				content: none;
			}
			
			#saved-carts .dropdown__trigger h2 {
				justify-content: flex-start;
			}

			#saved-carts .dropdown__trigger h2::after {
				content: none;
			}

	.dropdown-disclaimer {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

		.cart-icon-dropdowned {
			display: inline-block;
			width: 14px;
			height: 14px;
			-webkit-mask-image: url("../images/cart_icon.svg");
			mask-image: url("../images/cart_icon.svg");
			mask-size: cover;
			-webkit-mask-size: cover;
			background: #636E72;
			position: relative;
			top: -1px;
			margin: 0px 5px 0px 0px;
		}

		.empty-icon-dropdowned {
			display: inline-block;
			width: 14px;
			height: 14px;
			-webkit-mask-image: url("../images/empty-box.svg");
			mask-image: url("../images/empty-box.svg");
			mask-size: cover;
			-webkit-mask-size: cover;
			background: #636E72;
			position: relative;
			top: -1px;
			margin: 0px 5px 0px 0px;
		}

			#list__popup-container .dropdown-disclaimer {
				font-size: 15px;
				font-weight: 400;
			}

			#list__popup-container .cart-icon-dropdowned, #list__popup-container .empty-icon-dropdowned {
				width: 16px;
				height: 16px;
				mask-size: 100%;
				-webkit-mask-size: 100%;
				mask-repeat: no-repeat;
				-webkit-mask-repeat: no-repeat;
				margin: 0px 8px 0px 0px;
			}

	.dropdown-empty {
		font-size: 16px;
		box-sizing: border-box;
		padding: 17px;
		font-weight: 400;
		color: #828282;
		margin: 0px !important;
	}

	.trim-dropdown {
		height: auto !important;
		overflow: unset;
	}

	#list__popup-container .dropdown-disclaimer {
		pointer-events: auto;
	}

	#list__popup-container .dropdown__trigger h2 {
		justify-content: flex-start;
	}

	#list__popup-container .dropdown__trigger h2:after {
		content: none;
	}

	#list__popup-container .dropdown__trigger:after {
		content: url("../images/chevron_down.svg");
		display: inline-block;
		opacity: 1;
		width: 10px;
		height: 10px;
		line-height: 10px;
	}

		.dropdown-disclaimer.spacing {
			margin-bottom: 5px;
		}

	.dropdown-divider {
		width: 278px;
		box-sizing: border-box;
		display: block;
		height: 1px;
		border: 0px;
		background: #00000017;
		margin: 0px auto;
		margin-bottom: 3px;
	}
	
	.dropdown-main-src {
		position: relative;
	}

	/* 
		SLIDER [MINICAR]
	*/

	.fixed-height {
		height: 310px;
	}
	
	.minicar-list .eSGzmI {
		box-sizing: border-box;
		padding: 15px 0px 0px 0px;
	}

	.minicar .animated {
		animation-fill-mode: unset;
		-webkit-animation-fill-mode: unset;
	}

/* 
	#PRINT MODE
*/

.print-logo,
.print-table-data,
.print-table-head {
	display: none;
}

.print-intro {
	margin: 0px 0px 0px 0px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 50px 50px 15px 50px;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    display: -ms-flexbox;
    display: none;
    border-top: 15px solid #FA583A;
    background: white;
}

	.basic-info-src {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

		.logo-src {
			margin: 0px 20px 0px 0px;
		}

		.text-src h2 {
			font-size: 16px;
			font-weight: normal;
			white-space: nowrap;
			margin: 0px 0px 5px 0px;
		}

	.date-src {
		box-sizing: border-box;
		padding: 15px;
		border: 1px solid #00000024;
		border-radius: 20px;
	}

		.date-src h2 {
			font-size: 16px;
			font-weight: normal;
			white-space: nowrap;
			margin: 0px 0px 5px 0px;
		}

			.date-src h2 strong {
				display: inline-block;
				margin: 0px 0px 0px 5px;
			}

			.date-src h2:last-of-type {
				margin-bottom: 0px;
			}

@media print {

	.print-block {
		display: none !important;
	}

	.print-intro {
		display: flex !important;
	}

	.collapsible__header {
		display: none;
	}

	.orders__table tr th {
		padding: 10px;
	}

	.orders__table thead {
		margin: 0px 0px 30px 0px;
		padding: 0px 0px 0px 0px;
	}

		.orders__table thead tr {
			background: #f5f6fa;
			vertical-align: middle;
		}

	.print-table-data,
	.print-table-data {
		display: table-cell;
	}

	.collapsible__triger.collapsible__content.basic_info.expand .orders-src {
		padding: 50px;
	}

	.orders__table tbody tr td {
		padding: 10px 0px;
	}

	.collapsible__triger.collapsible__content.basic_info.expand .orders-src {
		max-height: 100%;
		height: 100%;
		box-shadow: none;
		border: 0px;
	}

	.collapsible__triger.collapsible__content.basic_info.expand {
		box-shadow: none;
		border: 0px;
	}

}

/* 
	* Search Functionality
*/

.pointer-events {
    position: fixed;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 99999;
	top: 123px;
	overflow: auto;
}

.searched-value {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
}

	.searched-value h2 {
		font-size: 18px;
		font-weight: normal;
		color: #636E72;
		margin: 0px 5px 0px 0px;
	}

	.searched-value h4 {
		background: #fa583a;
		color: #fff;
		font-weight: normal;
		box-sizing: border-box;
		padding: 4px 15px 4px 15px;
		border-radius: 50px;
		font-size: 18px;
	}

		.searched-value h4 .remove-search-value{
			display: inline-block;
			mask: url("../images/x_icon.svg") no-repeat center center;
			-webkit-mask: url("../images/x_icon.svg") no-repeat center center;
			-webkit-mask-size: 100%;
			background: #fff;
			width: 8px;
			height: 9px;
			margin: 0px 0px 0px 5px;
		}

	#searched-products .component__content {
		margin: 10px 0px 0px 0px;
	}

	#searched-products .all-products .raft__content {
		margin-top: 0px;
		padding-top: 0px;
		height: auto;
		background-position-y: 270px;
    	background-size: 100% 333px;
	}

	#searched-products .all-products .raft-illustration {
		top: 268px;
	}

	#searched-products .all-products .favorite {
		margin: 8px 6px 50px 0px;
	}

	#searched-products .all-products .raft-card {
		margin-bottom: 0px;
	}

	#favorites .component__content {
		margin: 10px 0px 0px 0px;
	}

	#favorites .all-products .raft__content {
		margin-top: 0px;
		padding-top: 0px;
		height: auto;
		background-position-y: 265px;
    	background-size: 100% 333px;
	}

	#favorites .all-products .favorite {
		margin: 8px 6px 50px 0px;
	}

	#favorites .all-products .raft-card {
		margin-bottom: 0px;
	}

/* 
	* COOKIES DISCLAIMER *
*/

.cookies-disclaimer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: flex-end;
}

	.disclaimer-overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: #000000a6;
	}

	.cookies-container {
		width: 100%;
		box-sizing: border-box;
		background: #fff;
		position: relative;
		padding: 30px 40px;
		box-shadow: 0px -10px 115px 5px #00000038;
		border-top: 5px solid #FA583A;
		display: flex;
		align-items: center;
	}

		.cookies-container .cookies-icon {
			display: inline-block;
			mask: url("../images/info.svg") no-repeat center center;
			-webkit-mask: url("../images/info.svg") no-repeat center center;
			-webkit-mask-size: 100%;
			background: #FA583A;
			width: 7px;
			height: 18px;
			margin: 0px 10px 5px 0px;
		}

		.cookies-container h2 {
			font-size: 20px;
			font-weight: normal;
			color: #5f5f5f;
			margin: 0px 20px 0px 0px;
		}

/* 
	* TOUR COMPONENT *
*/

.tour-component-overlay {
	opacity: 0.8;
}

.reactour__helper.tour-component {
	--reactour-accent: #FA583A;
	border-radius: 5px;
	box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
	width: 50%;
	max-width: unset;
}

	.walkthrough-container {
		width: 100%;
		margin: 15px 0px 0px 0px; 
	}

		.walkthrough-title { 
			font-size: 20px;
			color: #505050;
		}

		.content {
			margin: 10px 0px 0px 0px;
		}
		
			.walkthrough-content {
				font-size: 17px;
				line-height: 22px;
				color: #6b6b6b;
				margin: 0px;
			}

	.tour-component .eLzUuG {
		width: 12px;
    	height: 12px;
	}

	.media {
		width: 100%;
		box-sizing: border-box;
		padding: 14px 14px 10px 14px;
		background: whitesmoke;
		margin: 15px 0px 0px 0px;
	}

		.media video {
			width: 100%;
		}

.tour-component-mobile .walkthrough-title {
	font-size: 18px;
	width: calc(100% - 30px);
}

.tour-component-mobile .walkthrough-content {
    font-size: 16px;
    line-height: 20px;
}

.tour-component-mobile .media {
	width: 70%;
	margin: 5px 0px 5px 0px;
}

#___reactour .reactour__helper.tour-component-mobile .fqGNjt {
	margin-top: 10px;
}

.reactour__helper.tour-component-mobile {
	--reactour-accent: #FA583A;
	border-radius: 5px;
	-webkit-box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);
	width: 79%;
	max-width: unset;
	padding: 10px;
	max-height: 77%;
}

	.reactour__helper.tour-component-mobile  .walkthrough-container {
		width: 100%;
		margin: 5px 0px 0px 0px;
		height: 100%;
		overflow: auto;
	}

		.reactour__helper.tour-component-mobile .content {
			margin: 5px 0px 0px 0px;
		}

/* 
	*VALIDATION*
*/

.error-holder {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row-reverse;
}

	.error-holder .single-error{
		width: 49%;
	}

.single-error .invalid-feedback {
	color: #fff;
	font-size: 14px;
	font-weight: 100;
	display: inline-block;
	margin: 0px 10px 5px 0px;
	background: #e74c3c;
	box-sizing: border-box;
	padding: 1px 4px 2px 4px;
}

.invalid-feedback.without-holder {
	margin: 0px 10px 5px 0px;
}

.invalid-input {
	border-color: #e74c3c;
}

.popup__block.popup__inputs.trim-margin {
    margin: 10px 0px 0px 0px;
}


#location__popup .input-grid {
    align-items: flex-end;
}

.label-holder {
	width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
	line-height: 19px;
	height: 22px;
}

	.invalid-feedback {
		color: #fff;
		font-size: 14px;
		font-weight: 100;
		display: inline-block;
		margin: 0px 10px 0px 0px;
		background: #e74c3c;
		box-sizing: border-box;
		white-space: nowrap;
		padding: 1px 4px 2px 4px;
	}
	
	/* .single-error {
		height: 0px;
		opacity: 0;
		visibility: hidden;
		margin: 0px 0px 5px 0px;
		transition: all 0.2s ease-in-out;
	} */
	
	/* .animate-errors .single-error {
		height: 20px;
		opacity: 1;
		visibility: visible;
	} */

	.invalid-input .select-box {
		border-color: #e74c3c;
	}

	#payment__popup .input-grid {
		align-items: flex-end;
	}

	.centered-data {
		width: 100%;
		/* position: relative; */
		flex-grow: 1;
	}

		.centered-data__picture {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

			.centered-data__picture img {
				max-height: 100px;
				/* width: 100%; */
				pointer-events: none;
			}

		.meta-measurment {
			position: absolute;
			top: 125px;
			right: 20px;
			background: #FA583A;
			color: #fff;
			font-size: 15px;
			font-weight: normal;
			font-family: sans-serif;
			box-sizing: border-box;
			padding: 3px 7px 4px 7px;
			border-radius: 6px;
			box-shadow: 0px 0px 5px -1px #FA583A;
		}

			.meta-measurment span {
				margin: 0px !important;
				text-transform: lowercase !important;
			}

	/* .product__popup .meta-measurment {
		position: static;
	}

	.popup__product-category {
		display: flex;
		align-items: center;
	}

		.popup__product-category .meta-measurment {

		} */


.reactour__helper.tour-component span[data-tour-elem="badge"] {
	background: #FA583A !important;
}

.reactour__helper.tour-component button.reactour__dot--is-active[data-tour-elem="dot"] {
	background: #FA583A !important;
}

.raft-card__block.raft-badges {
    height: 21px;
}

.text-loader {
	display: block;
	width: 100%;
	position: relative;
	height: 30px;
	text-align: left;
	overflow: hidden;
	display: flex;
	align-items: center;
}

	.text-loader:before {
		content: "";
		display: inline-block;
		position: absolute;
		left: 0;
		top: 0;
		width: 30px;
		height: 100%;
		background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
		background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
		background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
	} 

	.text-loader:after {
		content: "";
		display: inline-block;
		position: absolute;
		right: 0;
		top: 0;
		width: 30px;
		height: 100%;
		background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
		background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
	} 

	.loader-slider {
		display: inline-block;
		width: 50px;
		height: 50%;
		background: -moz-linear-gradient(left, rgba(237,237,237,0) 0%, rgba(237,237,237,0.98) 50%, rgba(237,237,237,1) 51%, rgba(237,237,237,0) 100%);
		background: -webkit-linear-gradient(left, rgba(237,237,237,0) 0%,rgba(237,237,237,0.98) 50%,rgba(237,237,237,1) 51%,rgba(237,237,237,0) 100%);
		background: linear-gradient(to right, rgba(237,237,237,0) 0%,rgba(237,237,237,0.98) 50%,rgba(237,237,237,1) 51%,rgba(237,237,237,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ededed', endColorstr='#00ededed',GradientType=1 );
		animation-name: LOADER_SLIDE;
		animation-duration: 0.62s;
		animation-iteration-count: infinite;
	}

	@keyframes LOADER_SLIDE {
		0% { transform: translate3d(-40px, 0px, 0px); }
		100% { transform: translate3d(140px, 0px, 0px); }
	}

	.input__holders--col.ihc--full textarea {
		width: 100%;
		height: 75px;
		padding: 15px;
	}

	.input__holders--col h2.label__input {
		padding: 0px 0px 10px 0px !important;
	}

	.centered-data .meta-measurment .litars, 
	.meta-measurment .litars {
		text-transform: uppercase !important;
	}

	.product__popup--recommended .empty-data-container.recommended-by-category {
		margin: 0px;
		height: auto;
		padding: 10px 0px 0px 0px;
	}

	.recommended-static {
		display: flex;
		overflow: hidden;
		scroll-snap-type: x mandatory;
		position: relative;
		box-sizing: border-box;
		padding: 0px;
	}

	.recommended__product {
		cursor: pointer;
		scroll-snap-align: start;
		flex: 0 0 auto;
	}

	.recommended__product .centered-data__picture {
		width: 100%;
		height: 70px;
	}

		.recommended__product .centered-data__picture img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

	.raft__content.raft__content-expanded {
		margin: 16px 0px 0px 0px;
		display: flex;
		flex-wrap: wrap;
	}

		/* .raft__content.raft__content-expanded .carousel-raft-item {
			position: relative;
			margin: 0px 0px 50px 0px;
		} */

		/* .raft__content .carousel-raft-item {
			position: relative;
			margin: 0px 0px 50px 0px;
		} */

		.raft.expand .raft-illustration--expanded {
			top: 276px !important;
		}

		.raft.expand .raft-illustration-shadow--expanded {
			bottom: -9px !important;
			top: 259px !important;
		}

		.raft__content.raft__content-expanded {
			margin: 16px 0px 0px 0px !important;			
		}

		.raft__content.raft__content-expanded .ri + .ris + div{
			margin: 0px 0px 9px 0px;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
		}

		.raft__content.raft__content-expanded .carousel-raft-item {
			margin: 0px 0px 57px 0px;
		}

		.raft__content .ri + .ris + div{
			margin: 0px 0px 37px 0px;
		}

		.carousel-raft-item {
			position: relative;
		}

		/* .carousel-track {
			counter-reset: test;
		}

		.carousel-item {
			counter-increment: test;
			z-index: counter(test) !important;
		} */

		.product__popup--recommended button.carousel-arrow.block.carousel-prev.custom {
			position: absolute;
			left: 32px;
			top: 36px;
			bottom: 0;
			margin: auto;
			z-index: 1;
		}

		.product__popup--recommended button.carousel-arrow.block.carousel-next.custom {
			position: absolute;
			right: 0;
			top: 36px;
			bottom: 0;
			margin: auto;
			z-index: 1;
		}

		.product__popup--recommended button.carousel-arrow.block.carousel-next:before {
			content: "";
			display: inline-block;
			mask: url("../images/slider_arrow_right_icon.svg") no-repeat center center;
			-webkit-mask-size: contain;
			mask-size: contain;
			height: 10px;
			background: #FA583A;
			width: 17px;
		}

		.product__popup--recommended button.carousel-arrow.block.carousel-next:disabled, .product__popup--recommended button.carousel-arrow.block.carousel-prev:disabled {
			border-color: #cecece;
		}

		.product__popup--recommended button.carousel-arrow.block.carousel-next:disabled:before, .product__popup--recommended button.carousel-arrow.block.carousel-prev:disabled:before {
			background: #cecece;
		}

		.product__popup--recommended button.carousel-arrow.block.carousel-prev:before {
			content: "";
			display: inline-block;
			mask: url("../images/slider_arrow_left_icon.svg") no-repeat center center;
			-webkit-mask-size: contain;
			mask-size: contain;
			height: 10px;
			background: #FA583A;
			width: 17px;
		}

		.raft .raft__categories .carousel-arrow:disabled {
			border-color: #cecece;
			cursor: not-allowed;
		}

		.main-content-component.wrapper .raft .raft__categories .carousel-arrow:disabled:before {
			background: #cecece;
			cursor: not-allowed;
		}

		.raft.expand {
			margin-bottom: 0px;
		}

		_::-webkit-full-page-media,
		_:future,
		:root .product__popup--recommended button.carousel-arrow.block.carousel-prev:before {
			right: 5px;
			top: 1px;
			position: relative;
		}

		_::-webkit-full-page-media,
		_:future,
		:root .product__popup--recommended button.carousel-arrow.block.carousel-next:before {
			right: 4px;
			top: 1px;
			position: relative;
		}

		_::-webkit-full-page-media,
		_:future,
		:root .disabled-buttons button.carousel-arrow.block.carousel-prev:before {
			right: 5px;
			position: relative;
		}

		_::-webkit-full-page-media,
		_:future,
		:root .disabled-buttons button.carousel-arrow.block.carousel-next:before {
			right: 4px;
			position: relative;
		}


/* STUFF */

.signup-form-fail {
	width: 100%;
	background-color: #E74C3C;
	color: #fff;
	padding: 10px;
	box-sizing: border-box;
	text-align: center;
	margin-top: 15px;
	border-radius: 5px;
}

	.signup-form-fail span {
		display: inline-block;
		background-color: #fff;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		color: #E74C3C;
		font-weight: bold;
		line-height: 20px;
		font-size: 18px;
		margin-right: 10px;
	}

.signup-form-success {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
		
	.signup-form-success .success-email {
		width: 25%;
		margin: 20px auto;
	}

	.signup-form-success h3 {
		color: #646E72;
		/* width: 90%; */
		text-align: center;
	}
			
	.signup-form-success p {
		color: #B3BEC3;
		text-align: left;
		width: 100%;
	}