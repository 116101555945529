@media only screen and (min-width : 1601px) and (max-width : 1920px) {

	.raft.expand .raft-illustration-shadow--expanded {
		width: calc(100% + 105px);
	}

	#product--web .product__section--left .raft-card__product-img {
		width: 75%;
		margin: 50px 0px 0px 0px;
	}

	.raft-illustration__shadow {
		width: calc(100% + 103px);
	}
}


@media only screen and (min-width : 1401px) and (max-width : 1600px) {
	.favorite {
		width: 193px;
	}

	.raft.expand .slick-list .slick-slide {
		float: left;
	}

	#product--web .product__section--left .raft-card__product-img {
		width: 75%;
		margin: 50px 0px 0px 0px;
	}

	.raft.expand .raft-illustration-shadow--expanded {
		width: calc(100% + 80px);
	}

}

@media only screen and (min-width : 1281px) and (max-width : 1400px) {

	.favorite {
		width: 193px;
	}

	.raft.expand .slick-list .slick-slide {
		float: left;
	}

	.raft.expand .raft-illustration-shadow--expanded {
		width: calc(100% + 68px);
	}
}

@media only screen and (max-width : 1280px) {

	.cart__pay-button {
		font-size: 15px;
	}

	.rdtPicker {
		width: 300px;
		height: 210px;
		overflow: auto;
		position: fixed;
	}

	.raft.expand .raft-illustration-shadow--expanded {
		width: calc(100% + 60px);
	}

}

@media only screen and (min-width : 1024px) and (max-width : 1279px) {


	.raft.expand .slick-list .slick-slide {
		float: left;
	}

	.payment-item--web .datepicker.d-from.datepicker--web {
		width: calc(100% - 150px);
	}

	.halfed .input__holders--col input {
		width: 130px !important;
		max-width: 130px !important;
	}
}


/*  */

/*------------------------------------------------------------------------------*\
  #MOBILE
/*------------------------------------------------------------------------------*\

/*  */

@media only screen and (min-width : 320px) and (max-width : 480px) {

	.recommended__product {
		box-sizing: border-box;
		padding: 0px 5px;
	}

	.sub-page {
		margin-bottom: 30px;
	}

	.raft.expand .raft__content {
		background-size: calc(100% + 60px) 323px;
		width: 100%;
		background-position-y: 271px;
		margin-bottom: 20px;
	}

	.raft.expand .raft__content .raft-card {
		margin-bottom: 35px;
	}

	.product__popup--recommended button.carousel-arrow.block.carousel-prev.custom,
	.product__popup--recommended button.carousel-arrow.block.carousel-next.custom {
		display: none !important;
	}

	.recommended-static {
		overflow: auto;
	}

	.product__popup--recommended button.carousel-arrow.block.carousel-prev.custom {
		left: 0;
	}

	disabled-buttons {
		display: none;
	}

	.raft__content {
		overflow: auto;
		padding: 0px 0px 0px 18px;
		scroll-snap-type: x mandatory;
		scroll-padding: 18px;
		background-position-y: 270px;
		background-size: 100% 319px;
		height: 319px;
	}

	.raft__content .raft-card {
		scroll-snap-align: start;
	}

	#raft__slider button.carousel-arrow.block.custom,
	.disabled-buttons {
		display: none !important;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .reactour__helper.tour-component span[data-tour-elem="badge"] {
		background: #FA583A !important;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .reactour__helper.tour-component button.reactour__dot--is-active[data-tour-elem="dot"] {
		background: #FA583A !important;
	}

	.footer-block-description {
		width: 100%;
	}

	.footer--block.sitemap ul li {
		width: 100%;
		min-width: unset;
	}

	#processing__popup .product__popup-container {
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	#processing__popup .product__popup-container .product__popup-main {
		flex-wrap: wrap;
		align-content: center;
	}

	.transaction-popup--block {
		width: 100%;
		padding: 10px;
	}

	.transaction--done .done {
		width: 40px;
		height: 40px;
	}

	.transaction-processing h2 {
		font-size: 20px;
	}

	.cookies-container {
		flex-wrap: wrap;
		padding: 25px 25px;
	}

	.cookies-container .cookies-icon {
		display: none;
	}

	.cookies-container h2 {
		margin: 0px 0px 20px 0px;
	}

	.cookies-container .small-button--primary {
		padding: 0px 30px;
		font-size: 18px;
		height: 38px;
		line-height: 38px;
	}

	.mobile__cart .cart__buttons {
		height: 42px;
	}

	.raft.expand .raft__categories .tabs-container-right .tabs-buttons-list {
		width: calc(100% - 37px);
	}

	.raft.expand.button-expand .raft__categories .tabs-container-right .tabs-buttons-list {
		width: calc(100% - 37px);
	}

	#payment__popup .label__input {
		background: #fff;
		width: 100% !important;
	}

	#payment__popup .label__input.select-box {
		background-image: unset;
		background: url("../images/chevron_down.svg") #fff;
		background-repeat: no-repeat, repeat;
		background-position: right .7em top 50%, 0 0;
		background-size: .65em auto, 100%;
	}

	#payment__popup .address-chosen .input__holders--col .label__input {
		background: transparent;
	}

	#payment__popup .input__holders--col.input__holders--col--half.input__holders--col--sts {
		width: 100% !important;
	}

	#payment__popup .di--address.disabled input,
	#payment__popup .di--delivery_number.disabled input {
		background: #f3f3f3 !important;
		border-color: #ececec;
		pointer-events: none;
		cursor: default;
		color: #848484;
	}

	#payment__popup .payment-item--web .datepicker input {
		background: #fff;
	}

	#payment__popup .input-grid .input__holders--col {
		width: 100% !important;
	}

	#payment__popup .new-address-button {
		margin: 10px 0px 5px 0px;
		width: 100%;
	}

	#payment__popup .input__holders--col.input__holders--col--half.input__holders--col--sts.di--address {
		margin: 0px 0px 15px 0px;
	}

	#payment__popup .input__holders--col.input__holders--col--half.input__holders--col--stt.di--delivery_number-auth {
		margin: 0px !important;
	}

	.category-src.tabs.mobile {
		display: flex;
	}

	.category-src.tabs.web {
		display: none;
	}

	#product--mobile .product__section--left .animated-product-image-container .raft-card__product-img .raft-card__product-img-src {
		justify-content: center;
	}

	.raft-card__product-img.placeholder {
		height: 100%;
	}

	.raft.expand .minicar {
		margin: 2px 0 0 0px !important;
	}

	.raft .minicar .minicar-list {
		width: 100%;
	}

	.raft.expand .raft-illustration--expanded {
		width: calc(100% + 60px);
		left: 0;
	}

	.raft.expand .minicar-list .eSGzmI {
		padding: 15px 0 0 0px !important;
	}

	.popup__add-to-cart {
		width: 11px;
		height: 11px;
		text-indent: -9999px;
		margin: 0px 7px 1px 0px;
	}

	.raft .minicar {
		margin: 2px 0px 0px 0px;
	}

	.cart-item-src:last-of-type {
		margin: 0px 0px 20px 0px;
	}

	.cart-item-src {
		margin: 0px 0px 20px 0px;
	}

	.raft.expand .next-button {
		left: unset;
	}

	.category-src.tabs.mobile {
		display: flex;
	}

	.category-src.tabs.web {
		display: none;
	}

	.order-status {
		margin: 0px 0px 0px 0px;
	}

	#payment__popup .payment-popup--item.unavailable .cart__product-img {
		opacity: 0.2;
	}

	#payment__popup .payment-popup--item.unavailable .product__title,
	#payment__popup .payment-popup--item.unavailable .product__subtitle,
	#payment__popup .payment-popup--item.unavailable .quantity-container__values h2,
	#payment__popup .payment-popup--item.unavailable .quantity-container__values h1 {
		color: #2f36403d;
	}

	.status-disclaimer {
		display: none;
		border-radius: 50px;
		height: 22px;
		line-height: 22px;
		padding: 0px 10px;
		font-size: 14px;
		font-weight: bold;
	}

	.payment-popup--item.unavailable .status-disclaimer {
		background: #FA583A;
		color: #fff;
	}

	.payment-popup--item.limited .status-disclaimer {
		background: #f39c12;
		color: #fff;
	}

	#payment__popup .payment-popup--item.limited .status-disclaimer,
	#payment__popup .payment-popup--item.unavailable .status-disclaimer {
		display: block
	}

	.title-status {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.payment-popup--item:first-child .cart__item-container {
		padding-top: 0px;
	}

	.mobile__more-button span.undo {
		mask: url("../images/undo.svg") no-repeat center center;
		-webkit-mask: url("../images/undo.svg") no-repeat center center;
		-webkit-mask-size: 70%;
		mask-size: 70%;
		background: #fff;
		width: 17px;
		height: 17px;
		display: inline-block;
	}

	.avatar__image-holder {
		width: 30px;
		height: 30px;
	}

	.inputs-container {
		overflow: auto;
		height: calc(100% - 20px);
		padding: 0px 0px 60px 0px;
	}

	#location__popup .settings__content-block--actions div {
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0px 15px;
	}

	.button__ghost--primary.full-width-mobile-button {
		width: 100%;
		height: 34px !important;
	}

	.settings-children-container .settings__content--main p .label__info-src {
		font-size: 16px;
		font-weight: bold;
	}

	.date-decorator div {
		left: 10px;
	}

	.order-history__wrapper .datepicker:after {
		right: 20px;
	}

	#statistics .settings__content--block.statistics.yearly .single-stat-picker {
		width: 100%;
	}

	.category-title.category-title-mobile {
		display: block;
		box-sizing: border-box;
		padding: 15px;
	}

	#statistics .settings__content--header .category-title {
		display: none;
	}

	.chart.monthly-chart .chart-disclaimer {
		margin: 20px 0px 0px 0px;
	}

	.statistics-tab {
		flex-wrap: wrap;
	}

	.single-tab {
		width: 100%;
	}

	.settings__content--header {
		padding: 0px 15px;
	}

	.settings__content--main {
		padding: 15px;
	}

	#statistics .settings__content--header {
		flex-wrap: wrap;
		height: auto;
		border-top: 1px solid rgba(223, 230, 233, 0.5);
	}

	#statistics .settings__content--header .category-title {
		width: 100%;
	}

	#statistics .settings__content--header .stats-picker {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#statistics .settings__content--header .single-stat-picker {
		width: 48%;
		margin: 0px;
	}

	.charts {
		flex-wrap: wrap;
		margin: 60px 0px 0px 0px;
	}

	.content--two-type .chart--pie {
		width: 100%;
		height: auto;
	}

	.stats-grouped-item {
		height: 90px;
		margin: 0px 0px 30px 0px;
	}

	.stats-grouped-item:last-of-type {
		margin: 0px 0px 0px 0px;
	}

	.single-stat--block .visual {
		width: 50px;
		height: 50px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.single-stat--block .visual .raft-card__product-img {
		height: 100%;
	}

	.single-stat--block .visual .raft-card__product-img {
		max-width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.toast {
		width: 100% !important;
		left: 0px;
		bottom: 0;
		border-radius: 0px !important;
		max-width: 100%;
	}

	.checkbox__label label {
		font-size: 16px;
		white-space: nowrap;
	}

	.checkbox__label.recovery .recovery-link {
		font-size: 16px;
	}

	.cart__items.trim__items.expand-cart-items {
		height: calc(100% - 85px) !important;
		padding-bottom: 20px;
	}

	.social-login a {
		width: 100%;
	}

	.slb__facebook {
		margin: 0px 0px 10px 0px;
	}

	.avatar__button {
		padding-right: 0px;
	}

	#empty-cart {
		height: calc(100% - 67px);
	}

	.meta-item-group {
		margin: 0px 20px 10px 0px;
	}

	#list__popup-container .product__popup-container {
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	#list__popup-container .confirmation__popup .popup__content {
		height: calc(100% - 120px);
		align-content: baseline;
	}

	#list__popup-container .confirmation__popup .popup__content .label__input {
		width: 100%;
	}

	#list__popup-container .confirmation__popup .popup__content .dropdown-main-src {
		width: 100%;
	}

	#list__popup-container .confirmation__popup .popup__content .dropdown-main-src .dropdown__trigger {
		width: 100%;
	}

	#list__popup-container .confirmation__popup .popup__content #exisitinglist {
		width: 100%;
	}

	.trigger-buttons {
		display: none;
	}

	.spinner-container {
		width: 100%;
		height: calc(100% - 61px);
	}

	.datepicker.d-from.datepicker--mobile.payment-picker input {
		padding-left: 15px !important;
		height: 40px !important;
	}

	.datepicker.d-from.datepicker--mobile.payment-picker .label__input {
		margin-top: 0px !important;
		width: 48%;
	}

	.datepicker.datepicker--mobile.statistics-datepicker .picker-form {
		width: 100%;
	}

	.datepicker.datepicker--mobile.order-history-datepicker .picker-form {
		width: 100%;
	}

	.datepicker.d-from.datepicker--mobile.payment-picker .picker-form {
		width: 100%;
		display: inline-block;
		margin: 0px 0px 10px 0px;
	}

	.datepicker--mobile input {
		padding-left: 50px !important;
	}

	.datepicker--mobile {
		margin: 0px 0px 10px 0px;
	}

	.datepicker--mobile:last-of-type {
		margin: 0px;
	}

	.datepicker--mobile .picker-form .rdtOpen .rdtPicker {
		display: flex;
		height: auto !important;
		background: #00000085 !important;
		box-shadow: none !important;
		border: 0px !important;
	}

	.datepicker--mobile .picker-form .rdtPicker {
		display: none;
		position: fixed;
		width: 100%;
		z-index: 99999 !important;
		background: transparent;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
		border: 1px solid #f9f9f9;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		height: 100%;
		align-items: center;
		justify-content: center;
	}

	.datepicker--mobile .picker-form .rdtDays {
		width: 300px;
		max-width: 400px;
		display: inline-block;
		background: #fff;
		border-radius: 4px;
		border: 1px solid #0000001c;
		height: 281px;
		box-sizing: border-box;
		padding: 10px;
	}

	.rdtPicker td.rdtDisabled,
	.rdtPicker td.rdtDisabled:hover {
		color: #cacaca !important;
		text-decoration: line-through;
	}

	.rdtPicker td.rdtActive,
	.rdtPicker td.rdtActive:hover {
		background-color: #ee582e !important;
		font-weight: bold;
	}

	.rdtPicker td.rdtToday:before {
		border-bottom: 7px solid #ee582e !important;
	}

	.rdtPicker td.rdtToday {
		font-weight: bold;
	}

	.datepicker--mobile .picker-form input {
		background: #fff !important;
		width: 100% !important;
		max-width: 100% !important;
		margin: 0px 0px 0px 0px;
		height: 50px;
		background: #F5F6FA;
		border: 1px solid #DFE6E9;
		border-radius: 5px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 0px 15px;
		font-size: 18px;
		font-weight: 400;
		color: #B2BEC3;
		-webkit-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}

	.datepicker--mobile .date-decorator {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		bottom: 0;
		margin: auto;
	}

	.raft-card__product-img .animated {
		height: 100%;
	}

	.popup__buttons--web {
		display: none;
	}

	.popup__buttons--mobile {
		display: flex !important;
	}

	.mobile__cart .cart__buttons .cart__pay-button {
		padding: 0px 0px 0px 12px;
	}

	.mobile__cart .cart__buttons .cart__pay-button .total {
		position: static;
		box-sizing: border-box;
		padding: 0px 20px;
		height: 42px;
		margin: 0px 0px 0px 0px;
		pointer-events: none;
	}

	.mobile__cart .cart__payments .cart__buttons .clear-total {
		background: #646e72;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	_::-webkit-full-page-media,
	_:future,
	:root #product--mobile .quantity-container__actions .plus span {
		height: 14px !important;
		width: 14px !important;
	}

	_::-webkit-full-page-media,
	_:future,
	:root #product--mobile .popup__quantity .actions__value {
		font-size: 22px;
	}

	.mobile__cart .actions__value {
		width: 45px;
		height: 26px;
		font-size: 20px;
	}

	#help .single-page-wrapper {
		flex-wrap: wrap;
	}

	.help-nav {
		width: 100%;
	}

	.help-nav ul {
		position: static;
	}

	.help-container-switcher {
		width: 100%;
	}

	.sub-page {
		padding: 0px;
	}

	.text-block-inputs .input__holders--col {
		width: 100%;
	}

	#location__popup .settings__content-block--actions {
		position: fixed;
		z-index: 1;
		background: #fff;
		padding: 0px;
		bottom: 0;
		width: 100%;
		margin: 0px 0px 0px 0px !important;
		height: 60px;
	}

	#location__popup .settings__content-block--actions .button__ghost--primary {
		margin: 0px;
	}

	#location__popup .product__popup-container .inputs {
		margin: 15px 0px 30px 0px;
		height: calc(100% - 70px);
	}

	#location__popup .settings__content-block--actions .button__ghost--borderless--primary.delete-location {
		width: 22px !important;
		margin: 0px 10px 0px 15px;
		height: 23px;
	}

	#settings .show-map {
		font-size: 12px;
		width: 35px;
	}

	.show-map span {
		margin: 0px 0px 0px 0px;
		width: 11px;
		height: 15px;
	}

	#settings--locations .settings__content-block--actions button {
		width: 100%;
	}

	#location__popup .product__popup-container {
		padding: 15px;
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	#mobile__payment-container #location__popup .product__popup-container {
		padding: 15px;
		width: 100%;
		height: 100%;
		max-height: 100%;
		background: #fff;
	}

	#location__popup .product__popup-container .inputs .input__holders--col {
		flex-wrap: wrap;
	}

	#location__popup .input__holders--col input {
		margin: 10px 0px 0px 0px;
	}

	.raft.expand .raft__content .slick-list .slick-track {
		display: block;
	}

	.raft .raft__content .slick-list .slick-track {
		display: flex;
	}

	.raft.expand .slick-list .slick-slide {
		margin: 0px 6px 10px 0px !important;
		float: left;
		width: calc(100% / 2 - 6px);
	}

	.slick-list .slick-slide {
		width: 176px;
	}

	.order-history .single-page-wrapper.order-history__wrapper .date__input--icon {
		height: 50px;
		line-height: 50px;
		top: 17px;
	}

	.date__input--icon {
		height: 60px;
		line-height: 60px;
	}

	.date__input--icon:after {
		line-height: 17px;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .dropdowns ul li button {
		padding: 0px 10px;
	}


	.file-upload__button-container .label__info {
		white-space: nowrap;
	}

	.web-categories {
		display: none;
	}

	.mobile-categories {
		display: block;
	}

	.product__section--left .raft-card__product-img {
		width: 75%;
		margin: 20px 0px;
	}

	.rc-collapse-content-inactive {
		display: none !important;
	}

	.mobile__cart .cart__items.trim__items {
		height: calc(100% - 171px);
	}

	.mobile__cart .cart__items.trim__items .cart__item-container:first-of-type {
		margin-top: 5px;
	}

	.mobile__cart .dropdown__container .dropdown__content {
		z-index: 2;
		top: 104px;
		-webkit-overflow-scrolling: touch;
	}

	.mobile__cart .dropdown__trigger {
		width: 100%;
		background: #f3f3f3;
		border: 0px;
		border-top: 1px solid #00000017;
		border-bottom: 1px solid #00000017;
	}

	.mobile__cart .dropdown__trigger h2 {
		color: #636E72;
		align-items: center;
	}

	.mobile__cart .dropdown__trigger h2:after {
		width: 10px;
		height: 10px;
		display: inline-block;
		background-color: #fff;
		line-height: 7px;
		border-radius: 50%;
		padding: 3px;
	}

	.icon-button__textless--dark {
		width: 35px;
		height: 35px;
	}

	#payment__popup .basic_info .input__holders--col label {
		width: 100%;
		max-width: 100%;
	}

	.header__menu {
		width: 55px;
	}

	.raft.expand .raft__categories--expander {
		transform: none;
	}

	#payment__popup .delivery_info .input__holders--col--halfed {
		display: none;
	}

	.content--two-type {
		flex-wrap: wrap;
		justify-content: center;
		margin: 30px 0px 0px 0px;
	}

	.chart--pie {
		width: 180px;
		height: 180px;
		margin: 0px 0px 40px 0px;
	}

	.stats-grouped {
		width: 100%;
		padding: 0px;
		margin: 20px 0px 0px 0px;
		align-items: baseline;
		justify-content: flex-start;
	}

	.single-stat--block,
	.static {
		width: 100%;
	}

	.single-stat--block .visual {
		margin: 0px 20px 0px 0px;
	}

	.single-stat--block .textual {
		margin: 0px 0px 0px 0px;
	}

	.user__corner-favorites.user__corner-favorites-icon {
		display: inline-block;
		width: 22px;
		height: 20px;
		background: url("../images/heart_icon_ghost.svg") no-repeat center center;
		text-indent: -9999px;
	}

	#payment__popup .card_info .input__holders--col label {
		width: 100%;
		max-width: 100%;
	}

	.collapsible__content .input__holders--col {
		flex-wrap: wrap;
		width: 100%;
		max-width: 100% !important;
	}

	#payment__popup .payment-item--mobile,
	.datepicker--mobile {
		display: flex !important;
		width: 100%;
		flex-wrap: wrap;
	}

	#payment__popup .input__holders--col.payment-item--mobile .label__input {
		width: 48% !important;
		max-width: 100% !important;
		margin: 0px 0px 10px 0px;
	}

	#payment__popup .delivery_info .halfed {
		align-items: flex-start;
		flex-wrap: wrap;
	}

	.section__favorite .raft-card__favorited {
		left: 0px;
		top: 3px !important;
	}

	#payment__popup .delivery_info .halfed {
		align-items: flex-start;
		flex-wrap: wrap;
	}

	.section__favorite .raft-card__favorited {
		left: 0px;
		top: 3px !important;
	}

	.mobile__track-order .track-order__container .status__item.packing:before {
		border-radius: 5px;
		width: 30px;
		height: 5px;
	}

	#mobile__payment-container .payment__main-content .collapsibles .collapsible__content p.payment-item--web {
		display: none !important;
	}

	.mobile__track-order .track-order__container .status__item.packing:after {
		content: none;
	}

	.mobile__track-order .track-order__container .status__item.shipped:before {
		border-radius: 5px;
		width: 30px;
		height: 5px;
	}

	.mobile__track-order .track-order__container .status__item.shipped:after {
		content: none;
	}

	.popup--noscroll {
		pointer-events: none !important;
		overflow: hidden !important;
		touch-action: none;
		position: fixed;
		z-index: -9999;
	}

	.app-container.popup--hidden.mobile-route--overflow .settings__content--block {
		margin: 0px 0px 100px 0px;
	}

	.app-container.popup--hidden.mobile-route--overflow .raft--cart {
		display: none;
	}

	#settings {
		height: 100%;
	}

	.app-container {
		-webkit-overflow-scrolling: touch;
		padding: 60px 0px 0px 0px;
	}

	.app-container.popup--opened {
		-webkit-overflow-scrolling: touch;
	}

	.menu {
		box-shadow: none;
	}

	.raft.expand .slick-list .slick-slide {
		float: left;
	}

	.header {
		top: 0;
		z-index: 9997;
		padding: 0px;
		height: auto !important;
	}

	.mobile__header {
		width: 100%;
		height: 61px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 1;
		background: #fff;
	}

	.mobile__header-search {
		width: 20px;
		height: 22px;
		background-image: url("../images/search_icon.svg");
		background-repeat: no-repeat;
	}

	.mobile__header-cart {
		width: 24px;
		height: 22px;
		background-image: url("../images/cart_icon.svg");
		background-repeat: no-repeat;
	}

	.mobile__header .header__menu {
		background: transparent;
	}

	.mobile__header .header__menu-icon {
		background: url("../images/menu_dots_mobile.svg") no-repeat center center;
		margin-bottom: 0px;
	}

	.mobile__header .header__menu h2 {
		color: #B2BEC3;
		font-size: 16px;
	}

	.mobile__header .header__logo {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		width: 62px;
	}

	.header__logo a {
		width: 54px;
		height: 31px;
	}

	.mobile__header .header__section-right {
		margin: 0px 20px 0px 0px;
	}

	.mobile__header .header__section-right button {
		margin: 0px 0px 0px 15px;
	}

	.mobile__header .header__section-right button:first-of-type {
		margin: 0px;
	}

	.main-content-component {
		margin: 0px;
		width: 100%;
		height: 100%;
	}

	#raft__slider .slick-list {
		overflow-x: hidden !important;
	}

	.columns {
		width: 100%;
		margin: 0px 0px 0px 0px;
		overflow: hidden;
	}

	.columns .raft__content {
		margin: 0px;
		width: 100%;
		overflow: unset;
		overflow-y: hidden;
		padding: 0px 0px 0px 18px;
		scroll-snap-type: x mandatory;
		scroll-padding: 18px;
		background-position-y: 270px;
		background-size: calc(100% + 60px) 333px;
		height: 333px;
	}

	.raft {
		margin: 10px 0px 0px 0px;
	}

	.raft:last-of-type {
		margin-bottom: 100px;
	}

	.columns .raft__content .raft-card {
		width: 180px;
	}

	.raft__categories--right .web__more-button {
		display: none !important;
	}

	.mobile__more-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 37px;
		padding: 0px;
	}

	.mobile__more-button span {
		width: 18px;
		height: 9px;
		background: url("../images/more_icon.svg");
		background-repeat: no-repeat;
		background-position: center;
		display: inline-block;
		font-size: unset !important;
		max-width: 37px;
		background-size: 100%;
		margin: 0px 0px 0px 3px;
	}

	.cls-1 {
		fill: none;
	}

	.cls-2 {
		clip-path: url(#clip-path);
	}

	.cls-3 {
		fill: #fff;
	}

	.raft-illustration__boards {
		width: calc(100% + 40px);
	}

	.raft__categories {
		position: relative;
		height: 37px;
		background: #E8E9ED;
		padding: 0px 0px 0px 10px;
		display: flex;
		align-items: center;
	}

	.raft__categories--left {
		width: 100%;
		height: 100%;
	}

	.category-content-container {
		height: 100%;
	}

	.raft__slider--decorator-end {
		right: 0px;
		display: none;
	}

	.raft__categories--left {
		width: 100%;
	}

	.raft-category-divider {
		display: none;
	}

	.raft__categories--left .category-title a .chevron {
		width: 6px;
		height: 8px;
	}

	.raft:first-child {
		margin: 0px;
	}

	.raft__categories--left .category-title a h2 {
		font-size: 14px;
		color: #2F3640;
	}

	.raft__categories--left .category-src.tabs {
		width: 100%;
		height: 100%;
		overflow: auto;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		margin: 0px 0px 0px 10px;
		box-sizing: border-box;
		padding: 0px 0px 0px 0px;
		border-bottom: 0px;
	}

	.tabs-container-left {
		padding: 0px;
	}

	.raft__categories--left .category-src.tabs ul {
		display: flex;
		flex-wrap: nowrap;
	}

	.raft__categories--left .category-src.tabs ul li {
		white-space: nowrap;
		padding: 10px 12px 6px 12px;
	}

	.tabs ul li.active__sub {
		background-image: linear-gradient(to top, #ffffff, #E8E9ED);
		border-bottom: 2px solid #fa583a;
	}

	.tabs ul li.active__sub h3 {
		font-weight: normal;
		text-shadow: 0 0 0.1px #292929;
	}

	.tabs-buttons-list {
		width: calc(100% - 37px);
	}

	.raft__categories--left .category-src.tabs ul h3 {
		font-size: 14px;
	}

	.raft__categories--right {
		right: 0;
		top: 0;
		height: 100%;
	}


	.raft__categories--expander {
		width: 170px;
		height: 100%;
		left: -170px;
	}

	.raft__categories--right button {
		height: 100%;
		border-radius: 0px;
		font-size: 13px;
	}

	.raft.expand {}

	.menu {
		width: 100%;
		height: 100%;
		position: fixed;
		transform: translate3d(-100%, 0, 0) !important;
		will-change: transform;
		transform-style: preserve-3d;
		backface-visibility: hidden;
		transform: translate3d(0, 0, 0);
	}

	.slide__menu--on {
		transform: translate3d(100%, 0, 0);
		overflow: unset !important;
	}

	.menu__header--item.close-menu {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 50px;
		height: 50px;
	}

	.menu__header .close-menu .close__popup-button {
		width: 12px;
		height: 12px;
		padding: 8px;
	}

	.cart {
		display: none;
	}

	.mobile__cart {
		display: block;
		width: 100%;
		background: #fff;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 1;
		height: 80%;
		transition: transform 0.15s ease-in-out, box-shadow 0.2s ease-in-out;
		will-change: transform;
	}

	.mobile__cart.hide--cart {
		/* transform: translate(0px, 100%); */
		transform: translate3d(0px, 100%, 0px);
		box-shadow: none;
	}

	.mobile__cart.show--cart {
		/* transform: translate(0px, 0px); */
		transform: translate3d(0px, 0px, 0px);
		box-shadow: 0 -17px 56px 0 rgba(0, 0, 0, 0.14);
		will-change: transform;
	}

	.cart__overflow .app-container {
		-webkit-overflow-scrolling: touch;
		overflow: auto;
	}

	.mobile-cart__header {
		width: 100%;

	}

	.full-width__cart-header--content {
		width: 100%;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 10px 10px 0px 12px;
	}

	.close-cart {
		width: 32px;
		height: 32px;
		position: absolute;
		left: 0;
		right: 0;
		top: -17px;
		margin: auto;
		background: #636e72;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2px;
		z-index: 1;
	}

	.mobile-cart__header-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.mobile-cart__header .cart__header-title h2 {
		font-size: 17px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		color: #636e72;
	}

	.mobile-cart__header .cart__save-button {
		height: 28px;
		font-size: 15px;
		padding: 0px 18px;
		text-transform: lowercase;
		border-radius: 3px;
	}

	.mobile-cart__header .cart__save-button .cart__save-button-icon {
		width: 10px;
		height: 10px;
	}

	.mobile-cart__header .close-car--icon {
		background: url("../images/chevron_up_icon.svg") no-repeat center center;
		width: 14px;
		height: 8px;
		transform: rotate(180deg);
	}

	.hide--cart .close-cart {
		display: none;
	}

	.mobile__cart .cart__items {
		width: 100%;
		height: calc(100% - 129px);
		left: 0px;
		margin: 0px;
		padding: 10px;
		box-sizing: border-box;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	.quantity-container__actions .plus,
	.quantity-container__actions .minus {
		width: 26px;
		height: 26px;
		font-size: 50% !important;
		line-height: 30px;
	}

	.quantity-container__actions .plus span,
	.quantity-container__actions .minus span {
		width: 17px;
		background-size: 100%;
		font-size: 18px !important;
	}

	.quantity-container__actions .plus span {
		height: 12px;
		width: 12px;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .quantity-container__actions .plus span {
		height: 14px !important;
		width: 14px !important;
	}

	.quantity-container__actions .minus span {
		height: 3px;
	}

	.mobile__cart .cart__payments {
		width: 100%;
		margin: 15px 0px 0px 0px;
		position: relative;
		float: left;
		box-sizing: border-box;
		padding: 0px 0px 30px 0px;
		background: #fff;
	}

	.remove__cart-item {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
	}

	.mobile__cart .cart__items::-webkit-scrollbar {
		display: none;
	}

	.mobile__cart .cart__items::-webkit-scrollbar-track {
		display: none;
	}

	.mobile__cart .cart__items::-webkit-scrollbar-thumb {
		display: none;
	}

	.mobile__cart .cart--positioned {
		box-sizing: border-box;
		padding: 0px 10px;
	}

	.mobile__cart .cart__buttons {
		position: relative;
		flex-direction: column;
		margin: 0px;
	}

	.mobile__cart .cart__buttons .total {
		height: 100%;
		position: absolute;
		border-radius: 4px;
		background: #e83d1e;
		box-sizing: border-box;
		padding: 0px 15px;
		display: flex;
		align-items: center;
		top: 0;
		left: 0;
		font-family: sans-serif;
	}

	.mobile__cart .cart__buttons .total h2 {
		font-size: 15px;
		font-weight: 600;
		color: #f5f6fa;
	}

	.mobile__cart .cart__buttons .cart__pay-button {
		width: 100% !important;
		height: 42px;
		display: flex;
		flex-flow: nowrap;
		justify-content: space-between;
		border-radius: 4px;
		flex-direction: row-reverse;
	}

	.mobile__cart .cart__buttons .cart__pay-button:before,
	.mobile__cart .cart__buttons .cart__pay-button:after {
		display: none;
	}

	.mobile__cart .cart__item-container:last-of-type {
		margin-bottom: 0px;
	}

	.mobile__cart .cart__payments .cart--gradient {
		height: 150px;
		padding: 0px 0px 100px 0px;
		bottom: 0;
	}

	.track-order.track-order--slideup {
		display: none;
	}

	.columns .raft #raft__slider .slick-arrow {
		display: none !important;
	}

	.raft-illustration {
		width: calc(100% + 5px);
		position: absolute;
		/* bottom: -44px; */
		bottom: -62px;
		z-index: 0;
		height: 106px;
		white-space: nowrap;
		overflow: hidden;
	}

	.mobile__menu-corner .avatar__button h2 {
		display: none;
	}

	.mobile__menu-corner .dropdowns ul li .icon__divider {
		margin: 0px 5px;
	}

	.all-products .favorite {
		position: relative;
		width: 176px;
		margin-right: 0px;
	}

	.all-products .favorite .raft-card {
		width: 100%;
	}

	.all-products .favorite:nth-child(even) {
		margin-right: 6px;
	}

	.component__header {
		box-sizing: border-box;
		padding: 10px;
	}

	.favorite .remove__cart-item {
		left: -9px;
		top: -16px;
	}

	.favorite .remove__cart-item button {
		width: 36px;
		height: 36px;
	}

	.all-products .raft-illustration {
		background-size: 100% 340px;
	}

	.menu__user-action--content {
		width: 100%;
		height: calc(100% - 70px);
	}

	.mobile__track-order {
		width: 100%;
		height: 100%;
		position: static;
		margin: 0px;
		background: transparent;
		flex-wrap: wrap;
	}

	.mobile__track-order .track-order__header {
		width: 100%;
		text-align: center;
		height: 41px;
		line-height: 41px;
		background: #dfe6e9;
	}

	.mobile__track-order .track-order__header h2 {
		font-size: 16px;
		font-weight: bold;
		color: #636e72;
	}

	.mobile__track-order .track-order__container {
		flex-wrap: wrap;
		height: calc(100% - 41px);
		align-items: center;
		justify-content: space-evenly;
		overflow: auto;
	}

	.mobile__track-order .track-order__container .track-order__item.status {
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
	}

	.mobile__track-order .track-order__container .status__item {
		width: calc(100% / 3);
		margin-bottom: 0px;
	}

	.mobile__track-order .track-order__container .status__item.loading,
	.mobile__track-order .track-order__container .status__item.shipping {
		margin-bottom: 0px;
	}

	.mobile__track-order .track-order__item.prev__orders {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.mobile__track-order .track-order__item.prev__orders button {
		width: 100%;
		text-align: center;
	}

	.mobile__track-order .timing {
		display: flex;
		white-space: nowrap;
		margin: 0px 0px 22px 0px;
	}

	.mobile__notifications {
		height: 100%;
	}

	.mobile__notifications .dropdown-src {
		position: static;
		height: 100%;
		padding: 0px;
		box-shadow: none;
	}

	.mobile__notifications .dropdown__header {
		justify-content: center;
		background: #dfe6e9;
	}

	.mobile__notifications #notifications .notifications-container {
		-webkit-overflow-scrolling: touch;
		background: #f5f6fa;
	}

	.mobile__notifications #notifications .notifications-container ul li:hover {
		background: none;
	}

	.mobile__notifications #notifications .notifications-container ul li h2 {
		font-size: 18px;
	}

	.menu__content {
		-webkit-overflow-scrolling: touch;
	}

	.mobile__usersettings {
		width: 100%;
		height: 100%;
	}

	.mobile__usersettings .dropdown-src.user-dropdown {
		width: 100%;
		height: 100%;
		box-shadow: none;
		position: static;
		padding: 0px;
		margin: 0px;
		background: #f5f6fa;
	}

	.mobile__usersettings .dropdown-src.user-dropdown .track-order__header {
		width: 100%;
		height: 41px;
		line-height: 41px;
		background: #dfe6e9;
		text-align: center;
	}

	.mobile__usersettings .dropdown-src.user-dropdown .track-order__header h2 {
		font-size: 16px;
		font-weight: bold;
		color: #636e72;
	}

	.mobile__usersettings .notifications-container {
		box-sizing: border-box;
		padding: 35px 30px;
	}

	.mobile__usersettings .notifications-container ul li {
		margin: 0px 0px 35px 0px;
		height: 35px;
		display: flex;
		align-items: center;
		position: relative;
	}

	.mobile__usersettings .notifications-container ul li a {
		font-size: 20px;
		padding: 0px;
		height: 100%;
		display: block;
		box-sizing: border-box;
	}

	.mobile__usersettings .notifications-container ul li a:before {
		position: absolute;
		left: 0;
	}

	.mobile__usersettings .notifications-container ul li a:before {
		margin: 0px 24px 0px 0px;
	}

	.notifications-container ul li.notifications__list--settings a:before {
		width: 20px;
		height: 25px;
	}

	.notifications-container ul li.notifications__list--statistics a:before {
		width: 24px;
		height: 22px;
	}

	.notifications-container ul li.notifications__list--order-history a:before {
		width: 22px;
		height: 22px;
	}

	.notifications-container ul li.notifications__list--logout a:before {
		width: 30px;
		height: 24px;
		left: -1px;
	}


	#product--mobile {
		display: block;
		width: 100%;
		height: 100%;
	}

	.settings__content-block--actions button {
		height: 38px;
	}

	#product--mobile .product__popup-container {
		width: 100%;
		height: 100%;
		max-height: 100%;
		margin: 0px;
		padding: 0px;
		border-radius: 0px;
		-webkit-overflow-scrolling: touch;
	}

	#product--mobile .product__popup-container::-webkit-scrollbar {
		display: none;
	}

	#product--mobile .product__popup-container::-webkit-scrollbar-track {
		display: none;
	}

	#product--mobile .product__popup-container::-webkit-scrollbar-thumb {
		display: none;
	}

	#product--mobile .product__popup--full {
		display: block;
		width: 100%;
		height: 100%;
		position: relative;
		padding: 15px 15px 0px 15px;
		top: 0px;
		overflow: auto;
	}

	.rc-tabs-top .rc-tabs-content {
		height: auto !important;
	}

	.mobile__product--decorator {
		width: 100%;
		height: 201px;
		background-position: top left;
		background-size: 100% 201px;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
	}

	.cls-1 {
		fill: #f5f6fa;
	}

	.product__popup-actions--top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		box-sizing: border-box;
		padding: 10px 15px 0px 15px;
	}

	.product__popup-actions--bottom {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 30px 0px 15px 0px;
		position: relative;
	}

	#product--mobile .product__section--left {
		height: 120px;
		background: none;
		width: auto;
		min-width: 130px;
		max-width: 130px;
	}

	#product--mobile .product__section--left .raft-card__product-img img {
		width: 100%;
		height: 100%;
	}

	#product--mobile .product__section-block-inside.product__section--top {
		display: block;
		box-sizing: border-box;
		padding: 0px 0px 0px 20px;
	}

	#product--mobile .recommended__header h2 {
		font-size: 14px;
		letter-spacing: 3px;
		white-space: nowrap;
	}

	#product--mobile .popup__product-title {
		margin: 5px 0px 0px 0px;
	}

	#product--mobile .popup__product-price {
		margin: 0px 0px 0px 0px;
		display: flex;
		align-items: center;
	}

	#product--mobile .popup__product-price span {
		font-size: 18px;
		margin-top: 5px;
		margin: 0px 10px 0px 0px;
	}

	#product--mobile .cross {
		margin: 0px 5px 0px 0px;
	}

	#product--mobile .product__popup-main {
		box-sizing: border-box;
		display: block;
	}

	#product--mobile .popup__product-title {
		font-size: 26px;
	}

	#product--mobile .product__popup--recommended {
		padding: 0px;
		margin-bottom: 150px;
		margin-top: 25px;
	}

	#product--mobile .product__popup--recommended .slick-slider {
		padding: 0px 0px 30px 0px;
	}

	#product--mobile .recommended__gradient--decorator {
		width: 70px;
		right: 0;
	}

	#product--mobile .product__popup--recommended .slick-arrow {
		display: none !important;
	}

	#product--mobile .product__section-block-inside.popup__quantity {
		position: fixed;
		bottom: 0px;
		left: 0;
		width: 100%;
		display: block;
		box-sizing: border-box;
		padding: 0px 15px 15px 15px;
		z-index: 9999;
		background: #f5f6fa;
	}

	#product--mobile .quantity-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: nowrap;
		margin: 0px 0px 15px 0px;
		position: relative;
	}

	#product--mobile .popup__quantity .quantity-container__actions {
		width: auto;
	}

	#product--mobile .quantity-container__actions .plus,
	#product--mobile .quantity-container__actions .minus {
		width: 30px !important;
		height: 30px;
	}

	#product--mobile .popup__quantity .quantity-container__values {
		text-align: right;
		margin: 0px;
	}

	#product--mobile .popup__quantity .actions__value {
		width: 60px;
		height: 30px;
		font-size: 20px;
	}

	#product--mobile .product__section-block-inside button {
		width: 100%;
	}

	#product--mobile .product__section-block-inside .icon-button--secondary {
		height: 40px;
		padding: 0px;
		position: relative;
	}

	.actions__gradient--decorator {
		width: 100%;
		height: 78px;
		position: absolute;
		left: 0;
		top: -76px;
		background-image: linear-gradient(to top, #f5f6fa, #f5f6fa00);
		pointer-events: none;
	}

	#web__payment-container {
		display: none;
	}

	#mobile__payment-container {
		display: block;
		z-index: 9999;
		width: 100%;
		height: 100%;
		margin: 0px 0px 0px 0px;
	}

	#mobile__payment-container .payment__cart {
		display: none;
	}

	#mobile__payment-container .product__popup--overlay {
		display: none;
	}

	_::-webkit-full-page-media,
	_:future,
	:root #mobile__payment-container .product__popup {
		position: fixed;
		z-index: 9999;
	}

	#mobile__payment-container .product__popup-container {
		padding: 0px;
		width: 100%;
		height: 100%;
		max-height: 100%;
		border-radius: 0px;
		background: #F5F6FA;
	}

	#mobile__payment-container .product__popup-container .close__popup-button {
		width: 18px;
		height: 18px;
	}

	#mobile__payment-container .product__popup-main {
		width: 100%;
		overflow: auto;
		height: calc(100% - 80px);
		-webkit-overflow-scrolling: touch;
	}

	#mobile__payment-container .payment__main-content {
		padding: 20px;
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	#mobile__payment-container .payment__main-content .collapsibles {
		width: 100%;
		height: auto;
		margin: 30px 0px 0px 0px;
	}

	#mobile__payment-container .payment__main-content .collapsibles .collapsible__triger {
		padding: 0px 0px 15px 0px;
		margin: 0px 0px 15px 0px;
	}


	#mobile__payment-container .payment__main-content .collapsibles .rc-collapse-content {
		background: transparent;
	}

	#mobile__payment-container .payment__main-content .collapsibles .collapsible__content p {
		display: block !important;
		width: 100%;
		max-width: 100%;
		margin-bottom: 30px;
	}

	#mobile__payment-container .payment__main-content .halfed p input {
		width: 100% !important;
		max-width: 100% !important;
	}

	#mobile__payment-container .payment__main-content .collapsibles .rc-collapse-content p label {
		display: block;
		width: 100%;
	}

	#mobile__payment-container .payment__main-content .collapsibles .rc-collapse-content p input {
		display: block;
		width: 100%;
		max-width: 100%;
		background: #fff;
		margin: 10px 0px 0px 0px;
	}


	#mobile__payment-container #mobile__payment-footer {
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 0;
		box-sizing: border-box;
		padding: 20px;
		background: #f5f6fa;
		z-index: 9997;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#login__popup .product__popup-container {
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	.popup__block.social-login {
		flex-wrap: wrap;
	}

	.popup__block.social-login button {
		width: 100%;
		margin: 0px 0px 10px 0px;
	}

	.popup__block.social-login button:last-of-type {
		margin-bottom: 0px;
	}

	.single-page-wrapper {
		width: 90%;
		margin-top: 0px;
		padding-top: 20px;
		-webkit-overflow-scrolling: touch;
	}

	.single-page-wrapper:after {
		content: "";
		display: table;
		clear: both;
	}

	.order-history .component__header {
		flex-wrap: wrap;
	}

	.order-history .date__input--holder {
		width: 100%;
	}

	.order-history .date__input--holder .date__input {
		width: 100%;
		height: 50px;
	}

	.order-history__wrapper .rc-collapse {
		padding: 0px;
	}

	.order-history__wrapper .component__content--footer {
		margin: 15px 0px 0px 0px;
	}

	.order-history__wrapper .component__content--footer .component__footer--block.cfb__total span,
	.order-history__wrapper .component__content--footer .component__footer--block.cfb__total h2 {
		width: 100%;
		text-align: center;
	}

	.order-history__wrapper .component__footer--block button {
		width: 100%;
		margin-top: 10px !important;
		margin-right: 0px !important;
	}

	.component__footer--block button:last-of-type {
		display: none;
	}

	.order-history__wrapper .short__detail-info {
		display: none;
	}

	.order-history__wrapper .orders__table .cart__product-img {
		display: none;
	}

	.order-history__wrapper .orders__table--product {
		width: auto;
	}

	.order-history__wrapper .row_count {
		display: none;
	}

	.order-history__wrapper .orders__table .orders__table tbody tr {
		height: auto;
	}

	.order-history__wrapper .orders__table .orders__table tbody tr td {
		padding: 10px 0px;
	}

	.order-history__wrapper .mobile--alignment {
		text-align: center;
	}

	.order-history__wrapper .orders__table .cart__product--top {
		width: auto;
	}

	.order-history__wrapper .orders__table tbody tr .product__title {
		font-size: 16px;
	}

	.order-history__wrapper .orders__table tbody tr .product__subtitle {
		white-space: nowrap;
	}

	.order-history__wrapper .orders__table tbody tr:nth-child(even) {
		background: #f5f6fa;
	}

	.order-history__wrapper .chc--mobile {
		width: 100%;
		display: flex;
		margin: 20px 0px 30px 0px;
	}

	#save-cart__popup .product__popup-container {
		width: 90%;
	}

	#save-cart__popup .popup__content {
		flex-wrap: wrap;
	}

	#save-cart__popup .popup__content label {
		width: 100%;
		margin: 0px 0px 10px 0px;
	}

	#save-cart__popup .popup__content input {
		width: 100%;
	}

	.mobile__cart.show--cart.expand__cart {
		height: calc(100% - 83px);
		z-index: 2;
	}

	.mobile__cart.show--cart.expand__cart .cart__items {
		height: calc(100% - 135px);
		padding-bottom: 20px;
	}

	.mobile__cart.show--cart.expand__cart .dropdown__content {
		top: 95px;
		z-index: 2;
		box-shadow: 0px 20px 81px 20px rgba(0, 0, 0, 0.19);
	}

	.mobile__cart .saved-cards--mobile {
		display: block;
	}

	.saved-cards--mobile {
		width: 100%;
		margin: 15px 0px 0px 0px;
	}

	.saved-cards--mobile .dropdown__trigger {
		border-radius: 0px;
	}

	.info__navigation--left {
		width: 100%;
	}

	.settings__avatar__image-holder {
		float: left;
	}

	.settings__avatar h2,
	.settings__avatar a {
		float: left;
		margin-left: 20px;
		width: calc(100% - 120px);
		word-break: break-word;
	}

	.settings__content--right {
		width: 100%;
		margin: 30px 0px 0px 0px;
	}

	.settings__content--block p {
		width: 100%;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0px 0px 0px 0px;
		box-sizing: border-box;
		padding: 13px 0px 13px 0px;
	}

	#settings--basic-info .label__info {
		width: 120px;
		margin: 0px 0px 0px 0px;
	}

	.settings__content--main .switch__holder {
		padding: 0px;
		border: none;
	}

	.switch__holder .switch {
		width: 43px !important;
	}

	.switch__holder slider.round {
		width: 43px;
	}

	#settings--basic-info .button-holder {
		justify-content: space-between;
	}

	#settings--basic-info .button-holder .button__ghost--primary {
		width: calc(100% - 130px);
	}

	#settings--basic-info .button-holder .label__info {
		width: 120px;
		margin: 0px 0px 0px 0px;
	}

	#settings .linked-profile .profile-info p .label__info {
		width: 120px !important;
	}

	.settings__content--block p .label__input {
		width: 100%;
		max-width: 100%;
	}

	.settings__content--main .file-upload__holder {
		margin-top: 15px;
		flex-wrap: wrap;
		width: 100%;
		justify-content: space-between;
	}

	#settings--basic-info .file-upload__holder .label__info {
		width: 120px;
		margin: 0px 0px 0px 0px;
	}

	.settings__content-block--actions {
		display: flex;
		justify-content: space-between;
	}

	.settings__content-block--actions button {
		margin: 0px;
		width: 100%;
		text-align: center;
	}

	.file-upload__button-container label {
		float: left;
		width: auto !important;
		margin: 0px !important;
	}

	.file-upload__button-container {
		width: calc(100% - 130px);
	}

	.settings__content--block {
		margin: 0px 0px 20px 0px;
	}

	#location__popup .settings__content-block--actions div button {
		white-space: nowrap;
	}

	.settings__content-block--actions {
		position: fixed;
		z-index: 1;
		bottom: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 20px;
		left: 0;
		background: #f5f6fa;
	}

	.datepicker--mobile {
		display: block;
		width: 100%;
	}

	.datepicker--mobile p {
		width: 100%;
		margin: 0px 0px 10px 0px;
	}

	.datepicker--mobile .date__input {
		width: 100%;
	}

	#statistics .component__header {
		flex-wrap: wrap;
		padding: 0px;
	}

	.settings__content--block.statistics {
		margin: 0px;
	}

	.order-history__wrapper .datepicker {
		width: 100%;
	}

	.order-history__wrapper .datepicker:first-child {
		margin: 10px 0px 10px 0px;
	}

	.search--mobile {
		position: fixed;
		left: 0;
		top: 61px;
		background: #fff;
		color: #2F3640;
		width: 100%;
		transform: translate3d(0px, -61px, 0px);
		box-shadow: none;
		visibility: visible;
		opacity: 0;
		transition: all 0.2s ease-in-out;
	}

	.search--mobile.show--search {
		box-shadow: 0 28px 26px 0 #0000001a;
		opacity: 1;
		transform: translate3d(0px, 0px, 0px);
	}

	.search--mobile input {
		width: 100%;
		height: 61px;
		border: 0px;
		font-size: 18px;
		color: #2F3640;
		box-sizing: border-box;
		padding: 0px 50px 0px 20px;
	}

	.search--mobile input::-webkit-input-placeholder {
		color: #B2BEC3;
		font-size: 18px;
		transition: all 0.2s ease-in-out;
	}

	.search--mobile input::-moz-placeholder {
		color: #B2BEC3;
		font-size: 18px;
	}

	.search--mobile input:-ms-input-placeholder {
		color: #B2BEC3;
		font-size: 18px;
	}

	.search--mobile input:-moz-placeholder {
		color: #B2BEC3;
		font-size: 18px;
	}

	.mobile__header-search--goback {
		background: url("../images/back_icon.svg");
		width: 15px;
		height: 15px;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 20px;
		margin: auto;
	}

	.mobile__header-search--delete {
		background: url("../images/delete_icon.svg");
		width: 15px;
		height: 15px;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 20px;
		margin: auto;
		display: none;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .mobile__header-search--delete {
		right: 10px;
	}

	.mobile__header-search--delete.show--delete {
		display: block;
	}

	#payment__popup .product__popup-container {
		padding: 0px;
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
		transform: scale(1);
		background: #F5F6FA;
	}

	#payment__popup .product__popup-container .product__popup-main {
		flex-wrap: wrap;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	#payment__popup .product__popup-container .product__popup-main::-webkit-scrollbar {
		display: none;
	}

	#payment__popup .product__popup-container .product__popup-main::-webkit-scrollbar-track {
		display: none;
	}

	#payment__popup .product__popup-container .product__popup-main::-webkit-scrollbar-thumb {
		display: none;
	}

	.transaction-popup--block {
		width: 100%;
	}

	.transaction-popup--block.transaction__register-helper {
		padding-top: 0px;
	}

	.transaction-processing h2 {
		margin-bottom: 0px;
	}

	.transaction__register-helper .insider {
		background: #fff;
		padding: 0px;
	}

	.transaction__register-helper .insider div {
		width: 80%;
	}

	.transaction-popup--block p {
		flex-wrap: wrap;
	}

	.transaction-popup--block p label {
		width: 100%;
		margin: 0px 0px 10px 0px;
	}

	.transaction__register-helper .insider p .label__input {
		width: 100%;
	}

	.confirmation__popup .product__popup-container {
		width: 90%;
	}

	#confirmation__popup .popup__buttons {
		justify-content: space-between;
		padding: 0px 25px 25px 25px;
	}

	#confirmation__popup .popup__buttons button {
		width: 48%;
		text-align: center;
		margin: 0px;
	}

	#toast {
		box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.1);
		width: 100%;
		max-width: 100%;
		left: 0;
		bottom: 0;
		border-radius: 0px;
	}

	.cart--number-icon {
		display: flex;
		align-items: center;
		margin: 0px 0px 0px 10px;
		position: relative;
	}

	.cart--number-icon span {
		margin: 0px 0px 0px 2px;
		width: 20px;
		height: 20px;
		display: flex;
		justify-content: center;
		background: #f9583a;
		color: #fff;
		border-radius: 50%;
		line-height: 20px;
		font-size: 12px;
		position: absolute;
		top: -10px;
		right: -9px;
		font-family: sans-serif;
		font-weight: 400;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .cart--number-icon span {
		right: -4px;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .cart--number-icon {
		margin: 0px 0px 0px 5px;
	}

	.icon__counter--round {
		width: 20px;
		height: 20px;
		line-height: 20px;
		box-shadow: 3px 3px 4px 0 #0000001a;
		background-color: #fa583a;
		font-size: 11px;
		font-weight: bold;
		color: #ffffff;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		position: absolute;
		top: -8px;
		right: -3px;
		font-family: sans-serif;
		font-weight: 400;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .user__corner-notifications .icon__counter--round {
		right: 7px !important;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .icon__counter--round {
		right: 6px;
		top: -9px;
	}

	.datepicker--mobile {
		display: block !important;
	}

	#settings #footer {
		padding: 0px 15px;
		margin-bottom: 90px;
		margin-top: 30px;
	}

	#statistics #footer {
		padding: 0px 15px;
		margin-bottom: 30px;
		margin-top: 30px;
	}

	.order-history #footer {
		padding: 0px 15px;
		margin-bottom: 30px;
		margin-top: 30px;
	}


	#footer {
		box-sizing: border-box;
		padding: 0px 15px;
	}

	#favorites #footer {
		margin-top: 30px;
	}

	.footer--block.disclaimer {
		margin: 5px 0px 0px 0px;
		flex-wrap: wrap;
	}

}


/*  */

/*------------------------------------------------------------------------------*\
  #TABLET
/*------------------------------------------------------------------------------*\

/*  */


@media only screen and (min-width : 480px) and (max-width : 768px) {

	.recommended__product {
		box-sizing: border-box;
		padding: 0px 5px;
	}

	.sub-page {
		margin-bottom: 30px;
	}

	#product--mobile .product__section--left .meta-measurment {
		right: 10px;
	}

	.raft.expand .raft__content {
		background-size: calc(100% + 60px) 323px;
		width: 100%;
		background-position-y: 271px;
		margin-bottom: 20px;
	}

	.raft.expand .raft__content .raft-card {
		margin-bottom: 35px;
	}

	.product__popup--recommended button.carousel-arrow.block.carousel-prev.custom {
		left: 0;
	}

	.product__popup--recommended button.carousel-arrow.block.carousel-prev.custom,
	.product__popup--recommended button.carousel-arrow.block.carousel-next.custom {
		display: none !important;
	}

	.recommended-static {
		overflow: auto;
	}

	disabled-buttons {
		display: none;
	}

	.raft__content {
		overflow: auto;
		padding: 18px 0px 0px 18px;
		scroll-snap-type: x mandatory;
		scroll-padding: 18px;
	}

	.raft__content .raft-card {
		scroll-snap-align: start;
	}

	.footer-block-description {
		width: 100%;
	}

	#processing__popup .product__popup-container {
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	#processing__popup .product__popup-container .product__popup-main {
		flex-wrap: wrap;
		align-content: center;
	}

	.transaction-popup--block {
		width: 100%;
		padding: 10px;
	}

	.transaction--done .done {
		width: 40px;
		height: 40px;
	}

	.transaction-processing h2 {
		font-size: 20px;
	}

	.reactour__helper.tour-component-mobile {
		width: 65%;
	}

	.cookies-container {
		flex-wrap: wrap;
		padding: 25px 25px;
	}

	.cookies-container .cookies-icon {
		display: none;
	}

	.cookies-container h2 {
		margin: 0px 0px 20px 0px;
	}

	.cookies-container .small-button--primary {
		padding: 0px 30px;
		font-size: 18px;
		height: 38px;
		line-height: 38px;
	}

	.raft.expand .raft__categories .tabs-container-right .tabs-buttons-list {
		width: calc(100% - 37px);
	}

	.raft.expand.button-expand .raft__categories .tabs-container-right .tabs-buttons-list {
		width: calc(100% - 37px);
	}

	#mobile__payment-container #location__popup .product__popup-container {
		padding: 15px;
		width: 100%;
		height: 100%;
		max-height: 100%;
		background: #fff;
	}

	#payment__popup .payment-item--web .datepicker input {
		background: #fff !important;
	}

	#payment__popup .address-chosen .input__holders--col .label__input {
		background: transparent;
		width: 100% !important;
		margin: 0px 0px 0px 0px;
	}

	.settings-children-container .settings__content--main p .label__info-src {
		font-size: 16px;
		font-weight: bold;
	}

	#payment__popup .label__input {
		background: #fff;
	}

	#payment__popup .label__input.select-box {
		background-image: unset;
		background: url("../images/chevron_down.svg") #fff;
		background-repeat: no-repeat, repeat;
		background-position: right .7em top 50%, 0 0;
		background-size: .65em auto, 100%;
	}

	#payment__popup .di--address.disabled input,
	#payment__popup .di--delivery_number.disabled input {
		background: #f3f3f3 !important;
		border-color: #ececec;
		pointer-events: none;
		cursor: default;
		color: #848484;
	}

	#payment__popup .input__holders--col.input__holders--col--half.input__holders--col--sts.di--address-select.disabled input:disabled {
		background: #f3f3f3 !important;
		border-color: #ececec;
		pointer-events: none;
		cursor: default;
		color: #848484;
	}

	#payment__popup .input__holders--col.input__holders--col--half.input__holders--col--sts.di--address-select.disabled select {
		background: #f3f3f3 !important;
		border-color: #ececec;
		pointer-events: none;
		cursor: default;
		color: #848484;
	}

	#payment__popup .input__holders--col.input__holders--col--half.input__holders--col--sts.di--address {
		margin: 0px 0px 0px 0px;
	}

	#payment__popup .input__holders--col.input__holders--col--half.input__holders--col--stt.di--delivery_number-auth {
		margin: 0px !important;
	}

	#payment__popup .input__holders--col.input__holders--col--half.input__holders--col--sts.di--address input {
		width: 100% !important;
	}

	#payment__popup .input__holders--col.input__holders--col--half.input__holders--col--stt.di--delivery_number {
		margin: 0px;
	}

	.new-address-button button {
		width: 100%;
	}

	.new-address-button {
		margin: 13px 0px 0px 0px;
		white-space: nowrap;
		width: 29%;
	}

	.category-src.tabs.mobile {
		display: flex;
	}

	.category-src.tabs.web {
		display: none;
	}

	.raft-card__product-img.placeholder {
		height: 100%;
	}

	#product--mobile .popup__product-price span {
		margin: 0px 10px 0px 0px;
	}

	#product--mobile .product__section--left .animated-product-image-container .raft-card__product-img .raft-card__product-img-src {
		justify-content: center;
	}

	.popup__add-to-cart {
		width: 11px;
		height: 11px;
		text-indent: -9999px;
		margin: 0px 7px 1px 0px;
	}

	.raft .minicar {
		margin: 2px 0px 0px 0px;
	}

	.cart-item-src:last-of-type {
		margin: 0px 0px 20px 0px;
	}

	.raft.expand .next-button {
		left: unset;
	}

	.tabs-buttons-list {
		width: calc(100% - 37px);
	}

	.category-src.tabs.mobile {
		display: flex;
	}

	.category-src.tabs.web {
		display: none;
	}

	.order-status {
		margin: 0px 0px 0px 0px;
	}

	#payment__popup .payment-popup--item.unavailable .cart__product-img {
		opacity: 0.2;
	}

	#payment__popup .payment-popup--item.unavailable .product__title,
	#payment__popup .payment-popup--item.unavailable .product__subtitle,
	#payment__popup .payment-popup--item.unavailable .quantity-container__values h2,
	#payment__popup .payment-popup--item.unavailable .quantity-container__values h1 {
		color: #2f36403d;
	}

	.status-disclaimer {
		display: none;
		border-radius: 50px;
		height: 22px;
		line-height: 22px;
		padding: 0px 10px;
		font-size: 14px;
		font-weight: bold;
	}

	.payment-popup--item.unavailable .status-disclaimer {
		background: #FA583A;
		color: #fff;
	}

	.payment-popup--item.limited .status-disclaimer {
		background: #f39c12;
		color: #fff;
	}

	#payment__popup .payment-popup--item.limited .status-disclaimer,
	#payment__popup .payment-popup--item.unavailable .status-disclaimer {
		display: block
	}

	.title-status {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.payment-popup--item:first-child .cart__item-container {
		padding-top: 0px;
	}

	.date-decorator div {
		left: 10px;
	}

	#list__popup-container .product__popup-container {
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	.toast {
		width: 100% !important;
		left: 0px;
		bottom: 0;
		border-radius: 0px !important;
		max-width: 100%;
	}

	.button__ghost--primary.full-width-mobile-button {
		width: 100%;
		height: 34px !important;
	}

	.inputs-container {
		overflow: auto;
		height: calc(100% - 20px);
		padding: 0px 0px 60px 0px;
	}

	#statistics .settings__content--block.statistics.yearly .single-stat-picker {
		width: 100%;
	}

	.category-title.category-title-mobile {
		display: block;
		box-sizing: border-box;
		padding: 15px;
	}

	#statistics .settings__content--header .category-title {
		display: none;
	}

	.chart.monthly-chart .chart-disclaimer {
		margin: 20px 0px 0px 0px;
	}

	.statistics-tab {
		flex-wrap: wrap;
	}

	.single-tab {
		width: 100%;
	}

	.settings__content--header {
		padding: 0px 15px;
	}

	.settings__content--main {
		padding: 15px;
	}

	#statistics .settings__content--header {
		flex-wrap: wrap;
		height: auto;
		border-top: 1px solid rgba(223, 230, 233, 0.5);
	}

	#statistics .settings__content--header .category-title {
		width: 100%;
	}

	#statistics .settings__content--header .stats-picker {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#statistics .settings__content--header .single-stat-picker {
		width: 48%;
		margin: 0px;
	}

	.charts {
		flex-wrap: wrap;
		margin: 30px 0px 0px 0px;
	}

	.content--two-type .chart--pie {
		width: 100%;
		height: auto;
	}

	.stats-grouped-item {
		height: 90px;
		margin: 0px 0px 30px 0px;
	}

	.stats-grouped-item:last-of-type {
		margin: 0px 0px 0px 0px;
	}

	.single-stat--block .visual {
		width: 50px;
		height: 50px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.single-stat--block .visual .raft-card__product-img {
		height: 100%;
	}

	.single-stat--block .visual .raft-card__product-img {
		max-width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.cart__items.trim__items.expand-cart-items {
		height: calc(100% - 85px) !important;
		padding-bottom: 20px;
	}

	.trigger-buttons {
		display: none;
	}

	.spinner-container {
		width: 100%;
		height: calc(100% - 61px);
	}

	.datepicker.d-from.datepicker--mobile.payment-picker input {
		padding-left: 15px !important;
		height: 40px !important;
	}

	.datepicker.d-from.datepicker--mobile.payment-picker .label__input {
		margin-top: 0px !important;
		width: 48%;
	}

	.datepicker.datepicker--mobile.statistics-datepicker .picker-form {
		width: 100%;
	}

	.datepicker.datepicker--mobile.order-history-datepicker .picker-form {
		width: 100%;
	}

	.datepicker.d-from.datepicker--mobile.payment-picker .picker-form {
		width: 48%;
		display: inline-block;
	}

	.datepicker--mobile input {
		padding-left: 50px !important;
	}

	.datepicker--mobile {
		margin: 0px 0px 10px 0px;
	}

	.datepicker--mobile:last-of-type {
		margin: 0px;
	}

	.datepicker--mobile .picker-form .rdtOpen .rdtPicker {
		display: flex;
		height: auto !important;
		background: #00000085 !important;
		box-shadow: none !important;
		border: 0px !important;
	}

	.datepicker--mobile .picker-form .rdtPicker {
		display: none;
		position: fixed;
		width: 100%;
		z-index: 99999 !important;
		background: transparent;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
		border: 1px solid #f9f9f9;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		height: 100%;
		align-items: center;
		justify-content: center;
	}

	.datepicker--mobile .picker-form .rdtDays {
		width: 400px;
		max-width: 400px;
		display: inline-block;
		background: #fff;
		border-radius: 4px;
		border: 1px solid #0000001c;
		height: 281px;
		box-sizing: border-box;
		padding: 10px;
	}

	.rdtPicker td.rdtDisabled,
	.rdtPicker td.rdtDisabled:hover {
		color: #cacaca !important;
		text-decoration: line-through;
	}

	.rdtPicker td.rdtActive,
	.rdtPicker td.rdtActive:hover {
		background-color: #ee582e !important;
		font-weight: bold;
	}

	.rdtPicker td.rdtToday:before {
		border-bottom: 7px solid #ee582e !important;
	}

	.rdtPicker td.rdtToday {
		font-weight: bold;
	}

	.datepicker--mobile .picker-form input {
		background: #fff !important;
		width: 100% !important;
		max-width: 100% !important;
		margin: 0px 0px 0px 0px;
		height: 50px;
		background: #F5F6FA;
		border: 1px solid #DFE6E9;
		border-radius: 5px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 0px 15px;
		font-size: 18px;
		font-weight: 400;
		color: #B2BEC3;
		-webkit-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}

	.datepicker--mobile .date-decorator {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		bottom: 0;
		margin: auto;
	}

	.raft-card__product-img .animated {
		height: 100%;
	}

	.popup__buttons--web {
		display: none;
	}

	.popup__buttons--mobile {
		display: flex !important;
	}

	.mobile__cart .cart__buttons .cart__pay-button {
		padding: 0px 0px 0px 12px;
	}

	.mobile__cart .cart__buttons .cart__pay-button .total {
		position: static;
		box-sizing: border-box;
		padding: 0px 20px;
		height: 42px;
		margin: 0px 0px 0px 0px;
		pointer-events: none;
	}

	.mobile__cart .cart__payments .cart__buttons .clear-total {
		background: #646e72;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.product__section--left .raft-card__product-img {
		width: 100%;
		margin: 0px;
		justify-content: flex-end !important;
	}

	#help .single-page-wrapper {
		flex-wrap: wrap;
	}

	.help-nav {
		width: 100%;
	}

	.help-nav ul {
		position: static;
	}

	.help-container-switcher {
		width: 100%;
	}

	.sub-page {
		padding: 0px;
	}

	.text-block-inputs .input__holders--col {
		width: 100%;
	}

	#location__popup .settings__content-block--actions {
		position: fixed;
		z-index: 1;
		background: #fff;
		padding: 0px;
		bottom: 0;
		width: 100%;
		margin: 0px 0px 0px 0px !important;
		height: 60px;
	}

	#location__popup .settings__content-block--actions div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0px 15px;
	}

	#location__popup .settings__content-block--actions .button__ghost--primary {
		margin: 0px;
	}

	#location__popup .product__popup-container .inputs {
		margin: 15px 0px 30px 0px;
		height: calc(100% - 70px);
	}

	#location__popup .settings__content-block--actions .button__ghost--borderless--primary.delete-location {
		width: 22px !important;
		margin: 0px 10px 0px 15px;
		height: 23px;
	}

	#settings .show-map {
		font-size: 12px;
		width: 35px;
	}

	.show-map span {
		margin: 0px 0px 0px 0px;
		width: 11px;
		height: 15px;
	}

	#settings--locations .settings__content-block--actions button {
		width: 100%;
	}

	#location__popup .product__popup-container {
		padding: 15px;
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	#location__popup .product__popup-container .inputs .input__holders--col {
		flex-wrap: wrap;
	}

	#location__popup .input__holders--col input {
		margin: 10px 0px 0px 0px;
	}

	.order-history .single-page-wrapper.order-history__wrapper .date__input--icon {
		height: 50px;
		line-height: 50px;
		top: 17px;
	}

	.date__input--icon {
		height: 60px;
		line-height: 60px;
	}

	.date__input--icon:after {
		line-height: 17px;
	}

	.web-categories {
		display: none;
	}

	.mobile-categories {
		display: block;
	}

	.product__section--left .raft-card__product-img {
		margin-top: 0px;
	}

	.cart--number-icon {
		display: flex;
		align-items: center;
		margin: 0px 0px 0px 20px;
		position: relative;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .cart--number-icon {
		margin: 0px 0px 0px 20px;
	}


	.mobile__cart .cart__items.trim__items {
		height: calc(100% - 171px);
	}

	.mobile__cart .cart__items.trim__items .cart__item-container:first-of-type {
		margin-top: 5px;
	}

	.mobile__cart .dropdown__container .dropdown__content {
		z-index: 2;
		top: 104px;
		-webkit-overflow-scrolling: touch;
	}

	.mobile__cart .dropdown__trigger {
		width: 100%;
		background: #f3f3f3;
		border: 0px;
		border-top: 1px solid #00000017;
		border-bottom: 1px solid #00000017;
	}

	.mobile__cart .dropdown__trigger h2 {
		color: #636E72;
		align-items: center;
	}

	.mobile__cart .dropdown__trigger h2:after {
		width: 10px;
		height: 10px;
		display: inline-block;
		background-color: #fff;
		line-height: 7px;
		border-radius: 50%;
		padding: 3px;
	}

	.mobile__cart .dropdown__container .dropdown__content {
		z-index: 2;
		top: 104px;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .user__corner-notifications .icon__counter--round {
		right: 7px !important;
	}


	.collapsible__content .input__holders--col {
		flex-wrap: wrap;
		width: 100%;
		max-width: 100% !important;
	}

	.collapsible__content .input__holders--col.di--address .label__input {
		width: 48% !important;
	}

	#payment__popup .input__holders--col label {
		max-width: 100% !important;
		width: 100% !important;
	}

	#payment__popup .collapsibles .rc-collapse-content .input__holders--col input {
		width: 69%;
	}

	#payment__popup .label__input {
		margin: 10px 0px 0px 0px;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .quantity-container__actions .plus span {
		height: 12px;
		width: 14px;
	}

	.raft.expand .raft__categories--expander {
		transform: none;
	}

	#payment__popup .delivery_info .input__holders--col--halfed {
		display: none;
	}

	.content--two-type {
		flex-wrap: wrap;
		justify-content: center;
		margin: 30px 0px 0px 0px;
	}

	.chart--pie {
		width: 180px;
		height: 180px;
		margin: 0px 0px 40px 0px;
	}

	.stats-grouped {
		width: 100%;
		padding: 0px;
	}

	.single-stat--block,
	.static {
		width: 100%;
	}

	.single-stat--block .visual {
		margin: 0px 20px 0px 0px;
	}

	.single-stat--block .textual {
		margin: 0px 0px 0px 0px;
	}


	.section__favorite .raft-card__favorited {
		left: 0px;
		top: 3px !important;
	}

	#payment__popup .label__input {
		background: #fff;
	}

	#payment__popup .label__input.select-box {
		background-image: unset;
		background: url("../images/chevron_down.svg") #fff;
		background-repeat: no-repeat, repeat;
		background-position: right .7em top 50%, 0 0;
		background-size: .65em auto, 100%;
	}

	#payment__popup .payment-item--mobile,
	.datepicker--mobile {
		display: flex !important;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}

	#payment__popup .input__holders--col.payment-item--mobile .label__input {
		width: 100% !important;
		max-width: 48% !important;
		margin: 0px 0px 10px 0px;
	}

	#payment__popup .delivery_info .halfed {
		align-items: flex-start;
		flex-wrap: wrap;
	}

	.popup--noscroll {
		pointer-events: none !important;
		overflow: hidden !important;
		touch-action: none;
		position: fixed;
		z-index: -9999;
	}

	.datepicker--mobile {
		display: flex !important;
	}

	#settings #footer {
		box-sizing: border-box;
		padding: 0px 15px;
		margin-bottom: 90px;
	}

	#footer {
		box-sizing: border-box;
		padding: 0px 15px;
	}

	#favorites #footer {
		margin-top: 30px;
	}

	#mobile__payment-container .payment__main-content .collapsibles .collapsible__content p.payment-item--web {
		display: none !important;
	}

	.app-container.popup--hidden.mobile-route--overflow .settings__content--block {
		margin: 0px 0px 100px 0px;
	}

	.app-container.popup--hidden.mobile-route--overflow .raft--cart {
		display: none;
	}

	#settings {
		height: 100%;
	}

	.raft.expand .slick-list .slick-slide {
		margin: 0px 0px 10px 0px !important;
		float: left;
	}

	.app-container {
		-webkit-overflow-scrolling: touch;
		padding: 60px 0px 0px 0px;
	}

	.app-container.popup--opened {
		-webkit-overflow-scrolling: touch;
	}

	.menu {
		box-shadow: none;
	}

	.header {
		top: 0;
		z-index: 9997;
		padding: 0px;
		height: auto !important;
		overflow: visible !important;
	}

	.mobile__header {
		width: 100%;
		height: 61px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 1;
		background: #fff;
	}

	.mobile__header-search {
		width: 20px;
		height: 22px;
		background-image: url("../images/search_icon.svg");
		background-repeat: no-repeat;
	}

	.mobile__header-cart {
		width: 24px;
		height: 22px;
		background-image: url("../images/cart_icon.svg");
		background-repeat: no-repeat;
	}

	.mobile__header .header__menu {
		background: transparent;
	}

	.mobile__header .header__menu-icon {
		background: url("../images/menu_dots_mobile.svg") no-repeat center center;
		margin-bottom: 0px;
	}

	.mobile__header .header__menu h2 {
		color: #B2BEC3;
		font-size: 16px;
	}

	.mobile__header .header__logo {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		width: 62px;
	}

	.header__logo a {
		width: 54px;
		height: 31px;
	}

	.mobile__header .header__section-right {
		margin: 0px 20px 0px 0px;
	}

	.mobile__header .header__section-right button {
		margin: 0px 0px 0px 15px;
	}

	.mobile__header .header__section-right button:first-of-type {
		margin: 0px;
	}

	.main-content-component {
		margin: 0px;
		width: 100%;
		height: 100%;
	}

	#raft__slider .slick-list {
		overflow-x: hidden !important;
	}

	.columns {
		width: 100%;
		margin: 0px 0px 0px 0px;
		overflow: hidden;
	}

	.columns .raft__content {
		margin: 0px;
		width: 100%;
		padding: 0px 0px 0px 18px;
		background-position-y: 270px;
		background-size: calc(100% + 60px) 333px;
		height: 333px;
	}

	.raft.expand .raft-illustration--expanded {
		top: 283px !important;
		left: 0;
		width: calc(100% + 60px);
	}

	.raft {
		margin: 0px 0px 15px 0px;
		height: 367px;
	}

	.raft.expand {
		height: auto;
	}

	.raft.expand #raft__slider .slick-list {
		padding-bottom: 0px;
	}


	.raft:last-of-type {
		margin-bottom: 100px;
	}

	.columns .raft__content .raft-card {
		width: 180px;
		margin-bottom: 35px;
	}

	.disabled-buttons {
		display: none;
	}

	.raft__categories--right .web__more-button {
		display: none !important;
	}

	.mobile__more-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 37px;
		padding: 0px;
	}

	.mobile__more-button span {
		width: 18px;
		height: 9px;
		background: url("../images/more_icon.svg");
		background-repeat: no-repeat;
		background-position: center;
		display: inline-block;
		font-size: unset !important;
		max-width: 37px;
		background-size: 100%;
		margin: 0px 0px 0px 3px;
	}

	.mobile__more-button span.undo {
		mask: url("../images/undo.svg") no-repeat center center;
		-webkit-mask: url("../images/undo.svg") no-repeat center center;
		-webkit-mask-size: 70%;
		mask-size: 70%;
		background: #fff;
		width: 17px;
		height: 17px;
		display: inline-block;
	}

	.cls-1 {
		fill: none;
	}

	.cls-2 {
		clip-path: url(#clip-path);
	}

	.cls-3 {
		fill: #fff;
	}

	.raft-illustration__boards {
		width: calc(100% + 40px);
		top: 14px;
	}

	.raft__categories {
		position: relative;
		height: 37px;
		background: #E8E9ED;
		padding: 0px 0px 0px 10px;
	}

	.raft__slider--decorator-end {
		right: 0px;
	}

	.raft__categories--left {
		width: 100%;
	}

	.raft__categories--left .category-title a .chevron {
		width: 6px;
		height: 8px;
	}

	.raft__categories--left .category-title a h2 {
		font-size: 14px;
		color: #2F3640;
	}

	.tabs-container-left {
		padding: 0px;
	}

	.category-title {
		padding: 0px 10px 0px 0px;
	}

	.raft__categories--left .category-src.tabs {
		width: 100%;
		height: 100%;
		overflow: auto;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		margin: 0px 0px 0px 10px;
		box-sizing: border-box;
		padding: 0px 0px 0px 0px;
		border-bottom: 0px;
	}

	.raft__categories--left .category-src.tabs ul {
		display: flex;
		flex-wrap: nowrap;
	}

	.raft__categories--left .category-src.tabs ul li {
		white-space: nowrap;
		padding: 10px 12px 6px 12px;
	}

	.tabs ul li.active__sub {
		background-image: linear-gradient(to top, #ffffff, #E8E9ED);
		border-bottom: 2px solid #fa583a;
	}

	.tabs ul li.active__sub h3 {
		font-weight: normal;
		text-shadow: 0 0 0.1px #292929;
	}

	.raft__categories--left .category-src.tabs ul h3 {
		font-size: 14px;
	}

	.raft__categories--right {
		right: 0;
		top: 0;
		height: 100%;
	}

	.raft__categories--expander {
		height: 100%;
		left: -170px;
		width: 170px;
	}

	.raft__categories--right button {
		height: 100%;
		border-radius: 0px;
		font-size: 13px;
	}

	.raft-category-divider {
		display: none;
	}

	.menu {
		width: 100%;
		height: 100%;
		position: fixed;
		transform: translate3d(-100%, 0, 0) !important;
		will-change: transform;
		transform-style: preserve-3d;
		backface-visibility: hidden;
		transform: translate3d(0, 0, 0);
	}

	.slide__menu--on {
		transform: translate3d(100%, 0, 0);
		overflow: unset !important;
	}

	.menu__header--item.close-menu {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 50px;
		height: 50px;
	}

	.menu__header .close-menu .close__popup-button {
		width: 12px;
		height: 12px;
		padding: 8px;
	}

	.cart {
		display: none;
	}

	.mobile__cart {
		display: block;
		width: 100%;
		background: #fff;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 9997;
		height: 80%;
		transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
		will-change: transform;
	}

	.mobile__cart.hide--cart {
		transform: translate3d(0px, 100%, 0px);
		box-shadow: none;
	}

	.mobile__cart.show--cart {
		transform: translate3d(0px, 0px, 0px);
		box-shadow: 0 -17px 56px 0 rgba(0, 0, 0, 0.14);
		will-change: transform;
		z-index: 1;
	}

	.cart__overflow .app-container {
		-webkit-overflow-scrolling: touch;
		overflow: auto;
	}

	.mobile-cart__header {
		width: 100%;

	}

	.full-width__cart-header--content {
		width: 100%;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 10px 10px 0px 12px;
	}

	.close-cart {
		width: 32px;
		height: 32px;
		position: absolute;
		left: 0;
		right: 0;
		top: -17px;
		margin: auto;
		background: #636e72;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2px;
		z-index: 1;
	}

	.mobile-cart__header-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.mobile-cart__header .cart__header-title h2 {
		font-size: 17px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		color: #636e72;
	}

	.mobile-cart__header .cart__save-button {
		height: 28px;
		font-size: 15px;
		padding: 0px 18px;
		text-transform: lowercase;
		border-radius: 3px;
		margin: 3px 0px 0px 0px;
	}

	.mobile-cart__header .cart__save-button .cart__save-button-icon {
		width: 10px;
		height: 10px;
	}

	.mobile-cart__header .close-car--icon {
		background: url("../images/chevron_up_icon.svg") no-repeat center center;
		width: 14px;
		height: 8px;
		transform: rotate(180deg);
	}

	.hide--cart .close-cart {
		display: none;
	}

	.mobile__cart .cart__items {
		width: 100%;
		height: calc(100% - 129px);
		left: 0px;
		margin: 0px;
		padding: 10px;
		box-sizing: border-box;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	.mobile__cart .cart__items .product__title {
		font-size: 18px;
	}

	.quantity-container__actions .plus,
	.quantity-container__actions .minus {
		width: 30px;
		height: 30px;
		font-size: 50% !important;
		line-height: 30px;
	}

	.quantity-container__actions .plus span {
		height: 15px;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .quantity-container__actions .plus span {
		height: 15px !important;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .quantity-container__actions .minus span {
		height: 5px;
	}

	.quantity-container__actions .minus span {
		height: 3px;
	}

	.quantity-container__actions .plus span,
	.quantity-container__actions .minus span {
		width: 17px;
		background-size: 100%;
		font-size: 18px !important;
	}

	_::-webkit-full-page-media,
	_:future,
	:root #product--mobile .quantity-container__actions .plus span {
		height: 20px !important;
	}

	_::-webkit-full-page-media,
	_:future,
	:root #product--mobile .popup__quantity .actions__value {
		width: 70px;
		font-size: 22px;
	}

	.actions__value {
		width: 50px;
		height: 30px;
		font-size: 20px;
	}

	.mobile__cart .cart__payments {
		width: 100%;
		margin: 15px 0px 0px 0px;
		position: relative;
		float: left;
		box-sizing: border-box;
		padding: 0px 0px 30px 0px;
		background: #fff;
	}

	.remove__cart-item {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
	}

	.mobile__cart .cart__items::-webkit-scrollbar {
		display: none;
	}

	.mobile__cart .cart__items::-webkit-scrollbar-track {
		display: none;
	}

	.mobile__cart .cart__items::-webkit-scrollbar-thumb {
		display: none;
	}

	.mobile__cart .cart--positioned {
		box-sizing: border-box;
		padding: 0px 10px;
	}

	.mobile__cart .cart__buttons {
		position: relative;
		flex-direction: column;
		margin: 0px;
	}

	.mobile__cart .cart__buttons .total {
		height: 100%;
		position: absolute;
		border-radius: 4px;
		background: #e83d1e;
		box-sizing: border-box;
		padding: 0px 15px;
		display: flex;
		align-items: center;
		top: 0;
		left: 0;
		font-family: sans-serif;
	}

	.mobile__cart .cart__buttons .total h2 {
		font-size: 15px;
		font-weight: 600;
		color: #f5f6fa;
	}

	.mobile__cart .cart__buttons .cart__pay-button {
		width: 100% !important;
		height: 42px;
		display: flex;
		flex-flow: nowrap;
		justify-content: space-between;
		border-radius: 4px;
		flex-direction: row-reverse;
	}

	.mobile__cart .cart__buttons .cart__pay-button:before,
	.mobile__cart .cart__buttons .cart__pay-button:after {
		display: none;
	}

	.mobile__cart .cart__item-container:last-of-type {
		margin-bottom: 0px;
	}

	.mobile__cart .cart__payments .cart--gradient {
		height: 150px;
		padding: 0px 0px 100px 0px;
		bottom: 0;
	}

	.track-order.track-order--slideup {
		display: none;
	}

	.columns .raft #raft__slider .slick-arrow {
		display: none !important;
	}

	.raft-illustration {
		width: calc(100% + 5px);
		position: absolute;
		/* bottom: -44px; */
		bottom: -62px;
		z-index: 0;
		height: 106px;
		white-space: nowrap;
		overflow: hidden;
	}

	.menu__header--user {
		display: none;
	}

	.mobile__menu-corner .avatar__button h2 {
		display: none;
	}

	.mobile__menu-corner .dropdowns ul li .icon__divider {
		margin: 0px 9px;
	}

	.all-products .favorite {
		position: relative;
		width: 176px;
		margin-right: 6px;
	}

	.all-products .favorite .raft-card {
		width: 100%;
	}

	.component__header {
		box-sizing: border-box;
		padding: 10px;
	}

	.favorite .remove__cart-item {
		left: -9px;
		top: -16px;
	}

	.favorite .remove__cart-item button {
		width: 36px;
		height: 36px;
	}

	.all-products .raft-illustration {
		background-size: 100% 340px;
		width: calc(100% + 150px);
	}

	.menu__user-action--content {
		width: 100%;
		height: calc(100% - 70px);
	}

	.mobile__track-order {
		width: 100%;
		height: 100%;
		position: static;
		margin: 0px;
		background: transparent;
		flex-wrap: wrap;
	}

	.mobile__track-order .track-order__header {
		width: 100%;
		text-align: center;
		height: 41px;
		line-height: 41px;
		background: #dfe6e9;
	}

	.mobile__track-order .track-order__header h2 {
		font-size: 16px;
		font-weight: bold;
		color: #636e72;
	}

	.mobile__track-order .track-order__container {
		flex-wrap: wrap;
		height: calc(100% - 41px);
		align-items: center;
		justify-content: space-evenly;
		overflow: auto;
	}

	.mobile__track-order .track-order__container .track-order__item.status {
		flex-wrap: wrap;
		margin: 20px 0px 0px 0px;
		width: 100%;
		justify-content: space-between;
	}

	.mobile__track-order .track-order__container .status__item {
		width: calc(100% / 3);
		margin-bottom: 0px;
	}

	.mobile__track-order .track-order__container .status__item.loading,
	.mobile__track-order .track-order__container .status__item.shipping {
		margin-bottom: 0px;
	}

	.mobile__track-order .track-order__container .status__item.packing:before {
		border-radius: 5px;
		width: 30px;
		height: 5px;
	}

	.mobile__track-order .track-order__container .status__item.packing:after {
		content: none;
	}

	.mobile__track-order .track-order__container .status__item.shipped:before {
		border-radius: 5px;
		width: 30px;
		height: 5px;
	}

	.mobile__track-order .track-order__container .status__item.shipped:after {
		content: none;
	}

	.mobile__track-order .track-order__item.prev__orders {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin-top: 40px;
		margin-bottom: 10px;
	}

	.mobile__track-order .track-order__item.prev__orders button {
		width: 100%;
		height: 50px;
		text-align: center;
		font-size: 22px;
	}

	.mobile__track-order .timing {
		display: flex;
		white-space: nowrap;
		margin: 0px 0px 22px 0px;
	}

	.mobile__track-order .timing span {
		font-size: 22px;
	}

	.mobile__track-order .timing h2 {
		font-size: 22px;
	}

	.mobile__notifications {
		height: 100%;
	}

	.mobile__notifications .dropdown-src {
		position: static;
		height: 100%;
		padding: 0px;
		box-shadow: none;
	}

	.mobile__notifications .dropdown__header {
		justify-content: center;
		background: #dfe6e9;
	}

	.mobile__notifications #notifications .notifications-container {
		-webkit-overflow-scrolling: touch;
		background: #f5f6fa;
	}

	.mobile__notifications #notifications .notifications-container ul li:hover {
		background: none;
	}

	.mobile__notifications #notifications .notifications-container ul li h2 {
		font-size: 20px;
		width: 100%;
		display: block;
		display: -webkit-box;
		max-width: 100%;
		max-height: 50px;
		height: auto;
		margin: 0 auto;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.mobile__notifications #notifications .notifications-container ul li span {
		font-size: 15px;
		width: 100%;
	}

	.menu__content {
		-webkit-overflow-scrolling: touch;
	}

	.mobile__usersettings {
		width: 100%;
		height: 100%;
	}

	.mobile__usersettings .dropdown-src.user-dropdown {
		width: 100%;
		height: 100%;
		box-shadow: none;
		position: static;
		padding: 0px;
		margin: 0px;
		background: #f5f6fa;
	}

	.mobile__usersettings .dropdown-src.user-dropdown .track-order__header {
		width: 100%;
		height: 41px;
		line-height: 41px;
		background: #dfe6e9;
		text-align: center;
	}

	.mobile__usersettings .dropdown-src.user-dropdown .track-order__header h2 {
		font-size: 16px;
		font-weight: bold;
		color: #636e72;
	}

	.mobile__usersettings .notifications-container {
		box-sizing: border-box;
		padding: 35px 30px;
	}

	.mobile__usersettings .notifications-container ul li {
		margin: 0px 0px 35px 0px;
		height: 35px;
		display: flex;
		align-items: center;
		position: relative;
	}

	.mobile__usersettings .notifications-container ul li a {
		font-size: 20px;
		padding: 0px;
		height: 100%;
		display: block;
		box-sizing: border-box;
	}

	.mobile__usersettings .notifications-container ul li a:before {
		position: absolute;
		left: 0;
	}

	.mobile__usersettings .notifications-container ul li a:before {
		margin: 0px 24px 0px 0px;
	}

	.notifications-container ul li.notifications__list--settings a:before {
		width: 20px;
		height: 25px;
	}

	.notifications-container ul li.notifications__list--statistics a:before {
		width: 24px;
		height: 22px;
	}

	.notifications-container ul li.notifications__list--order-history a:before {
		width: 22px;
		height: 22px;
	}

	.notifications-container ul li.notifications__list--logout a:before {
		width: 30px;
		height: 24px;
		left: -1px;
	}


	#product--mobile {
		display: block;
		width: 100%;
		height: 100%;
	}

	#product--mobile .product__popup-container {
		width: 100%;
		height: 100%;
		max-height: 100%;
		margin: 0px;
		padding: 0px;
		border-radius: 0px;
		-webkit-overflow-scrolling: touch;
	}

	#product--mobile .product__popup-container::-webkit-scrollbar {
		display: none;
	}

	#product--mobile .product__popup-container::-webkit-scrollbar-track {
		display: none;
	}

	#product--mobile .product__popup-container::-webkit-scrollbar-thumb {
		display: none;
	}

	#product--mobile .product__popup--full {
		display: block;
		width: 100%;
		height: 100%;
		position: relative;
		padding: 25px 30px 0px 30px;
		top: 0px;
		overflow: auto;
	}

	.rc-tabs-top .rc-tabs-content {
		height: auto !important;
	}

	.mobile__product--decorator {
		width: 100%;
		height: 201px;
		background-position: top left;
		background-size: 100% 201px;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
	}

	.cls-1 {
		fill: #f5f6fa;
	}

	.product__popup-actions--top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		box-sizing: border-box;
		padding: 10px 15px 0px 15px;
	}

	.product__popup-actions--bottom {
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: flex-start;
		margin: 30px 0px;
		position: relative;
	}

	#product--mobile .product__section--left {
		height: 120px;
		background: none;
		width: auto;
		min-width: 170px;
		max-width: 200px;
	}

	#product--mobile .product__section--left .raft-card__product-img img {
		width: auto;
		height: 100%;
		position: static;
		margin: 0px;
	}

	#product--mobile .product__section-block-inside.product__section--top {
		display: block;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		width: 60%;
		padding: 0px 0px 0px 20px;
	}

	#product--mobile .recommended__header h2 {
		font-size: 14px;
		letter-spacing: 3px;
		white-space: nowrap;
	}

	#product--mobile .popup__product-title {
		margin: 5px 0px 0px 0px;
	}

	#product--mobile .popup__product-price {
		margin: 5px 0px 0px 0px;
		display: flex;
		align-items: center;
	}

	#product--mobile .product__popup-main {
		box-sizing: border-box;
		display: block;
	}

	#product--mobile .popup__product-title {
		font-size: 26px;
	}

	#product--mobile .product__popup--recommended {
		padding: 0px;
		margin-bottom: 190px;
		margin-top: 25px;
	}

	#product--mobile .product__popup--recommended .slick-slider {
		padding: 0px 0px 30px 0px;
	}

	#product--mobile .recommended__gradient--decorator {
		width: 70px;
		right: 0;
	}

	#product--mobile .product__popup--recommended .slick-arrow {
		display: none !important;
	}

	#product--mobile .product__section-block-inside.popup__quantity {
		position: fixed;
		bottom: 0px;
		left: 0;
		width: 100%;
		display: block;
		box-sizing: border-box;
		padding: 0px 30px 30px 30px;
		z-index: 9999;
		background: #f5f6fa;
	}

	#product--mobile .quantity-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: nowrap;
		margin: 0px 0px 15px 0px;
		position: relative;
	}

	#product--mobile .popup__quantity .quantity-container__actions {
		width: auto;
	}

	#product--mobile .quantity-container__actions .plus,
	#product--mobile .quantity-container__actions .minus {
		width: 40px !important;
		height: 40px;
		font-size: 50% !important;
		line-height: 40px !important;
	}

	#product--mobile .quantity-container__actions .plus span {
		width: 20px;
		height: 20px;
	}

	#product--mobile .quantity-container__actions .minus span {
		height: 5px;
	}

	#product--mobile .popup__quantity .quantity-container__values {
		text-align: right;
		margin: 0px;
	}

	#product--mobile .popup__quantity .actions__value {
		width: 60px;
		height: 40px;
		font-size: 20px;
	}

	#product--mobile .product__section-block-inside button {
		width: 100%;
	}

	#product--mobile .product__section-block-inside .icon-button--secondary {
		height: 50px;
		padding: 0px;
		position: relative;
	}

	.actions__gradient--decorator {
		width: 100%;
		height: 78px;
		position: absolute;
		left: 0;
		top: -76px;
		background-image: linear-gradient(to top, #f5f6fa, #f5f6fa00);
		pointer-events: none;
	}

	#web__payment-container {
		display: none;
	}

	#mobile__payment-container {
		display: block !important;
		z-index: 9999;
		width: 100%;
		height: 100%;
		margin: 0px 0px 0px 0px;
	}

	#mobile__payment-container .payment__cart {
		display: none;
	}

	#mobile__payment-container .product__popup--overlay {
		display: none;
	}

	#mobile__payment-container .product__popup {
		z-index: 9999;
	}

	_::-webkit-full-page-media,
	_:future,
	:root #mobile__payment-container .product__popup {
		z-index: 9999;
		position: fixed;
	}

	#mobile__payment-container .product__popup-container {
		padding: 0px;
		width: 100%;
		height: 100%;
		max-height: 100%;
		border-radius: 0px;
		background: #F5F6FA;
	}

	#mobile__payment-container .product__popup-container .close__popup-button {
		width: 18px;
		height: 18px;
	}

	#mobile__payment-container .product__popup-main {
		width: 100%;
		overflow: auto;
		height: calc(100% - 80px);
		-webkit-overflow-scrolling: touch;
	}

	#mobile__payment-container .payment__main-content {
		padding: 20px;
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	.payment__main-content .product__popup--full h2 {
		font-size: 26px;
	}

	#mobile__payment-container .payment__main-content .collapsibles {
		width: 100%;
		height: auto;
		margin: 30px 0px 0px 0px;
	}

	#mobile__payment-container .payment__main-content .collapsibles .collapsible__triger {
		padding: 0px 0px 15px 0px;
		margin: 0px 0px 15px 0px;
	}


	#mobile__payment-container .payment__main-content .collapsibles .rc-collapse-content {
		background: transparent;
		padding: 0px;
	}

	#mobile__payment-container .payment__main-content .collapsibles .collapsible__content p {
		display: block !important;
		width: 100%;
		max-width: 100%;
		margin-bottom: 30px;
	}

	#mobile__payment-container .payment__main-content .halfed p input {
		width: 100% !important;
		max-width: 100% !important;
	}

	#mobile__payment-container .payment__main-content .collapsibles .rc-collapse-content p label {
		display: block;
		width: 100%;
	}

	#mobile__payment-container .payment__main-content .collapsibles .rc-collapse-content p input {
		display: block;
		width: 100%;
		max-width: 100%;
		background: #fff;
		margin: 10px 0px 0px 0px;
	}

	#mobile__payment-container #mobile__payment-footer {
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 0;
		box-sizing: border-box;
		padding: 20px;
		background: #f5f6fa;
		z-index: 9998;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#login__popup .product__popup-container {
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	.popup__block.social-login {
		flex-wrap: wrap;
	}

	.popup__block.social-login button {
		width: 100%;
		margin: 0px 0px 10px 0px;
	}

	.popup__block.social-login button:last-of-type {
		margin-bottom: 0px;
	}

	.single-page-wrapper {
		width: 90%;
		margin-top: 0px;
		padding-top: 40px;
		-webkit-overflow-scrolling: touch;
	}

	.single-page-wrapper:after {
		content: "";
		display: table;
		clear: both;
	}

	.order-history .component__header {
		flex-wrap: wrap;
	}

	.order-history .date__input--holder {
		width: 100%;
		margin: 15px 0px 0px 0px;
	}

	.order-history .date__input--holder .date__input {
		width: 100%;
		height: 50px;
	}

	.order-history__wrapper .rc-collapse {
		padding: 0px;
	}

	.order-history__wrapper .component__content--footer {
		margin: 15px 0px 0px 0px;
	}

	.order-history__wrapper .component__content--footer .component__footer--block.cfb__total span,
	.order-history__wrapper .component__content--footer .component__footer--block.cfb__total h2 {
		width: 100%;
		text-align: center;
	}

	.order-history__wrapper .component__footer--block button {
		height: 48px;
		width: 100%;
		margin-top: 10px !important;
		margin-right: 0px !important;
	}

	.component__footer--block button:last-of-type {
		display: none;
	}

	.order-history__wrapper .short__detail-info {
		display: none;
	}

	.order-history__wrapper .orders__table--product {
		width: auto;
	}

	.order-history__wrapper .row_count {
		display: none;
	}

	.order-history__wrapper .orders__table .orders__table tbody tr {
		height: auto;
	}

	.order-history__wrapper .orders__table .orders__table tbody tr td {
		padding: 10px 0px;
	}

	.order-history__wrapper .mobile--alignment {
		text-align: center;
	}

	.order-history__wrapper .orders__table tbody tr .product__title {
		font-size: 16px;
	}

	.order-history__wrapper .orders__table tbody tr .product__subtitle {
		white-space: nowrap;
	}

	.order-history__wrapper .orders__table tbody tr:nth-child(even) {
		background: #f5f6fa;
	}

	.order-history__wrapper .chc--mobile {
		width: 100%;
		display: flex;
		margin: 20px 0px 30px 0px;
		justify-content: center;
	}

	.order-history__wrapper .chc--mobile .chc--rounded:first-child {
		margin-left: 0px;
	}

	#save-cart__popup .product__popup-container {
		width: 90%;
	}

	#save-cart__popup .popup__content {
		flex-wrap: wrap;
	}

	#save-cart__popup .popup__content label {
		width: 100%;
		margin: 0px 0px 10px 0px;
	}

	#save-cart__popup .popup__content input {
		width: 100%;
	}

	.mobile__cart.show--cart.expand__cart {
		height: calc(100% - 83px);
		z-index: 2;
	}

	.mobile__cart.show--cart.expand__cart .cart__items {
		height: calc(100% - 135px);
		padding-bottom: 20px;
	}

	.mobile__cart.show--cart.expand__cart .dropdown__content {
		top: 95px;
		z-index: 2;
		box-shadow: 0px 20px 81px 20px rgba(0, 0, 0, 0.19);
	}

	.mobile__cart .saved-cards--mobile {
		display: block;
	}

	.saved-cards--mobile {
		width: 100%;
		margin: 15px 0px 0px 0px;
	}

	.saved-cards--mobile .dropdown__trigger {
		border-radius: 0px;
	}

	.info__navigation--left {
		width: 100%;
	}

	.settings__avatar__image-holder {
		float: left;
	}

	.settings__avatar h2,
	.settings__avatar a {
		float: left;
		margin-left: 20px;
		width: calc(100% - 120px)
	}

	.settings__content--right {
		width: 100%;
		margin: 30px 0px 0px 0px;
	}

	.switch__holder .switch {
		width: 43px !important;
	}

	.switch__holder slider.round {
		width: 43px;
	}

	#settings--basic-info .label__info {
		width: 120px !important;
		margin: 0px 0px 0px 0px;
	}

	#settings .settings__content--block p {
		width: 100%;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0px 0px 0px 0px;
		box-sizing: border-box;
		padding: 13px 0px 13px 0px;
	}

	#settings--basic-info .button-holder .button__ghost--primary {
		margin: 0px 0px 0px 10px;
	}

	.settings__content--block p .label__input {
		width: 100%;
		max-width: 100%;
	}

	.settings__content--main .file-upload__holder {
		margin-top: 15px;
		flex-wrap: wrap;
	}

	.settings__content--main .file-upload__holder .label__info {
		width: 100%;
		margin: 0px 0px 5px 0px;
	}

	.settings__content-block--actions {
		display: flex;
		justify-content: space-between;
	}

	.settings__content-block--actions button {
		margin: 0px;
		width: 100%;
		text-align: center;
	}

	.file-upload__button-container label {
		float: left;
		width: auto !important;
		margin: 0px !important;
	}

	.file-upload__button-container {
		width: calc(100% - 130px);
	}

	#settings--basic-info .file-upload__holder .file-upload__button-container .label__info {
		text-align: center;
	}

	.settings__content--block {
		margin: 0px 0px 20px 0px;
	}

	.settings__content-block--actions {
		position: fixed;
		z-index: 1;
		bottom: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 20px;
		left: 0;
		background: #f5f6fa;
	}

	.datepicker--mobile {
		display: block;
		width: 100%;
	}

	.datepicker--mobile p {
		width: 100%;
		margin: 0px 0px 10px 0px;
	}

	.datepicker--mobile .date__input {
		width: 100%;
	}

	#statistics .component__header {
		flex-wrap: wrap;
		padding: 0px;
	}

	.settings__content--block.statistics {
		margin: 0px;
	}

	.order-history__wrapper .datepicker {
		width: 100%;
	}

	.order-history__wrapper .datepicker:first-child {
		margin: 0px 0px 10px 0px;
	}

	.search--mobile {
		position: fixed;
		left: 0;
		top: 61px;
		background: #fff;
		width: 100%;
		transform: translate3d(0px, -61px, 0px);
		box-shadow: none;
		visibility: visible;
		opacity: 0;
		transition: all 0.2s ease-in-out;
		will-change: transform;
	}

	.search--mobile.show--search {
		box-shadow: 0 28px 26px 0 #0000001a;
		visibility: visible;
		opacity: 1;
		transform: translate3d(0px, 0px, 0px);
		will-change: transform;
	}

	.search--mobile input {
		width: 100%;
		height: 61px;
		border: 0px;
		font-size: 18px;
		color: #2F3640;
		box-sizing: border-box;
		padding: 0px 50px 0px 20px;
	}

	.search--mobile input::-webkit-input-placeholder {
		color: #B2BEC3;
		font-size: 18px;
		transition: all 0.2s ease-in-out;
	}

	.search--mobile input::-moz-placeholder {
		color: #B2BEC3;
		font-size: 18px;
	}

	.search--mobile input:-ms-input-placeholder {
		color: #B2BEC3;
		font-size: 18px;
	}

	.search--mobile input:-moz-placeholder {
		color: #B2BEC3;
		font-size: 18px;
	}

	.mobile__header-search--goback {
		background: url("../images/back_icon.svg");
		width: 15px;
		height: 15px;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 20px;
		margin: auto;
	}

	.mobile__header-search--delete {
		background: url("../images/delete_icon.svg");
		width: 15px;
		height: 15px;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 20px;
		margin: auto;
		display: none;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .mobile__header-search--delete {
		right: 10px;
	}

	.mobile__header-search--delete.show--delete {
		display: block;
	}

	#payment__popup .product__popup-container {
		padding: 0px;
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
		transform: scale(1);
		background: #F5F6FA;
	}

	#payment__popup .product__popup-container .product__popup-main {
		flex-wrap: wrap;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	#payment__popup .product__popup-container .product__popup-main::-webkit-scrollbar {
		display: none;
	}

	#payment__popup .product__popup-container .product__popup-main::-webkit-scrollbar-track {
		display: none;
	}

	#payment__popup .product__popup-container .product__popup-main::-webkit-scrollbar-thumb {
		display: none;
	}

	.transaction-popup--block {
		width: 100%;
	}

	.transaction-popup--block.transaction__register-helper {
		padding-top: 0px;
	}

	.transaction-popup--block.transaction-processing {
		margin: 40px 0px 0px 0px;
	}

	.transaction-processing h2 {
		margin-bottom: 0px;
	}

	.transaction__register-helper .insider {
		background: #fff;
		padding: 30px 0px;
	}

	.transaction__register-helper .insider div {
		width: 80%;
	}

	.transaction-popup--block p {
		flex-wrap: wrap;
	}

	.transaction-popup--block p label {
		width: 100%;
		margin: 0px 0px 10px 0px;
	}

	.transaction__register-helper .insider p .label__input {
		width: 100%;
	}

	.confirmation__popup .product__popup-container {
		width: 90%;
	}

	#confirmation__popup .popup__buttons {
		justify-content: space-between;
		padding: 0px 25px 25px 25px;
	}

	#confirmation__popup .popup__buttons button {
		width: 48%;
		text-align: center;
		margin: 0px;
	}

	#toast {
		box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.1);
		width: 100%;
		max-width: 100%;
		left: 0;
		bottom: 0;
		border-radius: 0px;
	}

	.cart--number-icon {
		display: flex;
		align-items: center;
		margin: 0px 0px 0px 10px;
		position: relative;
	}

	.cart--number-icon span {
		margin: 0px 0px 0px 2px;
		width: 20px;
		height: 20px;
		display: flex;
		justify-content: center;
		background: #f9583a;
		color: #fff;
		border-radius: 50%;
		line-height: 20px;
		font-size: 12px;
		position: absolute;
		top: -10px;
		right: -9px;
		font-family: sans-serif;
		font-weight: 400;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .cart--number-icon span {
		right: -4px;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .cart--number-icon {
		margin: 0px 0px 0px 5px;
	}

	.icon__counter--round {
		width: 20px;
		height: 20px;
		line-height: 20px;
		box-shadow: 3px 3px 4px 0 #0000001a;
		background-color: #fa583a;
		font-size: 11px;
		font-weight: bold;
		color: #ffffff;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		position: absolute;
		top: -8px;
		right: -3px;
		font-family: sans-serif;
		font-weight: 400;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .icon__counter--round {
		right: 6px;
		top: -9px;
	}

	.status__item .status__item--circle {
		width: 80px;
		height: 80px;
	}

	.status__item .status__item--circle span {
		width: 30px;
		height: 30px;
	}

	#favorites .component__header {
		padding: 20px 10px;
	}

	#favorites .component__content {
		margin-top: 0px;
	}

	.dropdown__content {
		-webkit-overflow-scrolling: touch;
	}

}


/*  */

/*------------------------------------------------------------------------------*\
  #TABLET[PORTRAIT]
/*------------------------------------------------------------------------------*\

/*  */

@media only screen and (min-width : 769px) and (max-width : 1024px) {

	.recommended__product {
		box-sizing: border-box;
		padding: 0px 5px;
	}

	.sub-page {
		margin-bottom: 30px;
	}

	.raft.expand .raft__content {
		background-size: calc(100% + 60px) 323px;
		width: 100%;
		background-position-y: 271px;
		margin-bottom: 20px;
	}

	.raft.expand .raft__content .raft-card {
		margin-bottom: 35px;
	}

	.product__popup--recommended button.carousel-arrow.block.carousel-prev.custom,
	.product__popup--recommended button.carousel-arrow.block.carousel-next.custom {
		display: none !important;
	}

	.recommended-static {
		overflow: auto;
	}

	.product__popup--recommended button.carousel-arrow.block.carousel-prev.custom {
		left: 0;
	}

	disabled-buttons {
		display: none;
	}

	.raft.expand .raft-illustration--expanded {
		top: 276px !important;
		width: calc(100% + 60px);
		left: 0;
	}

	.raft__content {
		overflow: auto;
		padding: 18px 0px 0px 18px;
		scroll-snap-type: x mandatory;
		scroll-padding: 18px;
	}

	.raft__content .raft-card {
		scroll-snap-align: start;
	}

	.footer-block-description {
		width: 100%;
	}

	.reactour__helper.tour-component-mobile {
		width: 50%;
	}

	.cookies-container {
		flex-wrap: wrap;
		padding: 25px 25px;
	}

	.cookies-container .cookies-icon {
		display: none;
	}

	.cookies-container h2 {
		margin: 0px 0px 20px 0px;
	}

	.cookies-container .small-button--primary {
		padding: 0px 30px;
		font-size: 18px;
		height: 38px;
		line-height: 38px;
	}

	.raft.expand .raft__categories .tabs-container-right .tabs-buttons-list {
		width: calc(100% - 37px);
	}

	.raft.expand.button-expand .raft__categories .tabs-container-right .tabs-buttons-list {
		width: calc(100% - 37px);
	}

	#mobile__payment-container #location__popup .product__popup-container {
		padding: 15px;
		width: 100%;
		height: 100%;
		max-height: 100%;
		background: #fff;
	}

	#payment__popup .payment-item--web .datepicker input {
		background: #fff !important;
	}

	#payment__popup .address-chosen .input__holders--col .label__input {
		background: transparent;
		width: 100% !important;
		margin: 0px 0px 0px 0px;
	}

	#payment__popup .label__input {
		background: #fff;
	}

	#payment__popup .label__input.select-box {
		background-image: unset;
		background: url("../images/chevron_down.svg") #fff;
		background-repeat: no-repeat, repeat;
		background-position: right .7em top 50%, 0 0;
		background-size: .65em auto, 100%;
	}

	textarea#contact_message {
		width: 100% !important;
	}

	.category-src.tabs.mobile {
		display: flex;
	}

	.category-src.tabs.web {
		display: none;
	}

	#product--mobile .popup__product-price span {
		margin: 0px 10px 0px 0px;
	}

	#product--mobile .product__section--left .animated-product-image-container .raft-card__product-img .raft-card__product-img-src {
		justify-content: center;
	}

	.raft-card__product-img.placeholder {
		height: 100%;
	}

	.cart-item-src:last-of-type {
		margin: 0px 0px 20px 0px;
	}

	.category-src.tabs.mobile {
		display: flex;
	}

	.category-src.tabs.web {
		display: none;
	}

	.raft-category-divider {
		display: none;
	}

	.order-status {
		margin: 0px 0px 0px 0px;
	}

	#payment__popup .payment-popup--item.unavailable .cart__product-img {
		opacity: 0.2;
	}

	#payment__popup .payment-popup--item.unavailable .product__title,
	#payment__popup .payment-popup--item.unavailable .product__subtitle,
	#payment__popup .payment-popup--item.unavailable .quantity-container__values h2,
	#payment__popup .payment-popup--item.unavailable .quantity-container__values h1 {
		color: #2f36403d;
	}

	.status-disclaimer {
		display: none;
		border-radius: 50px;
		height: 22px;
		line-height: 22px;
		padding: 0px 10px;
		font-size: 14px;
		font-weight: bold;
	}

	.payment-popup--item.unavailable .status-disclaimer {
		background: #FA583A;
		color: #fff;
	}

	.payment-popup--item.limited .status-disclaimer {
		background: #f39c12;
		color: #fff;
	}

	#payment__popup .payment-popup--item.limited .status-disclaimer,
	#payment__popup .payment-popup--item.unavailable .status-disclaimer {
		display: block
	}

	.title-status {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.payment-popup--item:first-child .cart__item-container {
		padding-top: 0px;
	}

	.mobile__more-button span.undo {
		mask: url("../images/undo.svg") no-repeat center center;
		-webkit-mask: url("../images/undo.svg") no-repeat center center;
		-webkit-mask-size: 70%;
		mask-size: 70%;
		background: #fff;
		width: 17px;
		height: 17px;
		display: inline-block;
	}

	.toast {
		width: 100% !important;
		left: 0px;
		bottom: 0;
		border-radius: 0px !important;
		max-width: 100%;
	}

	.button__ghost--primary.full-width-mobile-button {
		width: 100%;
		height: 34px !important;
	}

	.inputs-container {
		overflow: auto;
		height: calc(100% - 20px);
	}

	#location__popup .settings__content-block--actions div {
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0px 15px;
	}

	#statistics .settings__content--block.statistics.yearly .single-stat-picker {
		width: 100%;
	}

	.category-title.category-title-mobile {
		display: none;
		box-sizing: border-box;
		padding: 15px;
	}

	#statistics .settings__content--header .category-title {
		display: block;
	}

	.chart.monthly-chart .chart-disclaimer {
		margin: 20px 0px 0px 0px;
	}

	.single-tab {
		width: 50%;
	}

	.settings__content--header {
		padding: 0px 15px;
	}

	.settings__content--main {
		padding: 15px;
	}

	#statistics .settings__content--header {
		flex-wrap: wrap;
		height: auto;
		border-top: 1px solid rgba(223, 230, 233, 0.5);
	}

	#statistics .settings__content--header .category-title {
		width: 100%;
	}

	#statistics .settings__content--header .stats-picker {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#statistics .settings__content--header .single-stat-picker {
		width: 48%;
		margin: 0px;
	}

	.charts {
		flex-wrap: wrap;
		margin: 0px 0px 0px 0px;
	}

	.content--two-type .chart--pie {
		width: 100%;
		height: auto;
	}

	.stats-grouped-item {
		height: 90px;
		width: 47%;
	}

	.stats-grouped-item.static {
		margin: 0px 0px 30px 0px;
	}

	.stats-grouped-item:last-of-type {
		margin: 0px 0px 0px 0px;
	}

	.single-stat--block .visual {
		width: 50px;
		height: 50px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.single-stat--block .visual .raft-card__product-img {
		height: 100%;
	}

	.single-stat--block .visual .raft-card__product-img {
		max-width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.cart__items.trim__items.expand-cart-items {
		height: calc(100% - 85px) !important;
		padding-bottom: 20px;
	}

	.trigger-buttons {
		display: none;
	}

	.spinner-container {
		width: 100%;
		height: calc(100% - 61px);
	}

	.datepicker.d-from.datepicker--mobile.payment-picker input {
		padding-left: 15px !important;
		height: 40px !important;
	}

	.datepicker.d-from.datepicker--mobile.payment-picker .label__input {
		margin-top: 0px !important;
		width: 48%;
	}

	.datepicker.datepicker--mobile.statistics-datepicker .picker-form {
		width: 100%;
	}

	.datepicker.datepicker--mobile.order-history-datepicker .picker-form {
		width: 100%;
	}

	.datepicker.d-from.datepicker--mobile.payment-picker .picker-form {
		width: 48%;
		display: inline-block;
	}

	.datepicker--mobile input {
		padding-left: 50px !important;
	}

	.datepicker--mobile {
		margin: 0px 0px 0px 0px;
	}

	.datepicker--mobile:last-of-type {
		margin: 0px;
	}

	.datepicker--mobile .picker-form .rdtOpen .rdtPicker {
		display: flex;
		height: auto !important;
		box-shadow: none !important;
		background: #00000085 !important;
		border: 0px !important;
	}

	.datepicker--mobile .picker-form .rdtPicker {
		display: none;
		position: fixed;
		width: 100%;
		z-index: 99999 !important;
		background: transparent;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
		border: 1px solid #f9f9f9;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		height: 100%;
		align-items: center;
		justify-content: center;
	}

	.datepicker--mobile .picker-form .rdtDays {
		width: 400px;
		max-width: 400px;
		display: inline-block;
		background: #fff;
		border-radius: 4px;
		border: 1px solid #0000001c;
		height: 281px;
		box-sizing: border-box;
		padding: 10px;
	}

	.rdtPicker td.rdtDisabled,
	.rdtPicker td.rdtDisabled:hover {
		color: #cacaca !important;
		text-decoration: line-through;
	}

	.rdtPicker td.rdtActive,
	.rdtPicker td.rdtActive:hover {
		background-color: #ee582e !important;
		font-weight: bold;
	}

	.rdtPicker td.rdtToday:before {
		border-bottom: 7px solid #ee582e !important;
	}

	.rdtPicker td.rdtToday {
		font-weight: bold;
	}

	.datepicker--mobile .picker-form input {
		background: #fff !important;
		width: 100% !important;
		max-width: 100% !important;
		margin: 0px 0px 0px 0px;
		height: 50px;
		background: #F5F6FA;
		border: 1px solid #DFE6E9;
		border-radius: 5px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 0px 15px;
		font-size: 18px;
		font-weight: 400;
		color: #B2BEC3;
		-webkit-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}

	.datepicker--mobile .date-decorator {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		bottom: 0;
		margin: auto;
	}

	.raft-card__product-img .animated {
		height: 100%;
	}

	.popup__buttons--web {
		display: none;
	}

	.popup__buttons--mobile {
		display: flex !important;
	}

	.mobile__cart .cart__buttons .cart__pay-button {
		padding: 0px 0px 0px 12px;
	}

	.mobile__cart .cart__buttons .cart__pay-button .total {
		position: static;
		box-sizing: border-box;
		padding: 0px 20px;
		height: 42px;
		margin: 0px 0px 0px 0px;
		pointer-events: none;
	}

	.mobile__cart .cart__payments .cart__buttons .clear-total {
		background: #646e72;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	#location__popup .settings__content-block--actions {
		position: fixed;
		z-index: 1;
		background: #fff;
		padding: 0px;
		bottom: 0;
		width: 100%;
		margin: 0px 0px 0px 0px !important;
		height: 60px;
	}

	#location__popup .settings__content-block--actions .button__ghost--primary {
		margin: 0px;
	}

	#location__popup .product__popup-container .inputs {
		margin: 15px 0px 30px 0px;
		height: calc(100% - 70px);
	}

	#location__popup .settings__content-block--actions .button__ghost--borderless--primary.delete-location {
		width: 22px !important;
		margin: 0px 10px 0px 15px;
		height: 23px;
	}

	#settings .show-map {
		font-size: 12px;
		width: 35px;
	}

	.show-map span {
		margin: 0px 0px 0px 0px;
		width: 11px;
		height: 15px;
	}

	#settings--locations .settings__content-block--actions button {
		width: 100%;
	}

	#location__popup .product__popup-container {
		padding: 15px;
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	#location__popup .product__popup-container .inputs .input__holders--col {
		flex-wrap: wrap;
	}

	#location__popup .input__holders--col input {
		margin: 10px 0px 0px 0px;
	}

	.order-history .single-page-wrapper.order-history__wrapper .date__input--icon {
		height: 50px;
		line-height: 50px;
		top: 17px;
	}

	.date__input--icon {
		height: 60px;
		line-height: 60px;
	}

	.date__input--icon:after {
		line-height: 17px;
	}

	.mobile-categories {
		display: block;
	}

	.web-categories {
		display: none;
	}

	.cart--number-icon {
		display: flex;
		align-items: center;
		margin: 0px 0px 0px 20px;
		position: relative;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .cart--number-icon {
		margin: 0px 0px 0px 20px;
	}

	.mobile__cart .cart__items.trim__items {
		height: calc(100% - 171px);
	}

	.mobile__cart .cart__items.trim__items .cart__item-container:first-of-type {
		margin-top: 5px;
	}

	.mobile__cart .dropdown__container .dropdown__content {
		z-index: 2;
		top: 104px;
		-webkit-overflow-scrolling: touch;
	}

	.mobile__cart .dropdown__trigger {
		width: 100%;
		background: #f3f3f3;
		border: 0px;
		border-top: 1px solid #00000017;
		border-bottom: 1px solid #00000017;
	}

	.mobile__cart .dropdown__trigger h2 {
		color: #636E72;
		align-items: center;
	}

	.mobile__cart .dropdown__trigger h2:after {
		width: 10px;
		height: 10px;
		display: inline-block;
		background-color: #fff;
		line-height: 7px;
		border-radius: 50%;
		padding: 3px;
	}

	.mobile__cart .dropdown__container .dropdown__content {
		z-index: 2;
		top: 104px;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .user__corner-notifications .icon__counter--round {
		right: 7px !important;
	}

	.raft-card__product-img img {
		cursor: pointer;
	}

	.raft.expand .raft__categories--expander {
		transform: none;
	}

	#payment__popup .delivery_info .input__holders--col--halfed {
		display: none;
	}

	.chart--pie {
		width: 180px;
		height: 180px;
	}

	.stats-grouped {
		width: 100%;
	}

	.single-stat--block .visual {
		margin: 0px 20px 0px 0px;
	}

	.single-stat--block .textual {
		margin: 0px 0px 0px 0px;
	}

	.section__favorite .raft-card__favorited {
		left: 30px;
		top: 0px !important;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .section__favorite .raft-card__favorited {
		top: 3px !important;
	}

	#payment__popup .label__input {
		background: #fff;
	}

	#payment__popup .payment-item--mobile,
	.datepicker--mobile {
		display: flex !important;
	}

	#payment__popup .payment-item--mobile .label__input {
		display: flex !important;
		width: 48%;
	}

	#payment__popup .delivery_info .halfed {
		align-items: flex-start;
		flex-wrap: wrap;
	}

	.popup--noscroll {
		pointer-events: none !important;
		overflow: hidden !important;
		touch-action: none;
		position: fixed;
		z-index: -9999;
	}

	#mobile__payment-container .payment__main-content .collapsibles .collapsible__content p.payment-item--web {
		display: none !important;
	}

	.app-container.popup--hidden.mobile-route--overflow .settings__content--block {
		margin: 0px 0px 100px 0px;
	}

	.app-container.popup--hidden.mobile-route--overflow .raft--cart {
		display: none;
	}

	#settings {
		height: 100%;
	}

	.app-container {
		-webkit-overflow-scrolling: touch;
		padding: 60px 0px 0px 0px;
	}

	.app-container.popup--opened {
		-webkit-overflow-scrolling: touch;
	}

	.menu {
		box-shadow: none;
	}

	.raft.expand .slick-list .slick-slide {
		margin: 0px 0px 10px 0px !important;
		float: left;
	}

	.header {
		top: 0;
		z-index: 9997;
		padding: 0px;
		height: auto !important;
		overflow: visible !important;
	}

	.mobile__header {
		width: 100%;
		height: 61px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 1;
		background: #fff;
	}

	.mobile__header-search {
		width: 20px;
		height: 22px;
		background-image: url("../images/search_icon.svg");
		background-repeat: no-repeat;
	}

	.mobile__header-cart {
		width: 24px;
		height: 22px;
		background-image: url("../images/cart_icon.svg");
		background-repeat: no-repeat;
	}

	.mobile__header .header__menu {
		background: transparent;
	}

	.mobile__header .header__menu-icon {
		background: url("../images/menu_dots_mobile.svg") no-repeat center center;
		margin-bottom: 0px;
	}

	.mobile__header .header__menu h2 {
		color: #B2BEC3;
		font-size: 16px;
	}

	.mobile__header .header__logo {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		width: 62px;
	}

	.header__logo a {
		width: 54px;
		height: 31px;
	}

	.mobile__header .header__section-right {
		margin: 0px 20px 0px 0px;
	}

	.mobile__header .header__section-right button {
		margin: 0px 0px 0px 15px;
	}

	.mobile__header .header__section-right button:first-of-type {
		margin: 0px;
	}

	.main-content-component {
		margin: 0px;
		width: 100%;
		height: 100%;
	}

	#raft__slider .slick-list {
		overflow-x: hidden !important;
	}

	.columns {
		width: 100%;
		margin: 0px 0px 0px 0px;
		overflow: hidden;
	}

	.columns .raft__content {
		margin: 0px;
		width: 100%;
		padding: 0px 0px 0px 18px;
		background-position-y: 270px;
		background-size: calc(100% + 60px) 333px;
		height: 333px;
	}

	.raft {
		/* margin: 35px 0px 0px 0px; */
		margin: 0px 0px 10px 0px !important;
	}

	.disabled-buttons {
		display: none;
	}

	.raft:last-of-type {
		margin-bottom: 100px;
	}

	.columns .raft__content .raft-card {
		width: 180px;
		margin-bottom: 35px;
	}

	.raft__categories--right .web__more-button {
		display: none !important;
	}

	.mobile__more-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 37px;
		padding: 0px;
	}

	.mobile__more-button span {
		width: 18px;
		height: 9px;
		background: url("../images/more_icon.svg");
		background-repeat: no-repeat;
		background-position: center;
		display: inline-block;
		font-size: unset !important;
		max-width: 37px;
		background-size: 100%;
		margin: 0px 0px 0px 3px;
	}

	.cls-1 {
		fill: none;
	}

	.cls-2 {
		clip-path: url(#clip-path);
	}

	.cls-3 {
		fill: #fff;
	}

	.raft-illustration__boards {
		width: calc(100% + 40px);
	}

	.raft__categories {
		position: relative;
		height: 37px;
		background: #E8E9ED;
		padding: 0px 0px 0px 10px;
	}

	.raft__slider--decorator-end {
		right: 0px;
	}

	.raft__categories--left {
		width: 100%;
	}

	.raft__categories--left .category-title a .chevron {
		width: 6px;
		height: 8px;
	}

	.raft__categories--left .category-title a h2 {
		font-size: 14px;
		color: #2F3640;
	}

	.raft__categories--left .category-src.tabs {
		width: 100%;
		height: 100%;
		overflow: auto;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		margin: 0px 0px 0px 10px;
		box-sizing: border-box;
		padding: 0px 0px 0px 0px;
		border-bottom: 0px;
	}

	.raft.expand .next-button {
		left: unset;
	}

	.tabs-container-left {
		padding: 0px;
	}

	.tabs-buttons-list {
		width: calc(100% - 37px);
	}

	.raft__categories--left .category-src.tabs ul {
		display: flex;
		flex-wrap: nowrap;
	}

	.raft__categories--left .category-src.tabs ul li {
		white-space: nowrap;
		padding: 10px 12px 6px 12px;
	}

	.tabs ul li.active__sub {
		background-image: linear-gradient(to top, #ffffff, #E8E9ED);
		border-bottom: 2px solid #fa583a;
	}

	.tabs ul li.active__sub h3 {
		font-weight: normal;
		text-shadow: 0 0 0.1px #292929;
	}

	.raft__categories--left .category-src.tabs ul h3 {
		font-size: 14px;
	}

	.raft__categories--right {
		right: 0;
		top: 0;
		height: 100%;
	}

	.raft__categories--expander {
		height: 100%;
		left: -170px;
		width: 170px;
	}

	.raft__categories--right button {
		height: 100%;
		border-radius: 0px;
		font-size: 13px;
	}

	.menu {
		width: 100%;
		height: 100%;
		position: fixed;
		transform: translate3d(-100%, 0, 0) !important;
		will-change: transform;
		transform-style: preserve-3d;
		backface-visibility: hidden;
		transform: translate3d(0, 0, 0);
	}

	.slide__menu--on {
		transform: translate3d(100%, 0, 0);
		overflow: unset !important;
	}

	.menu__header--item.close-menu {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 50px;
		height: 50px;
	}

	.menu__header .close-menu .close__popup-button {
		width: 12px;
		height: 12px;
		padding: 8px;
	}

	.cart {
		display: none;
	}

	.mobile__cart {
		display: block;
		width: 75%;
		background: #fff;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 9997;
		height: 80%;
		transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
		will-change: transform;
	}

	.mobile__cart.hide--cart {
		transform: translate3d(0px, 100%, 0px);
		box-shadow: none;
	}

	.mobile__cart.show--cart {
		transform: translate3d(0px, 0px, 0px);
		box-shadow: 0 -17px 56px 0 rgba(0, 0, 0, 0.14);
		will-change: transform;
	}

	.cart__overflow .app-container {
		-webkit-overflow-scrolling: touch;
		overflow: auto;
	}

	.mobile-cart__header {
		width: 100%;
	}

	.full-width__cart-header--content {
		width: 100%;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 10px 10px 0px 12px;
	}

	.close-cart {
		width: 32px;
		height: 32px;
		position: absolute;
		left: 0;
		right: 0;
		top: -17px;
		margin: auto;
		background: #636e72;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2px;
		z-index: 1;
	}

	.mobile-cart__header-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.mobile-cart__header .cart__header-title h2 {
		font-size: 17px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		color: #636e72;
	}

	.mobile-cart__header .cart__save-button {
		height: 28px;
		font-size: 15px;
		padding: 0px 18px;
		text-transform: lowercase;
		border-radius: 3px;
	}

	.mobile-cart__header .cart__save-button .cart__save-button-icon {
		width: 10px;
		height: 10px;
	}

	.mobile-cart__header .close-car--icon {
		background: url("../images/chevron_up_icon.svg") no-repeat center center;
		width: 14px;
		height: 8px;
		transform: rotate(180deg);
	}

	.hide--cart .close-cart {
		display: none;
	}

	.mobile__cart .cart__items {
		width: 100%;
		height: calc(100% - 129px);
		left: 0px;
		margin: 0px;
		padding: 10px;
		box-sizing: border-box;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	.mobile__cart .cart__items .product__title {
		font-size: 18px;
	}

	.quantity-container__actions .plus,
	.quantity-container__actions .minus {
		width: 30px;
		height: 30px;
		font-size: 50% !important;
		line-height: 30px;
	}

	.quantity-container__actions .plus span {
		height: 15px;
		width: 15px !important;
	}

	.quantity-container__actions .minus span {
		height: 3px;
	}

	.quantity-container__actions .plus span,
	.quantity-container__actions .minus span {
		width: 27px;
		background-size: 100%;
		font-size: 18px !important;
	}

	.actions__value {
		width: 50px;
		height: 30px;
		font-size: 20px;
	}

	.mobile__cart .cart__payments {
		width: 100%;
		margin: 15px 0px 0px 0px;
		position: relative;
		float: left;
		box-sizing: border-box;
		padding: 0px 0px 30px 0px;
		background: #fff;
	}

	.remove__cart-item {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
	}

	.mobile__cart .cart__items::-webkit-scrollbar {
		display: none;
	}

	.mobile__cart .cart__items::-webkit-scrollbar-track {
		display: none;
	}

	.mobile__cart .cart__items::-webkit-scrollbar-thumb {
		display: none;
	}

	.mobile__cart .cart--positioned {
		box-sizing: border-box;
		padding: 0px 10px;
	}

	.mobile__cart .cart__buttons {
		position: relative;
		flex-direction: column;
		margin: 0px;
	}

	.mobile__cart .cart__buttons .total {
		height: 100%;
		position: absolute;
		border-radius: 4px;
		background: #e83d1e;
		box-sizing: border-box;
		padding: 0px 15px;
		display: flex;
		align-items: center;
		top: 0;
		left: 0;
		font-family: sans-serif;
	}

	.mobile__cart .cart__buttons .total h2 {
		font-size: 15px;
		font-weight: 600;
		color: #f5f6fa;
	}

	.mobile__cart .cart__buttons .cart__pay-button {
		width: 100% !important;
		height: 42px;
		display: flex;
		flex-flow: nowrap;
		justify-content: space-between;
		border-radius: 4px;
		flex-direction: row-reverse;
	}

	.mobile__cart .cart__buttons .cart__pay-button:before,
	.mobile__cart .cart__buttons .cart__pay-button:after {
		display: none;
	}

	.mobile__cart .cart__item-container:last-of-type {
		margin-bottom: 0px;
	}

	.mobile__cart .cart__payments .cart--gradient {
		height: 150px;
		padding: 0px 0px 100px 0px;
		bottom: 0;
		width: 100% !important;
		left: 0px;
	}

	.track-order.track-order--slideup {
		display: none;
	}

	.columns .raft #raft__slider .slick-arrow {
		display: none !important;
	}

	.raft-illustration {
		width: calc(100% + 5px);
		position: absolute;
		bottom: -44px;
		bottom: -62px;
		z-index: 0;
		height: 106px;
		white-space: nowrap;
		overflow: hidden;
	}

	.menu__header--user {
		display: none;
	}

	.mobile__menu-corner .avatar__button h2 {
		display: none;
	}

	.mobile__menu-corner .dropdowns ul li .icon__divider {
		margin: 0px 9px;
	}

	.all-products .favorite {
		position: relative;
		width: 176px;
		margin-right: 6px;
	}

	.all-products .favorite .raft-card {
		width: 100%;
	}

	.all-products .favorite:nth-child(5n + 6) {
		margin-right: 0px;
	}

	.component__header {
		box-sizing: border-box;
		padding: 10px;
	}

	.favorite .remove__cart-item {
		left: -9px;
		top: -16px;
	}

	.favorite .remove__cart-item button {
		width: 36px;
		height: 36px;
	}

	.all-products .raft-illustration {
		background-size: 100% 340px;
		width: calc(100% + 150px);
	}

	.menu__user-action--content {
		width: 100%;
		height: calc(100% - 70px);
	}

	.mobile__track-order {
		width: 100%;
		height: 100%;
		position: static;
		margin: 0px;
		background: transparent;
		flex-wrap: wrap;
	}

	.mobile__track-order .track-order__header {
		width: 100%;
		text-align: center;
		height: 41px;
		line-height: 41px;
		background: #dfe6e9;
	}

	.mobile__track-order .track-order__header h2 {
		font-size: 16px;
		font-weight: bold;
		color: #636e72;
	}

	.mobile__track-order .track-order__container {
		flex-wrap: wrap;
		height: calc(100% - 41px);
		align-items: center;
		justify-content: space-evenly;
		overflow: auto;
	}

	.mobile__track-order .track-order__container .track-order__item.status {
		flex-wrap: wrap;
		margin: 20px 0px 0px 0px;
		justify-content: space-between;
		width: 100%;
	}

	.mobile__track-order .track-order__container .status__item {
		width: calc(100% / 3);
		margin-bottom: 0px;
		justify-content: space-between;
	}

	.mobile__track-order .track-order__container .status__item.loading,
	.mobile__track-order .track-order__container .status__item.shipping {
		margin-bottom: 0px;
	}

	.mobile__track-order .track-order__container .status__item.packing:before {
		border-radius: 5px;
		width: 30px;
		height: 5px;
	}

	.mobile__track-order .track-order__container .status__item.packing:after {
		content: none;
	}

	.mobile__track-order .track-order__container .status__item.shipped:before {
		border-radius: 5px;
		width: 30px;
		height: 5px;
	}

	.mobile__track-order .track-order__container .status__item.shipped:after {
		content: none;
	}

	.mobile__track-order .track-order__item.prev__orders {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin-top: 40px;
		margin-bottom: 40px;
	}

	.date-decorator div {
		left: 15px;
	}

	.mobile__track-order .track-order__item.prev__orders button {
		width: 100%;
		height: 50px;
		text-align: center;
		font-size: 22px;
	}

	.mobile__track-order .timing {
		display: flex;
		white-space: nowrap;
		margin: 0px 0px 22px 0px;
	}

	.mobile__track-order .timing span {
		font-size: 22px;
	}

	.mobile__track-order .timing h2 {
		font-size: 22px;
	}

	.mobile__notifications {
		height: 100%;
	}

	.mobile__notifications .dropdown-src {
		position: static;
		height: 100%;
		padding: 0px;
		box-shadow: none;
	}

	.mobile__notifications .dropdown__header {
		justify-content: center;
		background: #dfe6e9;
	}

	.mobile__notifications #notifications .notifications-container {
		-webkit-overflow-scrolling: touch;
		background: #f5f6fa;
	}

	.mobile__notifications #notifications .notifications-container ul li:hover {
		background: none;
	}

	.mobile__notifications #notifications .notifications-container ul li h2 {
		font-size: 20px;
		width: 100%;
		display: block;
		display: -webkit-box;
		max-width: 100%;
		max-height: 50px;
		height: auto;
		margin: 0 auto;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.mobile__notifications #notifications .notifications-container ul li span {
		font-size: 15px;
		width: 100%;
	}

	.menu__content {
		-webkit-overflow-scrolling: touch;
		padding: 15px 70px;
	}

	.mobile__usersettings {
		width: 100%;
		height: 100%;
	}

	.mobile__usersettings .dropdown-src.user-dropdown {
		width: 100%;
		height: 100%;
		box-shadow: none;
		position: static;
		padding: 0px;
		margin: 0px;
		background: #f5f6fa;
	}

	.mobile__usersettings .dropdown-src.user-dropdown .track-order__header {
		width: 100%;
		height: 41px;
		line-height: 41px;
		background: #dfe6e9;
		text-align: center;
	}

	.mobile__usersettings .dropdown-src.user-dropdown .track-order__header h2 {
		font-size: 16px;
		font-weight: bold;
		color: #636e72;
	}

	.mobile__usersettings .notifications-container {
		box-sizing: border-box;
		padding: 35px 30px;
	}

	.mobile__usersettings .notifications-container ul li {
		margin: 0px 0px 35px 0px;
		height: 35px;
		display: flex;
		align-items: center;
		position: relative;
	}

	.mobile__usersettings .notifications-container ul li a {
		font-size: 20px;
		padding: 0px;
		height: 100%;
		display: block;
		box-sizing: border-box;
	}

	.mobile__usersettings .notifications-container ul li a:before {
		position: absolute;
		left: 0;
	}

	.mobile__usersettings .notifications-container ul li a:before {
		margin: 0px 24px 0px 0px;
	}

	.notifications-container ul li.notifications__list--settings a:before {
		width: 20px;
		height: 25px;
	}

	.notifications-container ul li.notifications__list--statistics a:before {
		width: 24px;
		height: 22px;
	}

	.notifications-container ul li.notifications__list--order-history a:before {
		width: 22px;
		height: 22px;
	}

	.notifications-container ul li.notifications__list--logout a:before {
		width: 30px;
		height: 24px;
		left: -1px;
	}

	#product--web {
		display: block;
		width: 100%;
		height: 100%;
	}

	#product--mobile {
		width: 100%;
		height: 100%;
	}

	#product--web .product__popup-container {
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	#product--web .product__popup-container::-webkit-scrollbar {
		display: none;
	}

	#product--web .product__popup-container::-webkit-scrollbar-track {
		display: none;
	}

	#product--web .product__popup-container::-webkit-scrollbar-thumb {
		display: none;
	}

	.settings__content-block--actions button {
		height: 38px;
	}

	.product__section--left {
		width: 32%;
	}

	.product__section--left .raft-card__product-img {
		width: 65%;
		height: 180px;
		max-height: 250px;
		margin: 0px 0px 0px 0px;
	}

	.popup__quantity {
		margin: 30px 0px 0px 0px;
	}

	#product--mobile .product__popup-container {
		width: 100%;
		height: 100%;
		max-height: 100%;
		margin: 0px;
		padding: 0px;
		border-radius: 0px;
		-webkit-overflow-scrolling: touch;
	}

	#product--mobile .product__popup-container::-webkit-scrollbar {
		display: none;
	}

	#product--mobile .product__popup-container::-webkit-scrollbar-track {
		display: none;
	}

	#product--mobile .product__popup-container::-webkit-scrollbar-thumb {
		display: none;
	}

	#product--mobile .product__popup--full {
		display: block;
		width: 100%;
		height: 100%;
		position: relative;
		padding: 25px 30px 0px 30px;
		top: 0px;
		overflow: auto;
	}

	.rc-tabs-top .rc-tabs-content {
		height: auto !important;
	}

	.mobile__product--decorator {
		width: 100%;
		height: 201px;
		background-position: top left;
		background-size: 100% 201px;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
	}

	.cls-1 {
		fill: #f5f6fa;
	}

	.product__popup-actions--top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		box-sizing: border-box;
		padding: 10px 15px 0px 15px;
	}

	.product__popup-actions--bottom {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 30px 0px;
		position: relative;
	}

	#product--mobile .product__section--left {
		height: 120px;
		background: none;
		width: auto;
		min-width: 170px;
		max-width: 200px;
	}

	#product--mobile .product__section--left .raft-card__product-img img {
		width: 100%;
		height: 100%;
	}

	#product--mobile .product__section-block-inside.product__section--top {
		display: block;
		box-sizing: border-box;
		padding: 0px 0px 0px 30px;
	}

	#product--mobile .recommended__header h2 {
		font-size: 14px;
		letter-spacing: 3px;
		white-space: nowrap;
	}

	#product--mobile .popup__product-title {
		margin: 5px 0px 0px 0px;
	}

	#product--mobile .popup__product-price {
		margin: 5px 0px 0px 0px;
		display: flex;
		align-items: center;
	}

	#product--mobile .product__popup-main {
		box-sizing: border-box;
		display: block;
	}

	#product--mobile .popup__product-title {
		font-size: 26px;
	}

	#product--mobile .product__popup--recommended {
		padding: 0px;
		margin-bottom: 150px;
		margin-top: 25px;
	}

	#product--mobile .product__popup--recommended .slick-slider {
		padding: 0px 0px 30px 0px;
	}

	#product--mobile .recommended__gradient--decorator {
		width: 70px;
		right: 0;
	}

	#product--mobile .product__popup--recommended .slick-arrow {
		display: none !important;
	}

	#product--mobile .product__section-block-inside.popup__quantity {
		position: fixed;
		bottom: 0px;
		left: 0;
		width: 100%;
		display: block;
		box-sizing: border-box;
		padding: 0px 30px 30px 30px;
		z-index: 9999;
		background: #f5f6fa;
	}

	#product--mobile .quantity-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: nowrap;
		margin: 0px 0px 15px 0px;
		position: relative;
	}

	#product--mobile .popup__quantity .quantity-container__actions {
		width: auto;
	}

	#product--mobile .quantity-container__actions .plus,
	#product--mobile .quantity-container__actions .minus {
		width: 40px !important;
		height: 40px;
		font-size: 50% !important;
		line-height: 40px !important;
	}

	#product--mobile .quantity-container__actions .plus span {
		width: 20px;
		height: 20px;
	}

	#product--mobile .quantity-container__actions .minus span {
		height: 5px;
	}

	#product--mobile .popup__quantity .quantity-container__values {
		text-align: right;
		margin: 0px;
	}

	#product--mobile .popup__quantity .actions__value {
		width: 60px;
		height: 40px;
		font-size: 20px;
	}

	#product--mobile .product__section-block-inside button {
		width: 100%;
	}

	#product--mobile .product__section-block-inside .icon-button--secondary {
		height: 50px;
		padding: 0px;
		position: relative;
	}

	.actions__gradient--decorator {
		width: 100%;
		height: 78px;
		position: absolute;
		left: 0;
		top: -76px;
		background-image: linear-gradient(to top, #f5f6fa, #f5f6fa00);
		pointer-events: none;
	}

	#web__payment-container {
		display: none;
	}

	#mobile__payment-container {
		display: block;
		z-index: 9999;
		width: 100%;
		height: 100%;
		margin: 0px 0px 0px 0px;
	}

	#mobile__payment-container .payment__cart {
		display: none;
	}

	#mobile__payment-container .product__popup--overlay {
		display: none;
	}

	#mobile__payment-container .product__popup {
		z-index: 9999;
	}

	_::-webkit-full-page-media,
	_:future,
	:root #mobile__payment-container .product__popup {
		z-index: 9999;
		position: fixed;
	}

	#mobile__payment-container .product__popup-container {
		padding: 0px;
		width: 100%;
		height: 100%;
		max-height: 100%;
		border-radius: 0px;
		background: #F5F6FA;
	}

	#mobile__payment-container .product__popup-container .close__popup-button {
		width: 18px;
		height: 18px;
	}

	#mobile__payment-container .product__popup-main {
		width: 100%;
		overflow: auto;
		height: calc(100% - 80px);
		-webkit-overflow-scrolling: touch;
	}

	#mobile__payment-container .payment__main-content {
		padding: 40px;
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	#mobile__payment-container .payment__main-content .collapsibles {
		width: 100%;
		height: auto;
		margin: 30px 0px 0px 0px;
	}

	#mobile__payment-container .payment__main-content .collapsibles .collapsible__triger {
		padding: 0px 0px 15px 0px;
		margin: 0px 0px 15px 0px;
	}

	#mobile__payment-container .payment__main-content .collapsibles .rc-collapse-content {
		background: transparent;
		padding: 0px;
	}

	#mobile__payment-container .payment__main-content .collapsibles .collapsible__content p {
		display: block !important;
		width: 100%;
		max-width: 100%;
		margin-bottom: 30px;
	}

	#mobile__payment-container .payment__main-content .halfed p input {
		width: 100% !important;
		max-width: 100% !important;
	}

	#mobile__payment-container .payment__main-content .collapsibles .rc-collapse-content p label {
		display: block;
		width: 100%;
	}

	#mobile__payment-container .payment__main-content .collapsibles .rc-collapse-content p input {
		display: block;
		width: 100%;
		max-width: 100%;
		background: #fff;
		margin: 10px 0px 0px 0px;
	}

	#mobile__payment-container #mobile__payment-footer {
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 0;
		box-sizing: border-box;
		padding: 20px;
		background: #f5f6fa;
		z-index: 9998;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#login__popup .product__popup-container {
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	.popup__block.social-login {
		flex-wrap: wrap;
	}

	.popup__block.social-login button {
		width: 100%;
		margin: 0px 0px 10px 0px;
	}

	.popup__block.social-login button:last-of-type {
		margin-bottom: 0px;
	}

	.single-page-wrapper {
		width: 90%;
		margin-top: 0px;
		padding-top: 40px;
		-webkit-overflow-scrolling: touch;
	}

	.single-page-wrapper:after {
		content: "";
		display: table;
		clear: both;
	}

	.order-history .date__input--holder {
		width: 360px;
	}

	.order-history .date__input--holder .date__input {
		width: 100%;
		height: 50px;
	}

	.order-history__wrapper .rc-collapse {
		padding: 0px;
	}

	.order-history__wrapper .component__content--footer {
		margin: 15px 0px 0px 0px;
	}

	.order-history__wrapper .component__content--footer .component__footer--block.cfb__total span,
	.order-history__wrapper .component__content--footer .component__footer--block.cfb__total h2 {
		width: 100%;
		text-align: center;
	}

	.order-history__wrapper .component__footer--block button {
		width: 100%;
		height: 48px;
		margin-top: 10px !important;
		margin-right: 0px !important;
	}

	.component__footer--block button:last-of-type {
		display: none;
	}

	.order-history__wrapper .short__detail-info {
		display: none;
	}

	.order-history__wrapper .orders__table--product {
		width: auto;
	}

	.order-history__wrapper .row_count {
		display: none;
	}

	.order-history__wrapper .orders__table .orders__table tbody tr {
		height: auto;
	}

	.order-history__wrapper .orders__table .orders__table tbody tr td {
		padding: 10px 0px;
	}

	.order-history__wrapper .mobile--alignment {
		text-align: center;
	}

	.order-history__wrapper .orders__table tbody tr .product__title {
		font-size: 16px;
	}

	.order-history__wrapper .orders__table tbody tr .product__subtitle {
		white-space: nowrap;
	}

	.order-history__wrapper .orders__table tbody tr:nth-child(even) {
		background: #f5f6fa;
	}

	.order-history__wrapper .chc--mobile {
		width: 100%;
		display: flex;
		margin: 20px 0px 30px 0px;
		justify-content: center;
	}

	.order-history__wrapper .chc--mobile .chc--rounded:first-child {
		margin-left: 0px;
	}

	#save-cart__popup .product__popup-container {
		width: 90%;
	}

	#save-cart__popup .popup__content {
		flex-wrap: wrap;
	}

	#save-cart__popup .popup__content label {
		width: 100%;
		margin: 0px 0px 10px 0px;
	}

	#save-cart__popup .popup__content input {
		width: 100%;
	}

	.mobile__cart.show--cart.expand__cart {
		height: calc(100% - 83px);
		z-index: 2;
	}

	.mobile__cart.show--cart.expand__cart .cart__items {
		height: calc(100% - 135px);
		padding-bottom: 20px;
	}

	.mobile__cart.show--cart.expand__cart .dropdown__content {
		top: 95px;
		z-index: 2;
		box-shadow: 0px 20px 81px 20px rgba(0, 0, 0, 0.19);
	}

	.mobile__cart .saved-cards--mobile {
		display: block;
	}

	.saved-cards--mobile {
		width: 100%;
		margin: 15px 0px 0px 0px;
	}

	.saved-cards--mobile .dropdown__trigger {
		border-radius: 0px;
	}

	.info__navigation--left {
		width: 100%;
	}

	.settings__avatar__image-holder {
		float: left;
	}

	.settings__avatar h2,
	.settings__avatar a {
		float: left;
		margin-left: 20px;
		width: calc(100% - 120px)
	}

	.settings__content--right {
		width: 100%;
		margin: 30px 0px 0px 0px;
	}

	.settings__content--block p {
		width: 100%;
		flex-wrap: wrap;
	}

	.switch__holder .switch {
		width: 43px !important;
	}

	.switch__holder slider.round {
		width: 43px;
	}

	#settings--basic-info .label__info {
		width: 200px;
		margin: 0px 0px 0px 0px;
	}

	.settings-children-container .settings__content--main p .label__info-src {
		font-size: 16px;
		font-weight: bold;
	}

	.settings__content--block .settings__content--main .button-holder .button__ghost--primary {
		margin: 0px 0px 0px 30px;
	}

	.settings-children-container .settings__content--main p .label__info-src {
		width: calc(100% - 200px);
	}

	#settings--basic-info .file-upload__holder .label__info {
		width: 200px;
	}

	.settings__content--block p .label__input {
		width: 48%;
		max-width: 100%;
	}

	.settings__content--main .file-upload__holder {
		margin-top: 15px;
		flex-wrap: wrap;
	}

	.settings__content--main .file-upload__holder .label__info {
		width: 100%;
		margin: 0px 0px 5px 0px;
	}

	.settings__content-block--actions {
		display: flex;
		justify-content: space-between;
	}

	.settings__content-block--actions button {
		margin: 0px;
		width: 100%;
		text-align: center;
	}

	.file-upload__button-container label {
		float: left;
		width: auto !important;
		margin: 0px !important;
	}

	.file-upload__button-container {
		width: calc(100% - 200px);
	}

	.settings__content--block {
		margin: 0px 0px 20px 0px;
	}

	.settings__content-block--actions {
		position: fixed;
		z-index: 1;
		bottom: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 20px;
		left: 0;
		background: #f5f6fa;
	}

	.datepicker--mobile {
		display: flex !important;
		width: 100%;
		justify-content: space-between;
	}

	.datepicker--mobile p {
		width: 100%;
		margin: 0px;
	}

	.datepicker--mobile .date__input {
		width: 100%;
	}

	#statistics .component__header {
		padding: 0px;
	}

	.settings__content--block.statistics {
		margin: 0px;
	}

	.order-history__wrapper .datepicker {
		width: 100%;
	}

	.order-history__wrapper .datepicker:first-child {
		margin: 0px 0px 10px 0px;
	}

	.datepicker.datepicker--mobile.statistics-datepicker {
		width: 49%;
	}

	.search--mobile {
		position: fixed;
		left: 0;
		top: 61px;
		background: #fff;
		width: 100%;
		transform: translate3d(0px, -61px, 0px);
		box-shadow: none;
		visibility: visible;
		opacity: 0;
		transition: all 0.2s ease-in-out;
	}

	.search--mobile.show--search {
		box-shadow: 0 28px 26px 0 #0000001a;
		visibility: visible;
		opacity: 1;
		transform: translate3d(0px, 0px, 0px);
	}

	.search--mobile input {
		width: 100%;
		height: 61px;
		border: 0px;
		font-size: 18px;
		color: #2F3640;
		box-sizing: border-box;
		padding: 0px 50px 0px 20px;
	}

	.search--mobile input::-webkit-input-placeholder {
		color: #B2BEC3;
		font-size: 18px;
		transition: all 0.2s ease-in-out;
	}

	.search--mobile input::-moz-placeholder {
		color: #B2BEC3;
		font-size: 18px;
	}

	.search--mobile input:-ms-input-placeholder {
		color: #B2BEC3;
		font-size: 18px;
	}

	.search--mobile input:-moz-placeholder {
		color: #B2BEC3;
		font-size: 18px;
	}

	.mobile__header-search--goback {
		background: url("../images/back_icon.svg");
		width: 15px;
		height: 15px;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 20px;
		margin: auto;
	}

	.mobile__header-search--delete {
		background: url("../images/delete_icon.svg");
		width: 15px;
		height: 15px;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 20px;
		margin: auto;
		display: none;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .mobile__header-search--delete {
		right: 10px;
	}

	.mobile__header-search--delete.show--delete {
		display: block;
	}

	#payment__popup .product__popup-container {
		padding: 0px;
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
		transform: scale(1);
		background: #F5F6FA;
	}

	#payment__popup .product__popup-container .product__popup-main {
		flex-wrap: wrap;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	#payment__popup .product__popup-container .product__popup-main::-webkit-scrollbar {
		display: none;
	}

	#payment__popup .product__popup-container .product__popup-main::-webkit-scrollbar-track {
		display: none;
	}

	#payment__popup .product__popup-container .product__popup-main::-webkit-scrollbar-thumb {
		display: none;
	}

	.transaction-popup--block {
		width: 100%;
	}

	.transaction-popup--block.transaction__register-helper {
		padding-top: 0px;
	}

	.transaction-processing h2 {
		margin-bottom: 0px;
	}

	.transaction__register-helper .insider {
		background: #fff;
		padding: 30px 0px;
	}

	.transaction__register-helper .insider div {
		width: 80%;
	}

	.transaction-popup--block p {
		flex-wrap: wrap;
	}

	.transaction-popup--block p label {
		width: 100%;
		margin: 0px 0px 10px 0px;
	}

	.transaction__register-helper .insider p .label__input {
		width: 100%;
	}

	.confirmation__popup .product__popup-container {
		width: 90%;
	}

	#confirmation__popup .popup__buttons {
		justify-content: space-between;
		padding: 0px 25px 25px 25px;
	}

	#confirmation__popup .popup__buttons button {
		width: 48%;
		text-align: center;
		margin: 0px;
	}

	#toast {
		box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.1);
		width: 100%;
		max-width: 100%;
		left: 0;
		bottom: 0;
		border-radius: 0px;
	}

	.cart--number-icon {
		display: flex;
		align-items: center;
		margin: 0px 0px 0px 20px;
		position: relative;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .cart--number-icon {
		margin: 0px 0px 0px 20px;
	}

	.cart--number-icon span {
		margin: 0px 0px 0px 2px;
		width: 20px;
		height: 20px;
		display: flex;
		justify-content: center;
		background: #f9583a;
		color: #fff;
		border-radius: 50%;
		line-height: 20px;
		font-size: 12px;
		position: absolute;
		top: -10px;
		right: -9px;
		font-family: sans-serif;
		font-weight: 400;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .cart--number-icon span {
		right: -4px;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .cart--number-icon {
		margin: 0px 0px 0px 5px;
	}

	.icon__counter--round {
		width: 20px;
		height: 20px;
		line-height: 20px;
		box-shadow: 3px 3px 4px 0 #0000001a;
		background-color: #fa583a;
		font-size: 11px;
		font-weight: bold;
		color: #ffffff;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		position: absolute;
		top: -8px;
		right: -3px;
		font-family: sans-serif;
		font-weight: 400;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .icon__counter--round {
		right: 6px;
		top: -9px;
	}

	.status__item .status__item--circle {
		width: 80px;
		height: 80px;
	}

	.status__item .status__item--circle span {
		width: 30px;
		height: 30px;
	}

	#favorites .component__header {
		padding: 20px 10px;
	}

	#favorites .component__content {
		margin-top: 0px;
	}

	.dropdown__content {
		-webkit-overflow-scrolling: touch;
	}

	#settings #footer {
		box-sizing: border-box;
		padding: 0px 15px;
		margin-bottom: 90px;
	}

	#footer {
		box-sizing: border-box;
		padding: 0px 15px;
	}

	#favorites #footer {
		margin-top: 30px;
	}

	#payment__popup .input__holders--col.payment-item--mobile .label__input {
		max-width: 48% !important;
		width: 48% !important;
	}

	.collapsible__content .input__holders--col {
		flex-wrap: wrap;
	}

	#payment__popup .collapsibles .rc-collapse-content .input__holders--col label {
		width: 100%;
		max-width: 100%;
	}

	#payment__popup .collapsibles .rc-collapse-content .input__holders--col .label__input {
		width: 48%;
		max-width: 100%;
		margin: 10px 0px 0px 0px;
	}

	.footer-block-description {
		width: 100%;
	}

	.reactour__helper.tour-component-mobile {
		width: 50%;
	}

	.cookies-container {
		flex-wrap: wrap;
		padding: 25px 25px;
	}

	.cookies-container .cookies-icon {
		display: none;
	}

	.cookies-container h2 {
		margin: 0px 0px 20px 0px;
	}

	.cookies-container .small-button--primary {
		padding: 0px 30px;
		font-size: 18px;
		height: 38px;
		line-height: 38px;
	}

	.raft.expand .raft__categories .tabs-container-right .tabs-buttons-list {
		width: calc(100% - 37px);
	}

	.raft.expand.button-expand .raft__categories .tabs-container-right .tabs-buttons-list {
		width: calc(100% - 37px);
	}

	#mobile__payment-container #location__popup .product__popup-container {
		padding: 15px;
		width: 100%;
		height: 100%;
		max-height: 100%;
		background: #fff;
	}

	#payment__popup .payment-item--web .datepicker input {
		background: #fff !important;
	}

	#payment__popup .address-chosen .input__holders--col .label__input {
		background: transparent;
		width: 100% !important;
		margin: 0px 0px 0px 0px;
	}

	#payment__popup .label__input {
		background: #fff;
	}

	.category-src.tabs.mobile {
		display: flex;
	}

	.category-src.tabs.web {
		display: none;
	}

	#product--mobile .popup__product-price span {
		margin: 0px 10px 0px 0px;
	}

	#product--mobile .product__section--left .animated-product-image-container .raft-card__product-img .raft-card__product-img-src {
		justify-content: center;
	}

	.raft-card__product-img.placeholder {
		height: 100%;
	}

	.cart-item-src:last-of-type {
		margin: 0px 0px 20px 0px;
	}

	.category-src.tabs.mobile {
		display: flex;
	}

	.category-src.tabs.web {
		display: none;
	}

	.raft-category-divider {
		display: none;
	}

	.order-status {
		margin: 0px 0px 0px 0px;
	}

	#payment__popup .payment-popup--item.unavailable .cart__product-img {
		opacity: 0.2;
	}

	#payment__popup .payment-popup--item.unavailable .product__title,
	#payment__popup .payment-popup--item.unavailable .product__subtitle,
	#payment__popup .payment-popup--item.unavailable .quantity-container__values h2,
	#payment__popup .payment-popup--item.unavailable .quantity-container__values h1 {
		color: #2f36403d;
	}

	.status-disclaimer {
		display: none;
		border-radius: 50px;
		height: 22px;
		line-height: 22px;
		padding: 0px 10px;
		font-size: 14px;
		font-weight: bold;
	}

	.payment-popup--item.unavailable .status-disclaimer {
		background: #FA583A;
		color: #fff;
	}

	.payment-popup--item.limited .status-disclaimer {
		background: #f39c12;
		color: #fff;
	}

	#payment__popup .payment-popup--item.limited .status-disclaimer,
	#payment__popup .payment-popup--item.unavailable .status-disclaimer {
		display: block
	}

	.title-status {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.payment-popup--item:first-child .cart__item-container {
		padding-top: 0px;
	}

	.mobile__more-button span.undo {
		mask: url("../images/undo.svg") no-repeat center center;
		mask-size: 70%;
		background: #fff;
		width: 17px;
		height: 17px;
		display: inline-block;
	}

	.toast {
		width: 100% !important;
		left: 0px;
		bottom: 0;
		border-radius: 0px !important;
		max-width: 100%;
	}

	.button__ghost--primary.full-width-mobile-button {
		width: 100%;
		height: 34px !important;
	}

	.inputs-container {
		overflow: auto;
		height: calc(100% - 20px);
	}

	#location__popup .settings__content-block--actions div {
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0px 15px;
	}

	#statistics .settings__content--block.statistics.yearly .single-stat-picker {
		width: 100%;
	}

	.category-title.category-title-mobile {
		display: none;
		box-sizing: border-box;
		padding: 15px;
	}

	#statistics .settings__content--header .category-title {
		display: block;
	}

	.chart.monthly-chart .chart-disclaimer {
		margin: 20px 0px 0px 0px;
	}

	.single-tab {
		width: 50%;
	}

	.settings__content--header {
		padding: 0px 15px;
	}

	.settings__content--main {
		padding: 15px;
	}

	#statistics .settings__content--header {
		flex-wrap: wrap;
		height: auto;
		border-top: 1px solid rgba(223, 230, 233, 0.5);
	}

	#statistics .settings__content--header .category-title {
		width: 100%;
	}

	#statistics .settings__content--header .stats-picker {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#statistics .settings__content--header .single-stat-picker {
		width: 48%;
		margin: 0px;
	}

	.charts {
		flex-wrap: wrap;
		margin: 0px 0px 0px 0px;
	}

	.content--two-type .chart--pie {
		width: 100%;
		height: auto;
	}

	.stats-grouped-item {
		height: 90px;
		width: 47%;
	}

	.stats-grouped-item.static {
		margin: 0px 0px 30px 0px;
	}

	.stats-grouped-item:last-of-type {
		margin: 0px 0px 0px 0px;
	}

	.single-stat--block .visual {
		width: 50px;
		height: 50px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.single-stat--block .visual .raft-card__product-img {
		height: 100%;
	}

	.single-stat--block .visual .raft-card__product-img {
		max-width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.cart__items.trim__items.expand-cart-items {
		height: calc(100% - 85px) !important;
		padding-bottom: 20px;
	}

	.trigger-buttons {
		display: none;
	}

	.spinner-container {
		width: 100%;
		height: calc(100% - 61px);
	}

	.datepicker.d-from.datepicker--mobile.payment-picker input {
		padding-left: 15px !important;
		height: 40px !important;
	}

	.datepicker.d-from.datepicker--mobile.payment-picker .label__input {
		margin-top: 0px !important;
		width: 48%;
	}

	.datepicker.datepicker--mobile.statistics-datepicker .picker-form {
		width: 100%;
	}

	.datepicker.datepicker--mobile.order-history-datepicker .picker-form {
		width: 100%;
	}

	.datepicker.d-from.datepicker--mobile.payment-picker .picker-form {
		width: 48%;
		display: inline-block;
	}

	.datepicker--mobile input {
		padding-left: 50px !important;
	}

	.datepicker--mobile {
		margin: 0px 0px 0px 0px;
	}

	.datepicker--mobile:last-of-type {
		margin: 0px;
	}

	.datepicker--mobile .picker-form .rdtOpen .rdtPicker {
		display: flex;
		height: auto !important;
		box-shadow: none !important;
		background: #00000085 !important;
		border: 0px !important;
	}

	.datepicker--mobile .picker-form .rdtPicker {
		display: none;
		position: fixed;
		width: 100%;
		z-index: 99999 !important;
		background: transparent;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
		border: 1px solid #f9f9f9;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		height: 100%;
		align-items: center;
		justify-content: center;
	}

	.datepicker--mobile .picker-form .rdtDays {
		width: 400px;
		max-width: 400px;
		display: inline-block;
		background: #fff;
		border-radius: 4px;
		border: 1px solid #0000001c;
		height: 281px;
		box-sizing: border-box;
		padding: 10px;
	}

	.rdtPicker td.rdtDisabled,
	.rdtPicker td.rdtDisabled:hover {
		color: #cacaca !important;
		text-decoration: line-through;
	}

	.rdtPicker td.rdtActive,
	.rdtPicker td.rdtActive:hover {
		background-color: #ee582e !important;
		font-weight: bold;
	}

	.rdtPicker td.rdtToday:before {
		border-bottom: 7px solid #ee582e !important;
	}

	.rdtPicker td.rdtToday {
		font-weight: bold;
	}

	.datepicker--mobile .picker-form input {
		background: #fff !important;
		width: 100% !important;
		max-width: 100% !important;
		margin: 0px 0px 0px 0px;
		height: 50px;
		background: #F5F6FA;
		border: 1px solid #DFE6E9;
		border-radius: 5px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 0px 15px;
		font-size: 18px;
		font-weight: 400;
		color: #B2BEC3;
		-webkit-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}

	.datepicker--mobile .date-decorator {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		bottom: 0;
		margin: auto;
	}

	.raft-card__product-img .animated {
		height: 100%;
	}

	.popup__buttons--web {
		display: none;
	}

	.popup__buttons--mobile {
		display: flex !important;
	}

	.mobile__cart .cart__buttons .cart__pay-button {
		padding: 0px 0px 0px 12px;
	}

	.mobile__cart .cart__buttons .cart__pay-button .total {
		position: static;
		box-sizing: border-box;
		padding: 0px 20px;
		height: 42px;
		margin: 0px 0px 0px 0px;
		pointer-events: none;
	}

	.mobile__cart .cart__payments .cart__buttons .clear-total {
		background: #646e72;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	#location__popup .settings__content-block--actions {
		position: fixed;
		z-index: 1;
		background: #fff;
		padding: 0px;
		bottom: 0;
		width: 100%;
		margin: 0px 0px 0px 0px !important;
		height: 60px;
	}

	#location__popup .settings__content-block--actions .button__ghost--primary {
		margin: 0px;
	}

	#location__popup .product__popup-container .inputs {
		margin: 15px 0px 30px 0px;
		height: calc(100% - 70px);
	}

	#location__popup .settings__content-block--actions .button__ghost--borderless--primary.delete-location {
		width: 22px !important;
		margin: 0px 10px 0px 15px;
		height: 23px;
	}

	#settings .show-map {
		font-size: 12px;
		width: 35px;
	}

	.show-map span {
		margin: 0px 0px 0px 0px;
		width: 11px;
		height: 15px;
	}

	#settings--locations .settings__content-block--actions button {
		width: 100%;
	}

	#location__popup .product__popup-container {
		padding: 15px;
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	#location__popup .product__popup-container .inputs .input__holders--col {
		flex-wrap: wrap;
	}

	#location__popup .input__holders--col input {
		margin: 10px 0px 0px 0px;
	}

	.order-history .single-page-wrapper.order-history__wrapper .date__input--icon {
		height: 50px;
		line-height: 50px;
		top: 17px;
	}

	.date__input--icon {
		height: 60px;
		line-height: 60px;
	}

	.date__input--icon:after {
		line-height: 17px;
	}

	.mobile-categories {
		display: block;
	}

	.web-categories {
		display: none;
	}

	.cart--number-icon {
		display: flex;
		align-items: center;
		margin: 0px 0px 0px 20px;
		position: relative;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .cart--number-icon {
		margin: 0px 0px 0px 20px;
	}

	.mobile__cart .cart__items.trim__items {
		height: calc(100% - 171px);
	}

	.mobile__cart .cart__items.trim__items .cart__item-container:first-of-type {
		margin-top: 5px;
	}

	.mobile__cart .dropdown__container .dropdown__content {
		z-index: 2;
		top: 104px;
		-webkit-overflow-scrolling: touch;
	}

	.mobile__cart .dropdown__trigger {
		width: 100%;
		background: #f3f3f3;
		border: 0px;
		border-top: 1px solid #00000017;
		border-bottom: 1px solid #00000017;
	}

	.mobile__cart .dropdown__trigger h2 {
		color: #636E72;
		align-items: center;
	}

	.mobile__cart .dropdown__trigger h2:after {
		width: 10px;
		height: 10px;
		display: inline-block;
		background-color: #fff;
		line-height: 7px;
		border-radius: 50%;
		padding: 3px;
	}

	.mobile__cart .dropdown__container .dropdown__content {
		z-index: 2;
		top: 104px;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .user__corner-notifications .icon__counter--round {
		right: 7px !important;
	}

	.raft-card__product-img img {
		cursor: pointer;
	}

	.raft.expand .raft__categories--expander {
		transform: none;
	}

	#payment__popup .delivery_info .input__holders--col--halfed {
		display: none;
	}

	.chart--pie {
		width: 180px;
		height: 180px;
	}

	.stats-grouped {
		width: 100%;
	}

	.single-stat--block .visual {
		margin: 0px 20px 0px 0px;
	}

	.single-stat--block .textual {
		margin: 0px 0px 0px 0px;
	}

	.section__favorite .raft-card__favorited {
		left: 30px;
		top: 0px !important;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .section__favorite .raft-card__favorited {
		top: 3px !important;
	}

	#payment__popup .label__input {
		background: #fff;
	}

	#payment__popup .payment-item--mobile,
	.datepicker--mobile {
		display: flex !important;
	}

	#payment__popup .payment-item--mobile .label__input {
		display: flex !important;
		width: 48%;
	}

	#payment__popup .delivery_info .halfed {
		align-items: flex-start;
		flex-wrap: wrap;
	}

	.popup--noscroll {
		pointer-events: none !important;
		overflow: hidden !important;
		touch-action: none;
		position: fixed;
		z-index: -9999;
	}

	#mobile__payment-container .payment__main-content .collapsibles .collapsible__content p.payment-item--web {
		display: none !important;
	}

	.app-container.popup--hidden.mobile-route--overflow .settings__content--block {
		margin: 0px 0px 100px 0px;
	}

	.app-container.popup--hidden.mobile-route--overflow .raft--cart {
		display: none;
	}

	#settings {
		height: 100%;
	}

	.app-container {
		-webkit-overflow-scrolling: touch;
	}

	.app-container.popup--opened {
		-webkit-overflow-scrolling: touch;
	}

	.menu {
		box-shadow: none;
	}

	.raft.expand .slick-list .slick-slide {
		margin: 0px 0px 10px 0px !important;
		float: left;
	}

	.header {
		top: 0;
		z-index: 9997;
		padding: 0px;
		height: auto !important;
		overflow: visible !important;
	}

	.mobile__header {
		width: 100%;
		height: 61px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 1;
		background: #fff;
	}

	.mobile__header-search {
		width: 20px;
		height: 22px;
		background-image: url("../images/search_icon.svg");
		background-repeat: no-repeat;
	}

	.mobile__header-cart {
		width: 24px;
		height: 22px;
		background-image: url("../images/cart_icon.svg");
		background-repeat: no-repeat;
	}

	.mobile__header .header__menu {
		background: transparent;
	}

	.mobile__header .header__menu-icon {
		background: url("../images/menu_dots_mobile.svg") no-repeat center center;
		margin-bottom: 0px;
	}

	.mobile__header .header__menu h2 {
		color: #B2BEC3;
		font-size: 16px;
	}

	.mobile__header .header__logo {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		width: 62px;
	}

	.header__logo a {
		width: 54px;
		height: 31px;
	}

	.mobile__header .header__section-right {
		margin: 0px 20px 0px 0px;
	}

	.mobile__header .header__section-right button {
		margin: 0px 0px 0px 15px;
	}

	.mobile__header .header__section-right button:first-of-type {
		margin: 0px;
	}

	.main-content-component {
		margin: 0px;
		width: 100%;
		height: 100%;
	}

	#raft__slider .slick-list {
		overflow-x: hidden !important;
	}

	.columns {
		width: 100%;
		margin: 0px 0px 0px 0px;
		overflow: hidden;
	}

	.columns .raft__content {
		margin: 0px;
		width: 100%;
	}

	.raft {
		margin: 10px 0px 0px 0px;
	}

	.raft:last-of-type {
		margin-bottom: 100px;
	}

	.columns .raft__content .raft-card {
		width: 180px;
	}

	.raft__categories--right .web__more-button {
		display: none !important;
	}

	.mobile__more-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 37px;
		padding: 0px;
	}

	.mobile__more-button span {
		width: 18px;
		height: 9px;
		background: url("../images/more_icon.svg");
		background-repeat: no-repeat;
		background-position: center;
		display: inline-block;
		font-size: unset !important;
		max-width: 37px;
		background-size: 100%;
		margin: 0px 0px 0px 3px;
	}

	.cls-1 {
		fill: none;
	}

	.cls-2 {
		clip-path: url(#clip-path);
	}

	.cls-3 {
		fill: #fff;
	}

	.raft-illustration__boards {
		width: calc(100% + 40px);
	}

	.raft__categories {
		position: relative;
		height: 37px;
		background: #E8E9ED;
		padding: 0px 0px 0px 10px;
	}

	.raft__slider--decorator-end {
		right: 0px;
	}

	.raft__categories--left {
		width: 100%;
	}

	.raft__categories--left .category-title a .chevron {
		width: 6px;
		height: 8px;
	}

	.raft__categories--left .category-title a h2 {
		font-size: 14px;
		color: #2F3640;
	}

	.raft__categories--left .category-src.tabs {
		width: 100%;
		height: 100%;
		overflow: auto;
		overflow-y: hidden;
		-webkit-overflow-scrolling: touch;
		margin: 0px 0px 0px 10px;
		box-sizing: border-box;
		padding: 0px 0px 0px 0px;
		border-bottom: 0px;
	}

	.raft.expand .next-button {
		left: unset;
	}

	.tabs-container-left {
		padding: 0px;
	}

	.tabs-buttons-list {
		width: calc(100% - 37px);
	}

	.raft__categories--left .category-src.tabs ul {
		display: flex;
		flex-wrap: nowrap;
	}

	.raft__categories--left .category-src.tabs ul li {
		white-space: nowrap;
		padding: 10px 12px 6px 12px;
	}

	.tabs ul li.active__sub {
		background-image: linear-gradient(to top, #ffffff, #E8E9ED);
		border-bottom: 2px solid #fa583a;
	}

	.tabs ul li.active__sub h3 {
		font-weight: normal;
		text-shadow: 0 0 0.1px #292929;
	}

	.raft__categories--left .category-src.tabs ul h3 {
		font-size: 14px;
	}

	.raft__categories--right {
		right: 0;
		top: 0;
		height: 100%;
	}

	.raft__categories--expander {
		height: 100%;
		left: -170px;
		width: 170px;
	}

	.raft__categories--right button {
		height: 100%;
		border-radius: 0px;
		font-size: 13px;
	}

	.menu {
		width: 100%;
		height: 100%;
		position: fixed;
		transform: translate3d(-100%, 0, 0) !important;
		will-change: transform;
		transform-style: preserve-3d;
		backface-visibility: hidden;
		transform: translate3d(0, 0, 0);
	}

	.slide__menu--on {
		transform: translate3d(100%, 0, 0);
		overflow: unset !important;
	}

	.menu__header--item.close-menu {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 50px;
		height: 50px;
	}

	.menu__header .close-menu .close__popup-button {
		width: 12px;
		height: 12px;
		padding: 8px;
	}

	.cart {
		display: none;
	}

	.mobile__cart {
		display: block;
		width: 75%;
		background: #fff;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		z-index: 9997;
		height: 80%;
		transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
		will-change: transform;
	}

	.mobile__cart.hide--cart {
		transform: translate3d(0px, 100%, 0px);
		box-shadow: none;
	}

	.mobile__cart.show--cart {
		transform: translate3d(0px, 0px, 0px);
		box-shadow: 0 -17px 56px 0 rgba(0, 0, 0, 0.14);
		will-change: transform;
	}

	.cart__overflow .app-container {
		-webkit-overflow-scrolling: touch;
		overflow: auto;
	}

	.mobile-cart__header {
		width: 100%;
	}

	.full-width__cart-header--content {
		width: 100%;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		padding: 10px 10px 0px 12px;
	}

	.close-cart {
		width: 32px;
		height: 32px;
		position: absolute;
		left: 0;
		right: 0;
		top: -17px;
		margin: auto;
		background: #636e72;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2px;
		z-index: 1;
	}

	.mobile-cart__header-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.mobile-cart__header .cart__header-title h2 {
		font-size: 17px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		color: #636e72;
	}

	.mobile-cart__header .cart__save-button {
		height: 28px;
		font-size: 15px;
		padding: 0px 18px;
		text-transform: lowercase;
		border-radius: 3px;
	}

	.mobile-cart__header .cart__save-button .cart__save-button-icon {
		width: 10px;
		height: 10px;
	}

	.mobile-cart__header .close-car--icon {
		background: url("../images/chevron_up_icon.svg") no-repeat center center;
		width: 14px;
		height: 8px;
		transform: rotate(180deg);
	}

	.hide--cart .close-cart {
		display: none;
	}

	.mobile__cart .cart__items {
		width: 100%;
		height: calc(100% - 129px);
		left: 0px;
		margin: 0px;
		padding: 10px;
		box-sizing: border-box;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	.mobile__cart .cart__items .product__title {
		font-size: 18px;
	}

	.quantity-container__actions .plus,
	.quantity-container__actions .minus {
		width: 30px;
		height: 30px;
		font-size: 50% !important;
		line-height: 30px;
	}

	.quantity-container__actions .plus span {
		height: 15px;
		width: 15px !important;
	}

	.quantity-container__actions .minus span {
		height: 3px;
	}

	.quantity-container__actions .plus span,
	.quantity-container__actions .minus span {
		width: 27px;
		background-size: 100%;
		font-size: 18px !important;
	}

	.actions__value {
		width: 50px;
		height: 30px;
		font-size: 20px;
	}

	.mobile__cart .cart__payments {
		width: 100%;
		margin: 15px 0px 0px 0px;
		position: relative;
		float: left;
		box-sizing: border-box;
		padding: 0px 0px 30px 0px;
		background: #fff;
	}

	.remove__cart-item {
		opacity: 1;
		visibility: visible;
		transform: scale(1);
	}

	.mobile__cart .cart__items::-webkit-scrollbar {
		display: none;
	}

	.mobile__cart .cart__items::-webkit-scrollbar-track {
		display: none;
	}

	.mobile__cart .cart__items::-webkit-scrollbar-thumb {
		display: none;
	}

	.mobile__cart .cart--positioned {
		box-sizing: border-box;
		padding: 0px 10px;
	}

	.mobile__cart .cart__buttons {
		position: relative;
		flex-direction: column;
		margin: 0px;
	}

	.mobile__cart .cart__buttons .total {
		height: 100%;
		position: absolute;
		border-radius: 4px;
		background: #e83d1e;
		box-sizing: border-box;
		padding: 0px 15px;
		display: flex;
		align-items: center;
		top: 0;
		left: 0;
		font-family: sans-serif;
	}

	.mobile__cart .cart__buttons .total h2 {
		font-size: 15px;
		font-weight: 600;
		color: #f5f6fa;
	}

	.mobile__cart .cart__buttons .cart__pay-button {
		width: 100% !important;
		height: 42px;
		display: flex;
		flex-flow: nowrap;
		justify-content: space-between;
		border-radius: 4px;
		flex-direction: row-reverse;
	}

	.mobile__cart .cart__buttons .cart__pay-button:before,
	.mobile__cart .cart__buttons .cart__pay-button:after {
		display: none;
	}

	.mobile__cart .cart__item-container:last-of-type {
		margin-bottom: 0px;
	}

	.mobile__cart .cart__payments .cart--gradient {
		height: 150px;
		padding: 0px 0px 100px 0px;
		bottom: 0;
		width: 100% !important;
		left: 0px;
	}

	.track-order.track-order--slideup {
		display: none;
	}

	.columns .raft #raft__slider .slick-arrow {
		display: none !important;
	}

	.raft-illustration {
		width: calc(100% + 5px);
		position: absolute;
		bottom: -44px;
		bottom: -62px;
		z-index: 0;
		height: 106px;
		white-space: nowrap;
		overflow: hidden;
	}

	.menu__header--user {
		display: none;
	}

	.mobile__menu-corner .avatar__button h2 {
		display: none;
	}

	.mobile__menu-corner .dropdowns ul li .icon__divider {
		margin: 0px 9px;
	}

	.all-products .favorite {
		position: relative;
		width: 176px;
		margin-right: 6px;
	}

	.all-products .favorite .raft-card {
		width: 100%;
	}

	.all-products .favorite:nth-child(5n + 6) {
		margin-right: 0px;
	}

	.component__header {
		box-sizing: border-box;
		padding: 10px;
	}

	.favorite .remove__cart-item {
		left: -9px;
		top: -16px;
	}

	.favorite .remove__cart-item button {
		width: 36px;
		height: 36px;
	}

	.all-products .raft-illustration {
		background-size: 100% 340px;
		width: calc(100% + 150px);
	}

	.menu__user-action--content {
		width: 100%;
		height: calc(100% - 70px);
	}

	.mobile__track-order {
		width: 100%;
		height: 100%;
		position: static;
		margin: 0px;
		background: transparent;
		flex-wrap: wrap;
	}

	.mobile__track-order .track-order__header {
		width: 100%;
		text-align: center;
		height: 41px;
		line-height: 41px;
		background: #dfe6e9;
	}

	.mobile__track-order .track-order__header h2 {
		font-size: 16px;
		font-weight: bold;
		color: #636e72;
	}

	.mobile__track-order .track-order__container {
		flex-wrap: wrap;
		height: calc(100% - 41px);
		align-items: center;
		justify-content: space-evenly;
		overflow: auto;
	}

	.mobile__track-order .track-order__container .track-order__item.status {
		flex-wrap: wrap;
		margin: 20px 0px 0px 0px;
		justify-content: space-between;
		width: 100%;
	}

	.mobile__track-order .track-order__container .status__item {
		width: calc(100% / 3);
		margin-bottom: 0px;
		justify-content: space-between;
	}

	.mobile__track-order .track-order__container .status__item.loading,
	.mobile__track-order .track-order__container .status__item.shipping {
		margin-bottom: 0px;
	}

	.mobile__track-order .track-order__container .status__item.packing:before {
		top: 0px;
		bottom: 0;
	}

	.mobile__track-order .track-order__container .status__item.packing:after {
		top: 0px;
		bottom: 0;
	}

	.mobile__track-order .track-order__container .status__item.shipped:before {
		top: 0px;
		bottom: 0;
	}

	.mobile__track-order .track-order__container .status__item.shipped:after {
		top: 0px;
		bottom: 0;
	}

	.mobile__track-order .track-order__item.prev__orders {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		width: 100%;
		margin-top: 40px;
		margin-bottom: 40px;
	}

	.mobile__track-order .track-order__item.prev__orders button {
		width: 100%;
		height: 50px;
		text-align: center;
		font-size: 22px;
	}

	.mobile__track-order .timing {
		display: flex;
		white-space: nowrap;
		margin: 0px 0px 22px 0px;
	}

	.mobile__track-order .timing span {
		font-size: 22px;
	}

	.mobile__track-order .timing h2 {
		font-size: 22px;
	}

	.mobile__notifications {
		height: 100%;
	}

	.mobile__notifications .dropdown-src {
		position: static;
		height: 100%;
		padding: 0px;
		box-shadow: none;
	}

	.mobile__notifications .dropdown__header {
		justify-content: center;
		background: #dfe6e9;
	}

	.mobile__notifications #notifications .notifications-container {
		-webkit-overflow-scrolling: touch;
		background: #f5f6fa;
	}

	.mobile__notifications #notifications .notifications-container ul li:hover {
		background: none;
	}

	.mobile__notifications #notifications .notifications-container ul li h2 {
		font-size: 20px;
		width: 100%;
		display: block;
		display: -webkit-box;
		max-width: 100%;
		max-height: 50px;
		height: auto;
		margin: 0 auto;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.mobile__notifications #notifications .notifications-container ul li span {
		font-size: 15px;
		width: 100%;
	}

	.menu__content {
		-webkit-overflow-scrolling: touch;
		padding: 15px 70px;
	}

	.mobile__usersettings {
		width: 100%;
		height: 100%;
	}

	.mobile__usersettings .dropdown-src.user-dropdown {
		width: 100%;
		height: 100%;
		box-shadow: none;
		position: static;
		padding: 0px;
		margin: 0px;
		background: #f5f6fa;
	}

	.mobile__usersettings .dropdown-src.user-dropdown .track-order__header {
		width: 100%;
		height: 41px;
		line-height: 41px;
		background: #dfe6e9;
		text-align: center;
	}

	.mobile__usersettings .dropdown-src.user-dropdown .track-order__header h2 {
		font-size: 16px;
		font-weight: bold;
		color: #636e72;
	}

	.mobile__usersettings .notifications-container {
		box-sizing: border-box;
		padding: 35px 30px;
	}

	.mobile__usersettings .notifications-container ul li {
		margin: 0px 0px 35px 0px;
		height: 35px;
		display: flex;
		align-items: center;
		position: relative;
	}

	.mobile__usersettings .notifications-container ul li a {
		font-size: 20px;
		padding: 0px;
		height: 100%;
		display: block;
		box-sizing: border-box;
	}

	.mobile__usersettings .notifications-container ul li a:before {
		position: absolute;
		left: 0;
	}

	.mobile__usersettings .notifications-container ul li a:before {
		margin: 0px 24px 0px 0px;
	}

	.notifications-container ul li.notifications__list--settings a:before {
		width: 20px;
		height: 25px;
	}

	.notifications-container ul li.notifications__list--statistics a:before {
		width: 24px;
		height: 22px;
	}

	.notifications-container ul li.notifications__list--order-history a:before {
		width: 22px;
		height: 22px;
	}

	.notifications-container ul li.notifications__list--logout a:before {
		width: 30px;
		height: 24px;
		left: -1px;
	}

	#product--web {
		display: block;
		width: 100%;
		height: 100%;
	}

	#product--mobile {
		width: 100%;
		height: 100%;
	}

	#product--web .product__popup-container {
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	#product--web .product__popup-container::-webkit-scrollbar {
		display: none;
	}

	#product--web .product__popup-container::-webkit-scrollbar-track {
		display: none;
	}

	#product--web .product__popup-container::-webkit-scrollbar-thumb {
		display: none;
	}

	.settings__content-block--actions button {
		height: 38px;
	}

	.product__section--left {
		width: 32%;
	}

	.product__section--left .raft-card__product-img {
		width: 65%;
		height: 180px;
		max-height: 250px;
		margin: 0px 0px 0px 0px;
	}

	.popup__quantity {
		margin: 30px 0px 0px 0px;
	}

	#product--mobile .product__popup-container {
		width: 100%;
		height: 100%;
		max-height: 100%;
		margin: 0px;
		padding: 0px;
		border-radius: 0px;
		-webkit-overflow-scrolling: touch;
	}

	#product--mobile .product__popup-container::-webkit-scrollbar {
		display: none;
	}

	#product--mobile .product__popup-container::-webkit-scrollbar-track {
		display: none;
	}

	#product--mobile .product__popup-container::-webkit-scrollbar-thumb {
		display: none;
	}

	#product--mobile .product__popup--full {
		display: block;
		width: 100%;
		height: 100%;
		position: relative;
		padding: 25px 30px 0px 30px;
		top: 0px;
		overflow: auto;
	}

	.rc-tabs-top .rc-tabs-content {
		height: auto !important;
	}

	.mobile__product--decorator {
		width: 100%;
		height: 201px;
		background-position: top left;
		background-size: 100% 201px;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
	}

	.cls-1 {
		fill: #f5f6fa;
	}

	.product__popup-actions--top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		box-sizing: border-box;
		padding: 10px 15px 0px 15px;
	}

	.product__popup-actions--bottom {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 30px 0px;
		position: relative;
	}

	#product--mobile .product__section--left {
		height: 120px;
		background: none;
		width: auto;
		min-width: 170px;
		max-width: 200px;
	}

	#product--mobile .product__section--left .raft-card__product-img img {
		width: 100%;
		height: 100%;
	}

	#product--mobile .product__section-block-inside.product__section--top {
		display: block;
		box-sizing: border-box;
		padding: 0px 0px 0px 30px;
	}

	#product--mobile .recommended__header h2 {
		font-size: 14px;
		letter-spacing: 3px;
		white-space: nowrap;
	}

	#product--mobile .popup__product-title {
		margin: 5px 0px 0px 0px;
	}

	#product--mobile .popup__product-price {
		margin: 5px 0px 0px 0px;
		display: flex;
		align-items: center;
	}

	#product--mobile .product__popup-main {
		box-sizing: border-box;
		display: block;
	}

	#product--mobile .popup__product-title {
		font-size: 26px;
	}

	#product--mobile .product__popup--recommended {
		padding: 0px;
		margin-bottom: 150px;
		margin-top: 25px;
	}

	#product--mobile .product__popup--recommended .slick-slider {
		padding: 0px 0px 30px 0px;
	}

	#product--mobile .recommended__gradient--decorator {
		width: 70px;
		right: 0;
	}

	#product--mobile .product__popup--recommended .slick-arrow {
		display: none !important;
	}

	#product--mobile .product__section-block-inside.popup__quantity {
		position: fixed;
		bottom: 0px;
		left: 0;
		width: 100%;
		display: block;
		box-sizing: border-box;
		padding: 0px 30px 30px 30px;
		z-index: 9999;
		background: #f5f6fa;
	}

	#product--mobile .quantity-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: nowrap;
		margin: 0px 0px 15px 0px;
		position: relative;
	}

	#product--mobile .popup__quantity .quantity-container__actions {
		width: auto;
	}

	#product--mobile .quantity-container__actions .plus,
	#product--mobile .quantity-container__actions .minus {
		width: 40px !important;
		height: 40px;
		font-size: 50% !important;
		line-height: 40px !important;
	}

	#product--mobile .quantity-container__actions .plus span {
		width: 20px;
		height: 20px;
	}

	#product--mobile .quantity-container__actions .minus span {
		height: 5px;
	}

	#product--mobile .popup__quantity .quantity-container__values {
		text-align: right;
		margin: 0px;
	}

	#product--mobile .popup__quantity .actions__value {
		width: 60px;
		height: 40px;
		font-size: 20px;
	}

	#product--mobile .product__section-block-inside button {
		width: 100%;
	}

	#product--mobile .product__section-block-inside .icon-button--secondary {
		height: 50px;
		padding: 0px;
		position: relative;
	}

	.actions__gradient--decorator {
		width: 100%;
		height: 78px;
		position: absolute;
		left: 0;
		top: -76px;
		background-image: linear-gradient(to top, #f5f6fa, #f5f6fa00);
		pointer-events: none;
	}

	#web__payment-container {
		display: none;
	}

	#mobile__payment-container {
		display: block;
		z-index: 9999;
		width: 100%;
		height: 100%;
		margin: 0px 0px 0px 0px;
	}

	#mobile__payment-container .payment__cart {
		display: none;
	}

	#mobile__payment-container .product__popup--overlay {
		display: none;
	}

	#mobile__payment-container .product__popup {
		z-index: 9999;
	}

	_::-webkit-full-page-media,
	_:future,
	:root #mobile__payment-container .product__popup {
		z-index: 9999;
		position: fixed;
	}

	#mobile__payment-container .product__popup-container {
		padding: 0px;
		width: 100%;
		height: 100%;
		max-height: 100%;
		border-radius: 0px;
		background: #F5F6FA;
	}

	#mobile__payment-container .product__popup-container .close__popup-button {
		width: 18px;
		height: 18px;
	}

	#mobile__payment-container .product__popup-main {
		width: 100%;
		overflow: auto;
		height: calc(100% - 80px);
		-webkit-overflow-scrolling: touch;
	}

	#mobile__payment-container .payment__main-content {
		padding: 40px;
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	#mobile__payment-container .payment__main-content .collapsibles {
		width: 100%;
		height: auto;
		margin: 30px 0px 0px 0px;
	}

	#mobile__payment-container .payment__main-content .collapsibles .collapsible__triger {
		padding: 0px 0px 15px 0px;
		margin: 0px 0px 15px 0px;
	}

	#mobile__payment-container .payment__main-content .collapsibles .rc-collapse-content {
		background: transparent;
		padding: 0px;
	}

	#mobile__payment-container .payment__main-content .collapsibles .collapsible__content p {
		display: block !important;
		width: 100%;
		max-width: 100%;
		margin-bottom: 30px;
	}

	#mobile__payment-container .payment__main-content .halfed p input {
		width: 100% !important;
		max-width: 100% !important;
	}

	#mobile__payment-container .payment__main-content .collapsibles .rc-collapse-content p label {
		display: block;
		width: 100%;
	}

	#mobile__payment-container .payment__main-content .collapsibles .rc-collapse-content p input {
		display: block;
		width: 100%;
		max-width: 100%;
		background: #fff;
		margin: 10px 0px 0px 0px;
	}

	#mobile__payment-container #mobile__payment-footer {
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 0;
		box-sizing: border-box;
		padding: 20px;
		background: #f5f6fa;
		z-index: 9998;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#login__popup .product__popup-container {
		width: 100%;
		height: 100%;
		max-height: 100%;
	}

	.popup__block.social-login {
		flex-wrap: wrap;
	}

	.popup__block.social-login button {
		width: 100%;
		margin: 0px 0px 10px 0px;
	}

	.popup__block.social-login button:last-of-type {
		margin-bottom: 0px;
	}

	.single-page-wrapper {
		width: 90%;
		margin-top: 0px;
		padding-top: 40px;
		-webkit-overflow-scrolling: touch;
	}

	.single-page-wrapper:after {
		content: "";
		display: table;
		clear: both;
	}

	.order-history .date__input--holder {
		width: 360px;
	}

	.order-history .date__input--holder .date__input {
		width: 100%;
		height: 50px;
	}

	.order-history__wrapper .rc-collapse {
		padding: 0px;
	}

	.order-history__wrapper .component__content--footer {
		margin: 15px 0px 0px 0px;
	}

	.order-history__wrapper .component__content--footer .component__footer--block.cfb__total span,
	.order-history__wrapper .component__content--footer .component__footer--block.cfb__total h2 {
		width: 100%;
		text-align: center;
	}

	.order-history__wrapper .component__footer--block button {
		width: 100%;
		height: 48px;
		margin-top: 10px !important;
		margin-right: 0px !important;
	}

	.component__footer--block button:last-of-type {
		display: none;
	}

	.order-history__wrapper .short__detail-info {
		display: none;
	}

	.order-history__wrapper .orders__table--product {
		width: auto;
	}

	.order-history__wrapper .row_count {
		display: none;
	}

	.order-history__wrapper .orders__table .orders__table tbody tr {
		height: auto;
	}

	.order-history__wrapper .orders__table .orders__table tbody tr td {
		padding: 10px 0px;
	}

	.order-history__wrapper .mobile--alignment {
		text-align: center;
	}

	.order-history__wrapper .orders__table tbody tr .product__title {
		font-size: 16px;
	}

	.order-history__wrapper .orders__table tbody tr .product__subtitle {
		white-space: nowrap;
	}

	.order-history__wrapper .orders__table tbody tr:nth-child(even) {
		background: #f5f6fa;
	}

	.order-history__wrapper .chc--mobile {
		width: 100%;
		display: flex;
		margin: 20px 0px 30px 0px;
		justify-content: center;
	}

	.order-history__wrapper .chc--mobile .chc--rounded:first-child {
		margin-left: 0px;
	}

	#save-cart__popup .product__popup-container {
		width: 90%;
	}

	#save-cart__popup .popup__content {
		flex-wrap: wrap;
	}

	#save-cart__popup .popup__content label {
		width: 100%;
		margin: 0px 0px 10px 0px;
	}

	#save-cart__popup .popup__content input {
		width: 100%;
	}

	.mobile__cart.show--cart.expand__cart {
		height: calc(100% - 83px);
		z-index: 2;
	}

	.mobile__cart.show--cart.expand__cart .cart__items {
		height: calc(100% - 135px);
		padding-bottom: 20px;
	}

	.mobile__cart.show--cart.expand__cart .dropdown__content {
		top: 95px;
		z-index: 2;
		box-shadow: 0px 20px 81px 20px rgba(0, 0, 0, 0.19);
	}

	.mobile__cart .saved-cards--mobile {
		display: block;
	}

	.saved-cards--mobile {
		width: 100%;
		margin: 15px 0px 0px 0px;
	}

	.saved-cards--mobile .dropdown__trigger {
		border-radius: 0px;
	}

	.info__navigation--left {
		width: 100%;
	}

	.settings__avatar__image-holder {
		float: left;
	}

	.settings__avatar h2,
	.settings__avatar a {
		float: left;
		margin-left: 20px;
		width: calc(100% - 120px)
	}

	.settings__content--right {
		width: 100%;
		margin: 30px 0px 0px 0px;
	}

	.settings__content--block p {
		width: 100%;
		flex-wrap: wrap;
	}

	.switch__holder .switch {
		width: 43px !important;
	}

	.switch__holder slider.round {
		width: 43px;
	}

	#settings--basic-info .label__info {
		width: 200px;
		margin: 0px 0px 0px 0px;
	}

	.settings-children-container .settings__content--main p .label__info-src {
		font-size: 16px;
		font-weight: bold;
	}

	.settings__content--block .settings__content--main .button-holder .button__ghost--primary {
		margin: 0px 0px 0px 30px;
	}

	.settings-children-container .settings__content--main p .label__info-src {
		width: calc(100% - 200px);
	}

	#settings--basic-info .file-upload__holder .label__info {
		width: 200px;
	}

	.settings__content--block p .label__input {
		width: 48%;
		max-width: 100%;
	}

	.settings__content--main .file-upload__holder {
		margin-top: 15px;
		flex-wrap: wrap;
	}

	.settings__content--main .file-upload__holder .label__info {
		width: 100%;
		margin: 0px 0px 5px 0px;
	}

	.settings__content-block--actions {
		display: flex;
		justify-content: space-between;
	}

	.settings__content-block--actions button {
		margin: 0px;
		width: 100%;
		text-align: center;
	}

	.file-upload__button-container label {
		float: left;
		width: auto !important;
		margin: 0px !important;
	}

	.file-upload__button-container {
		width: calc(100% - 200px);
	}

	.settings__content--block {
		margin: 0px 0px 20px 0px;
	}

	.settings__content-block--actions {
		position: fixed;
		z-index: 1;
		bottom: 0;
		width: 100%;
		box-sizing: border-box;
		padding: 20px;
		left: 0;
		background: #f5f6fa;
	}

	.datepicker--mobile {
		display: flex !important;
		width: 100%;
		justify-content: space-between;
	}

	.datepicker--mobile p {
		width: 100%;
		margin: 0px;
	}

	.datepicker--mobile .date__input {
		width: 100%;
	}

	#statistics .component__header {
		padding: 0px;
	}

	.settings__content--block.statistics {
		margin: 0px;
	}

	.order-history__wrapper .datepicker {
		width: 100%;
	}

	.order-history__wrapper .datepicker:first-child {
		margin: 0px 0px 10px 0px;
	}

	.datepicker.datepicker--mobile.statistics-datepicker {
		width: 49%;
	}

	.search--mobile {
		position: fixed;
		left: 0;
		top: 61px;
		background: #fff;
		width: 100%;
		transform: translate3d(0px, -61px, 0px);
		box-shadow: none;
		visibility: visible;
		opacity: 0;
		transition: all 0.2s ease-in-out;
	}

	.search--mobile.show--search {
		box-shadow: 0 28px 26px 0 #0000001a;
		visibility: visible;
		opacity: 1;
		transform: translate3d(0px, 0px, 0px);
	}

	.search--mobile input {
		width: 100%;
		height: 61px;
		border: 0px;
		font-size: 18px;
		color: #2F3640;
		box-sizing: border-box;
		padding: 0px 50px 0px 20px;
	}

	.search--mobile input::-webkit-input-placeholder {
		color: #B2BEC3;
		font-size: 18px;
		transition: all 0.2s ease-in-out;
	}

	.search--mobile input::-moz-placeholder {
		color: #B2BEC3;
		font-size: 18px;
	}

	.search--mobile input:-ms-input-placeholder {
		color: #B2BEC3;
		font-size: 18px;
	}

	.search--mobile input:-moz-placeholder {
		color: #B2BEC3;
		font-size: 18px;
	}

	.mobile__header-search--goback {
		background: url("../images/back_icon.svg");
		width: 15px;
		height: 15px;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 20px;
		margin: auto;
	}

	.mobile__header-search--delete {
		background: url("../images/delete_icon.svg");
		width: 15px;
		height: 15px;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 20px;
		margin: auto;
		display: none;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .mobile__header-search--delete {
		right: 10px;
	}

	.mobile__header-search--delete.show--delete {
		display: block;
	}

	#payment__popup .product__popup-container {
		padding: 0px;
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
		transform: scale(1);
		background: #F5F6FA;
	}

	#payment__popup .product__popup-container .product__popup-main {
		flex-wrap: wrap;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}

	#payment__popup .product__popup-container .product__popup-main::-webkit-scrollbar {
		display: none;
	}

	#payment__popup .product__popup-container .product__popup-main::-webkit-scrollbar-track {
		display: none;
	}

	#payment__popup .product__popup-container .product__popup-main::-webkit-scrollbar-thumb {
		display: none;
	}

	.transaction-popup--block {
		width: 100%;
	}

	.transaction-popup--block.transaction__register-helper {
		padding-top: 0px;
	}

	.transaction-processing h2 {
		margin-bottom: 0px;
	}

	.transaction__register-helper .insider {
		background: #fff;
		padding: 30px 0px;
	}

	.transaction__register-helper .insider div {
		width: 80%;
	}

	.transaction-popup--block p {
		flex-wrap: wrap;
	}

	.transaction-popup--block p label {
		width: 100%;
		margin: 0px 0px 10px 0px;
	}

	.transaction__register-helper .insider p .label__input {
		width: 100%;
	}

	.confirmation__popup .product__popup-container {
		width: 90%;
	}

	#confirmation__popup .popup__buttons {
		justify-content: space-between;
		padding: 0px 25px 25px 25px;
	}

	#confirmation__popup .popup__buttons button {
		width: 48%;
		text-align: center;
		margin: 0px;
	}

	#toast {
		box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.1);
		width: 100%;
		max-width: 100%;
		left: 0;
		bottom: 0;
		border-radius: 0px;
	}

	.cart--number-icon {
		display: flex;
		align-items: center;
		margin: 0px 0px 0px 20px;
		position: relative;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .cart--number-icon {
		margin: 0px 0px 0px 20px;
	}

	.cart--number-icon span {
		margin: 0px 0px 0px 2px;
		width: 20px;
		height: 20px;
		display: flex;
		justify-content: center;
		background: #f9583a;
		color: #fff;
		border-radius: 50%;
		line-height: 20px;
		font-size: 12px;
		position: absolute;
		top: -10px;
		right: -9px;
		font-family: sans-serif;
		font-weight: 400;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .cart--number-icon span {
		right: -4px;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .cart--number-icon {
		margin: 0px 0px 0px 5px;
	}

	.icon__counter--round {
		width: 20px;
		height: 20px;
		line-height: 20px;
		box-shadow: 3px 3px 4px 0 #0000001a;
		background-color: #fa583a;
		font-size: 11px;
		font-weight: bold;
		color: #ffffff;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		position: absolute;
		top: -8px;
		right: -3px;
		font-family: sans-serif;
		font-weight: 400;
	}

	_::-webkit-full-page-media,
	_:future,
	:root .icon__counter--round {
		right: 6px;
		top: -9px;
	}

	.status__item .status__item--circle {
		width: 80px;
		height: 80px;
	}

	.status__item .status__item--circle span {
		width: 30px;
		height: 30px;
	}

	#favorites .component__header {
		padding: 20px 10px;
	}

	#favorites .component__content {
		margin-top: 0px;
	}

	.dropdown__content {
		-webkit-overflow-scrolling: touch;
	}

	#settings #footer {
		box-sizing: border-box;
		padding: 0px 15px;
		margin-bottom: 90px;
	}

	#footer {
		box-sizing: border-box;
		padding: 0px 15px;
	}

	#favorites #footer {
		margin-top: 30px;
	}

	#payment__popup .input__holders--col.payment-item--mobile .label__input {
		max-width: 48% !important;
		width: 48% !important;
	}

	.collapsible__content .input__holders--col {
		flex-wrap: wrap;
	}

	#payment__popup .collapsibles .rc-collapse-content .input__holders--col label {
		width: 100%;
		max-width: 100%;
	}

	#payment__popup .collapsibles .rc-collapse-content .input__holders--col .label__input {
		width: 48%;
		max-width: 100%;
		margin: 10px 0px 0px 0px;
	}

}