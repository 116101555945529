.checkout-popup {
    /* max-width: 960px;
    max-height: 580px;
    width: 100%;
    height: 100%; */
    width: 960px;
    height: 580px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 13px 0 rgb(0 0 0 / 10%);
    overflow: hidden;
    display: flex;
    box-sizing: border-box
}

    .checkout-popup__left {
        min-width: 300px;
        height: 100%;
        background-color: #f5f6fa;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }

        .checkout-popup__left__header {
            display: flex;
            padding: 15px 20px 15px 20px;
        }
    
            .checkout-popup__left__header .header-title {
                font-size: 24px;
                color: #2F3640;
                margin-right: auto;
            }

            .checkout-popup__left__header .item-count {
                display: inline-block;
                background-color: #fa583a;
                font-size: 18px;
                font-weight: bold;
                color: #ffffff;
                padding: 5px;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                text-align: center;
                line-height: 16px;
            }

        .checkout-popup__left__products-container {
            width: 100%;
            flex-grow: 1;
            overflow-y:scroll;
        }

        .checkout-popup__left__total {
            width: 100%;
            min-height: 70px;
            padding: 0 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            background-color: #E5E9EE;
        }

            .checkout-popup__left__total__label {
                font-size: 22px;
                color: #636E72;
                display: inline-block;
                margin-right: auto;
            }
            
            .checkout-popup__left__total__value {
                font-size: 22px;
                color: #636E72;
                font-weight: bold;
                display: inline-block;
            }
            
    .checkout-popup__right {
        flex-grow: 1;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
    
        .checkout-popup__right__header {
            padding: 15px 20px;
            width: 100%;
            display: flex;
            box-sizing: border-box;
        }
    
            .checkout-popup__right__header .header-title {
                font-size: 24px;
                color: #636E72;
                margin-right: auto;
            }

            .checkout-popup__right__header .close {
                    display: inline-block;
                    background: url(../../../assets/images/x_dark_icon.svg) no-repeat 50%;
                    width: 18px;
                    height: 18px;
                    margin-top: 5px;
            }

        .checkout-popup__right__main-content {
            flex-grow: 1;
        }

        .checkout-popup__right__main-content .half {
            width: 50%
        }

        .checkout-popup__right__footer {
            min-height: 70px;
            padding: 0 20px;
            display: flex;
            align-items: center;
        }

            .checkout-popup__right__footer__btn-previous {
                font-size: 22px;
                color: #B2BEC3;
                display: flex;
                align-items: center;
            }

                .checkout-popup__right__footer__btn-previous img {
                    margin-right: 10px;
                    margin-top: 2px;
                }

            .checkout-popup__right__footer__btn-next {
                font-size: 22px;
                color: #fff;
                background-color: #fa583a;
                display: flex;
                align-items: center;
                padding: 5px 15px;
                border-radius: 5px;
                box-shadow: 2px 2px 3px 0px #00000040;
                font-weight: 500;
            }

                .checkout-popup__right__footer__btn-next img {
                    margin-left: 10px;
                    margin-top: 2px;
                }

            .checkout-popup__right__footer__steps {
                flex-grow: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }

                .checkout-popup__right__footer__steps li {
                    display: block;
                    width: 8px;
                    height: 8px;
                    background-color: #E5E9EE;
                    border-radius: 50%;
                    margin: 3px;
                }

                .checkout-popup__right__footer__steps li.active {
                    background-color: #fa583a;
                }

/* ******************************************************************************** */
/* ***************************** CheckoutProductsList ***************************** */
/* ******************************************************************************** */

.checkout-products-list {
    list-style: none;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
}

    .checkout-products-list__item {
        display: flex;
        padding-bottom: 14px;
        margin-bottom: 14px;
        border-bottom: 1px solid #DFE6E9;
        /* flex-direction: ; */
    }

        .checkout-products-list__item__image {
            width: 57px;
            height: 57px;
            padding: 5px;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 5px;
            box-shadow: 0 0 24px rgba(0, 0, 0, .12);
            display: flex;
            align-items: center;
            justify-content: center;
        }

            .checkout-products-list__item__image img {
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }

        .checkout-products-list__item__text {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
        }

            .checkout-products-list__item__text__name {
                font-weight: bold;
                font-size: 16px;
                color: #2F3640;
            }
            
            .checkout-products-list__item__text__description {
                font-size: 14px;
                color: #B2BEC3;
            }
            
            .checkout-products-list__item__text__quantity-price {
                font-size: 14px;
                font-weight: bold;
                color: #636E72;
            }

                .checkout-products-list__item__text__quantity-price .orange {
                    color: #fa583a;
                }

/* ******************************************************************************** */
/* ***************************** CheckoutPopupStepMain **************************** */
/* ******************************************************************************** */

.workflow-chooser {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    /* justify-content: space-around; */
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    /* flex-wrap: wrap; */
}

    .workflow-chooser__intro {
        margin-bottom: 40px;
        width: 100%;
        color: #636E72;
    }

    .workflow-chooser-box__list {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
    }

        .workflow-chooser-box {
            width: 180px;
            height: 170px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #F5F6FA;
            cursor: pointer;
            transition: all .15s ease-in-out;
            border: 1px solid rgba(0, 0, 0, .1);
            position: relative;
            /* margin-top: 20px; */
        }

        .workflow-chooser-box.soon::after {
            content: 'наскоро';
            display: inline-block;
            top: 10px;
            right: 10px;
            background-color: #fa583a;
            color: #fff;
            font-weight: bold;
            height: 20px;
            border-radius: 10px;
            padding: 0 7px;
            position: absolute;
        }

        .workflow-chooser-box:not(.disabled):hover {
            background-image: none;
            border: 1px solid #B2BEC3;
            background-color: #fff;
            box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
        }

        .workflow-chooser-box:not(.disabled):hover:active {
            border-color: #fa583a;
        }

            .workflow-chooser-box__icon {
                margin: 30px 0;
                height: 50px;
                filter: saturate(0);
                opacity: .3;
                transition: all .15s ease-in-out;
                pointer-events: none;
            }

            .workflow-chooser-box:not(.disabled):hover .workflow-chooser-box__icon {
                filter: saturate(1);
                opacity: 1;
            }

            .workflow-chooser-box__label {
                color: #B9BABD;
                transition: all .15s ease-in-out;
                font-size: 16px;
                pointer-events: none;
            }
            
            .workflow-chooser-box:not(.disabled):hover .workflow-chooser-box__label {
                color: #636E72;
                font-weight: bold;
            }

    .workflow-chooser__tooltip {
        width: 100%;
        background-color: #fff;
        border: 1px solid #DFE6E9;
        border-radius: 5px;
        padding: 20px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, .05);
        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        margin-top: 40px;
        /* opacity: 0; */
        transition: all .15s ease-in-out;
        height: 70px;
    }

    .workflow-chooser__tooltip.default {
        background-color: #f5f6fa;
        box-shadow: none;
        border-color: rgba(0, 0, 0, .05);
    }

    /* .workflow-chooser__tooltip.show {
        opacity: 1;
    } */

    .workflow-chooser__tooltip::before {
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 13px solid #DFE6E9;
        display: block;
        position: absolute;
        content: '';
        top: -13px;
        transition: all .3s ease-in-out;
        /* left: calc(100% / 3 / 2 - 10px); */
    }
    

    .workflow-chooser__tooltip::after {
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 13px solid #fff;
        display: block;
        position: absolute;
        content: '';
        top: -11px;
        transition: all .3s ease-in-out;
        
    }

    .workflow-chooser__tooltip.self::before,
    .workflow-chooser__tooltip.self::after {
        left: calc(100% / 3 / 2 - 10px);
    }

    .workflow-chooser__tooltip.other-person::before,
    .workflow-chooser__tooltip.other-person::after {
        left: calc(100% / 2 - 10px);
    }

    .workflow-chooser__tooltip.company::before,
    .workflow-chooser__tooltip.company::after {
        /* right: calc(100% / 3 / 2 - 10px); */
        left: calc(100% / 3 * 2 + 100% / 3 / 2 - 10px);
    }
    
    .workflow-chooser__tooltip.default::before,
    .workflow-chooser__tooltip.default::after {
        left: calc(100% / 3 / 2 - 10px);
        opacity: 0;
    }

        .workflow-chooser__tooltip__icon {
            width: 21px;
            height: 21px;
            margin-right: 15px;
        }

        .workflow-chooser__tooltip__content {
            font-size: 16px;
            color: #636E72;
        }

        .workflow-chooser__tooltip__content.default,
        .workflow-chooser__tooltip__content.self,
        .workflow-chooser__tooltip__content.other-person,
        .workflow-chooser__tooltip__content.company {
            display: none;
        }

        .workflow-chooser__tooltip.default .workflow-chooser__tooltip__content.default,
        .workflow-chooser__tooltip.self .workflow-chooser__tooltip__content.self,
        .workflow-chooser__tooltip.other-person .workflow-chooser__tooltip__content.other-person,
        .workflow-chooser__tooltip.company .workflow-chooser__tooltip__content.company {
            display: block
        }

        .workflow-step__info-container {
            width: 100%;
            border-radius: 5px;
            background-color: #f5f6fa;
            color: #636E72;
            font-size: 14px;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            align-self: flex-start; 
            max-width: 250px;
            position: relative;
            border: 1px solid #fa583a;
            margin: 0;
        }

            .workflow-step__info-container::before {
                content: 'i';
                display: block;
                background-color: #636E72;
                /* background-color: #fa583a; */
                width: 30px;
                height: 30px;
                position: absolute;
                border-radius: 50%;
                color: #fff;
                text-align: center;
                font-weight: 900;
                font-size: 18px;
                top: -12px;
                left: -12px;
                line-height: 30px;
                font-family: serif;
                box-shadow: 0 5px 5px rgba(0, 0, 0, .1);
            }

        .workflow-step__form-container {
            /* width: 100%; */
            flex-grow: 1;
        }

        .restricted-width-input {
            max-width: 280px
        }

/* ******************************************************************************** */
/* ************************* CheckoutPopupStepPersonalInfo ************************ */
/* ******************************************************************************** */

.workflow-step__personal-info {
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    gap: 20px;
}

/* ******************************************************************************** */
/* *********************** CheckoutPopupStepDeliveryLocation ********************** */
/* ******************************************************************************** */

.workflow-step__delivery-location {
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    display: flex;
    gap: 20px;
}

    .workflow-step__delivery-location__city-region {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .workflow-step__delivery-location__appartment {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

/* ******************************************************************************** */
/* *********************** CheckoutPopupStepDeliveryDatetime ********************** */
/* ******************************************************************************** */

.workflow-step__delivery-datetime {
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    display: flex;
    gap: 20px;
}

/* ******************************************************************************** */
/* ************************* CheckoutPopupStepRecieverInfo ************************ */
/* ******************************************************************************** */

.workflow-step__reciever-info {
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

/* ******************************************************************************** */
/* ************************* CheckoutPopupStepCompanyInfo ************************ */
/* ******************************************************************************** */

.workflow-step__company-info {
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

        .workflow-step__company-info__id-numbers {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }

        .workflow-step__company-bank {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }

/* ******************************************************************************** */
/* ************************* CheckoutPopupStepPaymentMethod *********************** */
/* ******************************************************************************** */

.workflow-step__payment-method {
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-grow: 1;
    height: 100%;
    /* justify-content: center;
    align-items: center; */
}

    .workflow-step__payment-method__option {
        width: 100%;
        display: flex;
        padding: 15px;
        border: 1px solid #c1c1c1;
        border-radius: 5px;
        box-sizing: border-box;
        margin-bottom: 10px;
        cursor: pointer;
        transition: all .3s ease-in-out;
        background-color: #f5f6fa;
    }

    .workflow-step__payment-method__option:hover {
        /* border-color: #636E72; */
        background-color: #fff;
    }

    .workflow-step__payment-method input[type="radio"]:checked + .workflow-step__payment-method__option {
        border-color: #fa583a;
        background-color: #fff;
    }

        .workflow-step__payment-method__option img {
            max-width: 60px;
            max-height: 60px;
            width: 200px;
            margin-right: 15px;
        }

        .workflow-step__payment-method__text {
            display: flex;
            flex-direction: column;
        }

            .workflow-step__payment-method__text__top {
                font-weight: bold;
                color: #2F3640;
                transition: all .3s ease-in-out;
            }
            
            .workflow-step__payment-method__option:hover .workflow-step__payment-method__text__top {
                color: #fa583a;
            }

            .workflow-step__payment-method__text__bottom {
                font-size: 14px;
                color: #636E72;
            }

    /* .workflow-step__payment-method__option {
        width: 150px;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

        .workflow-step__payment-method__option img {
            flex-grow: 1;
            width: 60px;
            max-height: 60px;
            margin-bottom: 30px;
        } */