.form-select {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;
    width: 100%;
}

    .form-select__input-label {
        font-size: 16px;
        font-weight: bold;
        color: #90989A;
        margin-bottom: 6px;
    }

    .form-select__input-field {
        width: 100%;
	    height: 40px;
        background: #f5f6fa;
        border: 1px solid #c1c1c1;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 0 15px;
        font-size: 16px;
        font-weight: 400;
        color: #636e72;
        margin: 10px 0 0;
        transition: all .2s ease-in-out;
        background-image: url(../../../assets/images/chevron_down.svg);
        background-repeat: no-repeat,repeat;
        -webkit-appearance: none;
        appearance: none;
        background-position: right 0.7em top 50%,0 0;
        background-size: 0.65em auto,100%;
    }

    .form-select__input-field:focus {
        color: #636e72;
        border-color: #636e72;
        box-shadow: inset 0px 0px 10px rgba(0, 0, 0, .02);
    }

    .form-select.error .form-select__input-field {
        border: 1px solid red;
        box-shadow: 0px 0px 10px rgba(255, 0, 0, .3);
    }

    .form-select.error {
        
    }

    .form-select.error::before {
        content: '!';
        display: block;
        background-color: red;
        color: #fff;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        line-height: 17px;
        position: absolute;
        right: 5px;
        top: 2px;
    }

    .form-select.error .form-select__input-label {
        color: red;
    }